import { useAppDispatch } from '../../../common/store/hooks';
import { DashboardLabels } from '../../../common/labels/dashboard.labels';
import ThisIsMeComponent from '../this-is-me-component/this-is-me-component';
import IHaveComponent from '../i-have-component/i-have-component';
import IWantComponent from '../i-want-component/i-want-component';
import ICanComponent from '../i-can-component/i-can-component';
import ResultsComponent from '../results-component/results-component';
import { ErrorBoundaryComponent, WidgetComponent } from '@flexfront/ui/react';
import './dashboard.scss';
import { GridLoader } from 'react-spinners';
import ConfigComponentList from '../../../common/components/config-component-list/config-component-list';
import LogoutComponent from '../../../common/components/logout-component/logout-component';
import ConfigComponent from '../../../common/components/config-component/config-component';
import InfoModalComponent from '../info-modal-component/info-modal-component';
import RetirementChartHeaderComponent from '../retirement-chart-header-component/retirement-chart-header-component';
import CtaComponent from '../cta-component/cta-component';
import { NotificationComponent } from '../notification-component/notification.component';
import { removeErrorMessage, removeInfoMessage } from '../../../common/store/notifications/notifications.slice';
import DebugComponent from '../../../common/components/debug-component/debug-component';
import { NudgeComponent } from '../nudge-component/nudge-component';
import TimelineContainerComponent from '../timeline-container-component/timeline-container-component';
import TimelineRangeComponent from '../timeline-range-component/timeline-range-component';
import TimelineMarkerComponent from '../timeline-marker-component/timeline-marker-component';
import LandingModalComponent from '../landing-modal-component/landingModalComponent';
import { CtaConfirmComponent } from '../cta-confirm-component/cta-confirm-component';
import { fromCtaAndPersonaLabels } from '../../../common/labels/cta-component.labels';
import CtaModalComponent from '../cta-modal-component/cta-modal-component';
import { CtaConfirmModalComponent } from '../cta-confirm-modal-component/cta-confirm-modal-component';
import RetirementChartWrapperComponent from '../retirement-chart-component/retirement-chart-wrapper-component';
import { PersonalInfoProvider } from '../../../common/providers/personal-info-provider';
import { useDashboardContext } from '../../../common/providers/dashboard-provider';
import DashboardCompact from '../dashboard-compact/dashboard-compact';
import { GoalsProvider } from '../../../common/providers/goals-provider';
import { AssetsProvider } from '../../../common/providers/assets-provider';
import { SavingsProvider } from '../../../common/providers/savings-provider';
import { ReportComponentLabels } from '../report-component/report-component.labels';
import { NudgeProvider } from '../../../common/providers/nudge-provider';
import { ResultProvider } from '../../../common/providers/result-provider';
import { ChartHeaderProvider } from '../../../common/providers/chart-header-provider';
import { LogoComponent } from '../logo-component/logo-component';
import { DownloadReportComponent } from '../download-report-component/download-report-component';

export interface DashboardProps {
  labels: DashboardLabels;
  reportLabels: ReportComponentLabels;
}

export function Dashboard() {

  const dispatch = useAppDispatch();

  const {
    labels,
    isHorizontallyCompact,
    isInsideIframe,
    currentDate,
    showModal,
    listParam,
    configParam,
    loaderColor,
    resultsIcon,
    ctaIsOpen,
    ctaConfirmIsOpen,
    isLogoAvailable,

    iWantOrder,
    iHaveOrder,
    iCanOrder,
    thisIsMeOrder,

    landingModalLabels,
    thisIsMeLabels,
    iWantLabels,
    ihaveLabels,
    iCanLabels,
    resultLabels,
    nudgeLabels,
    disclaimerLabels,
    infoLabels,
    ctaLabels,
    ctaConfirmLabels,
    persona,
    menuLabels,

    auth,
    goals,
    simulation,
    assets,
    userSettings,
    notifications,
    tenantConfig,

    closeInfoModal,
    debounceSimulationUpdate,
    getQuarterSpanFromString,
    getQuarterFromYear,
    getQuarterSpanFromCurrentDate,
    onCtaSubmit,
    onCtaConfirmClose,

    isReportDownloading,
    onReportDowloading
  } = useDashboardContext();

  const dashboardLayout = isHorizontallyCompact ? (
    <DashboardCompact />
  ) : (
    <>
      {showModal ? (
        <LandingModalComponent
          labels={landingModalLabels}
          isOpen={showModal}
          closePopup={closeInfoModal}
          confirm={closeInfoModal}
        />
      ) : null}
      {isLogoAvailable ? 
        <div className="dashboard__header">
          <LogoComponent />
        </div>
      : null}
      <div className={`dashboard__personal dashboard__widget-${thisIsMeOrder ?? 0}`} style={{gridArea: `widget_${thisIsMeOrder ?? 0}`}}>
        <ErrorBoundaryComponent>
        <PersonalInfoProvider
          labels={thisIsMeLabels}
          onRequiresUpdate={debounceSimulationUpdate}
          tabOrder={thisIsMeOrder ?? 0}>
            <ThisIsMeComponent />
        </PersonalInfoProvider>
        </ErrorBoundaryComponent>
      </div>
      <div className={`dashboard__goals dashboard__widget-${iWantOrder ?? 1}`} style={{gridArea: `widget_${iWantOrder ?? 1}`}}>
        <ErrorBoundaryComponent>
          <GoalsProvider
            labels={iWantLabels}
            onRequiresUpdate={debounceSimulationUpdate}
            tabOrder={iWantOrder ?? 1}>
            <IWantComponent />
          </GoalsProvider>
        </ErrorBoundaryComponent>
      </div>
      <div className={`dashboard__assets dashboard__widget-${iHaveOrder ?? 2}`} style={{gridArea: `widget_${iHaveOrder ?? 2}`}}>
        <ErrorBoundaryComponent>
          <AssetsProvider
            labels={ihaveLabels}
            onRequiresUpdate={debounceSimulationUpdate}
            tabOrder={iHaveOrder ?? 2}>
              <IHaveComponent />
          </AssetsProvider>
        </ErrorBoundaryComponent>
      </div>
      <div className={`dashboard__savings dashboard__widget-${iCanOrder ?? 3}`} style={{gridArea: `widget_${iCanOrder ?? 3}`}}>
        <ErrorBoundaryComponent>
          <SavingsProvider
            labels={iCanLabels}
            onRequiresUpdate={debounceSimulationUpdate}
            tabOrder={iCanOrder ?? 3}>
              <ICanComponent />
          </SavingsProvider>
        </ErrorBoundaryComponent>
      </div>
      <div className="dashboard__chart">
        <ErrorBoundaryComponent>
          <div>
            <ChartHeaderProvider 
              labels={{
                TITLE: labels.RETIREMENT_CHART.TITLE,
                MENU: menuLabels,
                CLIMATE_SCENARIO: labels.CLIMATE_SCENARIO,
                INFLATION: {
                  ...iWantLabels,
                  OPEN_BUTTON: labels.INFLATION?.OPEN_BUTTON,
                  TITLE: labels.INFLATION?.TITLE,
                  DESC: labels.INFLATION?.DESC,
                  SELECTION: labels.INFLATION?.SELECTION,
                  EMPTY: labels.INFLATION?.EMPTY,
                },
              }}
              onRequiresUpdate={debounceSimulationUpdate}>
                <RetirementChartHeaderComponent />
            </ChartHeaderProvider>
            
          </div>
          <RetirementChartWrapperComponent labels={labels.RETIREMENT_CHART} onRequiresUpdate={debounceSimulationUpdate}/>
          <div className="timeline">
            <TimelineContainerComponent isRangeContainer={true}>
              {goals.preserveCapital.amount > 0 && (
                <TimelineRangeComponent
                  name="preserve-capital"
                  className="preserve-capital-timeline"
                  startQuarter={getQuarterSpanFromString(goals.preserveCapital.startDate)}
                  endQuarter={getQuarterSpanFromString(goals.preserveCapital.endDate)}
                />
              )}
              <TimelineRangeComponent
                name="retirement"
                className="retirement-timeline"
                startQuarter={simulation.activeQuarters - 1}
                endQuarter={simulation.totalQuarters}
              />
            </TimelineContainerComponent>
            <TimelineContainerComponent>
              {userSettings.includeIlliquid && assets.realEstate > 0 && (
                <TimelineMarkerComponent name="property" quarter={0} iconClassName="icon-home" order={0} />
              )}
              {userSettings.includeIlliquid && simulation.leverageQuarter <= simulation.totalQuarters && simulation.leverageQuarter > 0 && assets.realEstate > 0 && (
                <TimelineMarkerComponent
                  name="leverage"
                  quarter={simulation.leverageQuarter}
                  iconClassName="icon-leverage"
                  order={2}
                />
              )}
              <TimelineMarkerComponent
                name="retirement"
                rangeName="retirement"
                quarter={simulation.activeQuarters - 1}
                iconClassName="icon-pension"
                order={0}
              />
              {goals.legacy.amount > 0 && (
                <TimelineMarkerComponent
                  name="legacy"
                  quarter={simulation.totalQuarters - 1}
                  iconClassName="icon-legacy"
                  order={1}
                />
              )}
              {goals.dream.amount > 0 && goals.dream.year > currentDate.getFullYear() && (
                <TimelineMarkerComponent
                  name="dream"
                  quarter={getQuarterFromYear(goals.dream.year)}
                  iconClassName="icon-dream"
                  order={3}
                />
              )}
              {goals.preserveCapital.amount > 0 && goals.preserveCapital.startDate && (
                <TimelineMarkerComponent
                  name="preserve-capital"
                  quarter={getQuarterSpanFromCurrentDate(new Date(goals.preserveCapital.startDate))}
                  iconClassName="icon-piggy-bank"
                  order={4}
                />
              )}
              {goals.secureFamily.amount > 0 && goals.secureFamily.year > currentDate.getFullYear() && (
                <TimelineMarkerComponent
                  name="secure-family"
                  quarter={getQuarterFromYear(goals.secureFamily.year)}
                  iconClassName="icon-secure-family"
                  order={5}
                />
              )}
            </TimelineContainerComponent>
          </div>
          <div className="dashboard-disclaimer"
            dangerouslySetInnerHTML={{ __html: disclaimerLabels.TEXT }}>
          </div>
        </ErrorBoundaryComponent>
      </div>
      <div className="dashboard__results">
        <ErrorBoundaryComponent>
            <WidgetComponent className="results-component-widget">
              <div className="widget-title-container">
                {resultsIcon && <span className="widget-title-icon"
                  dangerouslySetInnerHTML={{ __html: resultsIcon ?? '' }}></span>}
                <h3 className="widget-title">{resultLabels.TITLE}</h3>
              </div>
              <div className="results-content">
                <ResultProvider labels={resultLabels}>
                  <ResultsComponent/>
                </ResultProvider>
                <NudgeProvider 
                    labels={nudgeLabels} >
                    <NudgeComponent />
                </NudgeProvider>
              </div>
              <div className="results-footer">
                <DebugComponent />
                {tenantConfig.isReportDownloadEnabled ? <DownloadReportComponent
                        title={menuLabels.DOWNLOAD}
                        reportTitle={menuLabels.REPORT_TITLE}
                        reportErrorMessage={menuLabels.REPORT_ERROR_MESSAGE}
                        reportInfoMessage={menuLabels.REPORT_INFO_MESSAGE.replace('{text}', menuLabels.CTA_TEXT)} 
                        isReportDownloading={isReportDownloading}
                        onReportDowloading={onReportDowloading}
                        isInsideMenu={false} /> : ''}
                <CtaModalComponent
                  labels={fromCtaAndPersonaLabels(ctaLabels, persona)}
                  isOpen={ctaIsOpen}>
                  <CtaComponent
                    labels={fromCtaAndPersonaLabels(ctaLabels, persona)}
                    onSubmit={onCtaSubmit} />
                </CtaModalComponent>
                <CtaConfirmModalComponent
                  labels={ctaConfirmLabels}
                  isOpen={ctaConfirmIsOpen}>
                  <CtaConfirmComponent
                    labels={ctaConfirmLabels}
                    onClose={onCtaConfirmClose}/>
                </CtaConfirmModalComponent>
              </div>
            </WidgetComponent>
        </ErrorBoundaryComponent>
      </div>
      <div className="dashboard__notify">
              <NotificationComponent
                isOpen={notifications.errorMessages.length > 0}
                type="error"
                notification={notifications.errorMessages[0]}
                onClose={(notification) => dispatch(removeErrorMessage(notification?.type))}
              />
              <span></span>
              <NotificationComponent
                isOpen={notifications.warningMessages.length > 0}
                type="warning"
                notification={notifications.warningMessages[0]}
                onClose={(notification) => dispatch(removeInfoMessage(notification?.type))}
              />
              <span></span>
              <NotificationComponent
                isOpen={notifications.infoMessages.length > 0}
                type="inform"
                notification={notifications.infoMessages[0]}
                onClose={(notification) => dispatch(removeInfoMessage(notification?.type))}
              />
        </div>
      {configParam && auth.isLoggedIn && (
        <div className="dashboard__config">
          <LogoutComponent />
          <ConfigComponent />
        </div>
      )}
      {listParam && auth.isLoggedIn && (
        <div className="dashboard__config">
          <LogoutComponent />
          <ConfigComponentList labels={labels.CONFIG_LIST} />
        </div>
      )}
    </>
  );

  return tenantConfig.isLoading ? (
    <GridLoader className="spinner" loading={tenantConfig.isLoading} color={loaderColor} />
  ) : (
    <>
      <div id='dashboard' className={`dashboard ${(isHorizontallyCompact && isInsideIframe) && 'quirion-dashboard--iframe'} ${(configParam || listParam) && auth.isLoggedIn && 'dashboard--config'}  ${(configParam || listParam) && isLogoAvailable && auth.isLoggedIn && 'dashboard--config-with_logo'} ${isLogoAvailable && 'dashboard--with_logo'}`}>
        {dashboardLayout}
      </div>
      <InfoModalComponent labels={infoLabels} />
    </>
  );
}

export default Dashboard;
