import { WidgetComponent } from '@flexfront/ui/react';
import { usePersonalInfoContext } from '../../../common/providers/personal-info-provider';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper/modules";
import { QuirionIconCardComponent } from '../quirion-icon-card-component/quirion-icon-card-component';
import NumberInputComponent from '../../../common/components/number-input-component/number-input-component';
import ToggleComponent from '../../../common/components/toggle-component/toggle-component';
import InfoIcon from '../../assets/images/info.svg';

const ThisIsMeComponent = () => {

  const {
    labels,
    gender,
    genderOptions,
    handleGenderChange,
    ageTabIndex,
    MIN_AGE,
    MAX_AGE,
    age,
    ageIcon,
    genderIcon,
    maleIcon,
    femaleIcon,
    handleAgeChange,
    handleAgeBlur,
    isHorizontallyCompact
  } = usePersonalInfoContext();

  const classNamePrefix = 'quirion';

  const localGenderOptions = genderOptions.map(g => {
      switch (g.value) {
        case "M":
          return { value: g.value, label: maleIcon , tooltip: labels.PERSONAL.MALE_GENDER_TOOTIP , tooltip_position : "bottom center"};
        case "F":
          return { value: g.value, label: femaleIcon , tooltip: labels.PERSONAL.FEMALE_GENDER_TOOLTIP , tooltip_position : "bottom center" };
        default:
          return femaleIcon;
      }
    });
  
  return (
    <>
      <label
        className={`${classNamePrefix}-widget-title`}>
          {labels.TITLE}
      </label>
      <WidgetComponent
        isExpanded={false}
        className={`${classNamePrefix}-widget`}>
        <div className={`${classNamePrefix}-swiper-component`}>
          <Swiper
            enabled={!isHorizontallyCompact}
            spaceBetween={10}
            slidesPerView={2}
            breakpoints={{
              768: {
                slidesPerView: 2,
                pagination: { clickable: true, enabled: true }
              },
              1440: {
                slidesPerView: 3,
                pagination: { clickable: true, enabled: true }
              }
            }}
            pagination={{ clickable: true, enabled: true }}
            modules={[Pagination]}
          >
            <SwiperSlide className={isHorizontallyCompact ? 'swiper-slide--flex' : ''}>
              <QuirionIconCardComponent
                title={labels.PERSONAL.AGE}
                icon={ageIcon}
                inputs={<NumberInputComponent
                  classNamePrefix={classNamePrefix}
                  title={labels.PERSONAL.AGE}
                  tabIndex={ageTabIndex}
                  min={MIN_AGE}
                  max={MAX_AGE}
                  value={age===0?'':age}
                  onChange={(event) => handleAgeChange(event, false, true)}
                  onBlur={handleAgeBlur} />}
                />
            </SwiperSlide>
            <SwiperSlide className={isHorizontallyCompact ? 'swiper-slide--flex' : ''}>
              <QuirionIconCardComponent
                title={labels.PERSONAL.GENDER}
                icon={genderIcon}
                info={<InfoIcon />}
                tooltip={labels.PERSONAL.GENDER_TOOLTIP}
                inputs={<ToggleComponent
                  classNamePrefix={classNamePrefix}
                  onChange={(o) => handleGenderChange(o, true)}
                  title={labels.PERSONAL.GENDER}
                  options={localGenderOptions}
                  value={gender} />}
                />
            </SwiperSlide>
          </Swiper>
        </div>
      </WidgetComponent>
    </>
  );
}

export default ThisIsMeComponent;
