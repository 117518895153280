import { saveDream, saveLegacy, savePreserveCapital, saveRetirement, saveSecureFamily, selectDreamState, selectLegacyState, selectPreserveCapitalState, selectRetirementState, selectSecureFamilyState } from "../../../common/store/goals/goals.slice";
import { useAppDispatch, useAppSelector } from "../../../common/store/hooks";
import RetirementIconCardComponent from "../icon-card-component/retirement-icon-card-component";
import PreserveCapitalIconCardComponent from "../icon-card-component/preserve-capital-icon-card-component";
import IconCardComponent from "../icon-card-component/icon-card-component";
import "./inflation-menu-component.scss";
import { getYearFromString } from "@flexfront/ui/react";
import { calculateLifeSpan } from "../../../common/shared/calculateLifeSpan";
import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from "../../../common/hooks/useTrackEvent";
import { InflationComponentLabels } from "../../../common/labels/inflation-modal-component.labels";
import { useComponentVisibility } from "../../../common/hooks/useComponentVisibility";
import { useState } from "react";

export interface InflationMenuComponentProps {
    labels: InflationComponentLabels;
    onRequiresUpdate?: () => void;
    onClose?: () => void;
}

export function InflationMenuComponent(props: InflationMenuComponentProps) {
    const retirement = useAppSelector(selectRetirementState);
    const legacy = useAppSelector(selectLegacyState);
    const dream = useAppSelector(selectDreamState);
    const secureFamily = useAppSelector(selectSecureFamilyState);
    const preserveCapital = useAppSelector(selectPreserveCapitalState);
    const dispatch = useAppDispatch();

    const [isRetirementSelected, setIsRetirementSelected] = useState<boolean>(!retirement.nominal ?? false);
    const [isDreamSelected, setIsDreamSelected] = useState<boolean>(!dream.nominal ?? false);
    const [isLegacySelected, setIsLegacySelected] = useState<boolean>(!legacy.nominal ?? false);
    const [isPreserveCapitalSelected, setIsPreserveCapitalSelected] = useState<boolean>(!preserveCapital.nominal ?? false);
    const [isSecureFamilySelected, setIsSecureFamilySelected] = useState<boolean>(!secureFamily.nominal ?? false);

    const {currentYear, yearOfDeath} = calculateLifeSpan();
    const trackEvent = useTrackEvent();

    const isDreamHidden = useComponentVisibility("I_WANT_DREAM");
    const isLegacyHidden = useComponentVisibility("I_WANT_LEGACY");
    const isSecureFamilyHidden = useComponentVisibility("I_WANT_SECURE_FAMILY");
    const isPreserveCapitalHidden = useComponentVisibility("I_WANT_PRESERVE_CAPITAL");

    const isRetirementIconHidden = useComponentVisibility("I_WANT_RETIREMENT_ICON");
    const isDreamIconHidden = useComponentVisibility("I_WANT_DREAM_ICON");
    const isLegacyIconHidden = useComponentVisibility("I_WANT_LEGACY_ICON");
    const isPreserveCapitalIconHidden = useComponentVisibility("I_WANT_PRESERVE_CAPITAL_ICON");
    const isSecureFamilyIconHidden = useComponentVisibility("I_WANT_SECURE_FAMILY_ICON");

    const onLegacySelectionChange = (isSelected: boolean) => {
        trackEvent({ category: trackEventCategories.INFLATION, action: trackEventActions.CLICK, name: trackEventNames.LEGACY_PAYOUT, value: +isSelected });
        setIsLegacySelected(!isLegacySelected);
    }

    const onRetirementSelectionChange = (isSelected: boolean) => {
        trackEvent({ category: trackEventCategories.INFLATION, action: trackEventActions.CLICK, name: trackEventNames.RETIREMENT_AGE, value: +isSelected });
        setIsRetirementSelected(!isRetirementSelected);
    }

    const onDreamSelectionChange = (isSelected: boolean) => {
        trackEvent({ category: trackEventCategories.INFLATION, action: trackEventActions.CLICK, name: trackEventNames.DREAM_YEAR, value: +isSelected });
        setIsDreamSelected(!isDreamSelected);
    }

    const onSecureFamilySelectionChange = (isSelected: boolean) => {
        trackEvent({ category: trackEventCategories.INFLATION, action: trackEventActions.CLICK, name: trackEventNames.SECURE_FAMILY_YEAR, value: +isSelected });
        setIsSecureFamilySelected(!isSecureFamilySelected);
    }

    const onPreserveCapitalSelectionChange = (isSelected: boolean) => {
        trackEvent({ category: trackEventCategories.INFLATION, action: trackEventActions.CLICK, name: trackEventNames.PRESERVE_CAPITAL_AMOUNT, value: +isSelected });
        setIsPreserveCapitalSelected(!isPreserveCapitalSelected);
    }

    const confirm = () => {
        trackEvent({ category: trackEventCategories.INFLATION, action: trackEventActions.CLICK, name: trackEventNames.CONFIRM });
        dispatch(saveRetirement({ ...retirement, nominal: !isRetirementSelected }));
        dispatch(saveLegacy({ ...legacy, nominal: !isLegacySelected }));
        dispatch(saveDream({ ...dream, nominal: !isDreamSelected }));
        dispatch(saveSecureFamily({ ...secureFamily, nominal: !isSecureFamilySelected }));
        dispatch(savePreserveCapital({ ...preserveCapital, nominal: !isPreserveCapitalSelected }));
        if (props.onRequiresUpdate) props.onRequiresUpdate();
        if (props.onClose) {
            props.onClose();
        }
    }

    const cancel = () => {
        trackEvent({ category: trackEventCategories.INFLATION, action: trackEventActions.CLICK, name: trackEventNames.CANCEL });

        if (props.onClose) {
            props.onClose();
        }
    }

    return <div className="inflation-menu">
        <span className="inflation-menu-desc" dangerouslySetInnerHTML={{ __html: props.labels.DESC }}></span>
        <span className="inflation-menu-select">{props.labels.SELECTION}</span>
        <div className="inflation-menu-cards">
            <RetirementIconCardComponent
                cardClassName="icon-card--solid icon-card--simple"
                valuePlaceholder={
                    props.labels.GOALS.RETIREMENT.AMOUNT_PLACEHOLDER
                }
                agePlaceholder={props.labels.GOALS.RETIREMENT.AGE_PLACEHOLDER}
                titleLabel={props.labels.GOALS.RETIREMENT.TITLE}
                valueLabel={props.labels.WHEN}
                totalLabel={props.labels.GOALS.RETIREMENT.AMOUNT}
                retirementAge={retirement.retirementAge}
                retirementMonthlyPayout={retirement.retirementMonthlyPayout}
                iconClassName="icon-pension"
                hideIcon={isRetirementIconHidden}
                allowSelection={true}
                isSelected={isRetirementSelected}
                displaySimple={true}
                onSelectionChange={onRetirementSelectionChange}
            />
            {!isLegacyHidden && <IconCardComponent
                cardClassName="icon-card--solid icon-card--simple"
                valueLabel={props.labels.GOALS.LEGACY.TITLE}
                valuePlaceholder={props.labels.GOALS.LEGACY.AMOUNT_PLACEHOLDER}
                totalLabel={props.labels.GOALS.GOAL.AMOUNT}
                iconClassName="icon-legacy"
                hideIcon={isLegacyIconHidden}
                inputNumber={legacy.amount}
                displayNumber={legacy.amount}
                allowSelection={true}
                isSelected={isLegacySelected}
                displaySimple={true}
                onSelectionChange={onLegacySelectionChange}
            />}
            {!isDreamHidden && <RetirementIconCardComponent
                cardClassName="icon-card--solid icon-card--simple"
                titleLabel={props.labels.GOALS.GOAL.TITLE}
                valueLabel={props.labels.WHEN}
                valuePlaceholder={props.labels.GOALS.GOAL.AMOUNT_PLACEHOLDER}
                agePlaceholder={props.labels.GOALS.GOAL.YEAR_PLACEHOLDER}
                totalLabel={props.labels.GOALS.GOAL.AMOUNT}
                retirementAge={dream.year}
                retirementMonthlyPayout={dream.amount}
                iconClassName="icon-dream"
                hideIcon={isDreamIconHidden}
                allowSelection={true}
                isSelected={isDreamSelected}
                displaySimple={true}
                onSelectionChange={onDreamSelectionChange}
            />}
            {!isPreserveCapitalHidden && <PreserveCapitalIconCardComponent
                cardClassName="icon-card--solid icon-card--simple"
                titleLabel={props.labels.GOALS.PRESERVE_CAPITAL?.TITLE}
                valueLabel={props.labels.WHEN}
                valuePlaceholder={props.labels.GOALS.PRESERVE_CAPITAL?.AMOUNT_PLACEHOLDER}
                totalLabel={props.labels.GOALS.PRESERVE_CAPITAL?.AMOUNT}
                iconClassName="icon-piggy-bank"
                hideIcon={isPreserveCapitalIconHidden}
                fromYear={getYearFromString(preserveCapital.startDate) ?? currentYear}
                toYear={getYearFromString(preserveCapital.endDate) ?? yearOfDeath}
                amount={preserveCapital.amount}
                allowSelection={true}
                isSelected={isPreserveCapitalSelected}
                displaySimple={true}
                onSelectionChange={onPreserveCapitalSelectionChange}
            />}
            {!isSecureFamilyHidden && <RetirementIconCardComponent
                cardClassName="icon-card--solid icon-card--simple"
                titleLabel={props.labels.GOALS.SECURE_FAMILY.TITLE}
                valueLabel={props.labels.WHEN}
                valuePlaceholder={props.labels.GOALS.SECURE_FAMILY.AMOUNT_PLACEHOLDER}
                agePlaceholder={props.labels.GOALS.SECURE_FAMILY.YEAR_PLACEHOLDER}
                totalLabel={props.labels.GOALS.SECURE_FAMILY.AMOUNT}
                retirementAge={secureFamily.year}
                retirementMonthlyPayout={secureFamily.amount}
                iconClassName="icon-secure-family"
                hideIcon={isSecureFamilyIconHidden}
                allowSelection={true}
                isSelected={isSecureFamilySelected}
                displaySimple={true}
                onSelectionChange={onSecureFamilySelectionChange}
            />}
        </div>
        <button className="button button--big button--big--solid" onClick={confirm}>
            {props.labels.CONFIRM}
        </button>
        <button className="button button--big" onClick={cancel}>
            {props.labels.CANCEL}
        </button>
    </div>;
}