import { getQuarterDate } from "@flexfront/ui/react";

export function getRetirementSeries(
  y: number,
  label: string,
  totalQuarters: number,
  activeQuarters: number,
  isHorizontallyCompact: boolean,
  plotBandStart?: number,
  animation?: boolean
): Highcharts.SeriesOptionsType[] {

  if (totalQuarters < 1 || activeQuarters < 1) {
    return [];
  }
  
    const currentDate = getQuarterDate(new Date());
    plotBandStart = plotBandStart ?? Date.UTC(
      currentDate.getFullYear(),
      currentDate.getMonth() + (activeQuarters - 2) * 3,
      1
    );

    const plotBandEnd = Date.UTC(
      currentDate.getFullYear(),
      currentDate.getMonth() + (totalQuarters + 1) * 3,
      1
    );

    let quarterDate = getQuarterDate(new Date(plotBandStart));
    let quarterDateUTC = Date.UTC(
      quarterDate.getFullYear(),
      quarterDate.getMonth(),
      1
    );  

    const pensionSeriesData = [];
    do {
      pensionSeriesData.push({
        x:quarterDateUTC,
        y:y
      });
      quarterDate = new Date(quarterDate.setMonth(quarterDate.getMonth() + 3));
      quarterDateUTC = Date.UTC(
        quarterDate.getFullYear(),
        quarterDate.getMonth(),
        1
      );
    } while (quarterDateUTC < plotBandEnd)

    const retirementSeries: Highcharts.SeriesOptionsType = {
      id: "retirementSeries",
      animation: animation,
      name: "retirementSeries",
      type: "line",
      dashStyle: "Solid",
      data: pensionSeriesData,
      pointStart: plotBandStart,
      color: "var(--accent-goal)",
      pointIntervalUnit: "month",
      pointInterval: 3,
      className: "retirement-series",
      lineWidth: 2,
      zIndex: 2,
      stickyTracking: false,
      cursor: 'ew-resize',
      dragDrop: {
        draggableX: true
      },
      states: {
        hover: {
          enabled: false
        }
      },
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false
          },
        },
      },
      dataLabels: {
        enabled: !isHorizontallyCompact,
        verticalAlign: 'middle',
        useHTML: true,
        y: 1.5,
        filter: {
          property: 'x',
          operator: '==',
          value: plotBandStart
        },
        formatter: function (e:any) {
            return `<svg class="retirement-chart-slider" width="24" height="24" fill="none">
              <circle cx="12" cy="12" r="12" fill="var(--accent-goal)" />
              <circle cx="12" cy="12" r="8" fill="var(--accent-goal)" />
              <path d="M6.71716 11.7172C6.56095 11.8734 6.56095 12.1266 6.71716 12.2828L9.26274 14.8284C9.41895 14.9846 9.67222 14.9846 9.82843 14.8284C9.98464 14.6722 9.98464 14.419 9.82843 14.2627L7.56569 12L9.82843 9.73726C9.98464 9.58105 9.98464 9.32778 9.82843 9.17157C9.67222 9.01536 9.41895 9.01536 9.26274 9.17157L6.71716 11.7172ZM17.2828 12.2828C17.4391 12.1266 17.4391 11.8734 17.2828 11.7172L14.7373 9.17157C14.581 9.01536 14.3278 9.01536 14.1716 9.17157C14.0154 9.32778 14.0154 9.58105 14.1716 9.73726L16.4343 12L14.1716 14.2627C14.0154 14.419 14.0154 14.6722 14.1716 14.8284C14.3278 14.9846 14.581 14.9846 14.7373 14.8284L17.2828 12.2828ZM7 12.4H17V11.6H7V12.4Z" fill="white" />
            </svg>`;
        }
      }
    };

    const retirementSeriesLabel: Highcharts.SeriesOptionsType = {
      id: "retirementSeriesLabel",
      animation: animation,
      name: "retirementSeriesLabel",
      type: "line",
      dashStyle: "Solid",
      data: pensionSeriesData,
      pointStart: plotBandStart,
      color: "var(--accent-goal)",
      pointIntervalUnit: "month",
      pointInterval: 3,
      className: "retirement-series-label",
      lineWidth: 0,
      zIndex: 2,
      stickyTracking: false,
      states: {
        hover: {
          enabled: false
        }
      },
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false
          },
        },
      },
      dataLabels: {
        enabled: true,
        align: 'left',
        position: 'right',
        y: -10,
        useHTML: true,
        filter: {
          property: 'x',
          operator: '==',
          value: plotBandStart
        },
        formatter: function () {
          return `<span>${label}</span>`;
        }
      }
    };
  
    return [retirementSeries, retirementSeriesLabel];
}
