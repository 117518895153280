import { useSavingsContext } from "../../../common/providers/savings-provider";
import { getCurrencyFormattedAmount } from "../../../common/shared/locale";

export function ICanReportComponent() {

    const {
        labels,
        currencyConfig,
    
        savings,
        totalSavings
    
      } = useSavingsContext();

    return <div className="report-table report-table--right">

            {/* Headers */}

            <div className="report-row">

                <div className="report-cell report-cell--merged report-cell--header report-cell--title">
                    <label>{labels.TITLE}</label>
                    <label>{labels.AMOUNT}</label>
                </div>

            </div>

            <div className="report-row">

                <div className="report-cell report-cell--merged">
                    <label>{labels.SAVINGS.TOTAL_SAVINGS}</label>
                    <label>
                        {getCurrencyFormattedAmount(totalSavings, currencyConfig)}
                    </label>
                </div>
            </div>

            <div className="report-row">

                <div className="report-cell report-cell--merged">
                    <label>{labels.LUMPSUM}</label>
                    <label>
                        {getCurrencyFormattedAmount(savings.lumpsum, currencyConfig)}
                    </label>
                </div>
            </div>

            <div className="report-row">
                <div className="report-cell report-cell--footer report-cell--title">
                    {labels.SAVINGS.TOTAL_SAVINGS_AMOUNT_INPUT_TITLE}
                </div>
                <div className="report-cell--footer report-cell--title report-cell--value">
                    {getCurrencyFormattedAmount(totalSavings + savings.lumpsum, currencyConfig)}
                </div>
            </div>

        </div>;
}