import { ForwardedRef, ReactNode, forwardRef } from "react";

export interface HiddenContentComponentProps {
    children: ReactNode;
}

export const HiddenContentComponent = forwardRef((props: HiddenContentComponentProps, ref: ForwardedRef<HTMLDivElement>) => {
  return <div
    style={{position: 'absolute', visibility: 'collapse'}}
    ref={ref}>
    {props.children}
  </div>;
})