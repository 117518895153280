import { useEffect, useState } from "react";
import { useAppSelector } from "../store/hooks";
import { selectLayouts } from "../store/layout/layouts.slice";
import { selectTenantConfig } from "../store/tenant-config/tenant-config.slice";

export const useLayoutMaxAchievementFraction = () => {
    const layouts = useAppSelector(selectLayouts);
    const tenantConfig = useAppSelector(selectTenantConfig);

    const getMaxAchievementFraction = () => {
        const layout=layouts.layouts.find((l:any) => l.layoutId==tenantConfig.layoutId);
        
        return layout?.maxAchievementFraction && layout?.maxAchievementFraction != 0 ? layout?.maxAchievementFraction : 1;
    }
    const [maxAchievementFraction, setMaxAchievementFraction] = useState<any>(getMaxAchievementFraction());

    useEffect(() => {
        setMaxAchievementFraction(getMaxAchievementFraction());
    }, [tenantConfig]);
    
   return maxAchievementFraction;
}