import { useEffect, useContext, useRef } from "react";
import { useAppSelector } from "../store/hooks";
import { selectTenantConfig } from "../store/tenant-config/tenant-config.slice";
import { MatomoContext } from "@jonkoops/matomo-tracker-react";

export const useTenantSiteId = () => {
    const tenantConfig = useAppSelector(selectTenantConfig);
    const matomoInstance = useContext(MatomoContext);
    const hasExecuted = useRef(false);

    useEffect(() => {
        if (tenantConfig.siteId && !hasExecuted.current) {
            matomoInstance?.pushInstruction('setSiteId', tenantConfig.siteId);
            setTimeout(() => matomoInstance?.trackPageView(), 300);
            hasExecuted.current = true;
        }
    }, [tenantConfig.siteId, matomoInstance]);
}