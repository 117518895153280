import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

export const SHORTFALL_ELIMINATION_FEATURE_KEY = "shortfall-elimination";

export interface ShortfallEliminationState {
    isShortfallLoading: boolean;
    shortfallEliminationFactor: number;
    shortfallEliminationSavings: number;
}

const initialState: ShortfallEliminationState = {
    isShortfallLoading: false,
    shortfallEliminationFactor: 0,
    shortfallEliminationSavings: 0
}

export const shortfallEliminationSlice = createSlice({
    name: SHORTFALL_ELIMINATION_FEATURE_KEY,
    initialState,
    reducers: {
      setShortfallEliminationSavings: (state, action: PayloadAction<number | undefined>) => {
        state = { ...state, shortfallEliminationSavings: action.payload ?? 0 };
        return state;
      },
      setShortfallEliminationFactor: (state, action: PayloadAction<number | undefined>) => {
        state = { ...state, shortfallEliminationFactor: action.payload ?? 0 };
        return state;
      },
      setShortfallLoading: (state, action: PayloadAction<boolean>) => {
        state = { ...state, isShortfallLoading: action.payload };
        return state;
      },
    }
});

export const { setShortfallEliminationFactor, setShortfallEliminationSavings, setShortfallLoading } = shortfallEliminationSlice.actions;

export const selectShortfallEliminationState = createSelector(
    (state: RootState) => state.shortfallElimination,
    (shortfallElimination) => shortfallElimination
  );
  
  export default shortfallEliminationSlice;