import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../hooks";
import { RootState } from "../store";
import { Assets } from "./assets.state";

export const ASSETS_FEATURE_KEY = "assets";

const initialState: Assets = {
  realEstate: 0,
  equity: 0,
  bonds: 0,
  cash: 0,
};

export const assetsSlice = createSlice({
  name: ASSETS_FEATURE_KEY,
  initialState,
  reducers: {
    saveAssets: (state, action: PayloadAction<Assets>) => {
      state = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const { saveAssets } = assetsSlice.actions;

export const selectAssetsState = createSelector(
  (state: RootState) => state.assets,
  (assets) => assets
);

export default assetsSlice;
