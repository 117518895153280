import { useEffect, useState } from "react";
import { useAppSelector } from "../store/hooks";
import { selectTenantConfig } from "../store/tenant-config/tenant-config.slice";

export const useComponentOrder = (componentName: string) => {
    const tenantConfig = useAppSelector(selectTenantConfig);

    const getComponentOrder = () => {
        if (!tenantConfig?.layout?.componentOrder) {
            return undefined;
        }

        const componentOrder = tenantConfig.layout.componentOrder
            .find((cp: any) => cp.component.name?.toLowerCase() === componentName?.toLowerCase());
        return componentOrder?.order;
    };

    const [order, setOrder] = useState<number | undefined>(getComponentOrder());

    useEffect(() => {
        setOrder(getComponentOrder());
    }, [tenantConfig]);

    return order;
}