import { InflationOptionsType } from "@flexfront/models";

const InflationOptions: Map<string, string> = new Map<string, string>([
    ["disable", "Disable Inflation"],
    ["enable_for_all_goals", "Enable Inflation for all Goals"],
    ["enable_per_individual_goal", "Enable Inflation per individual goal"]
])


export function GetInflationOptions(): InflationOptionsType[] {
    const results: InflationOptionsType[] = [];

        InflationOptions.forEach((value, key) => results.push({ TYPE: key, DISPLAY: value }));

    return results;
}