import { CurrencyConfig } from "@flexfront/ui/react";
import { useAppSelector } from "../store/hooks";
import { selectTenantConfig } from "../store/tenant-config/tenant-config.slice";
import { useEffect, useState } from "react";
import { currencies, defaultCurrencyConfig, localeCurrencyFormats } from "../shared/locale";

export function useCurrencyConfig() {
    const tenantConfig = useAppSelector(selectTenantConfig);

    const setCurrentCurrencyConfig = () => {
        const defaultLang = tenantConfig.defaultLang === "" ? undefined : tenantConfig.defaultLang;
        const currentLang = defaultLang ?? "en";
        const currentCurrency = tenantConfig.currency ?? defaultCurrencyConfig.currency;

        let hasSymbolSuffix: boolean | undefined = false;
        const selectedCurrency = currencies.find(c => c.value === currentCurrency);
        if (selectedCurrency) {
            hasSymbolSuffix = selectedCurrency.hasSymbolSuffix;
        }

        let userCountry = "CH";
        if (currentLang === "en") {
            userCountry = "GB";
        }

        if (currentLang === "de") {
            userCountry = "DE";
        }

        const selectedLocaleCurrencyFormatting = localeCurrencyFormats.find(c => c.country === userCountry);

        let localeGroupSeparator: string | undefined = "'";        
        if (selectedLocaleCurrencyFormatting) {
            localeGroupSeparator = selectedLocaleCurrencyFormatting.groupSeparator;
        }

        let localeDecimalSeparator: string | undefined = ".";        
        if (selectedLocaleCurrencyFormatting) {
            localeDecimalSeparator = selectedLocaleCurrencyFormatting.decimalSeparator;
        }

        return {
            locale: `${currentLang}-${userCountry}`,
            currency: currentCurrency,
            groupSeparator: localeGroupSeparator,
            decimalSeparator: localeDecimalSeparator,
            hasSymbolSuffix: hasSymbolSuffix
        };
    };

    const [currencyConfig, setCurrencyConfig] = useState<CurrencyConfig>(setCurrentCurrencyConfig());

    useEffect(() => {
        setCurrencyConfig(setCurrentCurrencyConfig());
    }, [tenantConfig]);

    return currencyConfig;
}