import { useEffect, useState } from "react";
import { useAppSelector } from "../store/hooks";
import { selectTenantConfig } from "../store/tenant-config/tenant-config.slice";

export const useComponentIcon = (componentName: string) => {
    const tenantConfig = useAppSelector(selectTenantConfig);

    const getComponentIcon = () => {
        if (!tenantConfig?.layout?.componentIcons) {
            return undefined;
        }

        const componentLayout = tenantConfig.layout.componentIcons
            .find((cp: any) => cp.component.name?.toLowerCase() === componentName?.toLowerCase());
        return componentLayout?.icon;
    };

    const [icon, setIcon] = useState<string | undefined>(getComponentIcon());

    useEffect(() => {
        setIcon(getComponentIcon());
    }, [tenantConfig]);

    return icon;
}