import { useLabelsContext } from '../../providers/labels-provider';

/* eslint-disable-next-line */
export interface NotFoundComponentProps {
  className?: string;
}

export function NotFoundComponent(props: NotFoundComponentProps) {
  const { labels } = useLabelsContext()
  return (
    <div className={props.className}>
      <h1>{labels?.NOT_FOUND.TITLE}</h1>
    </div>
  );
}

export default NotFoundComponent;
