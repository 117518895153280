import { Children, ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import { FlyoutMenuItemComponentProps } from "./flyout-menu-item-component";
import "./flyout-menu-component.scss";
import { useIsSafariMobile } from "../../../common/hooks/useIsSafariMobile";
import { useIsInIframe } from "../../../common/hooks/useIsInIframe";

export interface FlyoutMenuComponentProps {
    isOpen?: boolean;
    title: string;
    header: ReactNode;
    menuItems: ReactNode;
    footer: ReactNode;
    onClose?: () => void;
}

export function FlyoutMenuComponent(props: FlyoutMenuComponentProps) {
    const [isOpen, setIsOpen] = useState<boolean>(props.isOpen ?? false);
    const [selectedItem, setSelectedItem] = useState<ReactNode>();
    const flyoutNestedContentRef = useRef<HTMLDivElement>(null);

    const isSafariMobile = useIsSafariMobile();
    const isInIframe = useIsInIframe();

    const close = () => {
        setIsOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };

    const getSelectedItemContent = (selectedItem: ReactNode) => {
        
        const selectedFlyoutMenuItem = ((selectedItem as ReactElement)?.props as FlyoutMenuItemComponentProps);
        if (selectedFlyoutMenuItem) {
            return <>
                <div className="flyout-menu-flyout-content-header">
                    <span className="flyout-menu-flyout-content-back icon-arrow-left" onClick={() => setSelectedItem(null)}></span>
                    <span className="flyout-menu-flyout-content-title">{selectedFlyoutMenuItem.title}</span>
                </div>
                <hr></hr>
                <div ref={flyoutNestedContentRef} className="flyout-menu-flyout-content-nested">
                    {selectedFlyoutMenuItem.nestedContent}
                </div>
            </>;
        }
        return null;
    }

    useEffect(() => {
        const adjustment = isSafariMobile ? 0.6 : 0.99;
        if (flyoutNestedContentRef.current) {
            const elementRect = flyoutNestedContentRef.current.getBoundingClientRect();
            const availableSpace = window.innerHeight - elementRect.top;

            if (isInIframe) {
                let elementHeightDiff = 0;
                if (elementRect.height > availableSpace) {
                    elementHeightDiff = elementRect.height - availableSpace;
                }
                const nestedContentHeight = (window.innerHeight - elementHeightDiff) * adjustment;
                flyoutNestedContentRef.current.style.height = `${nestedContentHeight}px`;
            } else if (isSafariMobile) {
                flyoutNestedContentRef.current.style.height = `${(availableSpace * 0.8)}px`;
            } else {
                let elementHeightRatio = 100;
                if (elementRect.height > availableSpace) {
                    elementHeightRatio = (availableSpace / elementRect.height) * 100;
                }
                flyoutNestedContentRef.current.style.height = `${elementHeightRatio}%`;
            }
        }
    }, [selectedItem]);

    const hasContent = (selectedItem: ReactNode) => {
        return ((selectedItem as ReactElement)?.props as FlyoutMenuItemComponentProps)?.nestedContent !== undefined;
    }

    useEffect(() => {
        setIsOpen(props.isOpen ?? false);
        if (!props.isOpen) {
            setSelectedItem(null);
        }
    }, [props.isOpen]);

    const flyoutMenuItems = Children.toArray(props.menuItems);
    
    return <div className="flyout-menu">
        {props.header}
        <div className={`flyout-menu-flyout ${isOpen && 'flyout-menu-flyout--open'}`}>
            <div className="flyout-menu-flyout-header">
                <span className="flyout-menu-flyout-title">{props.title}</span>
                <span className="flyout-menu-flyout-close button-close icon-times" onClick={close}/>
            </div>
            <div className="flyout-menu-flyout-content">
                {!selectedItem && flyoutMenuItems.map((c, i) => {
                    if (hasContent(c)) {
                        return <div key={i} onClick={() => setSelectedItem(c)}>{c}</div>;    
                    }
                    return <div key={i}>{c}</div>;
                })}
                {selectedItem && getSelectedItemContent(selectedItem)}
            </div>
            {!selectedItem && <div className="flyout-menu-flyout-footer">
                <hr></hr>
                <div onClick={() => setSelectedItem(props.footer)}>{props.footer}</div>
            </div>}
        </div>
    </div>;
}