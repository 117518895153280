import { getQuarterDate } from "@flexfront/ui/react";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../store/hooks";
import { selectSimulationState } from "../store/simulation/simulation.slice";


  export function calculateLifeSpan() : {currentYear:number,yearOfDeath:number} {
    const simulation = useAppSelector(selectSimulationState);
    const currentYear = useMemo(() => new Date(), []).getFullYear();

    const getDateOfDeath = (totalQuarters: number): number => {
    const dateOfDeathDate = getQuarterDate(new Date());
    dateOfDeathDate.setMonth(dateOfDeathDate.getMonth() + (totalQuarters * 3));
    return dateOfDeathDate.getFullYear();
    }

    const [yearOfDeath, setYearOfDeath] = useState<number>(getDateOfDeath(simulation.totalQuarters-1));

    useEffect(() => {
    setYearOfDeath(getDateOfDeath(simulation.totalQuarters-1));
    }, [simulation]);
    return {currentYear,yearOfDeath};
  }