import {
  selectTimelineOrderedQuarterRanges,
  selectTimelineWidthPerQuarter,
  setTimelineQuarterRange,
} from '../../../common/store/timeline/timeline.slice';
import { useAppDispatch, useAppSelector } from '../../../common/store/hooks';
import { useEffect } from 'react';

export interface TimelineRangeComponentProps {
  startQuarter: number;
  endQuarter: number;
  name: string;
  className?: string;
}

export function TimelineRangeComponent(props: TimelineRangeComponentProps) {
  const widthPerQuarter = useAppSelector(selectTimelineWidthPerQuarter);
  const orderedQuarterRanges = useAppSelector(selectTimelineOrderedQuarterRanges);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const quarterRange = props.endQuarter - props.startQuarter;
    dispatch(setTimelineQuarterRange({ name: props.name, quarters: quarterRange }));
  }, [props.name, props.endQuarter, props.startQuarter, dispatch]);

  function getOrder() {
    return orderedQuarterRanges.findIndex((_:any) => _.name === props.name);
  }

  return (
    <div
      id={props.name}
      className={`timeline-range ${props.className ? props.className : ''}`}
      style={{
        width: (props.endQuarter - props.startQuarter) * widthPerQuarter,
        left: props.startQuarter * widthPerQuarter,
        order: getOrder(),
      }}
    />
  );
}

export default TimelineRangeComponent;
