import { TenantConfigState } from "../../../common/store/tenant-config/tenant-config.state";

export interface TenantDetailsReportComponentProps {
    title: string;
    tenantConfig: TenantConfigState;
}
export function TenantDetailsReportComponent(props: TenantDetailsReportComponentProps) {
    return <div className="report-tenant">
        <label>{props.title}</label>
        <br />
        <label>{props.tenantConfig.companyName}</label>
        <label>{props.tenantConfig.address}</label>
        <label>{props.tenantConfig.postalCodeAndCity}</label>
        <br/>
        <label>{props.tenantConfig.website}</label>
    </div>
}