import { Persona } from "../labels/cta-component.labels";
import { useEffect, useState } from "react";
import { DashboardLabels } from "../labels/dashboard.labels";
import { useDomainLayout } from "./useDomainLayout";
import { useParams } from "react-router-dom";

export const usePersona = (dashboardLabels: DashboardLabels): Persona => {
    const { id } = useParams();
    const domainLayout = useDomainLayout();

    const getPersonaFromDomainLayout = () => {
        if (domainLayout) {
            const personaKey = Object.keys(dashboardLabels.DOMAIN_SPECIFIC).find(k => k === domainLayout.translationKey);
            if (personaKey && dashboardLabels.DOMAIN_SPECIFIC[personaKey]) {
                return dashboardLabels.DOMAIN_SPECIFIC[personaKey].PERSONA as Persona;
            }
        }

        return undefined;
    }

    const getPersonaFromId = () => {
        const personaKey = Object.keys(dashboardLabels.DOMAIN_SPECIFIC).find(k => dashboardLabels.DOMAIN_SPECIFIC[k].PERSONA.ID === id);
        if (personaKey && dashboardLabels.DOMAIN_SPECIFIC[personaKey]) {
            return dashboardLabels.DOMAIN_SPECIFIC[personaKey].PERSONA as Persona;
        }

        return undefined;
    }
    
    const getPersona = () => {
        return getPersonaFromDomainLayout()
        || getPersonaFromId()
        || dashboardLabels.CTA.PERSONA as Persona;
    }

    const [persona, setPersona] = useState<Persona>(getPersona());

    useEffect(() => {
        setPersona(getPersona())
    }, [dashboardLabels, domainLayout]);

    return persona;
    
}