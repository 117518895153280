import _ from "lodash";
import moment from "moment";
import { TooltipLabels } from "../../../common/labels/retirement-chart-component.labels";
import { getCurrencyFormattedAmount } from "../../../common/shared/locale";
import { CurrencyConfig } from "@flexfront/ui/react";
import { format as formatDate  } from 'date-fns';

type Series = {
  good: string;
  expected: string;
  adverse: string;
};

const totalGoodSeriesName = "totalWealthGoodRangeSeries";
const totalExpectedSeriesName = "totalWealthExpectedSeries";
const totalAdverseSeriesName = "totalWealthAdverseRangeSeries";

function getRangeSeriesValues(
  points: Highcharts.TooltipFormatterContextObject[],
  expectedSeriesName: string,
  goodRangeSeriesName: string,
  adverseRangeSeriesName: string,
  currencyConfig?: CurrencyConfig
): Series | null {
  const expectedPoint = _.find(points, {
    series: { name: expectedSeriesName },
  });
  const expected = getCurrencyFormattedAmount(expectedPoint?.y ?? 0, currencyConfig);

  const goodRangePoint = _.find(points, {
    series: { name: goodRangeSeriesName },
  });
  const adverseRangePoint = _.find(points, {
    series: { name: adverseRangeSeriesName },
  });
  const good = goodRangePoint
    ? getCurrencyFormattedAmount(goodRangePoint["point"].high ?? 0, currencyConfig)
    : "";
  const adverse = adverseRangePoint
    ? getCurrencyFormattedAmount(adverseRangePoint["point"].low ?? 0, currencyConfig)
    : "";

  if (!goodRangePoint) {
    return null;
  }

  return {
    good: good,
    expected: expected,
    adverse: adverse,
  };
}

function addStandardSeriesHtml(
  tooltipLabels: TooltipLabels,
  isHorizontallyCompact: boolean,
  x: string | number,
  assets: Series | null,
  illiquid: Series | null,
  locale: Locale,
  points: Highcharts.TooltipFormatterContextObject[]
): string {

  const expectedPoint = _.find(points, {
    series: { name: totalExpectedSeriesName },
  });

  const showLeverage = _.get(expectedPoint, "point.marker.enabled", false);
  const startdate = new Date(x);
  const endDate = new Date(x).setMonth(startdate.getMonth()+2);

  if (!assets && !illiquid) {
    return "";
  }

  const age = _.get(expectedPoint, "point.age", "");

  const dateRange = `${formatDate(startdate, "MMM", { locale })} - ${formatDate(endDate, "MMM yyyy", { locale })}`;

  return `<div class="tooltip-title">
            <span class="tooltip-title-year">${dateRange}</span>
            ${!isHorizontallyCompact && illiquid ? `<span></span>` : ""}
            <div class="tooltip-title-age">          
              <span>${tooltipLabels.AGE}: ${age}</span>
            </div>
        </div>
      ${isHorizontallyCompact? 
            `<div class="tooltip-row">
            <span></span>
            <span class="labels">${tooltipLabels.EXPECTED}</span>
            </div>
            <div class="tooltip-row tooltip-row--expected">
              <span>${tooltipLabels.NET_ASSETS}</span>
              
              <span class="tooltip-value-assets">
                <span class="diamond chart-legend-good"></span>
                <span >${assets && assets.expected}</span>
              </span>
            </div>
            ${illiquid
              ? ` <div class="tooltip-row tooltip-row--expected">
                    <span>${tooltipLabels.PROPERTY}</span>
                    
                    <span class="tooltip-value-illiquid">
                    <span class="rectangle chart-legend-good"></span>
                      <span>${illiquid.expected}</span>
                    </span>
                  </div>`
              : ""
            }`
          :
          `<div class="tooltip-row">
            <span></span>
            <span class="labels">${tooltipLabels.NET_ASSETS}</span>
            ${ illiquid ? `<span class="labels">${tooltipLabels.PROPERTY}</span>` : ""}
        </div>
         <div class="tooltip-row">
            <span>${tooltipLabels.GOOD}</span>
            <span class="tooltip-value-assets">
              <span class="rectangle chart-legend-good"></span>
              <span>${assets && assets.good}</span>
            </span>
            ${
              illiquid
                ? `<span class="tooltip-value-illiquid">
                  <span class="rectangle chart-legend-good" ></span> 
                  <span>${illiquid.good}</span>
                </span>`
                : ""
            }
        </div>
        <div class="tooltip-row tooltip-row--expected">
            <span>${tooltipLabels.EXPECTED}</span>
            
            <span class="tooltip-value-assets">
            <span class="chart-legend-expected"></span>
              <span>${assets && assets.expected}</span>
            </span>
            ${illiquid
                ? `<span class="tooltip-value-illiquid">
                <span class="chart-legend-expected"></span>
                  <span>${illiquid.expected}</span>
                </span>`
                : ""
            }
        </div>
        <div class="tooltip-row">
            <span>${tooltipLabels.ADVERSE}</span>
            <span class="tooltip-value-assets">
            <span class="rectangle chart-legend-adverse"></span>
              <span>${assets && assets.adverse}</span>
            </span>
            ${
              illiquid
                ? `<span class="tooltip-value-illiquid">
                    <span class="rectangle chart-legend-adverse"></span>
                    <span>${illiquid.adverse}</span>
                  </span>`
                : ""
            }
        </div>`
      }
        ${
          showLeverage
            ? `<span class="tooltip-leverage"><span class="icon-leverage"></span>  ${tooltipLabels.COLLATERAL_REQUIRED}</span>`
            : ""
        }
        `;
        
}

export function getTooltipOptions(
  tooltipLabels: TooltipLabels,
  locale: Locale,
  isHorizontallyCompact?: boolean,
  currencyConfig?: CurrencyConfig
): Highcharts.TooltipOptions {

  const tooltipFormatter = (
    x: string | number | undefined,
    points: Highcharts.TooltipFormatterContextObject[] | undefined
  ) => {
    let toolTipHtml = "";

    if (x && points) {
      const xDate = new Date(x);

      const assets = getRangeSeriesValues(
        points,
        totalExpectedSeriesName,
        totalGoodSeriesName,
        totalAdverseSeriesName,
        currencyConfig
      );
      const illiquid = getRangeSeriesValues(
        points,
        "illiquidExpectedSeries",
        "illiquidGoodRangeSeries",
        "illiquidAdverseRangeSeries",
        currencyConfig
      );

      toolTipHtml = `<div class="retirement-chart-tooltip ${
        !isHorizontallyCompact && illiquid ? "retirement-chart-tooltip--illiquid" : ""
      }">`;

      toolTipHtml += `
          ${addStandardSeriesHtml(tooltipLabels, isHorizontallyCompact ?? false, x, assets, illiquid,locale, points)}
          
      </div>`;
    }

    return toolTipHtml.replace(/\s*/g, '') === '<divclass="retirement-chart-tooltip"></div>'
      ? "" : toolTipHtml;
  };

  const tooltipOptions: Highcharts.TooltipOptions = {
    shared: true,
    useHTML: true,
    formatter: function () {
      return tooltipFormatter(this.x, this.points);
    },
    padding: 0,
    shadow: false,
    borderColor: "#ccc",
    borderWidth: 0,
    positioner: function () {
      return { x: 45,
               y: 0 
            };
    }
  };

  return isHorizontallyCompact ? tooltipOptions : {
    shared: true,
    useHTML: true,
    formatter: function () {
      return tooltipFormatter(this.x, this.points);
    },
    padding: 0,
    shadow: false,
    borderColor: "#ccc",
    borderWidth: 0
  };
}
