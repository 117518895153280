import { useComponentOrder } from "./useComponentOrder";

const assetsWithComponentName : Map<string, string> = new Map<string, string>([
    ["I_HAVE_REAL_ESTATE", "REALESTATE"],
    ["I_HAVE_BONDS", "BONDS"],
    ["I_HAVE_EQUITY", "EQUITY"],
    ["I_HAVE_CASH", "CASH"],
])

const defaultAssetsOrder : string[] = ([
    "CASH",
    "REALESTATE",
    "BONDS",
    "EQUITY",
])

export const useLayoutAssetsOrder = () => {
    const assets : Map<number, string> = new Map<number, string>([]);

    assetsWithComponentName.forEach((value, key) => {
        const index = useComponentOrder(key);
        if (index !== undefined) {
            assets.set(index,value);
        }
    });
    const assetsOrder = new Map([...assets.entries()].sort());
    
    return assetsOrder.size > 0 ? Array.from(assetsOrder.values()) : defaultAssetsOrder;
}