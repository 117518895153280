import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { DashboardLabels, DisclaimerComponentLabels } from "../labels/dashboard.labels";
import { AssetType, GoalType, LiabilityType, Personal, SavingType, Savings, UserSettings } from "@flexfront/models";
import { CtaComponentLabels, CtaType, Persona } from "../labels/cta-component.labels";
import { Navigate, useLocation } from "react-router-dom";
import { useCurrencyConfig } from "../hooks/useCurrencyConfig";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectAuth } from "../store/auth/auth.slice";
import { selectPersonalState } from "../store/personal.slice";
import { selectTenantConfig } from "../store/tenant-config/tenant-config.slice";
import { fetchSimulation, selectSimulationState } from "../store/simulation/simulation.slice";
import { addErrorMessage, removeErrorMessage, selectNotificationsState } from "../store/notifications/notifications.slice";
import { saveDream, saveLegacy, savePreserveCapital, saveRetirement, saveSecureFamily, selectGoalsState, setDreamHasInvalidDate, setPreserveCapitalHasInvalidDate, setSecureFamilyHasInvalidDate } from "../store/goals/goals.slice";
import { saveAssets, selectAssetsState } from "../store/assets/assets.slice";
import { saveLiabilities, selectLiabilitiesState } from "../store/liabilities/liabilities.slice";
import { saveHasInvalidSavingsDates, saveLumpsumSavings, saveMonthlySavings, selectSavingsState } from "../store/savings.slice";
import { selectUserSettingsState } from "../store/user-settings.slice";
import { useLayoutLabels } from "../hooks/useLayoutLabels";
import { IWantComponentLabels } from "../labels/i-want-component.labels";
import { useMobileVersionLabels } from "../hooks/useMobileVersionLabels";
import { IHaveComponentLabels } from "../labels/i-have-component.labels";
import { ICanListComponentLabels, fromICanLabels } from "../labels/i-can-list-component-labels";
import { InfoModalComponentLabels } from "../labels/info-modal-component-labels";
import { CtaConfirmComponentLabels } from "../labels/cta-confirm-component.labels";
import { FlyoutMenuComponentLabels } from "../labels/flyout-menu-component.labels";
import { ResultsComponentLabels } from "../labels/results-component.labels";
import { NudgeComponentLabels } from "../labels/nudge-component.labels";
import { LandingComponentLabels, fromLandingLabels } from "../labels/landing-component.labels";
import { LandingModalComponentLabels, fromLandingModalLabels } from "../labels/landing-modal-component-labels";
import { useConfigChartNotification } from "../../default/components/notification-component/useConfigChartNotification";
import { calculateLifeSpan } from "../shared/calculateLifeSpan";
import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from "../hooks/useTrackEvent";
import { useComponentIcon } from "../hooks/useComponentIcon";
import { useComponentOrder } from "../hooks/useComponentOrder";
import { DebouncedFunc, debounce } from 'lodash';
import { useDeviceBounds } from "../hooks/useDeviceBounds";
import { useWindowSize } from "usehooks-ts";
import { usePersona } from "../hooks/usePersona";
import { NotificationType, Notifications } from "../store/notifications/notifications.state";
import { CurrencyConfig, getQuarterDate, getQuarterSpan } from "@flexfront/ui/react";
import { GoalsState } from "../store/goals/goals.state";
import { SimulationState } from "../store/simulation/simulation.state";
import { Assets } from "../store/assets/assets.state";
import { Liabilities } from "../store/liabilities/liabilities.state";
import { TenantConfigState } from "../store/tenant-config/tenant-config.state";
import { AuthState } from "../store/auth/auth.state";
import { ICanComponentLabels } from "../labels/i-can-component.labels";
import { ThisIsMeComponentLabels } from "../labels/this-is-me-component.labels";
import { ReportComponentLabels } from "../../default/components/report-component/report-component.labels";
import { useTenantLogo } from "../hooks/useTenantLogo";
import { useLabelsContext } from "./labels-provider";
import { useTenantSiteId } from "../hooks/useTenantSiteId";
import { useTenantColors } from "../hooks/useTenantColors";
import { useTenantFontType } from "../hooks/useFontType";
import { useFavicon } from "../hooks/useFavicon";
import { selectClimateChangeState } from "../store/climate-change/climate.slice";
import { ClimateChangeState } from "../store/climate-change/climate-change.state";
import { useIsInIframe } from "../hooks/useIsInIframe";
import { fromMenuLabels, MenuComponentLabels } from "../labels/menu-component.labels";

type EditItemType = GoalType | AssetType | LiabilityType | SavingType | CtaType | undefined;

export interface DashboardContextProps {
    children: ReactNode;
    hasLandingModal?: boolean;
    disableShortfallEliminationCall? : boolean;
}

interface DashboardFunctionality {
    labels: DashboardLabels;
    reportLabels: ReportComponentLabels;
    isHorizontallyCompact: boolean;
    isInsideIframe: boolean;
    currentDate: Date;
    currentYear: number;
    yearOfDeath: number;
    landingCompleted: boolean;
    showModal?: boolean;
    listParam: boolean;
    configParam: boolean;
    loaderColor: string;
    resultsIcon?: string;
    ctaIsOpen: boolean;
    ctaConfirmIsOpen: boolean;

    realEstateIcon?: string;
    mortgageIcon?: string;
    retirementIcon?: string;
    dreamIcon?: string;
    
    iWantOrder?: number;
    iHaveOrder?: number;
    iCanOrder?: number;
    thisIsMeOrder?: number;

    dashboardInputEditClassName: string;
    editComponentTitle?: string;
    editItemTitle?: string;
    showMortgageError: boolean;
    editComponentConfirmLabel?: string;

    flyoutMenuLabels: FlyoutMenuComponentLabels;
    landingLabels: LandingComponentLabels
    landingModalLabels: LandingModalComponentLabels;

    thisIsMeLabels: ThisIsMeComponentLabels;
    ihaveLabels: IHaveComponentLabels;
    iHaveListLabels: IHaveComponentLabels;
    iWantLabels: IWantComponentLabels;
    iwantListLabels: IWantComponentLabels;
    iCanLabels: ICanComponentLabels;
    iCanListLabels: ICanListComponentLabels;
    resultLabels: ResultsComponentLabels;
    nudgeLabels: NudgeComponentLabels;
    disclaimerLabels: DisclaimerComponentLabels;
    infoLabels: InfoModalComponentLabels;
    ctaLabels: CtaComponentLabels;
    ctaConfirmLabels: CtaConfirmComponentLabels;
    persona: Persona;
    menuLabels: MenuComponentLabels;

    auth:AuthState;
    personal: Personal;
    goals: GoalsState;
    simulation: SimulationState;
    assets: Assets;
    liabilities: Liabilities;
    savings: Savings;
    climateChange: ClimateChangeState;
    userSettings: UserSettings;
    notifications: Notifications;
    tenantConfig: TenantConfigState;
    currencyConfig: CurrencyConfig;
    isLogoAvailable: boolean;

    closeCurrentlyOpenEditItem: () => void;
    openEditComponentForGoal: (item: GoalType) => void;
    openEditComponentForAsset: (item: AssetType) => void;
    openEditComponentForLiability: (item: LiabilityType) => void;
    openEditComponentForSaving: (item: SavingType) => void;
    debounceSimulationUpdate: DebouncedFunc<() => void>;
    getQuarterSpanFromString: (dateAsString: string | undefined) => number;
    getQuarterFromYear: (year: number) => number;
    getQuarterSpanFromCurrentDate: (spanDate: Date) => number;
    getYearFromString:(year: string | undefined)=> number;

    closeInfoModal:()=>void;
    currentlyOpenEditItem: EditItemType;
    updateRetirement:(age: number,amount: number) => void;
    updateLegacy:(amount: number)=>void;
    updateDream:(year: number, amount: number)=>void;
    onDreamValidate:(isValid: boolean)=>void;
    updatePreserveCapital:(fromYear: number, toYear: number, amount: number)=>void;
    onPreserveCapitalValidate:(isValid: boolean)=>void;
    updateSecureFamily:(year: number, amount: number)=>void;
    onSecureFamilyValidate:(isValid: boolean)=>void;
    updateRealestate:(amount: number)=>void;
    updateBonds:(amount: number)=>void;
    updateEquities:(amount: number)=>void;
    updateCash:(amount: number)=>void;
    updateMortgage:(amount: number)=>void;
    updateMonthlySavings:(amount: number)=>void;
    onSavingsMonthlyValidate:(isValid: boolean)=>void;
    updateLumpsumSavings:(year: number, amount: number)=>void;
    onSavingsLumpsumValidate:(isValid: boolean)=>void;
    openCtaComponent:(setLabels: boolean)=>void;
    onCtaSubmit:()=>void;
    openCtaConfirmComponent:()=>void;
    onCtaConfirmComponentClose:()=>void;
    onCtaConfirmClose:()=>void;
    landingCTAClicked:()=>void;
    isDreamYearValid: boolean;
    isLumpsumYearValid: boolean;
    isSecureFamilyYearValid: boolean;
    isReportDownloading: boolean | undefined;
    onReportDowloading(isReportDownloading: boolean): void;
}

const DashboardContext = createContext<DashboardFunctionality | undefined>(undefined);

export const useDashboardContext = (): DashboardFunctionality => {
    const context = useContext(DashboardContext);
    if (!context) {
        throw new Error('useDashboardContext must be used within a DashboardProvider');
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useContext(DashboardContext)!;
};

export const DashboardProvider: React.FC<DashboardContextProps> = (props: DashboardContextProps) => {
    useTenantSiteId();
    useTenantColors();
    useTenantFontType();
    useFavicon();

    const tenantConfig = useAppSelector(selectTenantConfig);
    const {
        labels: labelsState
    } = useLabelsContext();

    const [labels, setLabels] = useState<DashboardLabels>(labelsState!.DASHBOARD);

    useEffect(() => {
        setLabels(labelsState!.DASHBOARD);
        debounceSimulationUpdate();
    }, [labelsState]);

    const isInsideIframe = useIsInIframe();

    const { pathname } = useLocation();
    const listParam = pathname.indexOf('list') > -1;
    const configParam = pathname.indexOf('config') > -1;

    const currencyConfig = useCurrencyConfig();
    const tenantLogo = useTenantLogo();

    const auth = useAppSelector(selectAuth);
    const personal = useAppSelector(selectPersonalState);
    
    const simulation = useAppSelector(selectSimulationState);
    const notifications = useAppSelector(selectNotificationsState);
    const goals = useAppSelector(selectGoalsState);
    const assets = useAppSelector(selectAssetsState);
    const liabilities = useAppSelector(selectLiabilitiesState);
    const savings = useAppSelector(selectSavingsState);
    const userSettings = useAppSelector(selectUserSettingsState);
    const climateChange = useAppSelector(selectClimateChangeState);

    const reportLabels = useLayoutLabels<ReportComponentLabels>(labels,"REPORT");
    const thisIsMeLabels = useLayoutLabels<ThisIsMeComponentLabels>(labels, "THIS_IS_ME");
    const iWantLabels = useLayoutLabels<IWantComponentLabels>(labels, "I_WANT");
    const iwantListLabels = useMobileVersionLabels<IWantComponentLabels>(labels, "I_WANT");
    const ihaveLabels = useLayoutLabels<IHaveComponentLabels>(labels, "I_HAVE");
    const iHaveListLabels = useMobileVersionLabels<IHaveComponentLabels>(labels, "I_HAVE");
    const iCanLabels = useLayoutLabels<ICanComponentLabels>(labels, "I_CAN");
    const iCanMobileSpecificLabels = useMobileVersionLabels<ICanComponentLabels>(labels, "I_CAN");
    const iCanListLabels = fromICanLabels(iCanMobileSpecificLabels);
    const infoLabels = useLayoutLabels<InfoModalComponentLabels>(labels, "INFO_MODAL");
    const ctaLabels = useLayoutLabels<CtaComponentLabels>(labels, "CTA");
    const ctaConfirmLabels = useLayoutLabels<CtaConfirmComponentLabels>(labels, "CTA_CONFIRM");
    const disclaimerLabels = useLayoutLabels<DisclaimerComponentLabels>(labels, "DISCLAIMER");
    const flyoutMenuLabels = useLayoutLabels<FlyoutMenuComponentLabels>(labels, "FLYOUT_MENU");
    const resultLabels = useLayoutLabels<ResultsComponentLabels>(labels, "RESULTS");
    const nudgeLabels = useLayoutLabels<NudgeComponentLabels>(labels, "NUDGE");
    const landingLabels = fromLandingLabels(labels.LANDING, disclaimerLabels);
    const documentTabTitle = useLayoutLabels<string>(labels,"DOCUMENT_TAB_TITLE");
    const landingModalLabels = fromLandingModalLabels(labels.LANDING_MODAL, disclaimerLabels);
    const menuLabels = fromMenuLabels(labels.MENU, ctaLabels);

    useConfigChartNotification(labels.RESULTS.NOTIFY);
    const dispatch = useAppDispatch();
    const [currentlyOpenEditItem, setCurrentlyOpenEditItem] = useState<EditItemType>(undefined);
    const [editComponentTitle, setEditComponentTitle] = useState<string | undefined>('');
    const [editComponentConfirmLabel, setEditComponentConfirmLabel] = useState<string | undefined>('');
    const [editItemTitle, setEditItemTitle] = useState<string | undefined>('');
    const [ctaIsOpen, setCtaIsOpen] = useState<boolean>(false);
    const [ctaConfirmIsOpen, setCtaConfirmIsOpen] = useState<boolean>(false);
    const {currentYear, yearOfDeath} = calculateLifeSpan();
    const [isLogoAvailable, setIsLogoAvailable] = useState<boolean>(true);
    const [isLumpsumYearValid, setIsLumpsumYearValid] = useState<boolean>(true);
    const [isDreamYearValid, setIsDreamYearValid] = useState<boolean>(true);
    const [isSecureFamilyYearValid, setIsSecureFamilyYearValid] = useState<boolean>(true);
    const [isReportDownloading, setIsReportDownloading] = useState<boolean | undefined>(false);

    const trackEvent = useTrackEvent();
    const resultsIcon = useComponentIcon("RESULTS");
    const realEstateIcon = useComponentIcon("I_HAVE_REAL_ESTATE_ICON");
    const mortgageIcon = useComponentIcon("I_HAVE_LIABILITY_ICON");
    const retirementIcon = useComponentIcon("I_WANT_RETIREMENT_ICON");
    const dreamIcon = useComponentIcon("I_WANT_DREAM_ICON");

    const thisIsMeOrder = useComponentOrder("THIS_IS_ME");
    const iWantOrder = useComponentOrder("I_WANT");
    const iHaveOrder = useComponentOrder("I_HAVE");
    const iCanOrder = useComponentOrder("I_CAN");

    let abortController = new AbortController();
    const updateSimulation = useCallback(() => {
        abortController.abort();
        abortController = new AbortController();
        dispatch(fetchSimulation({ abortSignal: abortController.signal, disableShortfallEliminationCall: props.disableShortfallEliminationCall }));
    }, [dispatch]);

    const debounceSimulationUpdate = useMemo(() => debounce(updateSimulation, 300), [updateSimulation]);
    const { isHorizontallyCompact } = useDeviceBounds();
    const [landingCompleted, setLandingCompleted] = useState<boolean>(false);
    const currentDate = useMemo(() => new Date(), []);
    const [showModal, setShowModal] = useState(props.hasLandingModal && !isHorizontallyCompact);
    const [showMortgageError, setShowMortgageError] = useState(false);
    const { width, height } = useWindowSize();
    const persona = usePersona(labels);
    const title = persona.TITLE;

    useEffect(() => {
        return () => {
            debounceSimulationUpdate.cancel();
            abortController?.abort();
        };
    }, []);

    useEffect(() => {
        debounceSimulationUpdate();
    }, [width,height]);

    useEffect(() => {
       tenantLogo ? setIsLogoAvailable(true) : setIsLogoAvailable(false); 
    }, [tenantConfig,tenantLogo]);

    useEffect(() => {
        if(documentTabTitle){
            document.title = documentTabTitle;
        }
    }, [documentTabTitle]);


    if (tenantConfig && !tenantConfig.isActive && !auth.isLoggedIn) {
        return <Navigate to="/" />;
    }

    const loaderColor = getComputedStyle(document.documentElement).getPropertyValue('--secondary-0');

    function onReportDowloading(isReportDownloading: boolean) {
        setIsReportDownloading(isReportDownloading);
    }

    const onCtaSubmit = () => {
        trackEvent({ category: trackEventCategories.CTA_FINAL, action: trackEventActions.CLICK, name: trackEventNames.SUBMIT });
        trackEvent({ category: trackEventCategories.CTA_FINAL_CONFIRM, action: trackEventActions.CLICK, name: trackEventNames.OPEN });
        setCtaIsOpen(false);
        setCtaConfirmIsOpen(true);
    }

    const onCtaConfirmClose = () => {
        setCtaConfirmIsOpen(false);
        trackEvent({ category: trackEventCategories.CTA_FINAL_CONFIRM, action: trackEventActions.CLICK, name: trackEventNames.CLOSE });
    }

    function landingCTAClicked() {
        trackEvent({ category: trackEventCategories.LANDING, action: trackEventActions.CLICK, name: trackEventNames.CONFIRM });
        setLandingCompleted(true);
    }

    function openEditComponentForGoal(item: GoalType) {
        trackEvent({ category: trackEventCategories.TAB, action: trackEventActions.CLICK, name: trackEventCategories.I_WANT });
        setEditComponentTitle(iwantListLabels.TITLE);
        setEditComponentConfirmLabel(iwantListLabels.CONFIRM);
        let editItemTitle = '';
        switch (item) {
        case GoalType.retirement:
            trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.CLICK, name: trackEventNames.RETIREMENT_EDIT });
            editItemTitle = iwantListLabels.GOALS.RETIREMENT.TITLE;
            break;
        case GoalType.legacy:
            trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.CLICK, name: trackEventNames.LEGACY_EDIT });
            editItemTitle = iwantListLabels.GOALS.LEGACY.TITLE;
            break;
        case GoalType.dream:
            trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.CLICK, name: trackEventNames.DREAM_EDIT });
            editItemTitle = iwantListLabels.GOALS.GOAL.TITLE;
            break;
        case GoalType.preserve_capital:
            trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.CLICK, name: trackEventNames.PRESERVE_CAPITAL_EDIT });
            editItemTitle = iwantListLabels.GOALS.PRESERVE_CAPITAL.TITLE;
            break;
        case GoalType.secure_family:
            trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.CLICK, name: trackEventNames.SECURE_FAMILY_EDIT });
            editItemTitle = iwantListLabels.GOALS.SECURE_FAMILY.TITLE;
            break;
        }
        setEditItemTitle(editItemTitle);
        setCurrentlyOpenEditItem(item);
    }

    function openCtaComponent(setLabels: boolean) {
        trackEvent({ category: trackEventCategories.CTA_FINAL_EDIT, action: trackEventActions.CLICK, name: trackEventNames.OPEN });
        
        if (setLabels) {
            setEditComponentTitle(title);
            setEditComponentConfirmLabel(undefined);
            setEditItemTitle(title);
        } else {
            setEditComponentTitle(undefined);
            setEditComponentConfirmLabel(undefined);
            setEditItemTitle(undefined);
        }
        setCurrentlyOpenEditItem(CtaType.cta);
    }

    function openCtaConfirmComponent() {
        trackEvent({ category: trackEventCategories.CTA_FINAL, action: trackEventActions.CLICK, name: trackEventNames.SUBMIT });
        trackEvent({ category: trackEventCategories.CTA_FINAL_CONFIRM_EDIT, action: trackEventActions.CLICK, name: trackEventNames.OPEN });
        
        setEditComponentTitle(ctaConfirmLabels.TITLE);
        setEditComponentConfirmLabel(undefined);
        setEditItemTitle(ctaConfirmLabels.TITLE);
        setCurrentlyOpenEditItem(CtaType.cta_confirm);
    }

    function onCtaConfirmComponentClose() {
        closeCurrentlyOpenEditItem();
        trackEvent({ category: trackEventCategories.CTA_FINAL_CONFIRM_EDIT, action: trackEventActions.CLICK, name: trackEventNames.CLOSE });
    }

    function updateRetirement(age: number,amount: number) {
        trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.INPUT, name: trackEventNames.RETIREMENT_AGE, value: age });
        trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.INPUT, name: trackEventNames.RETIREMENT_PAYOUT, value: amount });
        dispatch(saveRetirement({ ...goals.retirement,retirementAge: age, retirementMonthlyPayout: amount }));
        closeCurrentlyOpenEditItem();
        debounceSimulationUpdate();
    }

    function updateLegacy(amount: number) {
        trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.INPUT, name: trackEventNames.LEGACY_PAYOUT, value: amount });
        dispatch(saveLegacy({ ...goals.legacy, amount: amount }));
        closeCurrentlyOpenEditItem();
        debounceSimulationUpdate();
    }

    function updateDream(year: number, amount: number) {
        if((Number.isNaN(year) || year == 0) && amount != 0){
            setIsDreamYearValid(false);
            dispatch(
                addErrorMessage({
                    type: NotificationType.YEAR_ERROR,
                    message: notifications.errorMessageLabels.YEAR_ERROR_MESSAGE
                })
            );
        }
        else{
            setIsDreamYearValid(true);
            dispatch(removeErrorMessage(NotificationType.YEAR_ERROR));
        }
        trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.INPUT, name: trackEventNames.DREAM_YEAR, value: year });
        trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.INPUT, name: trackEventNames.DREAM_PAYOUT, value: amount });
        dispatch(saveDream({ ...goals.dream, year: year, amount: amount, hasInvalidDate: false }));
        closeCurrentlyOpenEditItem();
        if (year > 0 && amount > 0) {
            debounceSimulationUpdate();
        }
    }

    function onDreamValidate(isValid: boolean) {
        dispatch(setDreamHasInvalidDate(!isValid));
        if (!isValid) {
            dispatch(
                addErrorMessage({
                    type: NotificationType.DREAM_ERROR,
                    message: notifications.errorMessageLabels.GOAL_OR_CONTRIBUTION_AGE_CONFLICT_ERROR_MESSAGE
                })
            );
        } else {
            dispatch(
                removeErrorMessage(NotificationType.DREAM_ERROR)
            );
        }
    }

    function updatePreserveCapital(fromYear: number, toYear: number, amount: number) {
        trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.INPUT, name: trackEventNames.PRESERVE_CAPITAL_FROM, value: fromYear });
        trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.INPUT, name: trackEventNames.PRESERVE_CAPITAL_TO, value: toYear });
        trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.INPUT, name: trackEventNames.PRESERVE_CAPITAL_AMOUNT, value: amount });
        let newStartDate = undefined;
        let newEndDate = undefined;

        if (fromYear > 0) {
            newStartDate = goals.preserveCapital.startDate ? new Date(goals.preserveCapital.startDate) : new Date();
            newStartDate.setFullYear(fromYear);
        }

        if (toYear > 0) {
            newEndDate = goals.preserveCapital.endDate ? new Date(goals.preserveCapital.endDate) : new Date();
            newEndDate.setFullYear(toYear);
        }

        dispatch(
            savePreserveCapital({
                ...goals.preserveCapital,
                startDate: newStartDate ? newStartDate.toISOString() : undefined,
                endDate: newEndDate ? newEndDate.toISOString() : undefined,
                amount: amount,
                hasInvalidDate: false
            })
        );
        closeCurrentlyOpenEditItem();
        if (amount >= 0  && newStartDate && newEndDate) {
            debounceSimulationUpdate();
        }
    }

    function onPreserveCapitalValidate(isValid: boolean) {
        dispatch(setPreserveCapitalHasInvalidDate(!isValid));
        if (!isValid) {
            trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.VALIDATION, name: trackEventNames.PRESERVE_CAPITAL_DATE_ERROR });
            dispatch(
                addErrorMessage({
                    type: NotificationType.PRESERVE_CAPITAL_ERROR,
                    message: notifications.errorMessageLabels.GOAL_OR_CONTRIBUTION_AGE_CONFLICT_ERROR_MESSAGE
                })
            );
        } else {
            dispatch(
                removeErrorMessage(NotificationType.PRESERVE_CAPITAL_ERROR)
            );
        }
    }

    function updateSecureFamily(year: number, amount: number) {
        if((Number.isNaN(year) || year == 0) && amount != 0){
            setIsSecureFamilyYearValid(false);
            dispatch(
                addErrorMessage({
                    type: NotificationType.YEAR_ERROR,
                    message: notifications.errorMessageLabels.YEAR_ERROR_MESSAGE
                })
            );
        }
        else{
            setIsSecureFamilyYearValid(true);
            dispatch(removeErrorMessage(NotificationType.YEAR_ERROR));
        }
        trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.INPUT, name: trackEventNames.SECURE_FAMILY_YEAR, value: year });
        trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.INPUT, name: trackEventNames.SECURE_FAMILY_PAYOUT, value: amount });
        dispatch(saveSecureFamily({ ...goals.secureFamily, year: year, amount: amount, hasInvalidDate: false }));
        closeCurrentlyOpenEditItem();
        debounceSimulationUpdate();
    }

    function onSecureFamilyValidate(isValid: boolean) {
        dispatch(setSecureFamilyHasInvalidDate(!isValid));
        if (!isValid) {
            dispatch(
                addErrorMessage({
                    type: NotificationType.SECURE_FAMILY_ERROR,
                    message: notifications.errorMessageLabels.GOAL_OR_CONTRIBUTION_AGE_CONFLICT_ERROR_MESSAGE
                })
            );
        } else {
            dispatch(
                removeErrorMessage(NotificationType.SECURE_FAMILY_ERROR)
            );
        }
    }

    function openEditComponentForAsset(item: AssetType) {
        trackEvent({ category: trackEventCategories.TAB, action: trackEventActions.CLICK, name: trackEventCategories.I_HAVE });
        setEditComponentTitle(ihaveLabels.TITLE);
        setEditComponentConfirmLabel(ihaveLabels.CONFIRM);
        let editItemTitle = '';
        switch (item) {
        case AssetType.realestate:
            trackEvent({ category: trackEventCategories.I_HAVE, action: trackEventActions.CLICK, name: trackEventNames.REAL_ESTATE_EDIT });
            editItemTitle = ihaveLabels.ASSET.ASSET_TYPES.REALESTATE.DISPLAY;
            break;
        case AssetType.bonds:
            trackEvent({ category: trackEventCategories.I_HAVE, action: trackEventActions.CLICK, name: trackEventNames.BONDS_EDIT });
            editItemTitle = ihaveLabels.ASSET.ASSET_TYPES.BONDS.DISPLAY;
            break;
        case AssetType.equities:
            trackEvent({ category: trackEventCategories.I_HAVE, action: trackEventActions.CLICK, name: trackEventNames.EQUITY_EDIT });
            editItemTitle = ihaveLabels.ASSET.ASSET_TYPES.EQUITY.DISPLAY;
            break;
        case AssetType.cash:
            trackEvent({ category: trackEventCategories.I_HAVE, action: trackEventActions.CLICK, name: trackEventNames.CASH_EDIT });
            editItemTitle = ihaveLabels.ASSET.ASSET_TYPES.CASH.DISPLAY;
            break;
        }
        setEditItemTitle(editItemTitle);
        setCurrentlyOpenEditItem(item);
    }

    function updateRealestate(amount: number) {
        trackEvent({ category: trackEventCategories.I_HAVE, action: trackEventActions.INPUT, name: trackEventNames.REAL_ESTATE, value: amount });
        dispatch(saveAssets({ ...assets, realEstate: amount }));
        if (amount < liabilities.mortgage) {
            dispatch(addErrorMessage({type: NotificationType.MORTGAGE_ERROR, message: ihaveLabels.LIABILITY.LIABILITY_TYPES.MORTGAGE.ERROR_MESSAGE}));
        } else {
            dispatch(removeErrorMessage(NotificationType.MORTGAGE_ERROR));
        }
        validateLiquidity(assets.cash, assets.bonds, assets.equity, amount);
        closeCurrentlyOpenEditItem();
        debounceSimulationUpdate();
    }

    function updateBonds(amount: number) {
        trackEvent({ category: trackEventCategories.I_HAVE, action: trackEventActions.INPUT, name: trackEventNames.BONDS, value: amount });
        dispatch(saveAssets({ ...assets, bonds: amount }));
        validateLiquidity(assets.cash, amount, assets.equity, assets.realEstate);
        closeCurrentlyOpenEditItem();
        debounceSimulationUpdate();
    }

    function updateEquities(amount: number) {
        trackEvent({ category: trackEventCategories.I_HAVE, action: trackEventActions.INPUT, name: trackEventNames.EQUITY, value: amount });
        dispatch(saveAssets({ ...assets, equity: amount }));
        validateLiquidity(assets.cash, assets.bonds, amount, assets.realEstate);
        closeCurrentlyOpenEditItem();
        debounceSimulationUpdate();
    }

    function updateCash(amount: number) {
        trackEvent({ category: trackEventCategories.I_HAVE, action: trackEventActions.INPUT, name: trackEventNames.CASH, value: amount });
        dispatch(saveAssets({ ...assets, cash: amount }));
        validateLiquidity(amount, assets.bonds, assets.equity, assets.realEstate);
        closeCurrentlyOpenEditItem();
        debounceSimulationUpdate();
    }

    function updateMortgage(amount: number) {
        trackEvent({ category: trackEventCategories.I_HAVE, action: trackEventActions.INPUT, name: trackEventNames.MORTGAGE, value: amount });
        dispatch(saveLiabilities({ ...liabilities, mortgage: amount }));
        if (assets.realEstate < amount) {
            dispatch(addErrorMessage({type: NotificationType.MORTGAGE_ERROR, message: ihaveLabels.LIABILITY.LIABILITY_TYPES.MORTGAGE.ERROR_MESSAGE}));
        } else {
            dispatch(removeErrorMessage(NotificationType.MORTGAGE_ERROR));
        }
        closeCurrentlyOpenEditItem();
        debounceSimulationUpdate();
    }

    function validateLiquidity(cash: number, bonds: number, equity: number, realEstate: number) {
        if ((cash==0 && bonds==0 && equity==0) && realEstate!=0) {
            trackEvent({ category: trackEventCategories.I_HAVE, action: trackEventActions.VALIDATION, name: trackEventNames.EQUITY_ERROR });
            dispatch(addErrorMessage({type: NotificationType.LIQUIDITY_ERROR, message: ihaveLabels.ASSET.ERROR_MESSAGE}));
        } else {
            dispatch(removeErrorMessage(NotificationType.LIQUIDITY_ERROR));
        }
    }

    function openEditComponentForLiability(item: LiabilityType) {
        trackEvent({ category: trackEventCategories.TAB, action: trackEventActions.CLICK, name: trackEventCategories.I_HAVE });
        setEditComponentTitle(ihaveLabels.TITLE);
        setEditComponentConfirmLabel(ihaveLabels.CONFIRM);
        let editItemTitle = '';
        switch (item) {
        case LiabilityType.mortgage:
            trackEvent({ category: trackEventCategories.I_HAVE, action: trackEventActions.CLICK, name: trackEventNames.MORTGAGE_EDIT });
            editItemTitle = ihaveLabels.LIABILITY.LIABILITY_TYPES.MORTGAGE.DISPLAY;
            break;
        }
        setEditItemTitle(editItemTitle);
        setCurrentlyOpenEditItem(item);
    }

    function openEditComponentForSaving(item: SavingType) {
        trackEvent({ category: trackEventCategories.TAB, action: trackEventActions.CLICK, name: trackEventCategories.I_CAN });
        setEditComponentTitle(labels.I_CAN.TITLE);
        setEditComponentConfirmLabel(labels.I_CAN.CONFIRM);
        let editItemTitle = '';
        switch (item) {
        case SavingType.monthly:
            trackEvent({ category: trackEventCategories.I_CAN, action: trackEventActions.CLICK, name: trackEventNames.MONTHLY_SAVINGS_EDIT });
            editItemTitle = iCanListLabels.SAVINGS;
            break;
        case SavingType.lumpsum:
            trackEvent({ category: trackEventCategories.I_CAN, action: trackEventActions.CLICK, name: trackEventNames.LUMPSUM_SAVINGS_EDIT });
            editItemTitle = iCanListLabels.LUMPSUM_YEAR;
            break;
        }
        setEditItemTitle(editItemTitle);
        setCurrentlyOpenEditItem(item);
    }

    function updateMonthlySavings(amount: number) {
        trackEvent({ category: trackEventCategories.I_CAN, action: trackEventActions.INPUT, name: trackEventNames.MONTHLY_SAVINGS_YEAR, value: savings.monthlyYear });
        trackEvent({ category: trackEventCategories.I_CAN, action: trackEventActions.INPUT, name: trackEventNames.MONTHLY_SAVINGS_AMOUNT, value: amount });
        dispatch(saveMonthlySavings({ year: savings.monthlyYear , amount }));
        closeCurrentlyOpenEditItem();
        debounceSimulationUpdate();
    }
    
    function onSavingsMonthlyValidate(isValid: boolean) {
        dispatch(saveHasInvalidSavingsDates({
            hasInvalidMonthlyDate: !isValid,
            hasInvalidLumpsumDate: savings.hasInvalidLumpsumYear
        }));

        if (!isValid) {
            dispatch(
                addErrorMessage({
                type: NotificationType.SAVINGS_MONTHLY_ERROR,
                message: labels.I_CAN.DATE_ERRORS.INVALID
                })
            );
        } else {
            dispatch(
                removeErrorMessage(NotificationType.SAVINGS_MONTHLY_ERROR)
            );
        }
    }

    function updateLumpsumSavings(year: number, amount: number) {
        if((Number.isNaN(year) || year == 0) && amount != 0){
            setIsLumpsumYearValid(false);
            dispatch(
                addErrorMessage({
                    type: NotificationType.YEAR_ERROR,
                    message: notifications.errorMessageLabels.YEAR_ERROR_MESSAGE
                })
            );
        }
        else{
          setIsLumpsumYearValid(true);
            dispatch(removeErrorMessage(NotificationType.YEAR_ERROR));
        }
        trackEvent({ category: trackEventCategories.I_CAN, action: trackEventActions.INPUT, name: trackEventNames.LUMPSUM_SAVINGS_YEAR, value: year });
        trackEvent({ category: trackEventCategories.I_CAN, action: trackEventActions.INPUT, name: trackEventNames.LUMPSUM_SAVINGS_AMOUNT, value: amount });
        dispatch(saveLumpsumSavings({ year, amount }));
        closeCurrentlyOpenEditItem();
        debounceSimulationUpdate();
    }

    function onSavingsLumpsumValidate(isValid: boolean) {
        dispatch(saveHasInvalidSavingsDates({
            hasInvalidMonthlyDate: savings.hasInvalidMonthlyYear,
            hasInvalidLumpsumDate: !isValid
        }));

        if (!isValid) {
            dispatch(
                addErrorMessage({
                    type: NotificationType.SAVINGS_LUMPSUM_ERROR,
                    message: notifications.errorMessageLabels.GOAL_OR_CONTRIBUTION_AGE_CONFLICT_ERROR_MESSAGE
                })
            );
        } else {
            dispatch(
            removeErrorMessage(NotificationType.SAVINGS_LUMPSUM_ERROR)
            );
        }
    }

    function closeCurrentlyOpenEditItem() {
        trackEvent({ category: trackEventCategories.EDIT, action: trackEventActions.CLICK, name: trackEventNames.CLOSE });
        setCurrentlyOpenEditItem(undefined);
    }

    function closeInfoModal() {
        setShowModal(false);
    }

    useEffect(() => {
        if (!props.hasLandingModal) {
            setShowModal(false);
        }
    }, [props.hasLandingModal]);

    useEffect(() => {
        if (showModal) {
            document.body.classList.add('disable-scroll');
        } else {
            document.body.classList.remove('disable-scroll');
        }
    }, [showModal]);

    function getQuarterSpanFromCurrentDate(spanDate: Date) {
        const quarterSpan = getQuarterSpan(currentDate, spanDate);
        return quarterSpan > simulation.totalQuarters ? simulation.totalQuarters : quarterSpan;
    }

    function getYearFromString(dateAsString: string | undefined) {
        if (dateAsString) {
            return new Date(dateAsString).getFullYear();
        }
        return 0;
    }

    function getQuarterSpanFromString(dateAsString: string | undefined) {
        if (dateAsString) {
            const rangeDate = new Date(dateAsString);
            const quarterDate = getQuarterDate(rangeDate);
            return getQuarterSpanFromCurrentDate(quarterDate);
        }

        return 0;
    }

    function getQuarterFromYear(year: number) {
        const spanDate = new Date();
        spanDate.setFullYear(year);
        return getQuarterSpanFromCurrentDate(spanDate);
    }

    let dashboardInputEditClassName = "dashboard__input__edit";
    if(currentlyOpenEditItem == CtaType.cta || currentlyOpenEditItem == CtaType.cta_confirm){
        dashboardInputEditClassName += " dashboard__cta_input__edit";
    }

    useEffect(() => {
        const monthlyYear = currentYear + (goals.retirement.retirementAge - personal.age);
        dispatch(saveMonthlySavings({amount: savings.monthly, year: monthlyYear}));
    }, [personal.age, goals.retirement.retirementAge]);

    useEffect(() => {
        if (assets.cash !== 0
            || assets.bonds !== 0
            || assets.equity !== 0
            || assets.realEstate !== 0
            || savings.lumpsum !== 0
            || savings.monthly !== 0) {
                dispatch(removeErrorMessage(NotificationType.REPORT_DOWNLOAD_ERROR));
        }
    }, [
        dispatch,
        assets.cash,
        assets.bonds,
        assets.equity,
        assets.realEstate,
        savings.lumpsum,
        savings.monthly
    ]);

    const contextValue = {
        labels,
        reportLabels,
        isHorizontallyCompact,
        isInsideIframe,
        currentDate,
        currentYear,
        yearOfDeath,
        landingCompleted,
        showModal,
        listParam,
        configParam,
        loaderColor,
        resultsIcon,
        ctaIsOpen,
        ctaConfirmIsOpen,

        realEstateIcon,
        mortgageIcon,
        retirementIcon,
        dreamIcon,

        iHaveOrder,
        iWantOrder,
        iCanOrder,
        thisIsMeOrder,

        dashboardInputEditClassName,
        editComponentTitle,
        editItemTitle,
        showMortgageError,
        editComponentConfirmLabel,

        flyoutMenuLabels,
        landingLabels,
        landingModalLabels,
        
        thisIsMeLabels,
        iWantLabels,
        iwantListLabels,
        ihaveLabels,
        iHaveListLabels,
        iCanLabels,
        iCanListLabels,
        resultLabels,
        nudgeLabels,
        disclaimerLabels,
        infoLabels,
        ctaLabels,
        ctaConfirmLabels,
        persona,
        menuLabels,

        dispatch,
        auth,
        personal,
        goals,
        simulation,
        assets,
        liabilities,
        savings,
        climateChange,
        userSettings,
        notifications,
        tenantConfig,
        currencyConfig,
        isLogoAvailable,

        closeCurrentlyOpenEditItem,
        openEditComponentForGoal,
        openEditComponentForAsset,
        openEditComponentForLiability,
        openEditComponentForSaving,
        debounceSimulationUpdate,
        getQuarterSpanFromString,
        getQuarterFromYear,
        getQuarterSpanFromCurrentDate,
        getYearFromString,

        closeInfoModal,
        currentlyOpenEditItem,
        updateRetirement,
        updateLegacy,
        updateDream,
        onDreamValidate,
        updatePreserveCapital,
        onPreserveCapitalValidate,
        updateSecureFamily,
        onSecureFamilyValidate,
        updateRealestate,
        updateBonds,
        updateEquities,
        updateCash,
        updateMortgage,
        updateMonthlySavings,
        onSavingsMonthlyValidate,
        updateLumpsumSavings,
        onSavingsLumpsumValidate,
        openCtaComponent,
        onCtaSubmit,
        openCtaConfirmComponent,
        onCtaConfirmComponentClose,
        onCtaConfirmClose,
        landingCTAClicked,
        isDreamYearValid,
        isLumpsumYearValid,
        isSecureFamilyYearValid,
        isReportDownloading,
        onReportDowloading
    };

    return (
        <DashboardContext.Provider value={contextValue}>{props.children}</DashboardContext.Provider>
    );
}