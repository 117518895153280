import { ReactNode, useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from '../../../common/hooks/useTrackEvent';
import { CtaComponentLabels } from '../../../common/labels/cta-component.labels';
import { useComponentIcon } from '../../../common/hooks/useComponentIcon';
import { useComponentVisibility } from '../../../common/hooks/useComponentVisibility';
import { HiddenContentComponent } from '../hidden-content-component/hidden-content-component';

/* eslint-disable-next-line */
export interface CtaModalComponentProps {
  labels: CtaComponentLabels;
  children: ReactNode;
  isOpen?: boolean;
  renderChildrenAsHiddenContent?: boolean;
}

export function CtaModalComponent(props: CtaModalComponentProps) {
  const [isCtaPopupOpen, setIsCtaPopupOpen] = useState<boolean>(props.isOpen ?? false);
  const trackEvent = useTrackEvent();
  const ctaIcon = useComponentIcon("CTA");
  const isCtaHidden = useComponentVisibility("CTA");

  const childrenRef = useRef<HTMLDivElement | null>(null);

  const open = () => {
    trackEvent({ category: trackEventCategories.RESULTS, action: trackEventActions.OPEN, name: trackEventNames.CTA_FINAL });
    setIsCtaPopupOpen(true);
  }

  const close = () => {
    trackEvent({ category: trackEventCategories.RESULTS, action: trackEventActions.CLOSE, name: trackEventNames.CTA_FINAL });
    setIsCtaPopupOpen(false);
  }

  useEffect(() => {
    setIsCtaPopupOpen(props.isOpen ?? false);
    if (props.isOpen && childrenRef?.current) {
      childrenRef.current.style.visibility = 'visible'
    } else if (childrenRef?.current) {
      childrenRef.current.style.visibility = 'collapse'
    }
  }, [props.isOpen]);

  return (!isCtaHidden ?
      <>
      <Popup
        open={isCtaPopupOpen}
        onClose={close}
        position="center center"
        arrow={false}
        modal={true}>
            <div className="cta-component">
                <div className="modal-title modal-title--empty">
                    <span
                        className="modal-title-icon button-close icon-times"
                        style={{ alignSelf: 'center', textAlign: 'center', marginRight: 0 }}
                        onClick={close}
                        />
                </div>
                <div className="row" style={{ display: 'flex', height: '1px', background: 'lightgray' }} />
                {props.renderChildrenAsHiddenContent ?
                  <div dangerouslySetInnerHTML={{ __html: childrenRef.current?.innerHTML || '' }}></div>
                  : props.children}
            </div>
        </Popup>
        <button type="button" onClick={open} className={`button cta-button ${ctaIcon ? 'button-with-icon' : ''}`}>
            {props.labels.PERSONA.TITLE}
            {ctaIcon && <span className="cta-button-icon"
              dangerouslySetInnerHTML={{ __html: ctaIcon ?? '' }}></span>}
        </button>
        {props.renderChildrenAsHiddenContent && <HiddenContentComponent ref={childrenRef}>
          {props.children}
        </HiddenContentComponent>}
      </>
      :
        <a
          type="button"
          href={props.labels.SUBMIT_LOCATION}
          target='_blank'
          rel='noreferrer'
          className={`button cta-button ${ctaIcon ? 'button-with-icon' : ''}`}>
            {props.labels.PERSONA.TITLE}
            {ctaIcon && <span className="cta-button-icon"
              dangerouslySetInnerHTML={{ __html: ctaIcon ?? '' }}></span>}
        </a>
  );
}

export default CtaModalComponent;
