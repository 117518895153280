export enum GoalType {
  retirement = 0,
  legacy = 1,
  dream = 2,
  preserve_capital = 3,
  secure_family = 4,
}

export interface Goal {
  nominal?: boolean;
  name: string;
  amount: number;
  year: number;
}

export interface Goals {
  goals: Goal[];
  legacyNominal?: boolean;
  legacy: number;
  retirementNominal?: boolean;
  retirementAge: number;
  retirementMonthlyPayout: number;
  preserveCapitalNominal?: boolean;
  preserveCapitalStartDate?: string;
  preserveCapitalEndDate?: string;
  preserveCapitalAmount: number;
}

export interface GoalLabels {
  TOOLTIP: string;
  TYPE: string;
  TITLE: string;
  AMOUNT: string;
  AMOUNT_PLACEHOLDER: string;
  YEAR: string;
  YEAR_PLACEHOLDER: string;
  CANCEL: string;
  ADD: string;
  DATE_ERRORS: DateErrorLabels;
}

export interface LegacyLabels {
  TYPE: string;
  TITLE: string;
  AMOUNT: string;
  AMOUNT_PLACEHOLDER: string;
}

export interface DateErrorLabels {
  START_AFTER_END: string;
  END_BEFORE_START: string;
  START_INVALID: string;
  END_INVALID: string;
  DATE_AFTER_MAX: string;
  DATE_BEFORE_MIN: string;
  INVALID: string;
}

export interface PreserveCapitalLabels {
  TYPE: string;
  TITLE: string;
  AMOUNT: string;
  AMOUNT_PLACEHOLDER: string;
  FROM: string;
  TO: string;
  DATE_ERRORS: DateErrorLabels;
}

export interface RetirementLabels {
  TYPE: string;
  TITLE: string;
  AGE: string;
  TOOLTIP: string;
  AGE_PLACEHOLDER: string;
  AMOUNT: string;
  AMOUNT_PLACEHOLDER: string;
  MONTHLY:string;
}

export interface GoalsLabels {
  GOAL_ADD: string;
  GOAL: GoalLabels;
  SECURE_FAMILY: GoalLabels;
  LEGACY: LegacyLabels;
  RETIREMENT: RetirementLabels;
  PRESERVE_CAPITAL: PreserveCapitalLabels;
  CANCEL: string;
  SAVE: string;
}
