import { getQuarterDate } from "@flexfront/ui/react";
import { selectRetirementState } from "../../../common/store/goals/goals.slice";
import { useAppSelector } from "../../../common/store/hooks";
import { selectSimulationState } from "../../../common/store/simulation/simulation.slice";
import { useEffect, useState } from "react";
import { selectPersonalState } from "../../../common/store/personal.slice";
import { TimelineReportComponentLabels } from "./timeline-report.labels";
import moment from "moment";

export interface TimelineReportComponentProps {
    labels: TimelineReportComponentLabels;
}

export function TimelineReportComponent(props: TimelineReportComponentProps) {

    const personal = useAppSelector(selectPersonalState);
    const retirement = useAppSelector(selectRetirementState);
    const simulation = useAppSelector(selectSimulationState);

    const getDateOfRetirement = (age: number, retirementAge: number): Date => {
        const dateOfRetirement = getQuarterDate(new Date());
        const yearsUntilRetirement = retirementAge - age;
        dateOfRetirement.setFullYear(dateOfRetirement.getFullYear() + yearsUntilRetirement, dateOfRetirement.getMonth(), dateOfRetirement.getDate());
        return dateOfRetirement;
    }

    const getDateOfDeath = (totalQuarters: number): Date => {
        const dateOfDeathDate = getQuarterDate(new Date());
        dateOfDeathDate.setMonth(dateOfDeathDate.getMonth() + (totalQuarters * 3));
        return dateOfDeathDate;
    }

    const [dateOfRetirement, setDateOfRetirement] = useState<Date>(getDateOfRetirement(personal.age, retirement.retirementAge));
    const [dateOfDeath, setDateOfDeath] = useState<Date>(getDateOfDeath(simulation.totalQuarters-1));

    useEffect(() => {
        setDateOfDeath(getDateOfDeath(simulation.totalQuarters-1));
    }, [simulation.totalQuarters]);

    useEffect(() => {
        setDateOfRetirement(getDateOfRetirement(personal.age, retirement.retirementAge));
    }, [personal.age, retirement.retirementAge]);

    return <div className="report-table">

            {/* Headers */}

            <div className="report-row report-row--spaced">

                <div className="report-cell report-cell--header report-cell--title">
                    {props.labels.INCOME_PERIOD}
                </div>

                <div className="report-cell report-cell--header report-cell--title">
                {props.labels.RETIREMENT_PERIOD}
                </div>

                <div className="report-cell report-cell--header report-cell--title">
                    {props.labels.RETIREMENT_AGE}
                </div>

            </div>

            <div className="report-row report-row--spaced">

                <div className="report-cell">
                    {`${moment().format("MMM. yyyy")} - ${moment(dateOfRetirement).format("MMM. yyyy")}`}
                </div>

                <div className="report-cell">
                {`${moment(dateOfRetirement).format("MMM. yyyy")} - ${moment(dateOfDeath).format("MMM. yyyy")}`}
                </div>

                <div className="report-cell">
                    {retirement.retirementAge}
                </div>

            </div>

        </div>;
}