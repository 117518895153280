export enum SavingType {
  monthly = 30,
  lumpsum = 31
}

export interface SavingTypeLabel {
  DISPLAY: string;
}

export interface SavingTypeItem extends SavingTypeLabel {
  Type: string;
  Icon: string;
}

export interface Savings {
  monthly: number;
  maxSavings: number;
  monthlyYear: number;
  lumpsum: number;
  lumpsumYear: number;
  hasInvalidMonthlyYear: boolean;
  hasInvalidLumpsumYear: boolean;
}

export interface SavingsLabels {
  MONTHLY_SAVINGS: string;
  MONTHLY_SAVINGS_AMOUNT_INPUT_TITLE: string;
  MONTHLY_SAVINGS_PLACEHOLDER: string;
  MONTHLY_YEAR_PLACEHOLDER: string;
  TOTAL_SAVINGS: string;
  TOTAL_SAVINGS_AMOUNT_INPUT_TITLE: string;
  SAVINGS_TOOLTIP: string;
  MONTHLY: string;
}
