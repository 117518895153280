import { selectSimulationState } from "../../../common/store/simulation/simulation.slice";
import { useAppSelector } from '../../../common/store/hooks';
import { RetirementChartComponentLabels } from "../../../common/labels/retirement-chart-component.labels";
import { ChartProvider } from "../../../common/providers/chart-provider";
import { RetirementChartComponent } from "./retirement-chart-component";

export interface RetirementChartWrapperComponentProps {
  labels: RetirementChartComponentLabels;
  onRequiresUpdate?: () => void;
  onRendered?: () => void;
  heightOverride?: number;
  widthOverride?: number;
  animation?: boolean;
}

export function RetirementChartWrapperComponent(props: RetirementChartWrapperComponentProps) {
  
  const simulationState = useAppSelector(selectSimulationState);
  
  return <>
      <ChartProvider 
        labels={props.labels}
        simulationState={simulationState}
        showTooltip={true}
        onRequiresUpdate={props.onRequiresUpdate}
        onRendered={props.onRendered}
        heightOverride={props.heightOverride}
        widthOverride={props.widthOverride}
        animation={props.animation}>
        <RetirementChartComponent />
      </ChartProvider>
  </>

}

export default RetirementChartWrapperComponent;
