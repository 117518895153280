import { useEffect } from "react";
import { useAppSelector } from "../store/hooks";
import { selectLayouts } from "../store/layout/layouts.slice";
import { selectTenantConfig } from "../store/tenant-config/tenant-config.slice";

export const useFavicon = () => {
    const layouts = useAppSelector(selectLayouts);
    const tenantConfig = useAppSelector(selectTenantConfig);
    
    useEffect(() => {

      const layout = layouts.layouts.find((l: any) => l.layoutId === tenantConfig.layoutId);
      
      if (!tenantConfig.isLoading && tenantConfig.templateId !== 0) {

        const faviconLink = document.head.querySelector('link[rel="icon"]');
        if (faviconLink && layout) {
          faviconLink.setAttribute('href',`/assets/favicon/${layout.name}-favicon.png`);
        }

        const faviconLink2 = document.head.querySelector('link[rel="mask-icon"]');
        if (faviconLink2 && layout) {
          faviconLink2.setAttribute('href',`/assets/favicon/${layout.name}-favicon.png`);
        }

        const faviconLink3 = document.head.querySelector('link[rel="apple-touch-icon"]');
        if (faviconLink3 && layout) {
          faviconLink3.setAttribute('href',`/assets/favicon/${layout.name}-favicon.png`);
        }

        const manifest = document.head.querySelector('link[rel="manifest"]');
        if (manifest && layout) {
          manifest.setAttribute('href',`/assets/favicon/${layout.name}.site.webmanifest`);
        }
      }

    }, [layouts.layouts, tenantConfig.isLoading, tenantConfig.templateId, tenantConfig.layoutId]);
}