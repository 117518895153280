import { WidgetComponent } from "@flexfront/ui/react";
import { IconCardComponent } from "../icon-card-component/icon-card-component";
import "./i-have-component.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { AssetTypeItem, LiabilityTypeItem } from "@flexfront/models";
import { useAssetsContext } from "../../../common/providers/assets-provider";

export function IHaveComponent() {
  const {
    labels,
    liabilityTypes,
    shouldRenderLiability,
    isAssetVisible,
    liabilityHasError,
    getLiabilityStateValue,
    getAssetIconHidden,
    getLiabilityChangedFunction,
    currencyConfig,
    getLiabilityBlurFunction,
    getAssetStateValue,
    getAssetsChangedFunction,
    getAssetsBlurFunction,
    swiperContainerRef,
    onSwiperContainerKeyUp,
    setSwiperInstance,

    iHaveIcon,
    assetTypes,
    tabIndex,

  } = useAssetsContext();

  function renderAssosiatedLiability(assetType: string,assetTabIndex : number) {
    if (assetType === "realestate") {
      const liabilityType = liabilityTypes.find(liability => liability.Type === "mortgage");

      if (liabilityType){
        return renderLiability(liabilityType, false,assetTabIndex);
      }
    }

    return;
  }

  function renderLiability(liabilityType: LiabilityTypeItem, ignoreAssosiated: boolean,tabIndex : number) {
    if (shouldRenderLiability(liabilityType, ignoreAssosiated) && isAssetVisible(liabilityType.Type)) {
      return (
        <SwiperSlide key={liabilityType.Type}>
          <IconCardComponent
            tabIndex={tabIndex}
            hasError={liabilityHasError(liabilityType.Type)}
            valueLabel={liabilityType.DISPLAY}
            valuePlaceholder={labels.ASSET.AMOUNT}
            totalLabel={labels.ASSET_TOTAL}
            inputNumber={getLiabilityStateValue(liabilityType.Type)}
            displayNumber={getLiabilityStateValue(liabilityType.Type)}
            iconClassName={liabilityType.Icon}
            hideIcon={getAssetIconHidden(liabilityType.Type)}
            onChange={getLiabilityChangedFunction(liabilityType.Type)}
            currencyConfig={currencyConfig}
            onBlur={getLiabilityBlurFunction(liabilityType.Type)}
          />
        </SwiperSlide>
      )
    }
    else{
      return;  
    }     
  }

  function renderAsset(assetType : AssetTypeItem,assetTabIndex : number) {
    if (isAssetVisible(assetType.Type)) {
      return (
        <SwiperSlide key={assetType.Type}>
          <IconCardComponent
            tabIndex={assetTabIndex}
            valueLabel={assetType.DISPLAY}
            valuePlaceholder={labels.ASSET.AMOUNT}
            totalLabel={labels.ASSET_TOTAL}
            inputNumber={getAssetStateValue(assetType.Type)}
            displayNumber={getAssetStateValue(assetType.Type)}
            iconClassName={assetType.Icon}
            hideIcon={getAssetIconHidden(assetType.Type)}
            onChange={getAssetsChangedFunction(assetType.Type)}
            currencyConfig={currencyConfig}
            onBlur={getAssetsBlurFunction(assetType.Type)}
          />
        </SwiperSlide>
      )
    }
    return;
  }



  let localTabIndex = tabIndex;
  
  return (
    <WidgetComponent className="widget--slider" isExpanded={false}>
      <div className="widget-title-container">
        {iHaveIcon && <span className="widget-title-icon"
          dangerouslySetInnerHTML={{ __html: iHaveIcon ?? '' }}></span>}
        <h3 className="widget-title">
          {labels.TITLE}
        </h3>
      </div>
      <div
        className="swiper-component"
        ref={swiperContainerRef}
        onKeyUp={onSwiperContainerKeyUp}>

        <Swiper
          onSwiper={setSwiperInstance}
          spaceBetween={4}
          slidesPerView={2}
          pagination={{ clickable: true, enabled: true }}
          modules={[Pagination]}
          
        >
          {
            assetTypes.map((assetType) => {
              const asset = renderAsset(assetType, localTabIndex);
              localTabIndex += 1;
              const assosiatedLiability = renderAssosiatedLiability(assetType.Type, localTabIndex);
              if (assosiatedLiability) {
                localTabIndex += 1;
              }

              return (
                <div key={assetType.Type}>
                  {asset}
                  {assosiatedLiability}
                </div>
              )              
            })
          }
          {
            liabilityTypes.map((liabilityType) => {
              return renderLiability(liabilityType, true, localTabIndex++); 
            })
          }
        </Swiper>
      </div>
    </WidgetComponent>
  );
}

export default IHaveComponent;
