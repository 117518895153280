import { Observable, forkJoin, lastValueFrom, firstValueFrom, of, zip, delay } from 'rxjs';
import { GoalSection } from "@flexfront/models";
import {
  FetchSimulationPayload,
  FetchSimulationResponse,
  fetchSimulationResults,
} from "@flexfront/simulations";
import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  createSelector
} from "@reduxjs/toolkit";
import { setHasLegend, setIncludeSnapshot } from "../chartLegend/chartLegend.slice";
import { environment } from "../../../../environments/environment";
import { saveDebug } from "../debug/debug.slice";
import { saveTotalWealthSnapshot } from "../snapshots/snapshots.slice";
import { AppDispatch, RootState } from "../store";
import { GoalResult, SimulationState } from "./simulation.state";
import { revertAll } from '../hooks';
import { setDreamHasInvalidDate, saveRetirement, setPreserveCapitalHasInvalidDate, setSecureFamilyHasInvalidDate } from '../goals/goals.slice';
import { saveHasInvalidSavingsDates } from '../savings.slice';
import { addErrorMessage, removeErrorMessage } from '../notifications/notifications.slice';
import { getQuarterDate } from '@flexfront/ui/react';
import { GoalsState } from '../goals/goals.state';
import { NotificationType } from '../notifications/notifications.state';
import { setShortfallEliminationFactor, setShortfallEliminationSavings, setShortfallLoading } from '../shortfall-elimination.slice';

export const RESULTS_FEATURE_KEY = "results";

const initialState: SimulationState = {
  isLoading: false,
  ageAtQuarter: [],
  totalWealth: {
    percentile5: [],
    percentile50: [],
    percentile75: [],
  },
  illiquid: {
    percentile5: [],
    percentile50: [],
    percentile75: [],
  },
  retirementResult: { achievementFraction: 0, shortfall: 0 },
  legacyResult: { achievementFraction: 0, shortfall: 0 },
  dreamResult: { achievementFraction: 0, shortfall: 0 },
  secureFamilyResult: { achievementFraction: 0, shortfall: 0 },
  preserveCapitalResult: { achievementFraction: 0, shortfall: 0 },
  leverageQuarter: 0,
  activeQuarters: 0,
  totalQuarters: 0,
  maxPensionPayout: undefined,
  invalidDateGoals: [],
  invalidDateContributions: [],
};

export const simulationSlice = createSlice({
  name: RESULTS_FEATURE_KEY,
  initialState,
  reducers: {
    setSimulation: (state, action: PayloadAction<SimulationState>) => {
      state = { ...action.payload };
      return state;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSimulation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSimulation.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(revertAll, () => initialState);
  },
});

function canUpdateSimulation(state: RootState) {
  if (state.liabilities.mortgage > state.assets.realEstate){
    return false;
  }

  return true;
}

export const fetchSimulation = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  {
    abortSignal: AbortSignal,
    disableShortfallEliminationCall?: boolean
  },
  {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
  }
>("simulation/fetchSimulation", async ({abortSignal, disableShortfallEliminationCall}, thunkApi) => {
  const currentState = thunkApi.getState();
  const requestPayload = getRequestPayload(currentState);

  if (!canUpdateSimulation(currentState)){
    return;
  }

  const fetchSimulationResultsCall = new Observable<FetchSimulationResponse>((observer) => {
    fetchSimulationResults(
      environment.apiBaseUrl,
      currentState.tenantConfig.selectedTenantConfig.templateId,
      requestPayload,
      abortSignal
    )
    .then(async (response) => {
      const hadValues = currentState.simulation.totalWealth.percentile50.filter((entry: number) => entry > 0).length > 0;
      
      if (hadValues) {
        thunkApi.dispatch(
          saveTotalWealthSnapshot(currentState.simulation.totalWealth)
        );
      }

      const newSimulationState = getSimulationState(currentState, response);

      //Add external updaters here.. (Retirement, Goal, Contributions, etc.)
      enforceAgeValidations(currentState, newSimulationState, thunkApi.dispatch);
      updateRetirement(currentState, newSimulationState, thunkApi.dispatch);

      await thunkApi.dispatch(setSimulation(newSimulationState));

      const hasValues = newSimulationState.totalWealth.percentile50.filter((entry: number) => entry > 0).length > 0;

      if (hasValues) {
        if (!hadValues) {
          thunkApi.dispatch(setHasLegend(true));
        } else if (currentState.userSettings.includePrevious) {
          thunkApi.dispatch(setIncludeSnapshot(currentState.userSettings.includePrevious));
        }
      }

      if (currentState.debug.includeDebug) {
        thunkApi.dispatch(
          saveDebug({
            request: response?.request,
            response: response?.response,
          })
        );
      }

      observer.next();
      observer.complete();
    });
  });

  const fetchShortfallEliminationCall = new Observable<[FetchSimulationResponse | null, FetchSimulationResponse | null]>((observer) => {
    if (!disableShortfallEliminationCall && hasValidGoals(currentState.goals)) {
      thunkApi.dispatch(setShortfallLoading(true));
  
      lastValueFrom(getShortfallEliminationCalls(currentState, requestPayload, abortSignal))
        .then(async (shortfallEliminationResponses) => {

          if (shortfallEliminationResponses.length > 0) {
            await thunkApi.dispatch(setShortfallEliminationFactor(shortfallEliminationResponses[0]?.shortfallElimination));
          }
      
          if (shortfallEliminationResponses.length > 1) {
            await thunkApi.dispatch(setShortfallEliminationSavings(shortfallEliminationResponses[1]?.shortfallElimination));
          }

          await thunkApi.dispatch(setShortfallLoading(false));
          
          observer.next();
          observer.complete();
        });

    } else {
      observer.next();
      observer.complete();
    }
  });
  
  await firstValueFrom(zip(
      fetchSimulationResultsCall,
      fetchShortfallEliminationCall
    ));
});

function hasValidGoals(goals: GoalsState) {
  const hasRetirement = goals.retirement.retirementMonthlyPayout > 0;
  const hasLegacy = goals.legacy.amount > 0;
  const hasDream = goals.dream.amount > 0 && goals.dream.year > 0 && !goals.dream.hasInvalidDate;
  const hasPreserveCapital =
    goals.preserveCapital.amount > 0 &&
    goals.preserveCapital.startDate &&
    goals.preserveCapital.endDate &&
    !goals.preserveCapital.hasInvalidDate;
  const hasSecureFamily =
    goals.secureFamily.amount > 0 && goals.secureFamily.year > 0 && !goals.secureFamily.hasInvalidDate;

  return hasRetirement || hasLegacy || hasDream || hasPreserveCapital || hasSecureFamily;
}

function enforceAgeValidations(currentState: RootState, newSimulationState: SimulationState, dispatch: AppDispatch) {
  const hasInvalidDreamDate = newSimulationState.invalidDateGoals.find((_) => _ === 'Dream') !== undefined;
  dispatch(setDreamHasInvalidDate(hasInvalidDreamDate || currentState.goals.dream.hasInvalidDate));

  const hasInvalidPreserveCapitalDate =
    newSimulationState.invalidDateGoals.find((_) => _ === 'preserveCapital') !== undefined;
  dispatch(
    setPreserveCapitalHasInvalidDate(hasInvalidPreserveCapitalDate || currentState.goals.preserveCapital.hasInvalidDate)
  );

  const hasInvalidSecureFamilyDate =
    newSimulationState.invalidDateGoals.find((_) => _ === 'SecureFamily') !== undefined;
  dispatch(setSecureFamilyHasInvalidDate(hasInvalidSecureFamilyDate || currentState.goals.secureFamily.hasInvalidDate));

  const hasInvalidMonthlyDate = newSimulationState.invalidDateContributions.find((_) => _ === 'nominal') !== undefined;
  const hasInvalidLumpsumDate = newSimulationState.invalidDateContributions.find((_) => _ === 'lump') !== undefined;
  dispatch(saveHasInvalidSavingsDates({ hasInvalidMonthlyDate , hasInvalidLumpsumDate }));

  const hasAgeError =
    hasInvalidDreamDate ||
    hasInvalidPreserveCapitalDate ||
    hasInvalidSecureFamilyDate ||
    hasInvalidMonthlyDate ||
    hasInvalidLumpsumDate;

  if (hasAgeError) {
    dispatch(
      addErrorMessage({
        type: NotificationType.ON_AGE_CHANGE_ERROR,
        message: currentState.notifications.errorMessageLabels.GOAL_OR_CONTRIBUTION_AGE_CONFLICT_ERROR_MESSAGE
      })
    );
  } else {
    dispatch(
      removeErrorMessage(NotificationType.ON_AGE_CHANGE_ERROR)
    );
  }
}

function updateRetirement(currentState: RootState, newSimulationState: SimulationState, dispatch: AppDispatch) {
  const yearsUntilRetirement = Math.floor(newSimulationState.activeQuarters / 4);
  const retirementAge = currentState.personal.age + yearsUntilRetirement;
  const retirementDate = getQuarterDate(new Date());
  retirementDate.setFullYear(retirementDate.getFullYear() + yearsUntilRetirement);

  dispatch(
    saveRetirement({
      ...currentState.goals.retirement,
      retirementAge: retirementAge,
      retirementStartDateUtc: Date.UTC(retirementDate.getFullYear(), retirementDate.getMonth(), 1),
    })
  );
}

function getShortfallEliminationCalls(
  currentState: RootState,
  requestPayload: FetchSimulationPayload,
  abortSignal: AbortSignal
) {
  const isShortfallEliminationFactor =
    currentState.goals.retirement.retirementMonthlyPayout > 0 ||
    currentState.goals.legacy.amount > 0 ||
    currentState.goals.dream.amount > 0 ||
    currentState.goals.preserveCapital.amount > 0 ||
    currentState.goals.secureFamily.amount > 0;

  const isShortfallEliminationSavings = currentState.savings.monthly > 0;

  const fetchSimulationResultsWithFactor = fetchSimulationResults(
    environment.apiBaseUrl,
    currentState.tenantConfig.selectedTenantConfig.templateId,
    {...requestPayload, shortfallEliminationType: "factor"},
    abortSignal
  );

  const fetchSimulationResultsWithSavings = fetchSimulationResults(
    environment.apiBaseUrl,
    currentState.tenantConfig.selectedTenantConfig.templateId,
    {...requestPayload, shortfallEliminationType: "additional_saving"},
    abortSignal
  );

  let fetchSimulationResultsCall: Observable<[FetchSimulationResponse | null, FetchSimulationResponse | null]> = forkJoin([of(null), of(null)]);

  if (isShortfallEliminationFactor && isShortfallEliminationSavings) {    
    fetchSimulationResultsCall = forkJoin([fetchSimulationResultsWithFactor, fetchSimulationResultsWithSavings]);
  } else if (isShortfallEliminationFactor) {
    fetchSimulationResultsCall = forkJoin([fetchSimulationResultsWithFactor, of(null)]);
  } else if (isShortfallEliminationSavings) {
    fetchSimulationResultsCall = forkJoin([of(null), fetchSimulationResultsWithSavings]);
  }

  return fetchSimulationResultsCall;
}

function getRequestPayload(state: RootState) {
  const goals = [];
  if (state.goals.dream.amount > 0) {
    goals.push({
      nominal: state.goals.dream.nominal,
      name: "Dream",
      amount: state.goals.dream.amount,
      year: state.goals.dream.year,
    });
  }

  if (state.goals.secureFamily.amount > 0) {
    goals.push({
      nominal: state.goals.secureFamily.nominal,
      name: "SecureFamily",
      amount: state.goals.secureFamily.amount,
      year: state.goals.secureFamily.year,
    });
  }

  return {
    personal: state.personal,
    goals: {
      retirementNominal: state.goals.retirement.nominal,
      retirementAge: state.goals.retirement.retirementAge,
      retirementMonthlyPayout: state.goals.retirement.retirementMonthlyPayout,
      legacyNominal: state.goals.legacy.nominal,
      legacy: state.goals.legacy.amount,
      preserveCapitalNominal: state.goals.preserveCapital.nominal,
      preserveCapitalStartDate: state.goals.preserveCapital.startDate,
      preserveCapitalEndDate: state.goals.preserveCapital.endDate,
      preserveCapitalAmount: state.goals.preserveCapital.amount,
      goals: goals,
    },
    assets: [
      { type: "realestate", amount: state.assets.realEstate },
      { type: "equity", amount: state.assets.equity },
      { type: "bonds", amount: state.assets.bonds },
      { type: "cash", amount: state.assets.cash },
    ],
    liabilities: [
      { type: "mortgage", amount: state.liabilities.mortgage }
    ],
    monthlySavings: state.savings.monthly,
    monthlySavingsYear: state.savings.monthlyYear,
    lumpsum: state.savings.lumpsum,
    lumpsumYear: state.savings.lumpsumYear,
    climateChange: state.climateChange,
    includeDebug: state.debug.includeDebug,
    shortfallEliminationType: "skip"
  };
}

export const selectSimulationState = createSelector(
  (state: RootState) => state.simulation,
  (results) => results
);

function getSimulationState(
  state: RootState,
  response: FetchSimulationResponse
) {
  
  return {
    ...state.simulation,
    ageAtQuarter: response.ageAtQuarter,
    totalWealth: response.totalWealth,
    illiquid: response.illiquid,
    retirementResult: getGoalResult(
      response.goals,
      "pension",
      state.goals.retirement.retirementMonthlyPayout *
        3 *
        response.totalQuarters -
        response.activeQuarters
    ),
    legacyResult: getGoalResult(response.goals, "legacy", state.goals.legacy.amount),
    dreamResult: getGoalResult(
      response.goals,
      "dream",
      state.goals.dream.amount
    ),
    secureFamilyResult: getGoalResult(
      response.goals,
      "secureFamily",
      state.goals.secureFamily.amount
    ),
    preserveCapitalResult: getGoalResult(response.goals, "preserveCapital", state.goals.preserveCapital.amount),
    leverageQuarter: response.leverageQuarter,
    activeQuarters: response.activeQuarters,
    totalQuarters: response.totalQuarters,
    maxPensionPayout: response.maxPensionPayout,
    invalidDateGoals: response.invalidDateGoals ?? [],
    invalidDateContributions: response.invalidDateContributions ?? [],
  };
}

function getGoalResult(
  goals: GoalSection[],
  goalType: string,
  defaultShortfall: number
): GoalResult {
  const goal = goals.find((goal) => goal.type === goalType);
  let goalShortfall = 0;
  if (goal) {
    goalShortfall = goal.shortfall;
  } else {
    goalShortfall = defaultShortfall;
  }

  return {
    achievementFraction: goal?.achievementFraction ?? 0,
    shortfall: goalShortfall,
  };
}

export const { setSimulation } = simulationSlice.actions;
