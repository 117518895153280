import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTenantColors } from "../hooks/useTenantColors";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { fetchLayouts } from "../store/layout/layouts.slice";
import { fetchTenantConfig, fetchTenantConfigAll, fetchTenantConfigByDomain, selectTenantConfig } from "../store/tenant-config/tenant-config.slice";
import { useEffectOnce } from "usehooks-ts";
import { GridLoader } from "react-spinners";
import { CustomLoaderComponent } from "../../default/components/custom-loader-component/custom-loader-component";
import { LabelsProvider } from "../providers/labels-provider";
import { selectUserSettingsState } from "../store/user-settings.slice";

export interface EnsureTenantConfig {
    children: JSX.Element;
}

export function EnsureTenantConfig(props: EnsureTenantConfig) {
    const host = window.location.hostname;
    let abortController = new AbortController();
    const { name } = useParams();
    const { pathname } = useLocation();
    const dispatch = useAppDispatch();
    const [loadedName, setLoadedName] = useState<string | undefined>();

    const [children, setChildren] = useState<JSX.Element>(<CustomLoaderComponent>
        <GridLoader
          className="spinner"
          loading={true}
        />
      </CustomLoaderComponent>);

    useEffect(() => {
        const fetchConfig = async () => {
            await dispatch(fetchLayouts(abortController.signal));
    
            if (pathname.includes("list")) {
                await dispatch(fetchTenantConfigAll(abortController.signal));
            } else {
                const r = await dispatch(fetchTenantConfigByDomain({ domain: host, abortSignal: abortController.signal }))
                if (r.type === fetchTenantConfigByDomain.fulfilled.type && (r.payload as any).payload.templateId === 0) {
                    if (name !== loadedName) {
                        await dispatch(fetchTenantConfig({ name: name, abortSignal: abortController.signal }));
                        setLoadedName(name);
                    }
                }
            }
        }

        abortController.abort();
        abortController = new AbortController();

        fetchConfig()
            .then(() => {
                setChildren(props.children);
            });
    }, [host, name]);

    return children;
}