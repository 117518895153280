import { InfoLabel } from "../../../common/labels/info-component.labels";
import { GetChildProperties } from "@flexfront/utils";
import '../info-modal-component/info-modal-component.scss';

export interface InfoComponentProps {
  labels: any;
}

export function InfoComponent(props: InfoComponentProps) {
  const infoLabels: InfoLabel[] = GetChildProperties<InfoLabel>(props.labels)

  return (
    <div className="info-component-content">
      {infoLabels.map((info, index) => {
        return (
          <div key={index}>
            <h3>{info.TITLE}</h3>
            <p dangerouslySetInnerHTML={{ __html: info.DESCRIPTION }}></p>
          </div>
        );
      })}
    </div>
  );
}

export default InfoComponent;
