import { useEffect, useState } from 'react';
import { LegendComponentLabels } from '../../../common/labels/legend-component-labels';
import './legend-component.scss';

export interface LegendComponentProps {
  includeIlliquid: boolean;
  includeSnapshot: boolean;
  needsLeverage: boolean;
  labels: LegendComponentLabels;
}

export function LegendComponent(props: LegendComponentProps) {
  const [includeSnapshot, setIncludeSnapshot] = useState<boolean>(props.includeSnapshot);
  const [includeIlliquid, setIncludeIlliquid] = useState<boolean>(props.includeIlliquid);
  const [needsLeverage, setNeedsLeverage] = useState<boolean>(props.needsLeverage);
  const [snapshotLegendModifierClass, setSnapshotLegendModifierClass] = useState<string>();


  useEffect(() => {
    setIncludeSnapshot(props.includeSnapshot);
    if (props.includeSnapshot) {
      setSnapshotLegendModifierClass('');
    }
  }, [props.includeSnapshot]);

  useEffect(() => {
    setIncludeIlliquid(props.includeIlliquid);
  }, [props.includeIlliquid]);

  useEffect(() => {
    setNeedsLeverage(props.needsLeverage);
  }, [props.needsLeverage]);

  return (
    <div className="chart-legend">
      <span>{props.labels.TITLE}</span>
      <label className="chart-legend-title">{props.labels.NET_ASSETS_TITLE}</label>
      <div className="chart-legend-item">
        <div className="rectangle chart-legend-good" />
        <span className="chart-legend-label">{props.labels.GOOD}</span>
      </div>
      <div className="chart-legend-item">
        <hr className="chart-legend-expected" />
        <span className="chart-legend-label">{props.labels.EXPECTED}</span>
      </div>
      <div className="chart-legend-item">
        <div className="rectangle chart-legend-adverse" />
        <span className="chart-legend-label">{props.labels.ADVERSE}</span>
      </div>
      {includeIlliquid && (
        <>
          <label className="chart-legend-title">{props.labels.REAL_ESTATE_TITLE}</label>
            <div className="chart-legend-item">
              <div className="rectangle chart-legend-illiquid-good illiquid" />
              <span className="chart-legend-label">{props.labels.PROPERTY_GOOD}</span>
            </div>
            <div className="chart-legend-item">
              <hr className="chart-legend-illiquid-expected" />
              <span className="chart-legend-label">{props.labels.PROPERTY_EXPECTED}</span>
            </div>
            <div className="chart-legend-item">
              <div className="rectangle chart-legend-illiquid-adverse" />
              <span className="chart-legend-label">{props.labels.PROPERTY_ADVERSE}</span>
            </div>
        </>
      )}
      {includeSnapshot && (
        <div className={`chart-legend-item ${snapshotLegendModifierClass}`}>
          <hr className="chart-legend-snapshot" />
          <span className="chart-legend-label">{props.labels.PREVIOUS}</span>
        </div>
      )}
    </div>
  )
}

export default LegendComponent;
