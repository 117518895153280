import { defaultCurrencyInputFormatter } from '../../../common/shared/locale';
import { CurrencyConfig, MoneyInput } from '@flexfront/ui/react';
import { YearInputComponent } from './year-input-component';
import { useEffect, useState } from 'react';

export interface MoneyInYearEditComponentProps {
  yearLabel: string;
  amountLabel: string;
  year: number;
  amount: number;
  stepValue?: number;
  hasError?: boolean;
  minYear?: number;
  maxYear?: number;
  itemConfirmLabel?: string;
  onRequiresUpdate?: (year: number, value: number) => void;
  onValidate?:(hasError: boolean) => void;
  currencyConfig?: CurrencyConfig;
  isYearValid?: boolean;
}

export function MoneyInYearEditComponent(props: MoneyInYearEditComponentProps) {
  const [year, setYear] = useState<number>(props.year);
  const [amount, setAmount] = useState<number>(props.amount);

  function validate(year?: number) {
    let isValid = true;

    if (year && props.minYear && year < props.minYear) {
      isValid = false;
    }

    if (year && props.maxYear && year > props.maxYear) {
      isValid = false;
    }

    return isValid;
  }

  function validateYear() {
    const isValid = validate(year);
    if (props.onValidate) {
      props.onValidate(isValid);
    }
    return isValid;
  }

  function onYearChange(newYear: number) {
    setYear(newYear);
  }

  function onAmountChange(newAmount: number) {
    setAmount(newAmount);
  }

  function saveValue() {
    validateYear();
    if (props.onRequiresUpdate) {
      props.onRequiresUpdate(year,amount);
    }
  }

  return (
    <>
      <div className="edit-container-field edit-container-field-label">{props.yearLabel}</div>
      <YearInputComponent 
        year={year} 
        minYear={props.minYear}
        maxYear={props.maxYear}
        hasError={props.hasError || !props.isYearValid} 
        onYearChanged={onYearChange}  
      />
      <div className="money-input-container">
        <div
          className={`money-input-label ${props.hasError ? 'money-input-label-error': ''}`}>
            {props.amountLabel}
        </div>
      </div>
      <MoneyInput
        inputNumber={amount}
        currencyConfig={props.currencyConfig}
        currencyFormatter={defaultCurrencyInputFormatter}
        showCurrencySymbol={true}
        groupedClassNameSuffix="border"
        showIncrementAndDecrement={true}
        stepValue={props.stepValue ? props.stepValue : 1}
        onChange={onAmountChange}
        maxValue={9999999}
      />
      <button
          onClick={saveValue}
          className='button button--big button--big--solid edit-container-item-confirm'>
            {props.itemConfirmLabel}
        </button>
    </>
  );
}
