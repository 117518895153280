import { useTenantLogo } from "../../../common/hooks/useTenantLogo";

export function LogoComponent() {
    const tenantLogo = useTenantLogo();
    
    return(
        <div className="header-logo">
          {tenantLogo && <img className="logo" src={tenantLogo} alt="logo" />}
        </div>
    );
}