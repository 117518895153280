import { logout } from "../../store/auth/auth.slice";
import { useAppDispatch } from "../../store/hooks";
import "./logout-component.scss";

export function LogoutComponent() {
  const dispatch = useAppDispatch();
  const userLogout = () => {
    dispatch(logout());
  };
  return (
    <div className="button-group button-group--card">
      <button
        type="button"
        className="button button--card-solid config-logout"
        onClick={userLogout}
      >
        Logout
      </button>
    </div>
  );
}

export default LogoutComponent;
