import { ReactNode, useEffect, useRef, useState } from 'react';
import { selectTimelineWidth, selectXOffset, setTimelineRangeOffsets } from '../../../common/store/timeline/timeline.slice';
import { useAppDispatch, useAppSelector } from '../../../common/store/hooks';

export interface TimelineContainerComponentProps {
  children: ReactNode;
  isRangeContainer?: boolean;
  className?: string;
}

export function TimelineContainerComponent(props: TimelineContainerComponentProps) {
  const timelineWidth = useAppSelector(selectTimelineWidth);
  const xOffset = useAppSelector(selectXOffset);
  const [heightOverride, setHeightOverride] = useState<number|undefined>(undefined);
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (containerRef.current) {
      if (props.isRangeContainer) {
        const rangeOffsets: number[] = [];
        const height = containerRef.current.clientHeight;
        const timelineRanges = Array.from(containerRef.current.children).filter((_) =>
          _.className.includes('timeline-range')
        );

        timelineRanges.forEach((element: Element) => {
          if (element instanceof HTMLDivElement) {
            rangeOffsets.push(element.offsetTop - height);
          }
        });

        rangeOffsets.sort();

        dispatch(setTimelineRangeOffsets(rangeOffsets));
      } else {
        
        let maxHeight = 0;

        if (containerRef.current) {
          const childElements = Array.from(containerRef.current.childNodes) as HTMLElement[];
          
    
          // Iterate through child elements
          childElements.forEach((currentElement) => {
            const currentElementHeight = currentElement.clientHeight;
    
            // Compare and update maxHeight and maxHeightElement if needed
            if (currentElementHeight > maxHeight) {
              maxHeight = currentElementHeight;
            }
          });
        }

        setHeightOverride(maxHeight);
      }
    }
  }, [props.isRangeContainer, props.children, dispatch]);

  return (
    <div
      ref={containerRef}
      className={`timeline-container ${
        props.isRangeContainer ? 'timeline-range-container' : 'timeline-markers-container'
      } ${props.className ? props.className : ''}`}
      style={{ width: timelineWidth, height: heightOverride ? heightOverride : "100%", left: xOffset }}
    >
      {props.children}
    </div>
  );
}

export default TimelineContainerComponent;
