import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';

export interface ToggleComponentProps {
    classNamePrefix: string;
    title?: string;
    options: any[];
    value?: any;
    tabIndex?: number;
    className?: string;
    onChange?: (option: any) => void;
}

export default function ToggleComponent(props: ToggleComponentProps) {
    const [value, setValue] = useState<any | undefined>(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    return (<>
            <label className={`${props.classNamePrefix}-input-label`}>{props.title}</label>
            <div
                className={`${props.classNamePrefix}-toggle-switch`}
                tabIndex={props.tabIndex}>
                {props.options.map((o, i) => {
                    return <React.Fragment key={i}>
                        {i > 0 && <span>|</span>}
                        <input
                            type="radio"
                            id={`toggle-${o.value}`}
                            name="toggle"
                            value={o.value}
                            checked={o.value === value?.value}
                            onChange={() => props.onChange ? props.onChange(o) : null} />
                       
                        <Popup
                            trigger={<label
                                htmlFor={`toggle-${o.value}`} 
                                dangerouslySetInnerHTML={{ __html: o.label ?? '' }}>
                            </label>}
                            position={o.tooltip_position ?? "bottom center"}
                            on={['hover', 'click']}
                            closeOnDocumentClick
                            mouseLeaveDelay={300}
                            mouseEnterDelay={0}
                            className="quirion-icon-card-inner-details__tooltip gender-tooltip"
                            arrow={true}
                           >
                                {o.tooltip}
                        </Popup>
                    </React.Fragment>
                })}
            </div>
        </>
    );
}