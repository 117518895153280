import { downloadBlobAndOpen } from "@flexfront/ui/react";
import { ClimateScenarioComponentLabels } from "../../../common/labels/climate-scenario-component.labels";

export const getClimateScenarioOptions = (labels: ClimateScenarioComponentLabels) => {
    return Object.keys(labels.SCENARIOS)
        .map((key, index) => {
        const scenario = labels.SCENARIOS[key];
        return {
            value: key,
            label: scenario.TITLE
        };
        });
}

export const downloadScenarios = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    downloadBlobAndOpen('/assets/docs/PAGE.pdf', 'scenario', 'pdf', 'application/pdf');
}