import { ClimateScenarioComponentLabels } from "../../../common/labels/climate-scenario-component.labels";

export interface ClimateScenarioIconCardComponentProps{
    labels: ClimateScenarioComponentLabels;
    climateScenario: string;
    selectedClimateScenario?: string;
    onClimateScenarioSelected?:(climateScenario: string) => void;
}


export function ClimateScenarioIconCardComponent(props: ClimateScenarioIconCardComponentProps){
    
    const onSelectionChange = () => {
        if (props.onClimateScenarioSelected) {
          props.onClimateScenarioSelected(props.climateScenario);
        } 
    }

    let cardClassName = "icon-card icon-card-climate-scenario icon-card--solid"
    if (props.selectedClimateScenario && props.climateScenario==props.selectedClimateScenario) {
        cardClassName += " icon-card--selected";
    }

    return(
        <div className={cardClassName} onClick={onSelectionChange}>
            <div className="icon-card-type">
                <div className="icon-card-value icon-card-value-climate-name">{props.labels.SCENARIOS[props.climateScenario].TITLE}</div>
                {props.climateScenario!="NONE" ?
                <>
                <div className="icon-card-value icon-card-value-climate-icons">
                    <div>
                        <span><label className="icon-sun"></label></span> <span>&nbsp;</span>
                        <span>{props.labels.SCENARIOS[props.climateScenario].TEMP_INCREASE}</span>
                    </div>
                    <div>
                        <span><label className="icon-sea-level"></label></span> <span>&nbsp;</span>
                        <span>{props.labels.SCENARIOS[props.climateScenario].SEA_LEVEL_RISE}</span>
                    </div>
                </div>
                </>:''}
            </div>
        </div>
    );
}