import { CurrencyConfig, MoneyInput } from '@flexfront/ui/react';
import { defaultCurrencyInputFormatter } from '../../../common/shared/locale';
import { useState } from 'react';

export interface MoneyEditComponentProps {
  amountLabel: string;
  amount: number;
  itemConfirmLabel?: string;
  stepValue?: number;
  onRequiresUpdate: (amount: number) => void;
  currencyConfig?: CurrencyConfig;
  hasError?: Boolean;
}

export function MoneyEditComponent(props: MoneyEditComponentProps) {
  const [amount, setAmount] = useState<number>(props.amount);

  function onAmountChanged(newAmount: number) {
    setAmount(newAmount);
  }

  function saveValue() {
    if (props.onRequiresUpdate) {
      props.onRequiresUpdate(amount);
    }
  }

  return (
    <>
      <div className="money-input-container">
        <div
          className={`money-input-label ${props.hasError ? 'money-input-label-error': ''}`}>
            {props.amountLabel}
        </div>
      </div>
      <MoneyInput
        inputNumber={amount}
        currencyConfig={props.currencyConfig}
        currencyFormatter={defaultCurrencyInputFormatter}
        showCurrencySymbol={true}
        groupedClassNameSuffix={`border ${props.hasError ? 'error error-border': ''}`}
        showIncrementAndDecrement={true}
        stepValue={props.stepValue ? props.stepValue : 1}
        onChange={onAmountChanged}
        maxValue={9999999}
      />
      {props.itemConfirmLabel && <button
          onClick={saveValue}
          className='button button--big button--big--solid edit-container-item-confirm'>
            {props.itemConfirmLabel}
        </button>}
    </>
  );
}
