import { ReactNode, useEffect, useState } from 'react';
import './money-component.scss';
import { CurrencyConfig, CurrencyInputFormat, CurrencyInputFormatter } from '@flexfront/ui/react';
import { getCurrencyFormattedAmount } from '../../../common/shared/locale';
import Popup from 'reactjs-popup';
import { PopupPosition } from 'reactjs-popup/dist/types';

export interface MoneyComponentProps {
  iconClassName: string;
  hideIcon?: boolean;
  title: string;
  titleValue?: string | number | undefined;
  isTitleCurrency?: boolean;
  amountLabel: string;
  amountValue: string | number;
  titleValuePlaceholder?: string;
  amountValuePlaceholder?: string;
  onClick?: () => void;
  currencyConfig?: CurrencyConfig;
  hasError?: boolean;
  showTooltip?: boolean;
  infoIcon?: ReactNode;
  tooltip?: string;
  tooltipPosition?: PopupPosition;
}

export function MoneyComponent(props: MoneyComponentProps) {
  const [isOpen, setIsOpen] = useState(false);

  const getTitleValue = () => {
    if (props.isTitleCurrency && props.titleValue !== undefined) {
      return getCurrencyFormattedAmount(+props.titleValue, props.currencyConfig);
    }

    return props.titleValue && props.titleValue !== 0 ? props.titleValue : props.titleValuePlaceholder;
  }

  useEffect(() => {
    const handleScroll = () => {
      setIsOpen(false);
    };

    const dashboard = document.getElementById('dashboard');

    dashboard?.addEventListener('scroll', handleScroll);

    return () => {
      dashboard?.removeEventListener('scroll', handleScroll);
    };
  }, [isOpen]);

  return (
    <div className="money-component" onClick={props.onClick}>
      {!props.hideIcon && <label className={`money-component-icon ${props.iconClassName}`} />}
      <div className="money-component-detail">
        <div className="money-component-detail-container">
          <div className={`money-component-title ${props.hasError ? 'error': ''}`} dangerouslySetInnerHTML={{ __html: props.title }}></div>
          {props.showTooltip && props.infoIcon && <Popup
                        trigger={<div className="quirion-icon-card-inner__info-container">
                            <div className="icon-info info-icon"></div>
                        </div>}
                        position={props.tooltipPosition ?? "top center"}
                        open={isOpen}
                        closeOnDocumentClick
                        mouseLeaveDelay={300}
                        mouseEnterDelay={0}
                        className="quirion-icon-card-inner__tooltip"
                        arrow={true}
                        onOpen={() => setIsOpen(true)}>
                            {props.tooltip}
                    </Popup>}
          <div className={`money-component-title money-component-value ${props.hasError ? 'error': ''}`}>
            {getTitleValue()}
          </div>
        </div>
        <div className="money-component-detail-container">
          <div className={`money-component-amount ${props.hasError ? 'error': ''}`}>{props.amountLabel}</div>
          <div className={`money-component-amount money-component-value ${props.hasError ? 'error': ''}`}>
            {getCurrencyFormattedAmount(+props.amountValue, props.currencyConfig)}
          </div>
        </div>
      </div>
      <label className="money-component-edit icon-pen"/>
    </div>
  );
}

export default MoneyComponent;
