import { DisclaimerComponentLabels } from "./dashboard.labels";
export interface LandingModalComponentLabels {
    TITLE: string;
    INFO: string;
    START:string;
    DISCLAIMER: string;
  }

  export function fromLandingModalLabels(landingModalLabels: LandingModalComponentLabels, disclaimerLabels: DisclaimerComponentLabels) {
    return {
      ...landingModalLabels,
      DISCLAIMER: disclaimerLabels.TEXT
    };
  }