import localStorage from "redux-persist/es/storage";
import { useAppDispatch, useAppSelector } from "../../../common/store/hooks";
import { selectTenantConfig } from "../../../common/store/tenant-config/tenant-config.slice";
import { selectPersonalState } from "../../../common/store/personal.slice";
import moment from "moment";
import { CustomLoaderComponent } from "../custom-loader-component/custom-loader-component";
import { ScaleLoader } from "react-spinners";
import "./download-report-component.scss";
import { environment } from "../../../../environments/environment";
import { selectAssetsState } from "../../../common/store/assets/assets.slice";
import { NotificationType } from "../../../common/store/notifications/notifications.state";
import { addErrorMessage, addInfoMessage, removeInfoMessage } from "../../../common/store/notifications/notifications.slice";
import { selectSavingsState } from "../../../common/store/savings.slice";
import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from "../../../common/hooks/useTrackEvent";

export interface DownloadReportComponentProps {
    title: string;
    reportTitle: string;
    reportErrorMessage: string;
    reportInfoMessage: string;
    isInsideMenu:boolean;
    isReportDownloading?: boolean;
    onReportDowloading?: (isReportDownloading: boolean) => void;
}

export function DownloadReportComponent(props: DownloadReportComponentProps) {
    
    const tenantConfig = useAppSelector(selectTenantConfig);
    const personal = useAppSelector(selectPersonalState);
    const assets = useAppSelector(selectAssetsState);
    const savings = useAppSelector(selectSavingsState);
    const dispatch = useAppDispatch();
    const trackEvent = useTrackEvent();

    const savePDF = async () => {
        trackEvent({ category: trackEventCategories.REPORT, action: trackEventActions.CLICK, name: trackEventNames.DOWNLOAD });
        
        if (assets.cash === 0
            && assets.bonds === 0
            && assets.equity === 0
            && assets.realEstate === 0
            && savings.lumpsum === 0
            && savings.monthly === 0) {
            dispatch(addErrorMessage({type: NotificationType.REPORT_DOWNLOAD_ERROR, message: props.reportErrorMessage}));
        } else {
            if (props.onReportDowloading) {
                props.onReportDowloading(true);
            }

            const stateString = await localStorage.getItem('persist:root');
            const stateBase64String = btoa(stateString!);
            const url = `${environment.apiBaseUrl}/export`;
            
            const exportRequest = {
                name: tenantConfig.name,
                path: 'report',
                selector: 'report-loaded',
                title: props.reportTitle,
                state: stateBase64String
            };

            return fetch(url, {
                method: "POST",
                headers: {
                "Content-Type": "application/json"
                },
                body: JSON.stringify(exportRequest),
            })
            .then(async (response: Response) => {
                const blob = await response.blob();
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);

            let downloadFilename = `${tenantConfig.name}-${moment().format("DD-MM-yyyy")}-report.pdf`;
            if (personal?.name) {
                downloadFilename = `${personal.name}-${downloadFilename}`;
            }

            link.download = downloadFilename;
            link.click();
            if (props.onReportDowloading) {
                    props.onReportDowloading(false);
                    dispatch(
                        addInfoMessage({
                            type: NotificationType.REPORT_DOWNLOADED_SUCCESSFULLY,
                            message: props.reportInfoMessage
                        })
                    );
                    setTimeout(() => {
                        dispatch(removeInfoMessage(NotificationType.REPORT_DOWNLOADED_SUCCESSFULLY));
                      }, 30000);
                    
            }
            });
        }
    }

    return props.isInsideMenu ? 
        <div
            className="download-report"
            onClick={savePDF}>
                <i className="icon-download"></i>
                <span>{props.title}</span>
                <CustomLoaderComponent>
                    <ScaleLoader
                        loading={props.isReportDownloading}
                        height={11}
                        cssOverride={{
                            flex: 1,
                            width: '30px'
                        }}
                        />
                </CustomLoaderComponent>
        </div>
        :
        <div>
            <button type="button" className={`button pdf-download-button`}  onClick={savePDF}>
            {props.title}
            <i className="icon-download"></i>
            </button>
        </div>
        ;

}