import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { NudgeComponentLabels } from "../labels/nudge-component.labels";
import { useLayoutMaxAchievementFraction } from "../hooks/useLayoutMaxAchievementFraction";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { saveDream, saveLegacy, savePreserveCapital, saveRetirement, saveSecureFamily, selectGoalsState } from "../store/goals/goals.slice";
import { saveMonthlySavings, selectSavingsState } from "../store/savings.slice";
import { fetchSimulation, selectSimulationState } from "../store/simulation/simulation.slice";
import { selectAssetsState } from "../store/assets/assets.slice";
import { useCurrencyConfig } from "../hooks/useCurrencyConfig";
import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from "../hooks/useTrackEvent";
import { getCurrencyFormattedAmount } from "../shared/locale";
import { saveGoalsSliderIndex } from "../store/slider/goalsSlider.slice";
import { SimulationState } from "../store/simulation/simulation.state";
import { CurrencyConfig } from "@flexfront/ui/react";
import { Assets } from "../store/assets/assets.state";
import { selectTenantConfig } from "../store/tenant-config/tenant-config.slice";
import { TenantConfigState } from "../store/tenant-config/tenant-config.state";
import { ShortfallEliminationState, selectShortfallEliminationState } from "../store/shortfall-elimination.slice";
import { useDeviceBounds } from "../hooks/useDeviceBounds";
import { useIsInIframe } from "../hooks/useIsInIframe";

export interface NudgeContextProps {
    children: ReactNode;
    labels: NudgeComponentLabels;
    className?:string;
}

interface NudgeFunctionality {
    labels: NudgeComponentLabels;
    className?:string;
    nudgeText:string | undefined;
    newRetirement?: number;
    newLegacy?: number;
    newDream?: number;
    newSecureFamily?: number;
    newPreserveCapital?: number;
    newSavings?: number;
    newPension?: number;
    simulation: SimulationState;
    shortfallElimination: ShortfallEliminationState;
    isNudgeVisible:boolean;
    currencyConfig: CurrencyConfig,
    assets: Assets;
    tenantConfig: TenantConfigState,
    isInsideIframe: boolean,
    isHorizontallyCompact: boolean,
    getSavingsText: () => string;
    getSavingsTextWithoutShortfallElimination: () => string;
    getGoalText: () => string;
    getGoalTextWithoutshortfallElimination: () => string;
    getMaxPensionText: () => string;
    getMaxPensionTextWithoutshortfallElimination: () => string;
    needsShortfallElimination: () => boolean;
    hasGoals: () => boolean;
    hasSavingsAndShortfall: () => boolean;
    onApplySuggestions: () => void;
    onDiscardSuggestions: () => void;
    onHighGoalAchievementClose: () => void;
    setIsNudgeVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setNudgeText: React.Dispatch<React.SetStateAction<string | undefined>>;
    setNewRetirement: React.Dispatch<React.SetStateAction<number | undefined>>;
    setNewLegacy: React.Dispatch<React.SetStateAction<number | undefined>>;
    setNewDream: React.Dispatch<React.SetStateAction<number | undefined>>;
    setNewSecureFamily: React.Dispatch<React.SetStateAction<number | undefined>>;
    setNewPreserveCapital: React.Dispatch<React.SetStateAction<number | undefined>>;
    setNewSavings: React.Dispatch<React.SetStateAction<number | undefined>>;
    setNewPension: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const NudgeContext = createContext<NudgeFunctionality | undefined>(undefined);

export const useNudgeContext = (): NudgeFunctionality => {
    const context = useContext(NudgeContext);
    if (!context) {
        throw new Error('NudgeContext must be used within a NudgeProvider');
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useContext(NudgeContext)!;
};

export const NudgeProvider: React.FC<NudgeContextProps> = (props:NudgeContextProps ) => {

  const adjustmentFractionCutoff = 0.01;
  const maxAchievementFraction = useLayoutMaxAchievementFraction();
  

  const goals = useAppSelector(selectGoalsState);
  const savings = useAppSelector(selectSavingsState);
  const simulation = useAppSelector(selectSimulationState);
  const shortfallElimination = useAppSelector(selectShortfallEliminationState);
  const assets = useAppSelector(selectAssetsState);
  const tenantConfig = useAppSelector(selectTenantConfig);
  const dispatch = useAppDispatch();
  const { isHorizontallyCompact, } = useDeviceBounds();

  const [nudgeText, setNudgeText] = useState<string | undefined>();
  const [newSavings, setNewSavings] = useState<number>();
  const [newRetirement, setNewRetirement] = useState<number>();
  const [newLegacy, setNewLegacy] = useState<number>();
  const [newPreserveCapital, setNewPreserveCapital] = useState<number>();
  const [newDream, setNewDream] = useState<number>();
  const [newSecureFamily, setNewSecureFamily] = useState<number>();
  const [newPension, setNewPension] = useState<number>();
  const [isNudgeVisible, setIsNudgeVisible] = useState<boolean>(false);
  const [isshortfallEliminationInclude, setIsshortfallEliminationInclude] = useState<boolean>(true);
  const [goalActiveIndex, setgoalActiveIndexs] = useState<number>(-1);

  const currencyConfig = useCurrencyConfig();
  const trackEvent = useTrackEvent();
  const isInsideIframe = useIsInIframe();

  let abortController = new AbortController();

  function needsShortfallElimination() {
    const needsRetirement = goals.retirement.retirementMonthlyPayout > 0 &&
      simulation.retirementResult.achievementFraction < maxAchievementFraction;

    const needsLegacy = goals.legacy.amount > 0 && 
      simulation.legacyResult.achievementFraction < maxAchievementFraction;

    const needsDream = goals.dream.amount > 0 && 
      simulation.dreamResult.achievementFraction < maxAchievementFraction;

    const needsCapital = goals.preserveCapital.amount > 0 && 
      simulation.preserveCapitalResult.achievementFraction < maxAchievementFraction;

    const needsFamily = goals.secureFamily.amount > 0 && 
      simulation.secureFamilyResult.achievementFraction < maxAchievementFraction;

    return needsRetirement || needsLegacy || needsDream || needsCapital || needsFamily;
  }

  function hasSavingsAndShortfall() {
    const savingsPercentIncrease = shortfallElimination.shortfallEliminationSavings / 3 / savings.monthly;
    return (
      savings.monthly > 0 &&
      shortfallElimination.shortfallEliminationSavings > 0 &&
      savingsPercentIncrease > adjustmentFractionCutoff
    );
  }

  function hasGoals() {
    return (
      goals.legacy.amount > 0 ||
      goals.retirement.retirementMonthlyPayout > 0 ||
      goals.dream.amount > 0 ||
      goals.preserveCapital.amount > 0 ||
      goals.secureFamily.amount > 0
    );
  }

  function getSavingsText() {
    const newSavingsAmount = savings.monthly + shortfallElimination.shortfallEliminationSavings / 3;
    setNewSavings(newSavingsAmount);

    let text = props.labels.SAVINGS.replace('{amount}',getCurrencyFormattedAmount(newSavingsAmount, currencyConfig));

    const goalsText: string[] = [];
    if (goals.dream.amount > 0 && simulation.dreamResult.shortfall > 0) {
      goalsText.push(`<strong>${props.labels.DREAM_GOAL}</strong>`);
    }

    if (goals.secureFamily.amount > 0 && simulation.secureFamilyResult.shortfall > 0) {
      goalsText.push(`<strong>${props.labels.SECURE_FAMILY_GOAL}</strong>`);
    }

    if (goals.legacy.amount > 0 && simulation.legacyResult.shortfall > 0) {
      goalsText.push(`<strong>${props.labels.LEGACY_GOAL}</strong>`);
    }

    if (goals.preserveCapital.amount > 0 && simulation.preserveCapitalResult.shortfall > 0) {
      goalsText.push(`<strong>${props.labels.PRESERVE_CAPITAL_GOAL}</strong>`);
    }

    if (goals.retirement.retirementMonthlyPayout > 0 && simulation.retirementResult.shortfall > 0) {
      goalsText.push(`<strong>${props.labels.RETIREMENT_GOAL}</strong>`);
    }

    let newGoalsText='';
    if (goalsText && goalsText.length > 0) {
      newGoalsText= goalsText.reduce((previous, current, index) => {
        let joinText = ', ';
        if (index === goalsText.length - 1) {
          joinText = props.labels.JOIN;
        }
        return `${previous}${joinText}${current}`;
      });
    }
    text=text.replace('{text}', newGoalsText);
    return text;
  }

  function getSavingsTextWithoutShortfallElimination() {

    let text = props.labels.SAVINGS;
 
    const goalsText: string[] = [];
    if (goals.dream.amount > 0 && simulation.dreamResult.shortfall > 0) {
      goalsText.push(`<strong>${props.labels.DREAM_GOAL}</strong>`);
    }

    if (goals.secureFamily.amount > 0 && simulation.secureFamilyResult.shortfall > 0) {
      goalsText.push(`<strong>${props.labels.SECURE_FAMILY_GOAL}</strong>`);
    }

    if (goals.legacy.amount > 0 && simulation.legacyResult.shortfall > 0) {
      goalsText.push(`<strong>${props.labels.LEGACY_GOAL}</strong>`);
    }

    if (goals.preserveCapital.amount > 0 && simulation.preserveCapitalResult.shortfall > 0) {
      goalsText.push(`<strong>${props.labels.PRESERVE_CAPITAL_GOAL}</strong>`);
    }

    if (goals.retirement.retirementMonthlyPayout > 0 && simulation.retirementResult.shortfall > 0) {
      goalsText.push(`<strong>${props.labels.RETIREMENT_GOAL}</strong>`);
    }
    let newGoalsText='';
    if (goalsText && goalsText.length > 0) {
      newGoalsText= goalsText.reduce((previous, current, index) => {
        let joinText = ', ';
        if (index === goalsText.length - 1) {
          joinText = props.labels.JOIN;
        }
        return `${previous}${joinText}${current}`;
      });
    }
    text=text.replace('{text}', newGoalsText);
    return text;
  }

  function getGoalText() {
    const goalsText: string[] = [];

    if (goals.dream.amount > 0 && shortfallElimination.shortfallEliminationFactor > 0) {
      const newDreamAmount = goals.dream.amount * shortfallElimination.shortfallEliminationFactor;
      const dreamAmountPercentDecrease = (goals.dream.amount - newDreamAmount) / goals.dream.amount;
      if (dreamAmountPercentDecrease > adjustmentFractionCutoff) {
        setNewDream(newDreamAmount);
        goalsText.push(
          `<strong>${props.labels.DREAM_GOAL}</strong> ${props.labels.REDUCE} ${isshortfallEliminationInclude ? getCurrencyFormattedAmount(newDreamAmount, currencyConfig) : ''}`
        );
      }
    }

    if (goals.secureFamily.amount > 0 && shortfallElimination.shortfallEliminationFactor > 0) {
      const newSecureFamilyAmount = goals.secureFamily.amount * shortfallElimination.shortfallEliminationFactor;
      const secureFamilyAmountPercentDecrease =
        (goals.secureFamily.amount - newSecureFamilyAmount) / goals.secureFamily.amount;
      if (secureFamilyAmountPercentDecrease > adjustmentFractionCutoff) {
        setNewSecureFamily(newSecureFamilyAmount);
        goalsText.push(
          `<strong>${props.labels.SECURE_FAMILY_GOAL}</strong> ${props.labels.REDUCE} ${isshortfallEliminationInclude ? getCurrencyFormattedAmount(newSecureFamilyAmount, currencyConfig) : ''}`
        );
      }
    }

    if (goals.legacy.amount > 0 && shortfallElimination.shortfallEliminationFactor > 0) {
      const newLegacyAmount = goals.legacy.amount * shortfallElimination.shortfallEliminationFactor;
      const legacyAmountPercentDecrease = (goals.legacy.amount - newLegacyAmount) / goals.legacy.amount;
      if (legacyAmountPercentDecrease > adjustmentFractionCutoff && isshortfallEliminationInclude) {
        setNewLegacy(newLegacyAmount);
        goalsText.push(
          `<strong>${props.labels.LEGACY_GOAL}</strong> ${props.labels.REDUCE} ${isshortfallEliminationInclude? getCurrencyFormattedAmount(newLegacyAmount, currencyConfig) : ''}`
        );
      }
    }

    if (goals.preserveCapital.amount > 0 && shortfallElimination.shortfallEliminationFactor > 0) {
      const newPreserveCapitalAmount = goals.preserveCapital.amount * shortfallElimination.shortfallEliminationFactor;
      const preserveCapitalPercentDecrease =
        (goals.preserveCapital.amount - newPreserveCapitalAmount) / goals.preserveCapital.amount;
      if (preserveCapitalPercentDecrease > adjustmentFractionCutoff) {
        setNewPreserveCapital(newPreserveCapitalAmount);
        goalsText.push(
          `<strong>${props.labels.PRESERVE_CAPITAL_GOAL}</strong> ${props.labels.REDUCE} ${isshortfallEliminationInclude? getCurrencyFormattedAmount(newPreserveCapitalAmount, currencyConfig): ''}`
        );
      }
    }

    if (goals.retirement.retirementMonthlyPayout > 0 && shortfallElimination.shortfallEliminationFactor > 0) {
      const newRetirementAmount = goals.retirement.retirementMonthlyPayout * shortfallElimination.shortfallEliminationFactor;
      const retirementMonthlyPayoutPercentDecrease =
        (goals.retirement.retirementMonthlyPayout - newRetirementAmount) / goals.retirement.retirementMonthlyPayout;
      if (retirementMonthlyPayoutPercentDecrease > adjustmentFractionCutoff) {
        setNewRetirement(newRetirementAmount);
        goalsText.push(
          `<strong>${props.labels.RETIREMENT_GOAL}</strong> ${props.labels.REDUCE} ${isshortfallEliminationInclude ? getCurrencyFormattedAmount(newRetirementAmount, currencyConfig) : ''}`
        );
      }
    }

    let text = '';
    if (goalsText && goalsText.length > 0) {
      text += goalsText.reduce((previous, current, index) => {
        let joinText = ', ';
        if (index === goalsText.length - 1) {
          joinText = props.labels.JOIN;
        }
        return `${previous}${joinText}${current}`;
      });
    }

    if (text !== '') {
      text = props.labels.GOALS.replace('{text}', text);
    }

    return text;
  }

  function getGoalTextWithoutshortfallElimination() {
    const goalsText: string[] = [];

    if (goals.dream.amount > 0 && shortfallElimination.shortfallEliminationFactor > 0) {
        goalsText.push(
          `<strong>${props.labels.DREAM_GOAL}</strong>`);
    }

    if (goals.secureFamily.amount > 0 && shortfallElimination.shortfallEliminationFactor > 0) {
        goalsText.push(
          `<strong>${props.labels.SECURE_FAMILY_GOAL}</strong>`
        );
    }

    if (goals.legacy.amount > 0 && shortfallElimination.shortfallEliminationFactor > 0) {
        goalsText.push(
          `<strong>${props.labels.LEGACY_GOAL}</strong>`
        );
    }

    if (goals.preserveCapital.amount > 0 && shortfallElimination.shortfallEliminationFactor > 0) {
        goalsText.push(
          `<strong>${props.labels.PRESERVE_CAPITAL_GOAL}</strong>`
        );
    }

    if (goals.retirement.retirementMonthlyPayout > 0 && shortfallElimination.shortfallEliminationFactor > 0) {
        goalsText.push(
          `<strong>${props.labels.RETIREMENT_GOAL}</strong>`
        );
    }

    let text = '';
    if (goalsText && goalsText.length > 0) {
      text += goalsText.reduce((previous, current, index) => {
        let joinText = ', ';
        if (index === goalsText.length - 1) {
          joinText = props.labels.JOIN;
        }
        return `${previous}${joinText}${current}`;
      });
    }

    if (text !== '') {
      text = props.labels.GOALS.replace('{text}', text);
    }

    return text;
  }

  function getMaxPensionText() {
    let maxMonthlyPensionText = '';

    if (
      simulation.maxPensionPayout?.percentile50?.quarterlyPayoutValue &&
      simulation.maxPensionPayout?.percentile50?.quarterlyPayoutValue > 1
    ) {
      const newPensionAmount = simulation.maxPensionPayout.percentile50.quarterlyPayoutValue / 3;
      if (newPensionAmount > goals.retirement.retirementMonthlyPayout && isshortfallEliminationInclude) {
        setNewPension(newPensionAmount);
        maxMonthlyPensionText =
          props.labels.MAX_PENSION.replace('{amount}', getCurrencyFormattedAmount(newPensionAmount, currencyConfig)) +
          ` <strong>${props.labels.RETIREMENT_GOAL}</strong>`;
      }
      else{
        maxMonthlyPensionText =
          ` <strong>${props.labels.RETIREMENT_GOAL}</strong>`;
      }
    }

    return maxMonthlyPensionText;
  }

  function getMaxPensionTextWithoutshortfallElimination() {
    let maxMonthlyPensionText = '';

    if (
      simulation.maxPensionPayout?.percentile50?.quarterlyPayoutValue &&
      simulation.maxPensionPayout?.percentile50?.quarterlyPayoutValue > 1
    ) {
      const newPensionAmount = simulation.maxPensionPayout.percentile50.quarterlyPayoutValue / 3;
      if (newPensionAmount > goals.retirement.retirementMonthlyPayout) {
        maxMonthlyPensionText =` <strong>${props.labels.RETIREMENT_GOAL}</strong>`;
      }
    }

    return maxMonthlyPensionText;
  }

  function setValueforgoalActiveIndexs(index: number){
    if(goalActiveIndex === -1){
      setgoalActiveIndexs(index);
    }
  }

  const trackSuggestions = (action: string) => {
    if (hasSavingsAndShortfall()) {
      trackEvent({ category: trackEventCategories.RESULTS, action: action, name: trackEventNames.CTA_SHORTFALL_SAVE_MORE });
    } else if (needsShortfallElimination()) {
      trackEvent({ category: trackEventCategories.RESULTS, action: action, name: trackEventNames.CTA_SHORTFALL_REDUCE_GOALS });
    }
  }

  const onApplySuggestions = () => {
    trackSuggestions(trackEventActions.CLICK);
    let requiresUpdate = false;

    if (newPension) {
      requiresUpdate = true;
      dispatch(
        saveRetirement({
          ...goals.retirement,
          retirementMonthlyPayout: newPension,
        })
      );
    } else if (newSavings) {
      requiresUpdate = true;
      dispatch(saveMonthlySavings({ ...savings, year: savings.monthlyYear, amount: newSavings }));
    } else {
      if (newRetirement) {
        requiresUpdate = true;
        dispatch(
          saveRetirement({
            ...goals.retirement,
            retirementMonthlyPayout: newRetirement,
          })
        );
      }

      if (newLegacy) {
        requiresUpdate = true;
        dispatch(dispatch(saveLegacy({ ...goals.legacy, amount: newLegacy })));
      }

      if (newDream) {
        requiresUpdate = true;
        dispatch(dispatch(saveDream({ ...goals.dream, amount: newDream })));
      }

      if (newSecureFamily) {
        requiresUpdate = true;
        dispatch(dispatch(saveSecureFamily({ ...goals.secureFamily, amount: newSecureFamily })));
      }

      if (newPreserveCapital) {
        requiresUpdate = true;
        dispatch(dispatch(savePreserveCapital({ ...goals.preserveCapital, amount: newPreserveCapital })));
      }
    }

    if (newPension || newRetirement || newSavings) {
      setValueforgoalActiveIndexs(0);
    } else if (newLegacy) {
      setValueforgoalActiveIndexs(1);
    } else if (newDream) {
      setValueforgoalActiveIndexs(2);
    } else if (newPreserveCapital) {
      setValueforgoalActiveIndexs(3);
    } else if (newSecureFamily) {
      setValueforgoalActiveIndexs(4);
    } else {
      setValueforgoalActiveIndexs(-1);
    }

    if (requiresUpdate) {
      abortController.abort();
      abortController = new AbortController();
      dispatch(fetchSimulation({ abortSignal: abortController.signal }));
    }
  };

  function onClose() {
    if (isInsideIframe) {
        const element = document.getElementById('nudge');
        element?.classList.remove('transform');
    }
  
    setIsNudgeVisible(false);
  }

  const onDiscardSuggestions = () => {
    trackSuggestions(trackEventActions.CLOSE);
    onClose();
  };

  const onHighGoalAchievementClose = () => {
    trackEvent({ category: trackEventCategories.RESULTS, action: trackEventActions.CLOSE, name: trackEventNames.CTA_HIGH_GOAL });
    onClose();
  };

  useEffect(() =>{
    if (goalActiveIndex !== -1) {
      dispatch(saveGoalsSliderIndex({activeSlideindex: goalActiveIndex}));
      setgoalActiveIndexs(-1);
    }
  }, [goalActiveIndex]);

    const contextValue = {
        labels: props.labels,
        className:props.className,
        nudgeText:nudgeText,
        newRetirement: newRetirement,
        newLegacy: newLegacy,
        newDream: newDream,
        newSecureFamily: newSecureFamily,
        newPreserveCapital: newPreserveCapital,
        newSavings: newSavings,
        newPension: newPension,
        simulation:simulation,
        shortfallElimination:shortfallElimination,
        isNudgeVisible:isNudgeVisible,
        assets:assets,
        needsShortfallElimination:needsShortfallElimination,
        hasGoals:hasGoals,
        onApplySuggestions:onApplySuggestions,
        onDiscardSuggestions: onDiscardSuggestions,
        onHighGoalAchievementClose: onHighGoalAchievementClose,
        setIsNudgeVisible:setIsNudgeVisible,
        setIsshortfallEliminationInclude: setIsshortfallEliminationInclude,
        getSavingsText:getSavingsText,
        getSavingsTextWithoutShortfallElimination:getSavingsTextWithoutShortfallElimination,
        getGoalText:getGoalText,
        getGoalTextWithoutshortfallElimination:getGoalTextWithoutshortfallElimination,
        getMaxPensionText:getMaxPensionText,
        getMaxPensionTextWithoutshortfallElimination:getMaxPensionTextWithoutshortfallElimination,
        currencyConfig:currencyConfig,
        setNudgeText:setNudgeText,
        hasSavingsAndShortfall:hasSavingsAndShortfall,
        setNewRetirement:setNewRetirement,
        setNewLegacy:setNewLegacy,
        setNewDream:setNewDream,
        setNewSecureFamily:setNewSecureFamily,
        setNewPreserveCapital:setNewPreserveCapital,
        setNewSavings:setNewSavings,
        setNewPension:setNewPension,
        tenantConfig:tenantConfig,
        isInsideIframe: isInsideIframe,
        isHorizontallyCompact: isHorizontallyCompact,
    };

    return (
        <NudgeContext.Provider value={contextValue}>{props.children}</NudgeContext.Provider>
      );
};