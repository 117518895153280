export enum LiabilityType {
    mortgage = 20,
}

export interface Liability {
    type: string;
    amount: number;
  }
  
export interface LiabilityLabels {
    LIABILITY_TYPES: any;
}

export interface LiabilityTypeLabel {    
    DISPLAY: string;
    ERROR_MESSAGE : string;
}

export interface LiabilityTypeItem extends LiabilityTypeLabel {
    Type: string;
    Icon: string;
    IsAssosiated: boolean;
}