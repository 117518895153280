import { useEffect } from "react";
import { setIncludeDebug } from "../store/debug/debug.slice";
import { useAppDispatch } from "../store/hooks";
import { useQuery } from "./useQuery";

export function useDebug() {
  const dispatch = useAppDispatch();
  const queryString = useQuery().toString();

  useEffect(() => {
    dispatch(setIncludeDebug(queryString.includes("debug")));
  }, [dispatch, queryString]);
}
