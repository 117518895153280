import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { FlyoutMenuWrapperComponentLabels } from "../labels/flyout-menu-component.labels";
import { saveGoals, selectGoalsState } from "../store/goals/goals.slice";
import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from "../hooks/useTrackEvent";
import { GetChildProperties } from "@flexfront/utils";
import { GenderTypeLabel, Personal, UserSettings } from "@flexfront/models";
import { useComponentVisibility } from "../hooks/useComponentVisibility";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectTenantConfig } from "../store/tenant-config/tenant-config.slice";
import { selectUserSettingsState } from "../store/user-settings.slice";
import { selectClimateChangeState } from "../store/climate-change/climate.slice";
import { selectStaticSimulationState } from "../store/static-simulation/static-simulation.slice";
import { selectSimulationState } from "../store/simulation/simulation.slice";
import { selectPersonalState } from "../store/personal.slice";
import { useTenantMobileLogo } from "../hooks/useTenantLogo";
import { TenantConfigState } from "../store/tenant-config/tenant-config.state";
import { SimulationState } from "../store/simulation/simulation.state";

export interface FlyoutMenuWrapperContextProps {
    children: ReactNode;
    labels: FlyoutMenuWrapperComponentLabels;
    onRequiresUpdate?: () => void;
}

interface FlyoutMenuWrapperFunctionality {
    labels: FlyoutMenuWrapperComponentLabels;
    onRequiresUpdate?: () => void;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    tenantLogo: string | undefined;
    toggle: () => void;
    personal: Personal;
    getGenderDesc: (genderType: string) => string | undefined;
    tenantConfig: TenantConfigState;
    ClimateScenarioName: string;
    userSettings: UserSettings;
    isRealEstateHidden: boolean | undefined;
    onReportDowloading(isReportDownloading: boolean): void;
    isReportDownloading: boolean | undefined;
    simulationState: SimulationState;
    setisInflationToggleSelected: React.Dispatch<React.SetStateAction<boolean>>;
    isInflationToggleSelected: boolean;
    staticSimulationState: SimulationState;
    close: () => void;
    confirm: () => void;
}

const FlyoutMenuWrapperContext = createContext<FlyoutMenuWrapperFunctionality | undefined>(undefined);

export const useFlyoutMenuWrapperContext = (): FlyoutMenuWrapperFunctionality => {
    const context = useContext(FlyoutMenuWrapperContext);
    if (!context) {
        throw new Error('FlyoutMenuWrapperContext must be used within a FlyoutMenuWrapperProvider');
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useContext(FlyoutMenuWrapperContext)!;
};

export const FlyoutMenuWrapperProvider: React.FC<FlyoutMenuWrapperContextProps> = (props: FlyoutMenuWrapperContextProps) => {

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const tenantLogo = useTenantMobileLogo();
    const personal = useAppSelector(selectPersonalState);
    const simulationState = useAppSelector(selectSimulationState);
    const staticSimulationState = useAppSelector(selectStaticSimulationState);
    const climateChange = useAppSelector(selectClimateChangeState);
    const userSettings = useAppSelector(selectUserSettingsState);
    const tenantConfig = useAppSelector(selectTenantConfig);
    const goals = useAppSelector(selectGoalsState);

    const dispatch = useAppDispatch();
    
    const isClimateScenarioHidden = useComponentVisibility("CLIMATE_SCENARIO_SELECT");
    const isRealEstateHidden = useComponentVisibility("I_HAVE_REAL_ESTATE");

    const [isInflationToggleSelected, setisInflationToggleSelected] = useState<boolean>(false);
    const [isReportDownloading, setIsReportDownloading] = useState<boolean | undefined>(false);

    const trackEvent = useTrackEvent();

    const ClimateScenarioName = props.labels.CLIMATE_SCENARIO.SCENARIOS[climateChange.scenario ?? "NONE"].TITLE;

    const genderOptions = GetChildProperties<GenderTypeLabel>(props.labels.GENDER_TYPES).map((gender) => ({
        value: gender.TYPE,
        label: gender.DISPLAY,
      }));

    const getGenderDesc = (genderType: string) => {
        const gender = genderOptions.find(g => g.value === genderType);
        return gender?.label;
    }

    const toggle = () => {
        if (isOpen) {
            trackEvent({ category: trackEventCategories.FLYOUT, action: trackEventActions.CLICK, name: trackEventNames.OPEN });
        } else {
            trackEvent({ category: trackEventCategories.FLYOUT, action: trackEventActions.CLICK, name: trackEventNames.CLOSE });
        }

        setIsOpen(!isOpen);
    }

    const close = () => {
        trackEvent({ category: trackEventCategories.FLYOUT, action: trackEventActions.CLICK, name: trackEventNames.CLOSE });
        setIsOpen(false);
    }

    const confirm = () => {
        setIsOpen(false);

        if (props.onRequiresUpdate) {
            props.onRequiresUpdate();
        }
    }

    const OnApplyInflationOnAllGoals = (isSelected: boolean) => {
        dispatch(saveGoals({...goals, retirement: {...goals.retirement, nominal: !isSelected},
                legacy:{...goals.legacy, nominal: !isSelected},
                dream:{...goals.dream, nominal: !isSelected},
                preserveCapital:{...goals.preserveCapital, nominal: !isSelected},
                secureFamily:{...goals.secureFamily, nominal: !isSelected}}));
        if (props.onRequiresUpdate) props.onRequiresUpdate();
    }

    function onReportDowloading(isReportDownloading: boolean) {
        setIsReportDownloading(isReportDownloading);
    }

    useEffect(() => {
        OnApplyInflationOnAllGoals(isInflationToggleSelected);
    }, [isInflationToggleSelected]);
    

    useEffect(() => {
        const dashboard = document.getElementById('dashboard');
        if(isOpen){
          dashboard?.classList.add('dashboard-disable-scroll');
        }else{
            dashboard?.classList.remove('dashboard-disable-scroll');
        }
      }, [isOpen]);

    const contextValue = {
        labels: props.labels,
        onRequiresUpdate: props.onRequiresUpdate,
        isOpen: isOpen,
        setIsOpen: setIsOpen,
        tenantLogo: tenantLogo,
        toggle: toggle,
        personal: personal,
        getGenderDesc: getGenderDesc,
        tenantConfig: tenantConfig,
        ClimateScenarioName: ClimateScenarioName,
        userSettings: userSettings,
        isRealEstateHidden: isRealEstateHidden,
        onReportDowloading: onReportDowloading,
        isReportDownloading: isReportDownloading,
        simulationState: simulationState,
        setisInflationToggleSelected: setisInflationToggleSelected,
        isInflationToggleSelected: isInflationToggleSelected,
        staticSimulationState: staticSimulationState,
        confirm: confirm,
        close: close,

    };

    return (
        <FlyoutMenuWrapperContext.Provider value={contextValue}>{props.children}</FlyoutMenuWrapperContext.Provider>
      );
};