import { selectAllText } from "@flexfront/ui/react";
import { ChangeEvent,FocusEvent, useEffect, useState } from "react";

export interface NumberInputComponentProps {
    classNamePrefix: string;
    title?: string;
    value?: string | number;
    min?: number;
    max?: number;
    tabIndex?: number;
    placeholder?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>, shouldTrackEvent?: boolean) => void;
    onBlur?: () => void;
    hasError?: boolean;
}

export default function NumberInputComponent(props: NumberInputComponentProps) {

    const [hasError, setHasError] = useState<boolean>(props.hasError ?? false);

    function onInputFocussed(event: FocusEvent<HTMLInputElement>) {
        selectAllText(event);
    }

    useEffect(() => {
        setHasError(props.hasError ?? false);
      }, [props.hasError]);
    

    return <>  
        <label className={`${props.classNamePrefix}-input-label`}>{props.title}</label>
        <input
            type='number'
            className={`${props.classNamePrefix}-input-number ${hasError ? 'error' : ''}`}
            tabIndex={props.tabIndex}
            placeholder={props.placeholder}
            min={props.min}
            max={props.max}
            value={props.value == '0' ? '' : props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
            onFocus={onInputFocussed}
        />
    </>;
}