import { WealthSection } from "@flexfront/models";
import { getQuarterDate } from "@flexfront/ui/react";

interface LinePoint {
  x: number;
  y: number;
}

export const snapshotSeriesClass = "snapshot-series";

export function getTotalWealthSeries(
  totalWealth: WealthSection,
  leverageQuarter: number,
  ageAtQuarter: number[],
  includePrevious: boolean,
  isHorizontallyCompact: boolean,
  totalWealthSnapshot?: WealthSection
): Highcharts.SeriesOptionsType[] {
  let hasSnapshot = false;
  const date = getQuarterDate(new Date());
  const pointStart = Date.UTC(date.getFullYear(), date.getMonth(), 1);

  if (
    includePrevious &&
    totalWealthSnapshot &&
    totalWealthSnapshot.percentile50.filter((_) => _ > 0).length > 0
  ) {
    hasSnapshot = true;
  }

  const seriesData = getSeriesData(
    totalWealth,
    leverageQuarter,
    ageAtQuarter,
    isHorizontallyCompact,
    totalWealthSnapshot
  );
  const series: Highcharts.SeriesOptionsType[] = getMainSeries(
    seriesData.goodRangeData,
    seriesData.expectedSeriesData,
    seriesData.adverseRangeData,
    pointStart
  );

  if (hasSnapshot) {
    series.push(
      ...getSnapshotSeries(
        seriesData.deltaGoodRangeData,
        seriesData.deltaExpectedRangeData,
        seriesData.deltaAdverseRangeData,
        pointStart
      )
    );
  }

  return series;
}

function getSeriesData(
  wealthSection: WealthSection,
  leverageQuarter: number,
  ageAtQuarter: number[],
  isHorizontallyCompact: boolean,
  wealthSectionSnapshot?: WealthSection
) {
  const goodRangeData = [];
  const expectedSeriesData = [];
  const adverseRangeData = [];
  const snapshotGoodRangeData = [];
  const snapshotExpectedRangeData = [];
  const snapshotAdverseRangeData = [];

  for (
    let quarter = 0;
    quarter < wealthSection.percentile50.length;
    quarter++
  ) {
    const quarterDate = getQuarterDate(new Date());
    quarterDate.setMonth(quarterDate.getMonth() + quarter * 3);
    const dateUTC = Date.UTC(quarterDate.getFullYear(), quarterDate.getMonth());
    expectedSeriesData.push({
      x: dateUTC,
      y: wealthSection.percentile50[quarter],
      age: ageAtQuarter[quarter],
      marker: {},
    });
    goodRangeData.push([
      dateUTC,
      wealthSection.percentile50[quarter],
      wealthSection.percentile75[quarter],
    ]);
    adverseRangeData.push([
      dateUTC,
      wealthSection.percentile5[quarter],
      wealthSection.percentile50[quarter],
    ]);

    snapshotGoodRangeData.push({
      x: dateUTC,
      y: wealthSectionSnapshot?.percentile75[quarter] ?? 0,
    });
    snapshotExpectedRangeData.push({
      x: dateUTC,
      y: wealthSectionSnapshot?.percentile50[quarter] ?? 0,
    });
    snapshotAdverseRangeData.push({
      x: dateUTC,
      y: wealthSectionSnapshot?.percentile5[quarter] ?? 0,
    });
  }
  if (leverageQuarter > 0 && leverageQuarter < expectedSeriesData.length) {
    
    expectedSeriesData[leverageQuarter].marker = {
      enabled: isHorizontallyCompact,
      symbol: "circle",
      fillColor: "var(--accent-leverage)",
      width: 32,
      height: 32 ,
      states: {
        hover: {
          enabled: isHorizontallyCompact,
        },
      },
    };
  }  
  return {
    goodRangeData,
    expectedSeriesData,
    adverseRangeData,
    deltaGoodRangeData: snapshotGoodRangeData,
    deltaExpectedRangeData: snapshotExpectedRangeData,
    deltaAdverseRangeData: snapshotAdverseRangeData,
  };
}

function getMainSeries(
  goodRangeData: number[][],
  expectedSeriesData: { x: number; y: number; marker: any }[],
  adverseRangeData: number[][],
  pointStart: number
): Highcharts.SeriesOptionsType[] {
  const expectedName = "totalWealthExpectedSeries";
  const goodName = "totalWealthGoodRangeSeries";
  const adverseName = "totalWealthAdverseRangeSeries";

  return [
    {
      id: expectedName,
      name: expectedName,
      className: "total-wealth-expected-series",
      type: "line",
      data: expectedSeriesData,
      pointStart: pointStart,
      pointIntervalUnit: "month",
      pointInterval: 3,
      dashStyle: "Dash",
      marker: {
        enabled:false,
        states: {
          hover: {
            fillColor: "var(--secondary-0)"
          }
        }
      }
    },
    {
      id: goodName,
      name: goodName,
      className: "total-wealth-good-range-series",
      type: "arearange",
      data: goodRangeData,
      lineWidth: 0,
      lineColor: "transparent",
      linkedTo: "totalWealthExpectedSeries",
      zIndex: 1,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false
          }
        }
      },
    },
    {
      id: adverseName,
      name: adverseName,
      className: "total-wealth-adverse-range-series",
      type: "arearange",
      data: adverseRangeData,
      lineWidth: 0,
      lineColor: "transparent",
      linkedTo: "totalWealthExpectedSeries",
      zIndex: 1,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false          
          }
        }
      },
    },
  ];
}

function getSnapshotSeries(
  snapshotGoodRangeData: LinePoint[],
  snapshotExpectedRangeData: LinePoint[],
  snapshotAdverseRangeData: LinePoint[],
  pointStart: number
): Highcharts.SeriesOptionsType[] {
  const goodName = "snapshotWealthGoodRangeSeries";
  const expectedName = "snapshotExpectedRangeSeries";
  const adverseName = "snapshotWealthAdverseRangeSeries";

  return [
    {
      id: goodName,
      name: goodName,
      type: "line",
      data: snapshotGoodRangeData,
      pointStart: pointStart,
      pointIntervalUnit: "month",
      pointInterval: 3,
      zIndex: 2,
      enableMouseTracking: false,
      className: snapshotSeriesClass,
    },
    {
      id: expectedName,
      name: expectedName,
      type: "line",
      data: snapshotExpectedRangeData,
      pointStart: pointStart,
      pointIntervalUnit: "month",
      pointInterval: 3,
      zIndex: 2,
      enableMouseTracking: false,
      dashStyle: "Dash",
      className: snapshotSeriesClass,
    },
    {
      id: adverseName,
      name: adverseName,
      type: "line",
      data: snapshotAdverseRangeData,
      pointStart: pointStart,
      pointIntervalUnit: "month",
      pointInterval: 3,
      zIndex: 2,
      enableMouseTracking: false,
      className: snapshotSeriesClass,
    },
  ];
}
