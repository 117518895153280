import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../hooks";
import { RootState } from "../store";
import { ChartLegend } from "./chartLegend.state";

const initialState: ChartLegend = {
  hasLegend: false,
  includeSnapshot: false,
};

export const chartLegendSlice = createSlice({
  name: "chartLegend",
  initialState,
  reducers: {
    setHasLegend: (state, action: PayloadAction<boolean>) => {
      state.hasLegend = action.payload;
      return state;
    },
    setIncludeSnapshot: (state, action: PayloadAction<boolean>) => {
      state.includeSnapshot = action.payload;
      return state;
    }
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const { setHasLegend, setIncludeSnapshot } = chartLegendSlice.actions;

export const selectLegendState = createSelector(
  (state: RootState) => state.chartLegend,
  (legend) => legend
);

export default chartLegendSlice;
