import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { SimulationState } from "../simulation/simulation.state";
import { RootState } from "../store";

export const STATIC_SIMULATION_FEATURE_KEY = "static-simulation";

const initialState: SimulationState = {
  isLoading: false,
  ageAtQuarter: [
    35,
    35,
    35,
    35,
    35,
    36,
    36,
    36,
    36,
    37,
    37,
    37,
    37,
    38,
    38,
    38,
    38,
    39,
    39,
    39,
    39,
    40,
    40,
    40,
    40,
    41,
    41,
    41,
    41,
    42,
    42,
    42,
    42,
    43,
    43,
    43,
    43,
    44,
    44,
    44,
    44,
    45,
    45,
    45,
    45,
    46,
    46,
    46,
    46,
    47,
    47,
    47,
    47,
    48,
    48,
    48,
    48,
    49,
    49,
    49,
    49,
    50,
    50,
    50,
    50,
    51,
    51,
    51,
    51,
    52,
    52,
    52,
    52,
    53,
    53,
    53,
    53,
    54,
    54,
    54,
    54,
    55,
    55,
    55,
    55,
    56,
    56,
    56,
    56,
    57,
    57,
    57,
    57,
    58,
    58,
    58,
    58,
    59,
    59,
    59,
    59,
    60,
    60,
    60,
    60,
    61,
    61,
    61,
    61,
    62,
    62,
    62,
    62,
    63,
    63,
    63,
    63,
    64,
    64,
    64,
    64,
    65,
    65,
    65,
    65,
    66,
    66,
    66,
    66,
    67,
    67,
    67,
    67,
    68,
    68,
    68,
    68,
    69,
    69,
    69,
    69,
    70,
    70,
    70,
    70,
    71,
    71,
    71,
    71,
    72,
    72,
    72,
    72,
    73,
    73,
    73,
    73,
    74,
    74,
    74,
    74,
    75,
    75,
    75,
    75,
    76,
    76,
    76,
    76,
    77,
    77,
    77,
    77,
    78,
    78,
    78,
    78,
    79,
    79,
    79,
    79,
    80,
    80,
    80,
    80,
    81,
    81,
    81,
    81,
    82,
    82,
    82,
    82,
    83,
    83,
    83,
    83,
    84
  ],
  totalWealth: {
    percentile5: [
        254000,
        255749.55,
        259909.33,
        263736.22,
        268439.94,
        274580.25,
        279615,
        284763.94,
        289423.4,
        294367.12,
        300171.5,
        305921.94,
        311802,
        317746.8,
        323660.5,
        330761.38,
        337283.62,
        342284.5,
        347937.28,
        353397.03,
        358705.56,
        364719.8,
        370394.62,
        376738.5,
        383328.34,
        388473.94,
        393879.4,
        401299.28,
        407676.7,
        413363.5,
        317750.72,
        322990.75,
        329777.75,
        336169.44,
        342935,
        349465.38,
        355113.53,
        361718.56,
        367869.03,
        373540,
        380156.2,
        387743.56,
        393751.88,
        400616.03,
        405430.62,
        414431.8,
        419972.7,
        426429.06,
        432537.1,
        441234,
        448920.8,
        456728.4,
        463253.16,
        469080.3,
        476453.75,
        483760.34,
        491639.3,
        499491.25,
        507408.66,
        513038.34,
        521036.44,
        530556.3,
        538748.25,
        544172.6,
        554007.75,
        562318.3,
        568073.3,
        574778.6,
        582324.6,
        589022.7,
        596108.25,
        606048.1,
        613115.6,
        620871.9,
        628111.2,
        636966.44,
        643660.2,
        650919.2,
        659753.4,
        667622.7,
        675434,
        683729.9,
        691612.6,
        700532.3,
        708186.6,
        717353.94,
        726201.44,
        733626.8,
        740981.9,
        748934.9,
        757336,
        764549.8,
        772760.3,
        782135.2,
        789337.94,
        797358.1,
        804303.7,
        813007.4,
        822361.5,
        829194.25,
        837992.7,
        849253.7,
        858024,
        865515.06,
        875310.1,
        881449.7,
        889901.7,
        897982.94,
        906350.2,
        913503.2,
        921300.7,
        931597.06,
        938653.75,
        948226.8,
        956785.1,
        965777.1,
        972178.25,
        980149.06,
        989281.2,
        998191.2,
        1006567.5,
        1016461.2,
        1023405.75,
        1031180.1,
        1037062.9,
        1045115.4,
        1052053,
        1059618.9,
        1061030.6,
        1057844.1,
        1050957.9,
        1050535.4,
        1045014.2,
        1039770.3,
        1037521.75,
        1032648.5,
        1029964.7,
        1025538.06,
        1022657.9,
        1018599.1,
        1016572.1,
        1011648.4,
        1006421.8,
        1005356.6,
        1001872.9,
        996086.6,
        991723.7,
        987936.94,
        984008.4,
        980503.5,
        978861.94,
        973777.25,
        972446.2,
        968732.3,
        960118.1,
        956780.75,
        950690.7,
        949268.44,
        944730.44,
        943924.8,
        938222.8,
        933971.25,
        930295.6,
        926544,
        922417.3,
        919311.44,
        913380,
        910201.3,
        907337.44,
        899221.44,
        895610.56,
        895417,
        889082.25,
        885568.25,
        880432.6,
        879484.9,
        874437.1,
        870034.3,
        864739.94,
        860704.44,
        859869.3,
        852690.7,
        849869.56,
        844907.2,
        841767.1,
        835997.9,
        830901.94,
        826799.4,
        821022.7,
        816753.4,
        815177.1,
        809584.3,
        804613.7,
        799678.56,
        797468.4,
        794309.06,
        792344.44,
        795229.75
    ],
    percentile50: [
        254000,
        260386.19,
        266815.06,
        273133.03,
        279516.22,
        286253.34,
        292137.72,
        299066.97,
        305228.2,
        311918.38,
        318095.94,
        324847.44,
        331306.28,
        337113.2,
        343410.47,
        349380.03,
        356229.47,
        362586.16,
        369336.16,
        375407.53,
        382410.38,
        388989.72,
        395682.28,
        401554.53,
        408084.16,
        413822,
        420148.9,
        427018.3,
        433270.94,
        439538.94,
        347414.97,
        353632.1,
        359515.25,
        367114.03,
        373815.25,
        381245.9,
        387862.62,
        394988.1,
        400895.25,
        406786.28,
        413825.84,
        420606.03,
        428655.34,
        435771.03,
        442787.44,
        450231.16,
        457799.7,
        464600.47,
        472635.97,
        479782.2,
        486970.7,
        493764.25,
        501499.53,
        509261.34,
        517333.62,
        525473.5,
        534307.6,
        542118.25,
        549228.56,
        558744.75,
        566651.7,
        575495.75,
        582701.8,
        590260.56,
        597405.3,
        606551.06,
        614575.75,
        622565.5,
        629372.3,
        639073.5,
        647502.7,
        655499.75,
        663237.25,
        672636,
        682101.94,
        689114.75,
        697299.3,
        706373.9,
        715313.44,
        723548.06,
        730037.6,
        738039.7,
        746832.7,
        754000.9,
        762434.7,
        771758.2,
        777945.75,
        786045.3,
        795300.4,
        804799.8,
        814644.94,
        823611.8,
        830951.94,
        839837.56,
        848873.8,
        857695.7,
        868014.1,
        877199.25,
        885861.44,
        892682.06,
        900537.75,
        910374.8,
        918730.1,
        928783.56,
        936891.56,
        945476.06,
        954435.6,
        964790.25,
        973398.44,
        982032.56,
        991623.75,
        998518.25,
        1009732.25,
        1018381.25,
        1028697.9,
        1037467.3,
        1045611.44,
        1053664.9,
        1061657.6,
        1070459.5,
        1079781.8,
        1087264.8,
        1094755.4,
        1104062.1,
        1112832.5,
        1119751.9,
        1128869.8,
        1138628.4,
        1138578.4,
        1136014.4,
        1133416.9,
        1132849.9,
        1129561.9,
        1126881,
        1120911.8,
        1119034.6,
        1116351.6,
        1112215,
        1110798.2,
        1107493.1,
        1100925.5,
        1099714.6,
        1098193,
        1092551.8,
        1087219,
        1085352.9,
        1081031,
        1079304.8,
        1078435.6,
        1072940.5,
        1068618.8,
        1068127,
        1064933.2,
        1061048.9,
        1058760.4,
        1056752.5,
        1053026.6,
        1047491.06,
        1043863.94,
        1039124.25,
        1036824.3,
        1034314.1,
        1031823.56,
        1027762.75,
        1025269.8,
        1020855.8,
        1020154.2,
        1016461.5,
        1008168.6,
        1004562.44,
        1003006.9,
        1000050.06,
        995505.75,
        994048.5,
        990968.44,
        983151.3,
        980343.3,
        976151.56,
        973725.9,
        970544.5,
        966945.4,
        961696.56,
        958857.44,
        958067,
        955015.44,
        950075.94,
        943845.5,
        940326.75,
        938552.25,
        932951.3,
        931994.75,
        931310.4,
        926340.6,
        923059.06,
        918559.6,
        915475.1,
        917672.3,
        920018.7
    ],
    percentile75: [
        254000,
        262314.6,
        269696.66,
        277369.7,
        284273.1,
        291287,
        298348.94,
        305126.72,
        312540.78,
        318962.03,
        326037.53,
        332313.3,
        338888.4,
        345952.84,
        353489.28,
        359565.9,
        366134.4,
        372823.44,
        378810.44,
        385505.7,
        392730.22,
        399497.5,
        405690.97,
        411691.38,
        419179.25,
        426294.7,
        433444.66,
        440156.16,
        446787.72,
        453633.6,
        360441.34,
        366305.53,
        374306.16,
        380612.94,
        387389.3,
        394219.8,
        400725.16,
        407880.62,
        415764.7,
        422993.6,
        429366.53,
        438581.88,
        445812.97,
        453122.88,
        460009.16,
        467272.12,
        476178.5,
        484427.6,
        491414.12,
        499886.6,
        508702.1,
        516506.6,
        522912.53,
        531134.94,
        538638.44,
        545965.4,
        553301.06,
        560889,
        571590.56,
        579045.56,
        587268.44,
        593405.44,
        601836,
        610822.06,
        619122.6,
        628191.06,
        636496.2,
        645041.56,
        654479.1,
        664143.56,
        671658.1,
        679582.44,
        688227.4,
        697063.75,
        708526.44,
        717309.44,
        726362.5,
        734763.94,
        742136.7,
        750444.56,
        759853.3,
        770058.25,
        777630.25,
        786603.25,
        794385.25,
        803833.75,
        812594,
        821010.5,
        830902.94,
        838824.1,
        848574.75,
        858512.5,
        867523.7,
        874895.6,
        884898.1,
        893047.6,
        902604.2,
        910050.5,
        919868.9,
        929091.9,
        935558.5,
        946563.8,
        956284.6,
        965758.06,
        976674.75,
        985549.8,
        993179.6,
        1001745.6,
        1010414.94,
        1021501.94,
        1030741.56,
        1039898.4,
        1049144.2,
        1056030.4,
        1067985.4,
        1077980.8,
        1086958.1,
        1097351.8,
        1104784.1,
        1115156.1,
        1123646.1,
        1131686.9,
        1140105,
        1149693.6,
        1158988.1,
        1168389.6,
        1176611.4,
        1184969.5,
        1184774.5,
        1180694.2,
        1179361.5,
        1174876.8,
        1173285.5,
        1173277.8,
        1171584.1,
        1168861.1,
        1160815.9,
        1160915.9,
        1157764.4,
        1156097.2,
        1150397.2,
        1153078.1,
        1148403.8,
        1144166.6,
        1140439.2,
        1135652.2,
        1133061.6,
        1132993.9,
        1132348.2,
        1129709.8,
        1126590.1,
        1124783.8,
        1118509.5,
        1116349,
        1115891.6,
        1110985.6,
        1109061.5,
        1104270.4,
        1100711.4,
        1095513.4,
        1093236.8,
        1088731.8,
        1086461.2,
        1085234.9,
        1080957.4,
        1078242.6,
        1075165.4,
        1070913.5,
        1066867.6,
        1065741.2,
        1061174.9,
        1058428.6,
        1056058.5,
        1052508.6,
        1050275.4,
        1048531.6,
        1044267.94,
        1040337,
        1039137.94,
        1036601.6,
        1036358.6,
        1034643.5,
        1032751.75,
        1024066.5,
        1022362.7,
        1018816.94,
        1014004.1,
        1008339.5,
        1003263.6,
        1005011.7,
        1000898,
        997135.7,
        996765.4,
        992836.06,
        993243.25,
        985961.94,
        985186.1,
        988290.3
    ]
},
illiquid: {
  percentile5: [
      100000,
      95987.32,
      94186.086,
      92026.59,
      90695.53,
      90884.26,
      89778.555,
      88925.92,
      87635.18,
      86546.78,
      86272.52,
      85933.66,
      85604.78,
      85460.26,
      85468.04,
      86388.52,
      86807.87,
      85568.05,
      85156.15,
      84510.01,
      83586.96,
      83306.02,
      83028.48,
      83436.52,
      83881.766,
      82749.14,
      81944.266,
      83056.414,
      83303.09,
      82790.625,
      81233.336,
      80360.99,
      80721.9,
      80736.29,
      81147.79,
      81060.195,
      80300.37,
      80968.55,
      80473.7,
      79915.22,
      80042.69,
      80854.19,
      80524.87,
      80319.56,
      78668.305,
      80517.49,
      79090.22,
      78402.82,
      77735.44,
      79819.87,
      80056.95,
      80891.36,
      80691.164,
      79225.95,
      79638.12,
      79865.57,
      80492.414,
      80729.8,
      81560.55,
      79652.33,
      80455.29,
      82573.81,
      83137.15,
      81144.34,
      83599.55,
      84455.65,
      82499.65,
      81887.59,
      82067.61,
      80813.2,
      80427.21,
      82126.58,
      82246.64,
      82375.305,
      81843.766,
      83184.945,
      82779.22,
      82472.86,
      83125.32,
      82656.29,
      82928.14,
      83093.88,
      83736.18,
      85033.34,
      85050.17,
      85918.94,
      86177.336,
      85855.25,
      86046.91,
      85631.06,
      85606.7,
      85594.68,
      85290.88,
      86742.15,
      86204.55,
      85808.15,
      83738.57,
      84678.69,
      85795.37,
      84725.93,
      85568.695,
      87722.78,
      87757.4,
      88065.33,
      89193.664,
      88131.23,
      88711.96,
      88591.695,
      88138.9,
      87440,
      88158.09,
      88463.125,
      87134.625,
      88193.52,
      88474.04,
      87806.59,
      87136.92,
      86423.89,
      86543.79,
      88211.414,
      88193.305,
      89876.87,
      89833.76,
      90375.164,
      88611.6,
      88710.43,
      88165.79,
      86268.625,
      87193.15,
      88933.52,
      86875.11,
      88017.95,
      86988.18,
      87072.336,
      87967.25,
      86833.4,
      88169.47,
      88660.164,
      89868.945,
      90274.61,
      89802.43,
      90267.93,
      88511.016,
      91647.85,
      91248.29,
      90186.4,
      89702.71,
      89515.85,
      89452.17,
      89594.586,
      91967.6,
      90991.21,
      92166.164,
      92922.17,
      89786.484,
      90780.695,
      89337.29,
      92441.555,
      92395.56,
      94904.97,
      94538.71,
      95101.08,
      95574.875,
      95301.45,
      94439.14,
      96099.54,
      94946.73,
      95907.73,
      98153.44,
      93972.71,
      94735.69,
      97286.85,
      95916.69,
      96702.766,
      96260.766,
      98782.36,
      99068.09,
      97991.336,
      97377.89,
      97606.63,
      99328.695,
      97630.7,
      98738.7,
      97890.37,
      99032.94,
      99071.25,
      98164.61,
      99243.234,
      98093.04,
      97893.95,
      100480.66,
      99840.91,
      100062.41,
      99757.1,
      100654.914,
      101842.12,
      101119.67,
      101544.41
  ],
  percentile50: [
      100000,
      100292.66,
      100570.14,
      100663.74,
      100855.445,
      101438.78,
      101128.77,
      101854.375,
      101805.69,
      102322.195,
      102332.16,
      102951.234,
      103266.52,
      102960.055,
      102947.89,
      102736.305,
      103354.07,
      103513.875,
      104119.29,
      103940.6,
      104806.31,
      105164.23,
      105635.46,
      105271.36,
      105348.93,
      105073.59,
      105059.03,
      105734.04,
      105865.484,
      105619.9,
      106791.414,
      106839.01,
      106379.586,
      107460.26,
      107851.56,
      108923.13,
      109035.97,
      109668.34,
      109238.086,
      108356.77,
      108902.695,
      108789.43,
      109912.484,
      110159.79,
      110165.94,
      110563.02,
      111032.35,
      110938.62,
      111713.055,
      111667.43,
      111755.87,
      111467.664,
      111859.664,
      112383.54,
      113091.41,
      113537.85,
      115061.02,
      115402.8,
      114746.195,
      116507.47,
      116729.234,
      118101.266,
      117854.41,
      117950.055,
      117383.805,
      118777.37,
      118802.38,
      119408.27,
      118735.1,
      120386.54,
      120778.17,
      120933.6,
      120935.85,
      122660.63,
      123975.93,
      123276.734,
      123415.07,
      124264.836,
      125529.445,
      125724.66,
      124292.35,
      124842.34,
      125233.6,
      124752.32,
      125250.305,
      126189.99,
      124147.09,
      124097.49,
      125622.88,
      126751.72,
      128066.51,
      128896.76,
      128308.57,
      129069.234,
      129758.516,
      130292.66,
      131726.69,
      132423.06,
      132809.94,
      131385.83,
      130988.89,
      132703.25,
      132791.47,
      134192.12,
      133312.9,
      133672,
      133674.1,
      135680.28,
      135972.38,
      135996.45,
      136963.4,
      136118.16,
      138595.64,
      138461.66,
      140504.64,
      140973.52,
      140355.31,
      139880.48,
      140078.34,
      141358.73,
      142137.19,
      140934.72,
      140755.58,
      141288.73,
      142376.48,
      140779.08,
      141550.2,
      143024.4,
      142520.22,
      144665.55,
      145047.7,
      148247.6,
      149305.72,
      149967.52,
      147590.44,
      149158.44,
      150973.3,
      150984.84,
      152800.39,
      153487.1,
      151355.97,
      153755.86,
      155321.23,
      153689.44,
      152675.36,
      154557.67,
      154442.08,
      155586.47,
      158771.44,
      156914.94,
      156932.3,
      159913.83,
      160699.05,
      160756.52,
      162034.36,
      164022.44,
      164012.36,
      162532.47,
      162497.56,
      162030.67,
      163798.27,
      165124.14,
      166287.84,
      166312.3,
      168866.9,
      168117.97,
      170866.64,
      171177.52,
      167361.75,
      167245.08,
      168996.39,
      171272.34,
      171127.38,
      173682.22,
      174553.94,
      171661.8,
      173362.19,
      173108.34,
      175268.16,
      176835.97,
      177234.47,
      175567.22,
      177237.33,
      179159.38,
      180073.22,
      179788.44,
      177781.44,
      178455.12,
      180104.25,
      179335.78,
      182569.27,
      185505.03,
      185241.02,
      185861.89,
      186077.62,
      187396.69,
      188847.3,
      189735.1
  ],
  percentile75: [
      100000,
      101946.47,
      103047.12,
      104467.02,
      105127.51,
      105913.945,
      106795.05,
      107304.195,
      108477.195,
      108675.586,
      109484.05,
      109360.57,
      109749.67,
      110686.03,
      111864.94,
      111761.15,
      112037.1,
      112559.12,
      112328.63,
      112829.66,
      113866.67,
      114414.68,
      114257.1,
      113902.98,
      115066.875,
      115961.43,
      116854.79,
      117118.38,
      117314.56,
      117934,
      118271.484,
      117778.25,
      119377.22,
      119179.05,
      119530.19,
      119996.3,
      120032.875,
      120634.66,
      122210.914,
      122750.34,
      122296.33,
      124742.52,
      124961.02,
      125396.63,
      125259.875,
      125512.445,
      127410.36,
      128529.64,
      128523.42,
      129595.445,
      131124.75,
      131545.48,
      130460.06,
      131556.78,
      131778.5,
      131685.89,
      131300.1,
      131370.56,
      134429.3,
      134347.12,
      134867.23,
      133453.69,
      134170.05,
      135335.83,
      135969.8,
      137221.77,
      137824.88,
      138062.48,
      139753.66,
      141783.9,
      141548.08,
      141582.36,
      142061.92,
      143125.47,
      146901.31,
      147601.48,
      148525.27,
      148958.23,
      148205,
      148282.55,
      150095,
      152051.4,
      151211.67,
      152182.83,
      151636.66,
      153211.08,
      153849.95,
      154007.66,
      155580.02,
      155498.55,
      156229.47,
      158477.81,
      159411.6,
      158152.5,
      159738.55,
      159787.6,
      160770.98,
      160333.2,
      161330.25,
      162595.02,
      161034.94,
      162889.39,
      163707.78,
      164713.66,
      166924.45,
      167746.83,
      167084.28,
      166247.27,
      166372.95,
      168824.05,
      169019.48,
      169628.36,
      170085.66,
      168572.84,
      172224.36,
      173361.61,
      174529.7,
      176050.52,
      174750.27,
      177096.95,
      177298.3,
      176690.02,
      176945.17,
      178546.4,
      179868.94,
      180593.47,
      179705.14,
      180863.12,
      180175.86,
      178594.38,
      180749.05,
      179910.78,
      181963.53,
      184836.45,
      187241.03,
      188474.22,
      183291.86,
      187787.89,
      188127.22,
      190228.19,
      188473.75,
      193080.45,
      192501.42,
      191842.4,
      192923.48,
      192572.28,
      193134.78,
      196870.53,
      199399.12,
      199707.55,
      200620.64,
      202610.48,
      200289.5,
      202865.64,
      206650.19,
      205173.03,
      206531.23,
      205905.06,
      207129.75,
      205843.42,
      207170.89,
      206439.67,
      208119.08,
      210709.72,
      211196.52,
      212477.36,
      213443.52,
      213042.88,
      213122.64,
      215959.38,
      213997.95,
      215940.5,
      217260.69,
      217919.64,
      219125.1,
      221211.75,
      221405.22,
      221191.25,
      223364.62,
      224788.9,
      228657.08,
      230496.48,
      232773.22,
      228008.97,
      231715.56,
      232025.42,
      231707.17,
      231010.5,
      229461.42,
      235126.77,
      236265.06,
      236553.56,
      240589.58,
      240414.3,
      245482.22,
      242558.81,
      241531.69,
      243659.61
  ]
},
  retirementResult: { achievementFraction: 1, shortfall: 0 },
  legacyResult: { achievementFraction: 1, shortfall: 0 },
  dreamResult: { achievementFraction: 1, shortfall: 0 },
  secureFamilyResult: { achievementFraction: 0, shortfall: 0 },
  preserveCapitalResult: { achievementFraction: 0, shortfall: 0 },
  leverageQuarter: 199,
  activeQuarters: 129,
  totalQuarters: 197,
  maxPensionPayout: undefined,
  invalidDateGoals: [],
  invalidDateContributions: [],
};

export const staticSimulationSlice = createSlice({
  name: STATIC_SIMULATION_FEATURE_KEY,
  initialState,
  reducers: {
    setStaticLeverageQuarter: (state, action: PayloadAction<number>) => {
      state = { ...state, leverageQuarter: action.payload };
      return state;
    },
  },
});

export const selectStaticSimulationState = createSelector(
  (state: RootState) => state.staticSimulation,
  (staticSimulation) => staticSimulation
);

export const { setStaticLeverageQuarter } = staticSimulationSlice.actions;