import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from "../../../common/hooks/useTrackEvent";
import { revertAll, useAppDispatch } from "../../../common/store/hooks";
import { fetchSimulation } from "../../../common/store/simulation/simulation.slice";
import { persistor } from "../../../common/store/store";
import { ResetComponentLabels } from "../../../common/labels/reset-component.labels";
import "./reset-component.scss";

export interface ResetComponentProps {
    labels: ResetComponentLabels;
    onConfirm?: () => void;
    onCancel?: () => void;
}

export function ResetComponent(props: ResetComponentProps) {
    let abortController = new AbortController();
    const dispatch = useAppDispatch();

    const trackEvent = useTrackEvent();

    const reset = () => {
        if (props.onConfirm) {
            props.onConfirm();
        }

        trackEvent({ category: trackEventCategories.RESET, action: trackEventActions.CLICK, name: trackEventNames.CONFIRM });
        persistor.purge();
        dispatch(revertAll());
        abortController.abort();
        abortController = new AbortController();
        dispatch(fetchSimulation({ abortSignal: abortController.signal }));
    }

    return <div className="reset-component">
        <div dangerouslySetInnerHTML={{ __html: props.labels.DESC }}></div>
        <div className="reset-component-buttons">
            <button className="button button--big button--big--solid" onClick={reset}>
                {props.labels.CONFIRM}
            </button>
            <button className="button button--big" onClick={props.onCancel}>
                {props.labels.CANCEL}
            </button>
        </div>
    </div>;
}