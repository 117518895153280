import { useEffect, useState } from 'react';
import './report-legend-component.scss';
import { ReportLegendComponentLabels } from '../report-component/report-component.labels';

export interface ReportLegendComponentProps {
  classNamePrefix?: string;
  includeIlliquid: boolean;
  labels: ReportLegendComponentLabels;
}

export function ReportLegendComponent(props: ReportLegendComponentProps) {
  const [includeIlliquid, setIncludeIlliquid] = useState<boolean>(props.includeIlliquid);

  useEffect(() => {
    setIncludeIlliquid(props.includeIlliquid);
  }, [props.includeIlliquid]);

  return (
    <div className="report-chart-legend-container">
      <div className="report-chart-legend">
        <span>{props.labels.NET_ASSETS_TITLE}</span>
        <div className="report-chart-legend-items">
          <div className="report-chart-legend-item">
            <hr className="report-chart-legend-expected" />
            <span className="report-chart-legend-label">{props.labels.EXPECTED}</span>
          </div>
          <div className="report-chart-legend-item">
            <div className="rectangle chart-legend-good" />
            <span className="report-chart-legend-label">{props.labels.GOOD}</span>
          </div>
          <div className="report-chart-legend-item">
            <div className="rectangle chart-legend-adverse" />
            <span className="report-chart-legend-label">{props.labels.ADVERSE}</span>
          </div>
        </div>
      </div>
    {includeIlliquid && (
      <div className="report-chart-legend report-chart-legend-illiquid">
        <span>{props.labels.PROPERTY_TITLE}</span>
        <div className="report-chart-legend-items">
            <div className="report-chart-legend-item">
              <hr className="report-chart-legend-illiquid-expected" />
              <span className="report-chart-legend-label">{props.labels.EXPECTED}</span>
            </div>
            <div className="report-chart-legend-item">
              <div className="rectangle chart-legend-illiquid-good illiquid" />
              <span className="report-chart-legend-label">{props.labels.GOOD}</span>
            </div>
            <div className="report-chart-legend-item">
              <div className="rectangle chart-legend-illiquid-adverse" />
              <span className="report-chart-legend-label">{props.labels.ADVERSE}</span>
            </div>
        </div>
      </div>
      )}
    </div>
  )
}

export default ReportLegendComponent;
