import { WidgetComponent } from '@flexfront/ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { useSavingsContext } from '../../../common/providers/savings-provider';
import { QuirionIconCardComponent } from '../quirion-icon-card-component/quirion-icon-card-component';
import InfoIcon from '../../assets/images/info.svg';
import CurrencyInputComponent from '../../../common/components/currency-input-component/currency-input-component';
import NumberInputComponent from '../../../common/components/number-input-component/number-input-component';

export function ICanComponent() {

  const {
    labels,
    savings,
    currencyConfig,

    swiperContainerRef,
    onSwiperContainerKeyUp,
    setSwiperInstance,

    savingTabindex,
    totalSavings,
    savingIcon,
    
    onMonthlySavingsAmountChange: onMonthlySavingsChange,
    onMonthlySavingsAmountBlur,

    lumpsumTabindex,
    lumpsunIcon,
    onLumpsumSavingsYearChange,
    onLumpsumSavingsYearBlur,
    onLumpsumSavingsAmountChange,
    onLumpsumSavingsAmountBlur,
    isLumpsumYearValid
  } = useSavingsContext();

  const classNamePrefix = 'quirion';

  return (
    <div>
      <label
        className={`${classNamePrefix}-widget-title`}>
          {labels.TITLE}
      </label>
      <WidgetComponent
        isExpanded={false}
        className={`${classNamePrefix}-widget`}>
        <div
          className={`${classNamePrefix}-swiper-component`}
          ref={swiperContainerRef}
          onKeyUp={onSwiperContainerKeyUp}>
          <Swiper
            onSwiper={setSwiperInstance}
            spaceBetween={10}
            slidesPerView={2}
            pagination={{ clickable: true, enabled: true }}
            modules={[Pagination]}
          >
            <SwiperSlide>
              <QuirionIconCardComponent
                title={labels.SAVINGS.TOTAL_SAVINGS}
                icon={savingIcon}
                info={<InfoIcon />}
                tooltip={labels.SAVINGS.SAVINGS_TOOLTIP}
                tooltipPosition="bottom center"
                inputs={<>
                  <CurrencyInputComponent
                    disabled={true}
                    title={labels.SAVINGS.TOTAL_SAVINGS_AMOUNT_INPUT_TITLE}
                    classNamePrefix={classNamePrefix}
                    value={totalSavings}
                    placeholder={labels.SAVINGS.MONTHLY_SAVINGS_PLACEHOLDER}
                    currencyConfig={currencyConfig}
                    max={9999999}
                  />
                  <CurrencyInputComponent
                    title={labels.SAVINGS.MONTHLY_SAVINGS + ` (` + savings.monthlyYear + `)`}
                    classNamePrefix={classNamePrefix}
                    value={savings.monthly}
                    tabIndex={savingTabindex}
                    placeholder={labels.SAVINGS.MONTHLY_SAVINGS_PLACEHOLDER}
                    currencyConfig={currencyConfig}
                    onChange={onMonthlySavingsChange}
                    onBlur={onMonthlySavingsAmountBlur}
                    max={9999999}
                    suffix={labels.SAVINGS.MONTHLY}
                  />
                </>}
              />
              {/*
                cardClassName={savings.hasInvalidMonthlyYear ? 'error error-border' : ''}
              /> */}
            </SwiperSlide>
            <SwiperSlide>
              <QuirionIconCardComponent
                title={labels.LUMPSUM_YEAR}
                icon={lumpsunIcon}
                info={<InfoIcon />}
                tooltip={labels.LUMPSUM_TOOLTIP}
                inputs={<>
                  <NumberInputComponent
                    title={labels.WHEN}
                    value={savings.lumpsumYear}
                    placeholder={labels.LUMPSUM_YEAR_PLACEHOLDER}
                    tabIndex={lumpsumTabindex}
                    classNamePrefix={classNamePrefix}
                    onChange={onLumpsumSavingsYearChange}
                    onBlur={onLumpsumSavingsYearBlur}
                    hasError={!isLumpsumYearValid}/>
                  <CurrencyInputComponent
                    title={labels.AMOUNT}
                    classNamePrefix={classNamePrefix}
                    value={savings.lumpsum}
                    placeholder={labels.LUMPSUM_AMOUNT_PLACEHOLDER}
                    tabIndex={lumpsumTabindex ? lumpsumTabindex + 1 : -1}
                    currencyConfig={currencyConfig}
                    onChange={onLumpsumSavingsAmountChange}
                    onBlur={onLumpsumSavingsAmountBlur}
                    max={9999999}
                  />
                </>}
              />
              {/*
                cardClassName={savings.hasInvalidLumpsumYear ? 'error error-border' : ''}
              /> */}
            </SwiperSlide>
          </Swiper>
        </div>
      </WidgetComponent>
    </div>
  );
}

export default ICanComponent;
