import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { ASSETS_FEATURE_KEY, assetsSlice } from "./assets/assets.slice";
import { GOALS_FEATURE_KEY, goalsSlice } from "./goals/goals.slice";
import { simulationSlice } from "./simulation/simulation.slice";
import { SAVINGS_FEATURE_KEY, savingsSlice } from "./savings.slice";
import { PERSONAL_FEATURE_KEY, personalSlice } from "./personal.slice";
import climateChangeSlice, { CLIMATE_FEATURE_KEY } from "./climate-change/climate.slice";
import { LIABILITIES_FEATURE_KEY, liabilitiesSlice } from "./liabilities/liabilities.slice";
import debugSlice from "./debug/debug.slice";
import snapshotsSlice, { SNAPSHOTS_FEATURE_KEY } from "./snapshots/snapshots.slice";
import chartLegendSlice from "./chartLegend/chartLegend.slice";
import infoModalSlice from "./info-modal/info-modal.slice";
import tenantConfigSlice from "./tenant-config/tenant-config.slice";
import authSlice from "./auth/auth.slice";
import notificationsSlice from "./notifications/notifications.slice";
import timelineSlice from "./timeline/timeline.slice";
import { layoutsSlice } from "./layout/layouts.slice";
import { staticSimulationSlice } from "./static-simulation/static-simulation.slice";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import userSettingsSlice, { USER_SETTINGS_FEATURE_KEY } from "./user-settings.slice";
import { goalsSliderSlice } from "./slider/goalsSlider.slice";
import shortfallEliminationSlice from "./shortfall-elimination.slice";

const persistConfig = {
  key: 'root',
  version: 1,
  whitelist: [
    PERSONAL_FEATURE_KEY,
    GOALS_FEATURE_KEY,
    ASSETS_FEATURE_KEY,
    LIABILITIES_FEATURE_KEY,
    SAVINGS_FEATURE_KEY,
    CLIMATE_FEATURE_KEY,
    SNAPSHOTS_FEATURE_KEY,
    USER_SETTINGS_FEATURE_KEY
  ],
  storage
}

const rootReducer = combineReducers({
  personal: personalSlice.reducer,
  goals: goalsSlice.reducer,
  assets: assetsSlice.reducer,
  liabilities: liabilitiesSlice.reducer,
  savings: savingsSlice.reducer,
  climateChange: climateChangeSlice.reducer,
  simulation: simulationSlice.reducer,
  shortfallElimination: shortfallEliminationSlice.reducer,
  staticSimulation: staticSimulationSlice.reducer,
  debug: debugSlice.reducer,
  snapshots: snapshotsSlice.reducer,
  chartLegend: chartLegendSlice.reducer,
  chartOverlay: infoModalSlice.reducer,
  tenantConfig: tenantConfigSlice.reducer,
  auth: authSlice.reducer,
  notifications: notificationsSlice.reducer,
  timeline: timelineSlice.reducer,
  layouts: layoutsSlice.reducer,
  userSettings: userSettingsSlice.reducer,
  goalsSlideIndex: goalsSliderSlice.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);

export default store;
