import { useMatomo } from "@jonkoops/matomo-tracker-react"
import { useDeviceBounds } from "./useDeviceBounds"

export interface CustomDimension {
    id: number
    value: string
}

export interface TrackPageViewParams {
    documentTitle?: string
    href?: string | Location
    customDimensions?: boolean | CustomDimension[]
}
  
export interface TrackParams extends TrackPageViewParams {
    data: any[]
}

export interface TrackEventParams extends TrackPageViewParams {
    category: string
    action: string
    name?: string
    value?: number
}

export const useTrackEvent = () => {
    const { trackEvent } = useMatomo();
    const { isHorizontallyCompact } = useDeviceBounds();

    return (trackEventParams: TrackEventParams) => {
        if (isHorizontallyCompact) {
            trackEventParams.name += '_M';
        }
        trackEvent(trackEventParams);
    };
}

export const trackEventCategories = {
    THIS_IS_ME: 'THIS_IS_ME',
    I_WANT: 'I_WANT',
    I_HAVE: 'I_HAVE',
    I_CAN: 'I_CAN',
    FLYOUT: 'FLYOUT',
    CONFIG: 'CONFIG',
    CTA_FINAL: 'CTA_FINAL',
    RESULTS: 'RESULTS',
    CTA_FINAL_EDIT: 'CTA_FINAL_EDIT',
    CTA_FINAL_CONFIRM: 'CTA_FINAL_CONFIRM',
    CTA_FINAL_CONFIRM_MODAL: 'CTA_FINAL_CONFIRM_MODAL',
    CTA_FINAL_CONFIRM_EDIT: 'CTA_FINAL_CONFIRM_EDIT',
    EDIT: 'EDIT',
    TAB: 'TAB',
    LANDING: 'LANDING',
    LANDING_MODAL: 'LANDING_MODAL',
    INFLATION: 'INFLATION',
    LANGUAGE: 'LANGUAGE',
    LOGIN: 'LOGIN',
    RESET: 'RESET',
    NUDGE: 'NUDGE',
    INFO: 'INFO',
    PREVIOUS_VALUES: 'PREVIOUS_VALUES',
    PROPERTY: 'PROPERTY',
    REPORT: 'REPORT'
}

export const trackEventActions = {
    NAME: 'name',
    EMAIL: 'email',
    GENDER: 'gender',
    INPUT: 'input',
    VALIDATION: 'validation',
    CLICK: 'click',
    OPEN: 'open',
    CLOSE: 'close'
}

export const trackEventNames = {
    NAME: 'NAME',
    AGE: 'AGE',
    GENDER: 'GENDER',
    SAVE: 'SAVE',
    DOWNLOAD: 'DOWNLOAD',
    CLOSE: 'CLOSE',
    CANCEL: 'CANCEL',
    RETIREMENT_AGE: 'RETIREMENT_AGE',
    RETIREMENT_PAYOUT: 'RETIREMENT_PAYOUT',
    RETIREMENT_EDIT: 'RETIREMENT_EDIT',
    LEGACY_PAYOUT: 'LEGACY_PAYOUT',
    LEGACY_EDIT: 'LEGACY_EDIT',
    DREAM_YEAR: 'DREAM_YEAR',
    DREAM_PAYOUT: 'DREAM_PAYOUT',
    DREAM_EDIT: 'DREAM_EDIT',
    SECURE_FAMILY_YEAR: 'SECURE_FAMILY_YEAR',
    SECURE_FAMILY_PAYOUT: 'SECURE_FAMILY_PAYOUT',
    SECURE_FAMILY_EDIT: 'SECURE_FAMILY_EDIT',
    PRESERVE_CAPITAL_FROM: 'PRESERVE_CAPITAL_FROM',
    PRESERVE_CAPITAL_TO: 'PRESERVE_CAPITAL_TO',
    PRESERVE_CAPITAL_AMOUNT: 'PRESERVE_CAPITAL_AMOUNT',
    PRESERVE_CAPITAL_EDIT: 'PRESERVE_CAPITAL_EDIT',
    PRESERVE_CAPITAL_DATE_ERROR: 'PRESERVE_CAPITAL_DATE_ERROR',
    REAL_ESTATE: 'REAL_ESTATE',
    REAL_ESTATE_EDIT: 'REAL_ESTATE_EDIT',
    EQUITY: 'EQUITY',
    EQUITY_EDIT: 'EQUITY_EDIT',
    BONDS: 'BONDS',
    BONDS_EDIT: 'BONDS_EDIT',
    CASH: 'CASH',
    CASH_EDIT: 'CASH_EDIT',
    MORTGAGE: 'MORTGAGE',
    MORTGAGE_EDIT: 'MORTGAGE_EDIT',
    LIABILITY_ERROR: 'LIABILITY_ERROR',
    EQUITY_ERROR: 'EQUITY_ERROR',
    MONTHLY_SAVINGS_YEAR: 'MONTHLY_SAVINGS_YEAR',
    MONTHLY_SAVINGS_AMOUNT: 'MONTHLY_SAVINGS_AMOUNT',
    MONTHLY_SAVINGS_EDIT: 'MONTHLY_SAVINGS_EDIT',
    LUMPSUM_SAVINGS_YEAR: 'LUMPSUM_SAVINGS_YEAR',
    LUMPSUM_SAVINGS_AMOUNT: 'LUMPSUM_SAVINGS_AMOUNT',
    LUMPSUM_SAVINGS_EDIT: 'LUMPSUM_SAVINGS_EDIT',
    SAVING_ERROR: 'SAVING_ERROR',
    SUBMIT: 'SUBMIT',
    OPEN: 'OPEN',
    CONFIRM: 'CONFIRM',
    CTA: 'CTA',
    CHANGE: 'CHANGE',
    CLOSE_CONFIRM: 'CLOSE_CONFIRM',
    OPEN_CONFIRM: 'OPEN_CONFIRM',
    APPLY: 'APPLY',
    INFO_MODAL: 'INFO_MODAL',
    INFLATION_MODAL: 'INFLATION_MODAL',
    SHOW: 'SHOW',
    CTA_FINAL: 'CTA_FINAL',
    CTA_HIGH_GOAL: 'CTA_HIGH_GOAL',
    CTA_SHORTFALL_SAVE_MORE: 'CTA_SHORTFALL_SAVE_MORE',
    CTA_SHORTFALL_REDUCE_GOALS: 'CTA_SHORTFALL_REDUCE_GOALS',

}