import { useEffect, useRef } from 'react';
import Popup from 'reactjs-popup';
import InfoComponent from '../info-component/info-component';
import { useAppDispatch, useAppSelector } from '../../../common/store/hooks';
import { selectInfoModalState, setIsInfoModalOpen } from '../../../common/store/info-modal/info-modal.slice';
import { InfoModalComponentLabels } from '../../../common/labels/info-modal-component-labels';
import './info-modal-component.scss';

/* eslint-disable-next-line */
export interface InfoModalComponentProps {
  labels: InfoModalComponentLabels;
}

export function InfoModalComponent(props: InfoModalComponentProps) {
  const overlayRef = useRef<HTMLDivElement>(null);
  const overlay = useAppSelector(selectInfoModalState);
  const dispatch = useAppDispatch();

  function closeModal() {
    dispatch(setIsInfoModalOpen(false));
  }

  useEffect(() => {
    if (overlay.isOpen) {
      document.body.classList.add('disable-scroll');
    } else {
      document.body.classList.remove('disable-scroll');
    }
  }, [overlay.isOpen]);

  return overlay && overlay.isOpen ? (
    <div className="info-modal-component" ref={overlayRef}>
      <Popup
        className="info-popup"
        open={overlay.isOpen}
        onClose={closeModal}
        position="center center"
        arrow={false}
        modal={true}
      >
        <div className="info-modal-component-confirm-popup">
          <div className="modal-title">
            <span className="info-modal-component-header-title">
              <span className="icon-info"></span> {props.labels.TITLE}
            </span>
            <span
              className="modal-title-icon button-close icon-times"
              style={{ alignSelf: 'center', textAlign: 'center', marginRight: 0 }}
              onClick={closeModal}
            />
          </div>
          <div className="info-modal-component-body">
            <span className="info-modal-component-body-info-content">
              <InfoComponent labels={props.labels.INFO} />
            </span>
          </div>
          <div>
            <div className="popup-button-div">
              <button className="button button--solid" onClick={closeModal}>
                {props.labels.CLOSE}
              </button>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  ) : null;
}

export default InfoModalComponent;
