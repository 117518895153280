import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";
import { ResultsComponentLabels } from "../labels/results-component.labels";
import { useAppSelector } from "../store/hooks";
import { selectDreamState, selectLegacyState, selectPreserveCapitalState, selectRetirementState, selectSecureFamilyState } from "../store/goals/goals.slice";
import { selectSimulationState } from "../store/simulation/simulation.slice";
import { useDeviceBounds } from "../hooks/useDeviceBounds";
import { useCurrencyConfig } from "../hooks/useCurrencyConfig";
import { SwiperSlide } from "swiper/react";
import GoalAchievementComponent from "../../default/components/goal-achievement-component/goal-achievement-component";
import { SimulationState } from "../store/simulation/simulation.state";
import { CurrencyConfig } from "@flexfront/ui/react";
import { useComponentIcon } from "../hooks/useComponentIcon";


export interface ResultContextProps {
    children: ReactNode;
    labels: ResultsComponentLabels;
}

interface ResultFunctionality {
    labels: ResultsComponentLabels;
    isHorizontallyCompact: boolean;
    simulation: SimulationState;
    hasLegacy : boolean;
    hasRetirement: boolean;
    hasDream: boolean;
    hasSecureFamily: boolean;
    hasPreserveCapital: boolean;
    currencyConfig: CurrencyConfig;
    dreamIcon: string | undefined;
    retirementIcon: string | undefined;
}

const ResultContext = createContext<ResultFunctionality | undefined>(undefined);

export const useResultContext = (): ResultFunctionality => {
    const context = useContext(ResultContext);
    if (!context) {
        throw new Error('ResultContext must be used within a ResultProvider');
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useContext(ResultContext)!;
};

export const ResultProvider: React.FC<ResultContextProps> = (props: ResultContextProps) => {

  const retirement = useAppSelector(selectRetirementState);
  const legacy = useAppSelector(selectLegacyState);
  const dream = useAppSelector(selectDreamState);
  const secureFamily = useAppSelector(selectSecureFamilyState);
  const preserveCapital = useAppSelector(selectPreserveCapitalState);
  const simulation = useAppSelector(selectSimulationState);

  const [hasLegacy, setHasLegacy] = useState<boolean>(false);
  const [hasRetirement, setHasRetirement] = useState<boolean>(false);
  const [hasDream, setHasDream] = useState<boolean>(false);
  const [hasSecureFamily, setHasSecureFamily] = useState<boolean>(false);
  const [hasPreserveCapital, setHasPreserveCapital] = useState<boolean>(false);
  const currentYear = useMemo(() => new Date().getFullYear(), []);
  const { isHorizontallyCompact } = useDeviceBounds();

  const currencyConfig = useCurrencyConfig();

  const retirementIcon = useComponentIcon("I_WANT_RETIREMENT_ICON");
  const dreamIcon = useComponentIcon("I_WANT_DREAM_ICON");

  useEffect(() => {
    setHasRetirement(retirement.retirementAge > 0 && Math.round(retirement.retirementMonthlyPayout) > 0);
  }, [retirement]);

  useEffect(() => {
    setHasDream(dream.year >= currentYear && Math.round(dream.amount) > 0);
  }, [dream, currentYear]);

  useEffect(() => {
    setHasSecureFamily(secureFamily.year >= currentYear && Math.round(secureFamily.amount) > 0);
  }, [secureFamily, currentYear]);

  useEffect(() => {
    setHasLegacy(Math.round(legacy.amount) > 0);
  }, [legacy]);

  useEffect(() => {
    setHasPreserveCapital(
      Math.round(preserveCapital.amount) > 0 && preserveCapital.startDate !== undefined && preserveCapital.endDate !== undefined
    );
  }, [preserveCapital]);


    const contextValue = {
        labels: props.labels,
        isHorizontallyCompact: isHorizontallyCompact,
        simulation:simulation,
        hasRetirement: hasRetirement,
        hasLegacy:hasLegacy,
        hasDream:hasDream,
        hasPreserveCapital:hasPreserveCapital,
        hasSecureFamily:hasSecureFamily,
        currencyConfig:currencyConfig,
        retirementIcon: retirementIcon,
        dreamIcon: dreamIcon,
    };

    return (
        <ResultContext.Provider value={contextValue}>{props.children}</ResultContext.Provider>
      );
};