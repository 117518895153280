import { IWantComponentLabels } from "./i-want-component.labels";

export interface IWantListComponentLabels {
    I_WANT_TITLE: string;
    RETIREMENT: string;
    RETIREMENT_AMOUNT: string;
    RETIREMENT_AGE_PLACEHOLDER: string;
    RETIREMENT_AMOUNT_PLACEHOLDER: string;
    RETIREMENT_TOOLTIP: string;
    LEGACY: string;
    LEGACY_AMOUNT: string;
    LEGACY_AMOUNT_PLACEHOLDER: string;
    DREAM: string;
    DREAM_AMOUNT: string;
    DREAM_YEAR_PLACEHOLDER: string;
    DREAM_AMOUNT_PLACEHOLDER: string;
    DREAM_TOOLTIP: string;
    PRESERVE_CAPITAL: string;
    PRESERVE_CAPITAL_AMOUNT: string;
    PRESERVE_CAPITAL_DATE_PLACEHOLDER: string;
    PRESERVE_CAPITAL_AMOUNT_PLACEHOLDER: string;
    SECURE_FAMILY: string;
    SECURE_FAMILY_AMOUNT: string;
    SECURE_FAMILY_YEAR_PLACEHOLDER: string;
    SECURE_FAMILY_AMOUNT_PLACEHOLDER: string;
}

// NOTE: We should refactor the label structure at some point to avoid coupling the labels to a specific design.
// For example: We should not really have hard concepts like each component in the label structure.
// Ideally there should be sections for each concept, like GOALS etc. We then have SHARED labels for SAVE and CANCEL etc.
// And only adding specific labels where absolutely necessary.
export function fromIWantLabels(labels: IWantComponentLabels): IWantListComponentLabels {
    return {
        I_WANT_TITLE: labels.TITLE,
        RETIREMENT: labels.GOALS.RETIREMENT.TITLE,
        RETIREMENT_AMOUNT: labels.GOALS.RETIREMENT.AMOUNT,
        RETIREMENT_AGE_PLACEHOLDER: labels.GOALS.RETIREMENT.AGE_PLACEHOLDER,
        RETIREMENT_AMOUNT_PLACEHOLDER: labels.GOALS.RETIREMENT.AMOUNT_PLACEHOLDER,
        RETIREMENT_TOOLTIP: labels.GOALS.RETIREMENT.TOOLTIP,
        LEGACY: labels.GOALS.LEGACY.TITLE,
        LEGACY_AMOUNT: labels.GOALS.LEGACY.AMOUNT,
        LEGACY_AMOUNT_PLACEHOLDER: labels.GOALS.LEGACY.AMOUNT_PLACEHOLDER,
        DREAM: labels.GOALS.GOAL.TITLE,
        DREAM_AMOUNT: labels.GOALS.GOAL.AMOUNT,
        DREAM_YEAR_PLACEHOLDER: labels.GOALS.GOAL.YEAR_PLACEHOLDER,
        DREAM_AMOUNT_PLACEHOLDER: labels.GOALS.GOAL.AMOUNT_PLACEHOLDER,
        DREAM_TOOLTIP: labels.GOALS.GOAL.TOOLTIP,
        PRESERVE_CAPITAL: labels.GOALS.PRESERVE_CAPITAL.TITLE,
        PRESERVE_CAPITAL_AMOUNT: labels.GOALS.PRESERVE_CAPITAL.AMOUNT,
        PRESERVE_CAPITAL_DATE_PLACEHOLDER: 'YYYY',
        PRESERVE_CAPITAL_AMOUNT_PLACEHOLDER: labels.GOALS.PRESERVE_CAPITAL.AMOUNT_PLACEHOLDER,
        SECURE_FAMILY: labels.GOALS.SECURE_FAMILY.TITLE,
        SECURE_FAMILY_AMOUNT: labels.GOALS.SECURE_FAMILY.AMOUNT,
        SECURE_FAMILY_YEAR_PLACEHOLDER: labels.GOALS.SECURE_FAMILY.YEAR_PLACEHOLDER,
        SECURE_FAMILY_AMOUNT_PLACEHOLDER: labels.GOALS.SECURE_FAMILY.AMOUNT_PLACEHOLDER,
      }
}