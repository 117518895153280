import Popup from 'reactjs-popup';
import InfoComponent from '../info-component/info-component';
import { LandingModalComponentLabels } from '../../../common/labels/landing-modal-component-labels';
import './landing-modal-component.scss'
import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from '../../../common/hooks/useTrackEvent';
import { useComponentIcon } from '../../../common/hooks/useComponentIcon';

export interface LandingModalComponentProps {
    labels: LandingModalComponentLabels;
    isOpen: boolean;
    closePopup: () => void;
    confirm: () => void;
}

export function LandingModalComponent(props: LandingModalComponentProps) {

  const trackEvent = useTrackEvent();
  const ctaIcon = useComponentIcon("CTA");

  const close = () => {
    trackEvent({ category: trackEventCategories.LANDING_MODAL, action: trackEventActions.CLICK, name: trackEventNames.CLOSE });
    props.closePopup();
  }

  return (
    <div className="landing-modal-component">
      <Popup
        className="info-popup"
        open={props.isOpen}
        onClose={close}
        position="center center"
        arrow={false}
        modal={true}
      >
        <div className="confirm-popup">
          <div className="modal-title">
            <span className="landing-modal-component-header-title">{props.labels.TITLE}</span>
            <span  className="modal-title-icon icon-times landing-modal-component-icon button-close"  onClick={close} />
          </div>
          <div className="landing-modal-component-row"></div> 
          <div className="landing-modal-component-body">
            <span className="landing-modal-component-body-info-content">
              <InfoComponent labels={props.labels.INFO} />
            </span>
          </div> 
          <div className="landing-modal-component-row"></div>
          <div>
            <div className="popup-button-div landing-modal-component-footer">
              <div className="dashboard-disclaimer"
                dangerouslySetInnerHTML={{ __html: props.labels.DISCLAIMER }}>
              </div>
              <button className={`button button--solid button--auto ${ctaIcon ? 'button-with-icon' : ''}`} onClick={close}>
                {props.labels.START}
                {ctaIcon && <span className="cta-button-icon"
                  dangerouslySetInnerHTML={{ __html: ctaIcon ?? '' }}></span>}
              </button>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default LandingModalComponent;
