import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from "../../../common/hooks/useTrackEvent";
import { useAppDispatch, useAppSelector } from "../../../common/store/hooks";
import { selectUserSettingsState, setIncludeIlliquid } from "../../../common/store/user-settings.slice";

export interface ShowPropertyComponentProps {
    title: string;
}

export function ShowPropertyComponent(props: ShowPropertyComponentProps) {
    const userSettings = useAppSelector(selectUserSettingsState);
    const dispatch = useAppDispatch();

    const trackEvent = useTrackEvent();
    
    const showProperty = () => {
        trackEvent({ category: trackEventCategories.PROPERTY, action: trackEventActions.CLICK, name: trackEventNames.SHOW, value: +!userSettings.includeIlliquid });
        dispatch(setIncludeIlliquid(!userSettings.includeIlliquid)); 
    }

    return <>
        <span onClick={showProperty}>{props.title}</span>
        <label className="switch">
            <input
                type="checkbox"
                checked={userSettings.includeIlliquid}
                onChange={showProperty}
            />
            <span className="toggle round"></span>
        </label>
    </>;
}