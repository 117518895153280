import { useEffect } from 'react';
import './cta-component.scss';
import '../../../default/components/cta-component/cta-component.scss';

export function CtaComponent() {

  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://ms.quirinprivatbank.de/assets/webflow_forms.css?body=1';
    document.head.insertBefore(link, document.head.firstChild);

    const script = document.createElement('script');
    script.src = 'https://ms.quirinprivatbank.de/assets/external_forms.js?body=1';
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(link);
      document.head.removeChild(script);
    };
  }, []);

  return (<div
    className="quirion-cta-component-body">
      <div
        id="qpb-form"
        data-form-base-id="1107"
        data-content-article-form-id="2502"
        data-tag=""
        className='webflow-form qpb-form'>
      </div>
    </div>);

  
}

export default CtaComponent;
