import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { Personal } from "@flexfront/models";
import { revertAll } from "./hooks";

export const PERSONAL_FEATURE_KEY = "personal";
export const DEFAULT_AGE = 35;
export const DEFAULT_GENDER = "F";

export const initialState: Personal = {
  name: "",
  age: DEFAULT_AGE,
  gender: DEFAULT_GENDER,
};

export const personalSlice = createSlice({
  name: PERSONAL_FEATURE_KEY,
  initialState,
  reducers: {
    savePersonal: (state, action: PayloadAction<Personal>) => {
      state = { ...action.payload };
      return state;
    },
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const { savePersonal } = personalSlice.actions;

export const selectPersonalState = createSelector(
  (state: RootState) => state.personal,
  (personal) => personal
);

export default personalSlice;
