import RetirementChartWrapperComponent from "../retirement-chart-component/retirement-chart-wrapper-component";
import TimelineContainerComponent from "../timeline-container-component/timeline-container-component";
import TimelineRangeComponent from "../timeline-range-component/timeline-range-component";
import { useDashboardContext } from '../../../common/providers/dashboard-provider';
import TimelineMarkerComponent from "../timeline-marker-component/timeline-marker-component";
import { useParams } from "react-router-dom";
import { IWantReportComponent } from "./i-want-report-component";
import { GoalsProvider } from "../../../common/providers/goals-provider";
import { useAppDispatch } from "../../../common/store/hooks";
import { useEffect, useState } from "react";
import { AssetsProvider } from "../../../common/providers/assets-provider";
import { IHaveReportComponent } from "./i-have-report-component";
import './report-component.scss';
import { SavingsProvider } from "../../../common/providers/savings-provider";
import { ICanReportComponent } from "./i-can-report-component";
import { TimelineReportComponent } from "./timeline-report-component";
import { NetAssetsReportComponent } from "./net-assets-report-component";
import { useReportLogo } from "../../../common/hooks/useTenantLogo";
import { TenantDetailsReportComponent } from "./tenant-details-report-component";
import { ReportComponentLabels } from "./report-component.labels";
import { environment } from "../../../../environments/environment";
import { PERSONAL_FEATURE_KEY, savePersonal } from "../../../common/store/personal.slice";
import { Personal, Savings, UserSettings } from "@flexfront/models";
import { GOALS_FEATURE_KEY, saveGoals } from "../../../common/store/goals/goals.slice";
import { GoalsState } from "../../../common/store/goals/goals.state";
import { ASSETS_FEATURE_KEY, saveAssets } from "../../../common/store/assets/assets.slice";
import { Assets } from "../../../common/store/assets/assets.state";
import { LIABILITIES_FEATURE_KEY, saveLiabilities } from "../../../common/store/liabilities/liabilities.slice";
import { Liabilities } from "../../../common/store/liabilities/liabilities.state";
import { SAVINGS_FEATURE_KEY, saveSavings } from "../../../common/store/savings.slice";
import { CLIMATE_FEATURE_KEY, saveClimateChange } from "../../../common/store/climate-change/climate.slice";
import { ClimateChangeState } from "../../../common/store/climate-change/climate-change.state";
import { SNAPSHOTS_FEATURE_KEY, saveSnapshots } from "../../../common/store/snapshots/snapshots.slice";
import { Snapshots } from "../../../common/store/snapshots/snapshots.state";
import { USER_SETTINGS_FEATURE_KEY, saveUserSettings } from "../../../common/store/user-settings.slice";
import ReportLegendComponent from "../report-legend-component/report-legend-component";

export interface ReportComponentProps {
  labels: ReportComponentLabels;
}

export function ReportComponent() {
  const root = document.getElementById('root');
  if (root && !root.className.includes("report-root")) {
      root.className += "report-root";
  }
  
  const [isRendered, setIsRendered] = useState<boolean>(false);
  
  const dispatch = useAppDispatch();
    const tenantLogo = useReportLogo();
    const { state } = useParams();

    const {
      labels: dashboardLabels,
      reportLabels,
      currentDate,
      iWantLabels,
      ihaveLabels,
      iCanLabels,

      realEstateIcon,
      mortgageIcon,
      retirementIcon,
      dreamIcon,
      
      simulation,
      personal,
      goals,
      assets,
      userSettings,
      tenantConfig,
  
      getQuarterSpanFromString,
      getQuarterFromYear,
      getQuarterSpanFromCurrentDate,

      debounceSimulationUpdate

  } = useDashboardContext();

    const url = `${environment.apiBaseUrl}/export/${state}`;
    
    useEffect(() => {
      fetch(url)
      .then(async (response: Response) => {
          const applicationState = await response.text();
          const stateString = atob(applicationState!);
          const stateJson = JSON.parse(stateString);
          
          const personalState = JSON.parse(stateJson[PERSONAL_FEATURE_KEY]) as Personal;
          const goalsState = JSON.parse(stateJson[GOALS_FEATURE_KEY]) as GoalsState;
          const assetsState = JSON.parse(stateJson[ASSETS_FEATURE_KEY]) as Assets;
          const liabilitiesState = JSON.parse(stateJson[LIABILITIES_FEATURE_KEY]) as Liabilities;
          const savingsState = JSON.parse(stateJson[SAVINGS_FEATURE_KEY]) as Savings;
          const climateChangeState = JSON.parse(stateJson[CLIMATE_FEATURE_KEY]) as ClimateChangeState;
          const snapshotsState = JSON.parse(stateJson[SNAPSHOTS_FEATURE_KEY]) as Snapshots;
          const userSettingsState = JSON.parse(stateJson[USER_SETTINGS_FEATURE_KEY]) as UserSettings;

          if (!isRendered) {
            dispatch(savePersonal(personalState));
            dispatch(saveGoals(goalsState));
            dispatch(saveAssets(assetsState));
            dispatch(saveLiabilities(liabilitiesState));
            dispatch(saveSavings(savingsState));
            dispatch(saveClimateChange(climateChangeState));
            dispatch(saveSnapshots(snapshotsState));
            dispatch(saveUserSettings(userSettingsState));

            debounceSimulationUpdate();
          }
      });
    }, []);

    function onRendered() {
      setIsRendered(true);
    }

    return <div className="report">
      <div className="report-page">
        <div className="report-cover">
          <div className="report-cover-row report-cover-row--logo">
            <div className="spacer"></div>
            {tenantLogo && <div className="report-logo">
              <img src={tenantLogo} alt="logo" />
            </div>}
          </div>

          <div className="report-cover-row report-cover-row--title">
            <div className="report-title">
              {reportLabels?.TITLE}
            </div>
          </div>
          
          <div className="report-cover-row">
              <label className="report-personal">{personal?.name}</label>
              <TenantDetailsReportComponent title={reportLabels.TENANT_DETAILS_TITLE} tenantConfig={tenantConfig} />
          </div>
        </div>
      </div>
      <div className="page-break report-page">

        <div className="report-content">

          <div className="report-colspan-2">
            <TimelineReportComponent labels={reportLabels} />
          </div>

          <div className="report-colspan-2">
            <GoalsProvider labels={iWantLabels}>
              <IWantReportComponent />
            </GoalsProvider>
          </div>

          <div className="report-colspan-1">
            <AssetsProvider labels={ihaveLabels}>
              <IHaveReportComponent />
            </AssetsProvider>
            <SavingsProvider labels={iCanLabels}>
              <ICanReportComponent />
            </SavingsProvider>
          </div>

          <div className="report-colspan-2">
            <label className="report-cell report-cell--header report-cell--title">
                {dashboardLabels.RETIREMENT_CHART.TITLE}
            </label>
            <RetirementChartWrapperComponent
              labels={dashboardLabels.RETIREMENT_CHART}
              heightOverride={239}
              widthOverride={563}
              animation={false}
              onRendered={onRendered} />
            <div className="timeline report-timeline">
              <TimelineContainerComponent isRangeContainer={true}>
                {goals.preserveCapital.amount > 0 && (
                  <TimelineRangeComponent
                    name="preserve-capital"
                    className="preserve-capital-timeline"
                    startQuarter={getQuarterSpanFromString(goals.preserveCapital.startDate)}
                    endQuarter={getQuarterSpanFromString(goals.preserveCapital.endDate)}
                  />
                )}
                <TimelineRangeComponent
                  name="retirement"
                  className="retirement-timeline"
                  startQuarter={simulation.activeQuarters - 1}
                  endQuarter={simulation.totalQuarters}
                />
              </TimelineContainerComponent>
              <TimelineContainerComponent>
                {userSettings.includeIlliquid && assets.realEstate > 0 && (
                  <TimelineMarkerComponent
                    name="property"
                    quarter={0}
                    icon={realEstateIcon}
                    iconClassName="icon-home"
                    order={0} />
                )}
                {userSettings.includeIlliquid
                  && simulation.leverageQuarter <= simulation.totalQuarters
                  && simulation.leverageQuarter > 0
                  && assets.realEstate > 0 && (
                  <TimelineMarkerComponent
                    name="leverage"
                    quarter={simulation.leverageQuarter}
                    icon={mortgageIcon}
                    iconClassName="icon-leverage"
                    order={2}
                  />
                )}
                <TimelineMarkerComponent
                  name="retirement"
                  rangeName="retirement"
                  quarter={simulation.activeQuarters - 1}
                  icon={retirementIcon}
                  iconClassName="icon-pension"
                  order={0}
                />
                {goals.legacy.amount > 0 && (
                  <TimelineMarkerComponent
                    name="legacy"
                    quarter={simulation.totalQuarters - 1}
                    iconClassName="icon-legacy"
                    order={1}
                  />
                )}
                {goals.dream.amount > 0 && goals.dream.year > currentDate.getFullYear() && (
                  <TimelineMarkerComponent
                    name="dream"
                    quarter={getQuarterFromYear(goals.dream.year)}
                    icon={dreamIcon}
                    iconClassName="icon-dream"
                    order={3}
                  />
                )}
                {goals.preserveCapital.amount > 0 && goals.preserveCapital.startDate && (
                  <TimelineMarkerComponent
                    name="preserve-capital"
                    quarter={getQuarterSpanFromCurrentDate(new Date(goals.preserveCapital.startDate))}
                    iconClassName="icon-piggy-bank"
                    order={4}
                  />
                )}
                {goals.secureFamily.amount > 0 && goals.secureFamily.year > currentDate.getFullYear() && (
                  <TimelineMarkerComponent
                    name="secure-family"
                    quarter={getQuarterFromYear(goals.secureFamily.year)}
                    iconClassName="icon-secure-family"
                    order={5}
                  />
                )}
              </TimelineContainerComponent>
            </div>
            <ReportLegendComponent
              labels={dashboardLabels.REPORT.LEGEND}
              includeIlliquid={userSettings.includeIlliquid} />
          </div>

          <div className="report-colspan-2">
            <NetAssetsReportComponent labels={reportLabels} />
          </div>

        </div>

      </div>
      {isRendered && <input type="hidden" id="report-loaded" />}
    </div>;
}