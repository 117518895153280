import { useMediaQuery } from "usehooks-ts"

export interface DeviceBounds {
    isTabletLandscape: boolean;
    isTabletPortrait: boolean;
    isHorizontallyCompact: boolean;
}


export function useDeviceBounds() : DeviceBounds {
    const isTabletLandscape = useMediaQuery('(min-width: 1024px) and (max-width: 1439px)');
    const isTabletPortrait = useMediaQuery('(min-width: 768px) and (max-width: 1023px)');
    const isHorizontallyCompact = useMediaQuery('(max-width: 767px)');

    return {
        isTabletLandscape,
        isTabletPortrait,
        isHorizontallyCompact
    };
}