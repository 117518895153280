import { CurrencyConfig } from '@flexfront/ui/react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { useDeviceBounds } from '../../../common/hooks/useDeviceBounds';
import { getCurrencyFormattedAmount } from '../../../common/shared/locale';
import { ReactNode } from 'react';


export interface GoalAchievementComponentProps {
  title: string;
  shortfallLabel: string;
  achievementFraction: number;
  shortfall: number;
  icon?: string;
  currencyConfig?: CurrencyConfig;
}

export function GoalAchievementComponent(props: GoalAchievementComponentProps) {
  const { isHorizontallyCompact } = useDeviceBounds();

  const classNamePrefix = 'quirion';

  const layout = isHorizontallyCompact ? (
    <div className={`${classNamePrefix}-results-component-goal`}>
      <span className={`${classNamePrefix}-results-component-goal-name`}>{props.title}</span>
      <div className={`${classNamePrefix}-results-component-goal-progress`}>
        <CircularProgressbarWithChildren
          className={`${classNamePrefix}-results-component`}
          minValue={0}
          maxValue={1}
          value={props.achievementFraction}
          text={`${(props.achievementFraction * 100).toFixed()}%`}
        />
      </div>
      {props.shortfall > 0 && props.achievementFraction < 0.99 && (
        <>
        <span className={`${classNamePrefix}-results-component-goal-shortfall`}>
          {props.shortfallLabel}
        </span>
        <span className={`${classNamePrefix}-results-component-goal-shortfall`}>
          {getCurrencyFormattedAmount(props.shortfall, props.currencyConfig)}
        </span>
        </>
      )}
    </div>
  ) : (
    <div className={`${classNamePrefix}-results-component-goal`}>
      <div className={`${classNamePrefix}-results-component-goal-progress`}>
        <CircularProgressbarWithChildren
          className={`${classNamePrefix}-results-component-circular-chart`}
          minValue={0}
          maxValue={1}
          value={props.achievementFraction}
          text={`${(props.achievementFraction * 100).toFixed()}%`}
        />
      </div>
      <div className={`${classNamePrefix}-results-component-goal-group`}>
        <span className={`quirion-results-component-goal-icon`} dangerouslySetInnerHTML={{ __html: props.icon ?? '' }}></span>
        <span></span>
        <span className={`${classNamePrefix}-results-component-goal-name`}><strong>{props.title}</strong></span>
        {props.shortfall > 0 && props.achievementFraction < 0.99 && (
          <span className={`${classNamePrefix}-results-component-goal-shortfall`}>
            {props.shortfallLabel}: {getCurrencyFormattedAmount(props.shortfall, props.currencyConfig)}
          </span>
        )}
      </div>
    </div>
  );

  return layout;
}

export default GoalAchievementComponent;
