export function getYearFromString(dateAsString: string | undefined) {
  if (dateAsString) {
    return new Date(dateAsString).getFullYear();
  }
  return undefined;
}

export function getQuarter(date: Date): number {
  return Math.ceil((date.getMonth() + 1) / 3);
}

interface QuarterMonthMapping {
  [key: number]: number;
}

export function getQuarterDate(date: Date): Date {
  const quarter = getQuarter(date);
  const quarterMonthMapping: QuarterMonthMapping = {
    1: 0,
    2: 3,
    3: 6,
    4: 9,
  };
  return new Date(date.getFullYear(), quarterMonthMapping[quarter], 1);
}

export function getQuarterSpan(startDate: Date, endDate: Date) {
  const startQuarter = getQuarter(startDate);
  const endQuarter = getQuarter(endDate);
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  let result = 0;

  if (startYear === endYear) {
    result = endQuarter - startQuarter + 1;
  } else if (endYear === startYear + 1) {
    result = 5 - startQuarter + endQuarter;
  } else {
    const yearDiff = (endYear - 1 - startYear) * 4;
    result = 5 - startQuarter + yearDiff + endQuarter;
  }

  // This function was just taken from the BE, indexing differs between the 2 languages.
  if (result > 0) {
    result = result - 1;
  }

  return result;
}
