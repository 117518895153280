import { SubmitHandler, useForm } from 'react-hook-form';
import { CtaComponentLabels } from '../../../common/labels/cta-component.labels';
import './cta-component.scss';
import { useComponentIcon } from '../../../common/hooks/useComponentIcon';
import { useDeviceBounds } from '../../../common/hooks/useDeviceBounds';
import { environment } from '../../../../environments/environment';
import { useAppSelector } from '../../../common/store/hooks';
import { selectTenantConfig } from '../../../common/store/tenant-config/tenant-config.slice';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { GridLoader } from 'react-spinners';
import { useDomainLayout } from '../../../common/hooks/useDomainLayout';
import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from '../../../common/hooks/useTrackEvent';

type Inputs = {
  emailAddress: string;
};

/* eslint-disable-next-line */
export interface CtaComponentProps {
  labels: CtaComponentLabels;
  onSubmit?: () => void;
}

export function CtaComponent(props: CtaComponentProps) {
  const tenantConfig = useAppSelector(selectTenantConfig);
  const domainLayout = useDomainLayout();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const ctaIcon = useComponentIcon("CTA");
  const { isHorizontallyCompact } = useDeviceBounds();
  const trackEvent = useTrackEvent();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  const onSubmit: SubmitHandler<Inputs> = data => {
    trackEvent({ category: trackEventCategories.CTA_FINAL, action: trackEventActions.EMAIL, name: data.emailAddress });
    setIsLoading(true);
    const contactUrl = `${environment.apiBaseUrl}/contact`;
    fetch(contactUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        templateName: tenantConfig.name,
        emailAddress: data.emailAddress,
        layoutId: tenantConfig.layoutId,
        domainLayoutId: domainLayout?.domainLayoutId
      })
    }).then((result) => {
      if (result.ok) {
        if (props.onSubmit) {
          props.onSubmit();
        }
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const loaderColor = getComputedStyle(document.documentElement).getPropertyValue('--secondary-0');


  return (
    <>
      <form className="cta-component-form" onSubmit={handleSubmit(onSubmit)} noValidate={true}>
        <div className="cta-component-body">
          {isLoading
          ? <GridLoader className="spinner" loading={isLoading} color={loaderColor} />
          : <>
            <div className="cta-component-body-content">
              <p dangerouslySetInnerHTML={{ __html: props.labels.PERSONA.BODY }}></p>
            </div>
            <div className="cta-component-body-controls">
              <input
                className={`email-input ${errors['emailAddress'] && 'error'}`}
                placeholder={props.labels.PLACEHOLDER}
                {...register('emailAddress', {
                  required: true,
                  // eslint-disable-next-line no-useless-escape
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
              {!isHorizontallyCompact && <button
                type="submit"
                className={`button button--solid ${ctaIcon ? 'button-with-icon' : ''}`}>
                {props.labels.SUBMIT}
                {ctaIcon && <span className="cta-button-icon"
                  dangerouslySetInnerHTML={{ __html: ctaIcon ?? '' }}></span>}
              </button>}
            </div>
            <div className="footer ">
              <p dangerouslySetInnerHTML={{ __html: props.labels.PERSONA.FOOTER_TEXT }}></p>
            </div>
          </>}
        </div>
        {isHorizontallyCompact && <button
          type="submit"
          className={`button button--big button--big--solid ${ctaIcon ? 'button-with-icon' : ''}`}>
            {props.labels.SUBMIT}
            {ctaIcon && <span className="cta-button-icon"
                dangerouslySetInnerHTML={{ __html: ctaIcon ?? '' }}></span>}
        </button>}
      </form>
    </>
  );
}

export default CtaComponent;
