export enum AssetType {
  realestate = 10,
  bonds = 11,
  equities = 12,
  cash = 13,
}

export interface Asset {
  type: string;
  amount: number;
}

export interface AssetTypeLabel {
  DISPLAY: string;
}

export interface AssetTypeItem extends AssetTypeLabel {
  Type: string;
  Icon: string;
}

export interface AssetLabels {
  NEW_ASSET: string;
  AMOUNT: string;
  ASSET_TYPES: any;
  ERROR_MESSAGE: string;
}