import { useEffect, useState } from 'react';
import MoneyComponent from '../money-component/money-component';
import { useAppSelector } from '../../../common/store/hooks';
import { selectSavingsState } from '../../../common/store/savings.slice';
import { ICanListComponentLabels } from '../../../common/labels/i-can-list-component-labels';
import { SavingType } from '@flexfront/models';
import { useCurrencyConfig } from '../../../common/hooks/useCurrencyConfig';
import { useComponentVisibility } from '../../../common/hooks/useComponentVisibility';
import InfoIcon from '../../../quirion/assets/images/info.svg';

export interface ICanListComponentProps {
  labels: ICanListComponentLabels,
  onSavingSelected?: (savingType: SavingType) => void
  showTooltip?: boolean;
}

export function ICanListComponent(props: ICanListComponentProps) {
  const savings = useAppSelector(selectSavingsState);
  const currentYear = new Date().getFullYear();
  const [totalSavings, setTotalSavings] = useState<number>(0);

  const currencyConfig = useCurrencyConfig();
  
  const isSavingsIconHidden = useComponentVisibility("I_CAN_SAVINGS_ICON");
  const isLumpsumIconHidden = useComponentVisibility("I_CAN_LUMPSUM_ICON");

  function handleOnSavingSelected(savingType: SavingType) {
    if (props.onSavingSelected) {
      props.onSavingSelected(savingType);
    }
  }

  useEffect(() => {
    const activeYears = savings.monthlyYear - currentYear;
    setTotalSavings(activeYears * 12 * savings.monthly);
  }, [savings.monthlyYear, currentYear, savings.monthly]);

  return (
    <div className="mobile-list">
      <MoneyComponent
        iconClassName="icon-piggy-bank"
        hideIcon={isSavingsIconHidden}
        title={props.labels.SAVINGS}
        titleValue={totalSavings}
        isTitleCurrency={true}
        amountLabel={`${props.labels.SAVINGS_AMOUNT} ${savings.monthlyYear}:`}
        amountValue={savings.monthly}
        titleValuePlaceholder={`0`}
        amountValuePlaceholder={props.labels.SAVINGS_AMOUNT_PLACEHOLDER}
        onClick={() => handleOnSavingSelected(SavingType.monthly)}
        currencyConfig={currencyConfig}
        showTooltip={props.showTooltip}
        infoIcon={<InfoIcon />}
        tooltip={props.labels.SAVINGS_TOOLTIP}
        tooltipPosition="right center"
      />

      <div className="list-separator" />

      <MoneyComponent
        iconClassName="icon-lumpsum"
        hideIcon={isLumpsumIconHidden}
        title={props.labels.LUMPSUM_YEAR}
        titleValue={savings.lumpsumYear}
        titleValuePlaceholder="YYYY"
        amountLabel={`${props.labels.LUMPSUM}:`}
        amountValue={savings.lumpsum}
        amountValuePlaceholder={props.labels.LUMPSUM_AMOUNT_PLACEHOLDER}
        onClick={() => handleOnSavingSelected(SavingType.lumpsum)}
        currencyConfig={currencyConfig}
        showTooltip={props.showTooltip}
        infoIcon={<InfoIcon />}
        tooltip={props.labels.LUMPSUM_TOOLTIP}
        tooltipPosition="right center"
      />
    </div>
  );
}

export default ICanListComponent;
