import { Liabilities } from "./liabilities.state";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { revertAll } from "../hooks";

export const LIABILITIES_FEATURE_KEY = "liabilities";

const initialState: Liabilities = {
  mortgage: 0
};

export const liabilitiesSlice = createSlice({
    name: LIABILITIES_FEATURE_KEY,
    initialState,
    reducers: {
      saveLiabilities: (state, action: PayloadAction<Liabilities>) => {
        state = action.payload;
        return state;
      },
    },
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  });

  export const { saveLiabilities } = liabilitiesSlice.actions;

export const selectLiabilitiesState = createSelector(
  (state: RootState) => state.liabilities,
  (liabilities) => liabilities
);