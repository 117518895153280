import { getYearFromString, WidgetComponent } from "@flexfront/ui/react";
import RetirementIconCardComponent from "../icon-card-component/retirement-icon-card-component";
import IconCardComponent from "../icon-card-component/icon-card-component";
import "./i-want-component.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import PreserveCapitalIconCardComponent from "../icon-card-component/preserve-capital-icon-card-component";
import { useGoalsContext } from "../../../common/providers/goals-provider";

export function IWantComponent() {

  const {
    labels,
    onRequiresUpdate,

    iWantIcon,
    swiperContainerRef,
    onSwiperContainerKeyUp,
    setSwiperInstance,

    retirementTabindex,
    retirement,
    isRetirementIconHidden,
    onRetirementChange,
    onRetirementAgeBlur,
    currencyConfig,
    onRetirementMonthlyPayoutBlur,
    isRetirementYearValid,

    isLegacyHidden,
    legacyTabindex,
    isLegacyIconHidden,
    onLegacyChange,
    legacy,
    onLegacyBlur,

    isDreamHidden,
    dreamTabIndex,
    dream,
    isDreamIconHidden,
    onDreamChange,
    onDreamYearBlur,
    onDreamPayoutBlur,
    isDreamYearValid,

    isPreserveCapitalHidden,
    preserveCapitalTabIndex,
    preserveCapital,
    isPreserveCapitalIconHidden,
    currentYear,
    yearOfDeath,
    onPreserveCapitalChange,
    onPreserveCapitalValidate,
    onPreserveCapitalFromYearBlur,
    onPreserveCapitalToYearBlur,
    onPreserveCapitalAmountBlur,

    isSecureFamilyHidden,
    secureFamilyTabindex,
    secureFamily,
    isSecureFamilyIconHidden,
    onSecureFamilyChange,
    onSecureFamilyYearBlur,
    onSecureFamilyPayoutBlur,
    isSecureFamilyYearValid

  } = useGoalsContext();

  return (
    <WidgetComponent className="i-want-component widget--slider" isExpanded={false}>
      <div className="widget-title-container">
        {iWantIcon && <span className="widget-title-icon"
          dangerouslySetInnerHTML={{ __html: iWantIcon ?? '' }}></span>}
        <h3 className="widget-title">
          {labels.TITLE}
        </h3>
      </div>      
      <div
        className="swiper-component"
        ref={swiperContainerRef}
        onKeyUp={onSwiperContainerKeyUp}>
        <Swiper
          onSwiper={setSwiperInstance}
          spaceBetween={4}
          slidesPerView={2}
          pagination={{ clickable: true, enabled: true }}
          modules={[Pagination]}
        >
          <SwiperSlide>
            <RetirementIconCardComponent
              tabIndex={retirementTabindex}
              valuePlaceholder={
                labels.GOALS.RETIREMENT.AMOUNT_PLACEHOLDER
              }
              agePlaceholder={labels.GOALS.RETIREMENT.AGE_PLACEHOLDER}
              titleLabel={labels.GOALS.RETIREMENT.TITLE}
              valueLabel={labels.WHEN}
              totalLabel={labels.GOALS.RETIREMENT.AMOUNT}
              retirementAge={retirement.retirementAge}
              retirementMonthlyPayout={retirement.retirementMonthlyPayout}
              iconClassName="icon-pension"
              hideIcon={isRetirementIconHidden}
              onRetirementChange={onRetirementChange}
              currencyConfig={currencyConfig}
              onRetirementAgeBlur={onRetirementAgeBlur}
              onRetirementMonthlyPayoutBlur={onRetirementMonthlyPayoutBlur}
              isRetirementYearValid= {isRetirementYearValid}
            />
          </SwiperSlide>
          {!isLegacyHidden && <SwiperSlide>
            <IconCardComponent
              tabIndex={legacyTabindex}
              valueLabel={labels.GOALS.LEGACY.TITLE}
              valuePlaceholder={labels.GOALS.LEGACY.AMOUNT_PLACEHOLDER}
              totalLabel={labels.GOALS.GOAL.AMOUNT}
              iconClassName="icon-legacy"
              hideIcon={isLegacyIconHidden}
              onChange={onLegacyChange}
              inputNumber={legacy.amount}
              displayNumber={legacy.amount}
              currencyConfig={currencyConfig}
              onBlur={onLegacyBlur}
            />
          </SwiperSlide>}
          {!isDreamHidden && <SwiperSlide>
            <RetirementIconCardComponent
              tabIndex={dreamTabIndex}
              valueLabel={labels.WHEN}
              cardClassName={dream.hasInvalidDate ? 'error error-border' : ''}
              titleLabel={labels.GOALS.GOAL.TITLE}
              valuePlaceholder={labels.GOALS.GOAL.AMOUNT_PLACEHOLDER}
              agePlaceholder={labels.GOALS.GOAL.YEAR_PLACEHOLDER}
              totalLabel={labels.GOALS.GOAL.AMOUNT}
              retirementAge={dream.year}
              retirementMonthlyPayout={dream.amount}
              iconClassName="icon-dream"
              hideIcon={isDreamIconHidden}
              onRetirementChange={onDreamChange}
              currencyConfig={currencyConfig}
              onRetirementAgeBlur={onDreamYearBlur}
              onRetirementMonthlyPayoutBlur={onDreamPayoutBlur}
              isRetirementYearValid={isDreamYearValid}
            />
          </SwiperSlide>}
          {!isPreserveCapitalHidden && <SwiperSlide>
            <PreserveCapitalIconCardComponent
              tabIndex={preserveCapitalTabIndex}
              cardClassName={preserveCapital.hasInvalidDate ? 'error error-border' : ''}
              dateErrorLabels={labels.GOALS.PRESERVE_CAPITAL?.DATE_ERRORS}
              titleLabel={labels.GOALS.PRESERVE_CAPITAL?.TITLE}
              valueLabel={labels.WHEN}
              valuePlaceholder={labels.GOALS.PRESERVE_CAPITAL?.AMOUNT_PLACEHOLDER}
              totalLabel={labels.GOALS.PRESERVE_CAPITAL?.AMOUNT}
              iconClassName="icon-piggy-bank"
              hideIcon={isPreserveCapitalIconHidden}
              minYear={currentYear}
              maxYear={yearOfDeath}
              fromYear={getYearFromString(preserveCapital.startDate) ?? currentYear}
              toYear={getYearFromString(preserveCapital.endDate) ?? yearOfDeath}
              amount={preserveCapital.amount}
              onChange={onPreserveCapitalChange}
              onValidate={onPreserveCapitalValidate}
              currencyConfig={currencyConfig}
              onFromYearBlur={onPreserveCapitalFromYearBlur}
              onToYearBlur={onPreserveCapitalToYearBlur}
              onAmountBlur={onPreserveCapitalAmountBlur}
            />
          </SwiperSlide>}
          {!isSecureFamilyHidden && <SwiperSlide>
            <RetirementIconCardComponent
              tabIndex={secureFamilyTabindex}
              cardClassName={secureFamily.hasInvalidDate ? 'error error-border' : ''}
              titleLabel={labels.GOALS.SECURE_FAMILY.TITLE}
              valueLabel={labels.WHEN}
              valuePlaceholder={labels.GOALS.SECURE_FAMILY.AMOUNT_PLACEHOLDER}
              agePlaceholder={labels.GOALS.SECURE_FAMILY.YEAR_PLACEHOLDER}
              totalLabel={labels.GOALS.SECURE_FAMILY.AMOUNT}
              retirementAge={secureFamily.year}
              retirementMonthlyPayout={secureFamily.amount}
              iconClassName="icon-secure-family"
              hideIcon={isSecureFamilyIconHidden}
              onRetirementChange={onSecureFamilyChange}
              currencyConfig={currencyConfig}
              onRetirementAgeBlur={onSecureFamilyYearBlur}
              onRetirementMonthlyPayoutBlur={onSecureFamilyPayoutBlur}
              isRetirementYearValid={isSecureFamilyYearValid}
            />
          </SwiperSlide>}
        </Swiper>
      </div>
    </WidgetComponent>
  );
}

export default IWantComponent;
