import { AssetTypeLabel, AssetTypeItem } from "@flexfront/models";


const assetypeLookup : Map<string, string> = new Map<string, string>([
    ["REALESTATE", "realestate"],
    ["BONDS", "bonds"],
    ["EQUITY", "equity"],
    ["CASH", "cash"],
])
  
const assetIconLookup : Map<string, string> = new Map<string, string>([
    ["REALESTATE", "icon-home"],
    ["BONDS", "icon-bond"],
    ["EQUITY", "icon-trend-up"],
    ["CASH", "icon-cash"],
])
  
export function GetAssetItems(properties: Map<string, AssetTypeLabel>): AssetTypeItem[] {
    const results: AssetTypeItem[] = [];

        properties.forEach((value, key) => {
                results.push({...value, Type: assetypeLookup.get(key) ?? key.toLowerCase(), Icon: assetIconLookup.get(key) ?? ""});    
        });
  
    return results;
}

export function GetAssetItemsInOrder(assetItems: AssetTypeItem[],assetsOrder: string[]): AssetTypeItem[] {
    const results: AssetTypeItem[] = [];

    assetsOrder.map(orderkey => {
        assetItems.forEach((value, key) => {
            if(orderkey===value.Type.toUpperCase()){
                results.push(value);
            }
        });
    })
  
    return results;
}