import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../hooks";
import { RootState } from "../store";
import { DebugState } from "./debug.state";

export const DEBUG_FEATURE_KEY = "debug";

const initialState: DebugState = {
  includeDebug: false,
  request: {},
  response: {},
};

export const debugSlice = createSlice({
  name: DEBUG_FEATURE_KEY,
  initialState,
  reducers: {
    setIncludeDebug: (state, action: PayloadAction<boolean>) => {
      state.includeDebug = action.payload;
      return state;
    },
    saveDebug: (
      state,
      action: PayloadAction<{ request: any; response: any }>
    ) => {
      state = {
        ...state,
        request: action.payload.request,
        response: action.payload.response
      };
      return state;
    },
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const { setIncludeDebug, saveDebug } = debugSlice.actions;

export const selectDebugState = createSelector(
  (state: RootState) => state.debug,
  (debug) => debug
);

export default debugSlice;
