import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from "../../../common/hooks/useTrackEvent";
import { useDeviceBounds } from "../../../common/hooks/useDeviceBounds";
import { CtaConfirmComponentLabels } from "../../../common/labels/cta-confirm-component.labels";

export interface CtaConfirmComponentProps {
  labels: CtaConfirmComponentLabels;
  onClose?: () => void;
}

export function CtaConfirmComponent(props: CtaConfirmComponentProps) {
  const { isHorizontallyCompact } = useDeviceBounds();
  const trackEvent = useTrackEvent();
  
  const close = () => {
    window.open(props.labels.SUBMIT_LOCATION, '_blank', 'noreferrer');

    if (props.onClose) {
      props.onClose();
    }
  }

  return <>
    <div className="cta-component-body">
      <div className="cta-component-body-content">
        <p dangerouslySetInnerHTML={{ __html: props.labels.DESC }}></p>
      </div>
      <div className="cta-component-body-controls">
      {!isHorizontallyCompact && <button className="button button--solid button--auto" onClick={close}>
              {props.labels.SUBMIT}
          </button>}
      </div>
      <div className="footer ">
        <p dangerouslySetInnerHTML={{ __html: '' }}></p>
      </div>
    </div>
    {isHorizontallyCompact && <button
      onClick={close}
        className='button button--big button--big--solid'>
          {props.labels.SUBMIT}
      </button>}
  </>;
}