import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../hooks";
import { RootState } from "../store";
import { ClimateChangeState } from "./climate-change.state";

export const CLIMATE_FEATURE_KEY = "climateChange";

const initialState: ClimateChangeState = {
  maxTemp: undefined,
  scenario: undefined,
};

export const climateChangeSlice = createSlice({
  name: CLIMATE_FEATURE_KEY,
  initialState,
  reducers: {
    saveClimateChange: (state, action: PayloadAction<ClimateChangeState>) => {
      return {...state,...action.payload};
    },
    saveMaxTemp: (state, action: PayloadAction<number | undefined>) => {
      state = {
        ...state,
        maxTemp: action.payload
      };
      return state;
    },
    saveScenario: (state, action: PayloadAction<string | undefined>) => {
      state = {
        ...state,
        scenario: action.payload
      };
      return state;
    },
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const { saveClimateChange, saveMaxTemp, saveScenario } = climateChangeSlice.actions;

export const selectClimateChangeState = createSelector(
  (state: RootState) => state.climateChange,
  (climateChange) => climateChange
);

export default climateChangeSlice;