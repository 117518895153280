import { ChangeEvent, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { UserLogin } from "../../store/auth/login.model";
import { login, selectAuth } from "../../store/auth/auth.slice";
import { Navigate, useParams } from "react-router-dom";
import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from "../../hooks/useTrackEvent";

type Inputs = {
    usernameRequired : string;
    passwordRequired : string;
  };

export function LoginComponent() {
    const { name } = useParams();
    const auth = useAppSelector(selectAuth);

    const dispatch = useAppDispatch();
    const [userLogin, setUserLogin] = useState<UserLogin>();

    const trackEvent = useTrackEvent();

    const { handleSubmit } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        trackEvent({ category: trackEventCategories.LOGIN, action: trackEventActions.CLICK, name: trackEventNames.SUBMIT });
        if (userLogin) {
            dispatch(login(userLogin));
        }
    };

    function onUsernameChange(event: ChangeEvent<HTMLInputElement>) {
        setUserLogin({username: event.target.value, password: userLogin?.password, });
    }

    function onPasswordChange(event: ChangeEvent<HTMLInputElement>) {
        setUserLogin({username: userLogin?.username, password: event.target.value});
    }

    let loginRedirect = "/list";
    if (name) {
        loginRedirect = `/${name}/config`
    }

    return (auth.isLoggedIn ? <Navigate to={{ pathname: loginRedirect }} />
    : <form onSubmit={handleSubmit(onSubmit)}>
        <div className="control-group">
            <label className="black">Username:</label>
            <input type="text" onChange={onUsernameChange}></input>
        </div>
        <div className="control-group">
            <label className="black">Password:</label>
            <input type="password" onChange={onPasswordChange}></input>
        </div>
        <button className="button button--solid" type="submit">Login</button>
    </form>);
}