import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from "../../../common/hooks/useTrackEvent";
import { setIncludeSnapshot } from "../../../common/store/chartLegend/chartLegend.slice";
import { useAppDispatch, useAppSelector } from "../../../common/store/hooks";
import { selectUserSettingsState, setIncludePrevious } from "../../../common/store/user-settings.slice";

export interface ShowPreviousValuesComponentProps {
    title: string;
}

export function ShowPreviousValuesComponent(props: ShowPreviousValuesComponentProps) {
    const userSettings = useAppSelector(selectUserSettingsState);
    const dispatch = useAppDispatch();

    const trackEvent = useTrackEvent();
    
    const showPreviousValues = () => {
        const includePrevious = !userSettings.includePrevious;
        trackEvent({ category: trackEventCategories.PREVIOUS_VALUES, action: trackEventActions.CLICK, name: trackEventNames.SHOW, value: +includePrevious });
        dispatch(setIncludePrevious(includePrevious));
        dispatch(setIncludeSnapshot(includePrevious));
    }
    
    return <>
        <span onClick={showPreviousValues}>{props.title}</span>
        <label className="switch">
            <input
                type="checkbox"
                checked={userSettings.includePrevious}
                onChange={showPreviousValues}
            />
            <span className="toggle round"></span>
        </label>
    </>;
}