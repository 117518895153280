import {
  CurrencyConfig,
  CurrencyFormatter,
  getCurrencyNumberFormatParts,
  getCurrencyFormat,
  CurrencyInputFormatter,
  getCurrencyFormatFromParts,
} from "@flexfront/ui/react";

export const defaultCurrencyConfig: CurrencyConfig = {
  locale: "de-DE",
  currency: "EUR",
  groupSeparator: "'",
  decimalSeparator: ".",
};

const getSymbolFromCurrency = (currency?: string) => {
  const selectedCurrency = currencies.find(c => c.value === currency);
  return selectedCurrency?.symbol ?? currencies[0].symbol;
}

export const getCurrencySymbol = (currencyConfig?: CurrencyConfig) => currencyConfig
  ? getSymbolFromCurrency(currencyConfig?.currency)
  : getSymbolFromCurrency(defaultCurrencyConfig.currency);

export const localeCurrencyFormats = [
  {
    country: "CH",
    groupSeparator: "'",
    decimalSeparator: "."
  },
  {
    country: "DE",
    groupSeparator: ".",
    decimalSeparator: ","
  },
  {
    country: "GB",
    groupSeparator: ",",
    decimalSeparator: "."
  }
];

export const currencies = [
  {
    value: "EUR",
    label: "Euro",
    hasSymbolSuffix: true,
    symbol: "€"
  },
  {
    value: "CHF",
    label: "Swiss Franc",
    hasSymbolSuffix: true,
    symbol: "CHF"
  },
  {
    value: "TRY",
    label: "Turkish Lira",
    hasSymbolSuffix: true,
    symbol: "₺"
  },
  {
    value: "USD",
    label: "US Dollars",
    symbol: "$"
  },
  {
    value: "SGD",
    label: "Singapore Dollar",
    hasSymbolSuffix: true,
    symbol: "SGD"
  },
  {
    value: "HKD",
    label: "Hong-Kong Dollar",
    hasSymbolSuffix: true,
    symbol: "HK$"
  },
  {
    value: "GBP",
    label: "Pound Sterling",
    symbol: "£"
  },
  {
    value: "SEK",
    label: "Swedish Krona",
    hasSymbolSuffix: true,
    symbol: "SEK"
  },
  {
    value: "DKK",
    label: "Danish Krone",
    hasSymbolSuffix: true,
    symbol: "DKK"
  },
  {
    value: "NOK",
    label: "Norwegian Krone",
    symbol: "NOK"
  },
  {
    value: "AUD",
    label: "Australian Dollar",
    hasSymbolSuffix: true,
    symbol: "A$"
  },
  {
    value: "CAD",
    label: "Canadian Dollar",
    hasSymbolSuffix: true,
    symbol: "C$"
  },
  {
    value: "JPY",
    label: "Japanese Yen",
    symbol: "¥"
  },
  {
    value: "CNY",
    label: "Renminbi",
    hasSymbolSuffix: true,
    symbol: "CNY"
  },
  {
    value: "RUB",
    label: "Russian Rubble",
    hasSymbolSuffix: true,
    symbol: "₽"
  },
  {
    value: "INR",
    label: "Indian Rupee",
    symbol: "₹"
  },
  {
    value: "NZD",
    label: "New Zealand Dollar",
    hasSymbolSuffix: true,
    symbol: "NZ$"
  },
  {
    value: "RSD",
    label: "Serbian Dinar",
    hasSymbolSuffix: true,
    symbol: "RSD"
  }
];

export const defaultCurrencyDisplayFormatter: CurrencyFormatter = (
  value: number | null | undefined,
  currencyConfig?: CurrencyConfig
) => getCurrencyFormat(currencyConfig ?? defaultCurrencyConfig, value);

export const defaultCurrencyInputFormatter: CurrencyInputFormatter = (
  value: number | null | undefined,
  currencyConfig?: CurrencyConfig
) => {
  const currencyConfigToUse = currencyConfig ?? defaultCurrencyConfig;
  const formattedParts = getCurrencyNumberFormatParts(currencyConfigToUse, value);
  return {
    symbol: getSymbolFromCurrency(currencyConfigToUse.currency),
    value: getCurrencyFormatFromParts(
      currencyConfigToUse,
      formattedParts,
      [],
      ["currency", "literal"]
    ),
  };
};

export const getCurrencyFormattedAmount = (amount: number, currencyConfig?: CurrencyConfig, currencyInputFormatter?: CurrencyInputFormatter) => {
  const currencyConfigToUse = currencyConfig ?? defaultCurrencyConfig;
  const currencyInputFormatterToUse = currencyInputFormatter ?? defaultCurrencyInputFormatter;
  const currencyFormattedValue = currencyInputFormatterToUse(amount, currencyConfigToUse);
  if (currencyConfigToUse?.hasSymbolSuffix) {
    return `${currencyFormattedValue.value} ${currencyFormattedValue.symbol}`;
  }
  return `${currencyFormattedValue.symbol}${currencyFormattedValue.value}`;
}
