import { CurrencyConfig, MoneyInput } from "@flexfront/ui/react";
import { defaultCurrencyInputFormatter } from "../../shared/locale";

export interface CurrencyInputComponentProps {
    classNamePrefix: string;
    title?: string;
    value: number;
    tabIndex?: number;
    placeholder?: string;
    currencyConfig?: CurrencyConfig;
    max?: number;
    onChange?: (value: number) => void;
    onBlur?: (value: number) => void;
    disabled?: boolean;
    suffix?: string;
}

export default function CurrencyInputComponent(props: CurrencyInputComponentProps) {
    return <>  
        <label className={`${props.classNamePrefix}-input-label`}>{props.title}</label>
        <MoneyInput
            disabled={props.disabled}
            className={`${props.classNamePrefix}-input-number`}
            inputNumber={props.value}
            tabIndex={props.tabIndex}
            placeholder={props.placeholder}
            currencyConfig={props.currencyConfig}
            currencyFormatter={defaultCurrencyInputFormatter}
            showCurrencySymbol={true}
            onChange={props.onChange}
            onBlur={() => props.onBlur ? props.onBlur(props.value) : props.onBlur}
            autoWidth={true}
            maxValue={props.max}
            suffix={props.suffix ?? ''}
            />
    </>;
}