import { WidgetComponent } from "@flexfront/ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { useGoalsContext } from "../../../common/providers/goals-provider";
import { QuirionIconCardComponent } from "../quirion-icon-card-component/quirion-icon-card-component";
import InfoIcon from '../../assets/images/info.svg';
import NumberInputComponent from "../../../common/components/number-input-component/number-input-component";
import CurrencyInputComponent from "../../../common/components/currency-input-component/currency-input-component";

export function IWantComponent() {

  const {
    labels,
    swiperContainerRef,
    onSwiperContainerKeyUp,
    setSwiperInstance,
    currencyConfig,

    retirementTabindex,
    retirement,
    onRetirementAgeChange,
    onRetirementAgeBlur,
    onRetirementMonthlyPayoutChange,
    onRetirementMonthlyPayoutBlur,
    retirementIcon,
    isRetirementYearValid,

    dreamTabIndex,
    dream,
    onDreamYearChange,
    onDreamYearBlur,
    onDreamPayoutChange,
    onDreamPayoutBlur,
    dreamIcon,
    isDreamYearValid

  } = useGoalsContext();

  const classNamePrefix = 'quirion';

  return (
    <div>
      <label
          className={`${classNamePrefix}-widget-title`}>
            {labels.TITLE}
        </label>
      <WidgetComponent className={`${classNamePrefix}-widget`} isExpanded={false}>
        <div
          className={`${classNamePrefix}-swiper-component`}
          ref={swiperContainerRef}
          onKeyUp={onSwiperContainerKeyUp}>
          <Swiper
            onSwiper={setSwiperInstance}
            spaceBetween={10}
            slidesPerView={2}
            pagination={{ clickable: true, enabled: true }}
            modules={[Pagination]}
          >
            <SwiperSlide>
              <QuirionIconCardComponent
                title={labels.GOALS.RETIREMENT.TITLE}
                icon={retirementIcon}
                info={<InfoIcon />}
                tooltip={labels.GOALS.RETIREMENT.TOOLTIP}
                tooltipPosition="bottom center"
                inputs={<>
                  <NumberInputComponent
                    title={labels.WHEN}
                    value={retirement.retirementAge}
                    placeholder={labels.YEAR}
                    tabIndex={retirementTabindex}
                    classNamePrefix={classNamePrefix}
                    onChange={onRetirementAgeChange}
                    onBlur={onRetirementAgeBlur} 
                    hasError={!isRetirementYearValid}/>
                  <CurrencyInputComponent
                    title={labels.GOALS.RETIREMENT.AMOUNT}
                    classNamePrefix={classNamePrefix}
                    value={retirement.retirementMonthlyPayout}
                    tabIndex={retirementTabindex ? retirementTabindex + 1 : -1}
                    placeholder={labels.GOALS.RETIREMENT.AMOUNT_PLACEHOLDER}
                    currencyConfig={currencyConfig}
                    onChange={onRetirementMonthlyPayoutChange}
                    onBlur={onRetirementMonthlyPayoutBlur}
                    max={9999999}
                    suffix={labels.GOALS.RETIREMENT.MONTHLY}
                  />
                </>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <QuirionIconCardComponent
                title={labels.GOALS.GOAL.TITLE}
                icon={dreamIcon}
                info={<InfoIcon />}
                tooltip={labels.GOALS.GOAL.TOOLTIP}
                tooltipPosition="bottom center"
                inputs={<>
                  <NumberInputComponent
                    title={labels.WHEN}
                    value={dream.year}
                    placeholder={labels.YEAR}
                    tabIndex={dreamTabIndex}
                    classNamePrefix={classNamePrefix}
                    onChange={onDreamYearChange}
                    onBlur={onDreamYearBlur}
                    hasError={!isDreamYearValid}/>
                  <CurrencyInputComponent
                    title={labels.AMOUNT}
                    classNamePrefix={classNamePrefix}
                    value={dream.amount}
                    tabIndex={dreamTabIndex ? dreamTabIndex + 1 : -1}
                    placeholder={labels.AMOUNT}
                    currencyConfig={currencyConfig}
                    onChange={onDreamPayoutChange}
                    onBlur={onDreamPayoutBlur}
                    max={9999999}
                  />
                </>}
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </WidgetComponent>
    </div>
  );
}

export default IWantComponent;
