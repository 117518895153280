import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import './widget-component.scss';

export interface WidgetComponentProps extends PropsWithChildren {
  isExpanded?: boolean;
  isStatic?: boolean;
  className?: string;
}

export function WidgetComponent(props: WidgetComponentProps) {  
  const [isExpanded, setIsExpanded] = useState<boolean | undefined>(props.isExpanded);
  const [isStatic, setIsStatic] = useState<boolean | undefined>(props.isExpanded);

  useEffect(() => {
    setIsExpanded(props.isExpanded);
  }, [props.isExpanded]);

  useEffect(() => {
    setIsStatic(props.isStatic);
  }, [props.isStatic]);

  const getWidgetClassName = useCallback(() => {
    let className = "widget";

    if (isStatic) {
      className += ` widget--static`;
    } else if (isExpanded) {
      className += ` widget--expanded`;
    }

    return `${className} ${props.className}`;
  }, [isExpanded, isStatic])

  return (
    <div className={getWidgetClassName()}>
      {props.children}
    </div>
  );
}

export default WidgetComponent;
