import { Savings } from "@flexfront/models";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "./hooks";
import { RootState } from "./store";
import { DEFAULT_RETIREMENT_AGE } from "./goals/goals.slice";
import { DEFAULT_AGE } from "./personal.slice";

export const SAVINGS_FEATURE_KEY = "savings";
const currentYear = new Date().getFullYear();
const DEFAULT_MONTHLY_YEAR = currentYear + (DEFAULT_RETIREMENT_AGE - DEFAULT_AGE);

const initialState: Savings = {
  monthly: 0,
  maxSavings: 2000,
  monthlyYear: DEFAULT_MONTHLY_YEAR,
  lumpsum: 0,
  lumpsumYear: 0,
  hasInvalidMonthlyYear: false,
  hasInvalidLumpsumYear: false
};

export const savingsSlice = createSlice({
  name: SAVINGS_FEATURE_KEY,
  initialState,
  reducers: {
    saveSavings:  (state, action: PayloadAction<Savings>) => {
      return { ...state, ...action.payload };
    },
    saveMonthlySavings: (state, action: PayloadAction<{year: number, amount: number}>) => {
      state.monthly = action.payload.amount;
      state.monthlyYear = action.payload.year;
      state.hasInvalidMonthlyYear = false;

      if (action.payload.amount > state.maxSavings)
      {
        state.maxSavings = action.payload.amount;
      }

      return state;
    },
    saveLumpsumSavings: (state, action: PayloadAction<{year: number, amount: number}>) => {
      state.lumpsumYear = action.payload.year;
      state.lumpsum = action.payload.amount;
      state.hasInvalidLumpsumYear = false;
      return state;
    },
    saveHasInvalidSavingsDates: (state, action: PayloadAction<{hasInvalidMonthlyDate: boolean, hasInvalidLumpsumDate: boolean}>) => {
      state.hasInvalidMonthlyYear = action.payload.hasInvalidMonthlyDate;
      state.hasInvalidLumpsumYear = action.payload.hasInvalidLumpsumDate;

      return state;
    }
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const { saveSavings, saveMonthlySavings, saveLumpsumSavings, saveHasInvalidSavingsDates } = savingsSlice.actions;

export const selectSavingsState = createSelector(
  (state: RootState) => state.savings,
  (savings) => savings
);

export default savingsSlice;