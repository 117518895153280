import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../hooks";
import { RootState } from "../store";
import { InfoModal } from "./info-modal.state";

const initialState: InfoModal = {
  isOpen: false,
};

export const infoModalSlice = createSlice({
  name: "infoModal",
  initialState,
  reducers: {
    setIsInfoModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const { setIsInfoModalOpen } = infoModalSlice.actions;

export const selectInfoModalState = createSelector(
  (state: RootState) => state.chartOverlay,
  (overlay) => overlay
);

export default infoModalSlice;
