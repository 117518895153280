import { CurrencyConfig, MoneyInput, selectAllText } from "@flexfront/ui/react";
import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  defaultCurrencyInputFormatter, getCurrencyFormattedAmount,
} from "../../../common/shared/locale";
import "./icon-card-component.scss";

/* eslint-disable-next-line */
export interface SavingsIconCardComponentProps {
  cardClassName?: string;
  iconClassName: string;
  hideIcon?: boolean;
  tabIndex: number
  titleLabel: string;
  valueLabel: string;
  valuePlaceholder?: string;
  totalLabel: string;
  monthlySavings: number;
  year: number;
  yearPlaceholder: string;
  totalSavings: number;
  onChange?: (year: number, monthlySavings: number) => void;
  allowSelection?: boolean;
  isSelected?: boolean;
  displaySimple?: boolean;
  onSelectionChange?: (isSelected: boolean) => void;
  currencyConfig?: CurrencyConfig;
  onMonthlySavingsAmountBlur?: () => void;
  onMonthlySavingsYearBlur?: () => void;
}

export function SavingsIconCardComponent(props: SavingsIconCardComponentProps) {
  const [isSelected, setIsSelected] = useState<boolean | undefined>(props.isSelected);
  const currentYear = new Date().getFullYear();

  const [monthlySavings, setMonthlySavings] = useState<number>(
    props.monthlySavings
  );
  const [totalSavings, setTotalSavings] = useState<number>(props.totalSavings);
  const [year, setYear] = useState<number>(props.year);

  const moneyInputRef = useRef<HTMLInputElement>(null);
  const yearInputRef = useRef<HTMLInputElement>(null);

  function focusYearInput(event: SyntheticEvent) {
    if (!(event.target instanceof HTMLInputElement) && yearInputRef.current) {
      yearInputRef.current.focus();
    }
  }


  function onMonthlySavingsChange(monthlySavings: number) {
    if (props.onChange) {
      props.onChange(year, monthlySavings);
    }
  }

  function onMonthlySavingsBlur() {
    if (props.onMonthlySavingsAmountBlur) {
      props.onMonthlySavingsAmountBlur();
    }
  }

  const onSelectionChange = () => {
    setIsSelected(!isSelected);
    if (props.allowSelection && props.onSelectionChange) {
      props.onSelectionChange(!isSelected)
    }
  }

  useEffect(() => {
    setYear(props.year);
  }, [props.year]);

  useEffect(() => {
    setMonthlySavings(props.monthlySavings);
  }, [props.monthlySavings]);

  useEffect(() => {
    setTotalSavings(props.totalSavings);
  }, [props.totalSavings]);

  let cardClassName = "icon-card"
  if (props.allowSelection) {
    cardClassName += " icon-card-selection";
  }
  if (props.allowSelection && isSelected) {
    cardClassName += " icon-card--selected";
  }
  cardClassName += ` ${props.cardClassName}`;

  let emptyClassName = "";
  if (!monthlySavings || monthlySavings === 0) {
    emptyClassName = "empty";
  }

  return (
    <div
      className={cardClassName}
      onMouseUp={focusYearInput}
      onClick={onSelectionChange}>
      <div className="icon-card-type">
        {!props.hideIcon && <label className={`icon-card-icon ${props.iconClassName}`}></label>}
        <label className="icon-card-value">
          <span dangerouslySetInnerHTML={{ __html: props.totalLabel }}></span>
        </label>
      </div>
      {!props.displaySimple && <>
        <span className="icon-card-total" dangerouslySetInnerHTML={{ __html: props.valueLabel }}></span>
        <MoneyInput
          disabled={true}
          className={`icon-card-input ${emptyClassName}`}
          inputNumber={totalSavings}
          currencyConfig={props.currencyConfig}
          currencyFormatter={defaultCurrencyInputFormatter}
          showCurrencySymbol={true}
          autoWidth={true}
          maxValue={9999999}
        />
        <label className="icon-card-total"> 
          <span>{props.titleLabel}</span>
          <span>&nbsp;</span>
          <span>{year}</span>
        </label>
        <MoneyInput
          tabIndex={props.tabIndex}
          placeholder={props.valuePlaceholder}
          className={`icon-card-input ${emptyClassName}`}
          inputNumber={monthlySavings}
          currencyConfig={props.currencyConfig}
          currencyFormatter={defaultCurrencyInputFormatter}
          showCurrencySymbol={true}
          onChange={onMonthlySavingsChange}
          onBlur={onMonthlySavingsBlur}
          ref={moneyInputRef}
          autoWidth={true}
          maxValue={9999999}
        />
      </>}
    </div>
  );
}

export default SavingsIconCardComponent;
