import { CurrencyConfig, MoneyInput } from "@flexfront/ui/react";
import { useEffect, useRef, useState } from "react";
import { defaultCurrencyInputFormatter } from "../../../common/shared/locale";
import "./icon-card-component.scss";

export interface IconCardComponentProps {
  cardClassName?: string;
  iconClassName: string;
  hideIcon?: boolean;
  tabIndex?: number;
  valueLabel: string;
  valuePlaceholder?: string;
  totalLabel: string;
  inputNumber?: number;
  displayNumber?: number;
  hasError?: boolean;
  onChange?: (outputValue: number) => void;
  allowSelection?: boolean;
  isSelected?: boolean;
  displaySimple?: boolean;
  onSelectionChange?: (isSelected: boolean) => void;
  currencyConfig?: CurrencyConfig;
  onBlur?: (outputValue: number) => void;
}

export function IconCardComponent(props: IconCardComponentProps) {
  const [isSelected, setIsSelected] = useState<boolean | undefined>(props.isSelected);
  const [inputNumber, setInputNumber] = useState<number>(props.inputNumber ?? 0);

  const inputRef = useRef<HTMLInputElement>(null);

  function focusInput() {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  function hasError() : Boolean {
    if (props.hasError){
      return true;
    }

    return false;
  }

  const onBlur = () => {
    if (props.onBlur) {
      props.onBlur(inputNumber);
    }
  }

  const onSelectionChange = () => {
    setIsSelected(!isSelected);
    if (props.allowSelection && props.onSelectionChange) {
      props.onSelectionChange(!isSelected)
    }
  }

  useEffect(() => {
    setInputNumber(props.inputNumber ?? 0);
  }, [props.inputNumber]);

  useEffect(() => {
    setIsSelected(props.isSelected);
  }, [props.isSelected])

  let cardClassName = "icon-card"
  if (props.allowSelection) {
    cardClassName += " icon-card-selection";
  }
  if (props.allowSelection && isSelected) {
    cardClassName += " icon-card--selected";
  }
  cardClassName += ` ${props.cardClassName}`;
  if (hasError()) {
    cardClassName += " error error-border";
  }

  let emptyClassName = "";
  if (!inputNumber || inputNumber === 0) {
    emptyClassName = "empty";
  }

  return (
      <div
        className={cardClassName}
        onMouseUp={focusInput}
        onClick={onSelectionChange}>
        <div className={`icon-card-type ${hasError() ? '' : ''}`}>
          {!props.hideIcon && <label className={`icon-card-icon ${props.iconClassName}`}></label>}
          <label className="icon-card-value"  dangerouslySetInnerHTML={{ __html: props.valueLabel }}></label>
        </div>
        {!props.displaySimple && <>
          <label className="icon-card-total">{props.totalLabel}</label>
          <MoneyInput
            tabIndex={props.tabIndex}
            placeholder={props.valuePlaceholder}
            className={`icon-card-input  ${hasError() ? 'error' : ''} ${emptyClassName}`}
            inputNumber={inputNumber}
            currencyConfig={props.currencyConfig}
            currencyFormatter={defaultCurrencyInputFormatter}
            showCurrencySymbol={true}
            onChange={props.onChange}
            onBlur={onBlur}
            ref={inputRef}
            autoWidth={true}
            maxValue={9999999}
          />
        </>}
      </div>
  );
}

export default IconCardComponent;
