import { useEffect, useState } from "react";
import ConfigComponent from "../config-component/config-component";
import { ConfirmationModalComponent } from "../confirmation-modal-component/confirmation-modal-component";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { addConfig, deleteConfig, fetchTenantConfigAll, selectTenantConfig, selectTenantConfigAll, setTenantConfig, updateTenantConfig } from "../../store/tenant-config/tenant-config.slice";
import { TenantConfigState } from "../../store/tenant-config/tenant-config.state";
import { ConfigListLabels } from "./config-component-list.labels";
import "./config-component-list.scss";

export interface ConfigComponentListProps {
  labels: ConfigListLabels;
}

export function ConfigComponentList(props: ConfigComponentListProps) {
  let abortController = new AbortController();
  const dispatch = useAppDispatch();
  const tenantConfig = useAppSelector(selectTenantConfig);
  const tenantConfigAll = useAppSelector(selectTenantConfigAll);

  const [selectedTemplateFile, setSelectedTemplateFile] = useState<File | null>(null);
  const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState<boolean>(false);
  const [deletingTemplate, setDeletingTemplate] = useState<TenantConfigState>();

  const pageSize = 3;
  const [pageIndex, setPageIndex] = useState<number>(0);

  function getPages() {
    return Math.ceil(tenantConfigAll.allTenantConfigs.length / pageSize);
  }

  function getPageItems() {
    return tenantConfigAll.allTenantConfigs.slice(pageIndex * pageSize, (pageIndex * pageSize + pageSize));
  }

  const [pages, setPages] = useState<number>(getPages());
  const [pageItems, setPageItems] = useState<TenantConfigState[]>(getPageItems());

  function setSelectedTenantConfig(event: React.MouseEvent<HTMLLIElement>, tenantConfig: TenantConfigState) {
    if ((event.target as HTMLElement).id.indexOf("tenant-selector") > -1) {
      dispatch(setTenantConfig(tenantConfig));
    }
  }

  function onDeleteClicked(template: TenantConfigState) {
    setDeletingTemplate(template);
    setIsConfirmationPopupOpen(true);
  }

  function deleteConfirmed() {
    if (deletingTemplate)
    {
      dispatch(deleteConfig(deletingTemplate));
    }
    setIsConfirmationPopupOpen(false);
  }

  function closeConfirmation() {
    setIsConfirmationPopupOpen(false);
  }

  function addTenantConfig() {
    dispatch(addConfig())
  }

  function isAddConfigDisabled(): boolean {
    const tenantConfigWithoutAName = tenantConfigAll.allTenantConfigs.find((config: any) => !(config.name));
    return tenantConfigWithoutAName ? true : false;
  }

  const handleTemplateUploadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      setSelectedTemplateFile(files[0]);
    }
  };

  const templateIdReplacer = (key: string, value: any) => {
    return ['templateId','layout','colorId'].includes(key) ? undefined : value;
  };

  const onDownloadTemplateClicked = async (tenantConfigItem: TenantConfigState) => {
    const tenantConfigClone = {...tenantConfigItem};

    const jsonString = JSON.stringify(tenantConfigClone, templateIdReplacer, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });

    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = `${tenantConfigItem.name}-template.json`;
    downloadLink.click();
  }

  const onUploadTemplateClicked = async () => {
    if (selectedTemplateFile) {
      const fileReader = new FileReader();
      fileReader.onload = async (e) => {
        try {
          const templateJsonData = JSON.parse(e.target?.result as string);
          const uploadedTenantConfig = templateJsonData as TenantConfigState;
          await dispatch(updateTenantConfig(uploadedTenantConfig));

          abortController.abort();
          abortController = new AbortController();

          await dispatch(fetchTenantConfigAll(abortController.signal));
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      };

      fileReader.readAsText(selectedTemplateFile);
    }
  }

  useEffect(() => {
    setPages(getPages());
    setPageItems(getPageItems());
  }, [tenantConfigAll]);

  useEffect(() => {
    setPageItems(getPageItems());
  }, [pageIndex]);

  useEffect(() => {
    const itemIndex = tenantConfigAll.allTenantConfigs.findIndex((config,index) => config.templateId===tenantConfig.templateId);
    const pageIndex = Math.floor(itemIndex / pageSize);
    setPageIndex(pageIndex);
  }, [tenantConfig]);

  return (
    <div className="config-component-list">
      <div className="add-tenant">
        <button
          className="add-tenant-button button button--card-solid"
          onClick={addTenantConfig}
          disabled={isAddConfigDisabled()}
        >
          +
        </button>
      </div>

      <div className="control-group">
        <div className="config-title">
          <span className="black">Add via Upload</span>
        </div>
        <div className="config-tools">
          <input
              type="file"
              onChange={handleTemplateUploadChange} />
          {tenantConfig && <span className="icon-upload" onClick={onUploadTemplateClicked}/>}
        </div>
      </div>
      
      <ul className="config-component-list-tenants">
        {!tenantConfigAll.isLoading &&
          pageItems.map((tenantConfigItem) => {
            return <li
              key={tenantConfigItem.templateId}
              onClick={(event) => setSelectedTenantConfig(event, tenantConfigItem)}>
              <div className="config-component-list-tenant">
                <div className="config-component-list-title">
                  {tenantConfigItem.templateId !== tenantConfig.templateId
                    && 
                    <>
                    {tenantConfigItem.logo && <img id="tenant-selector" className="config-component-list-logo" alt={tenantConfigItem.name} src={tenantConfigItem.logo} />}
                    <label id="tenant-selector" className="config-component-list-name">{tenantConfigItem.name}</label>
                    <div className="config-component-list-tools">
                      {tenantConfig && <span className="icon-download" onClick={() => onDownloadTemplateClicked(tenantConfigItem)}/>}
                      {tenantConfigItem.templateId > 0 &&<span className="icon-trash-can config-component-list-delete" onClick={() => onDeleteClicked(tenantConfigItem)}/>}
                    </div>
                    </>
                  }
                </div>
                {tenantConfigItem.templateId === tenantConfig.templateId && <ConfigComponent onDelete={onDeleteClicked}/>}
              </div>
            </li>
          })
        }
      </ul>
      {pages > 1 && <ul className="pagination">
        {Array.from({length: pages}, (_, i) => i).map(p => {
          return <li className="page" key={p} onClick={() => setPageIndex(p)}>{p + 1}</li>
        })}
      </ul>}
      <ConfirmationModalComponent
           header={props.labels.CONFIRMATION_MODAL.MODAL_HEADER}
           body={props.labels.CONFIRMATION_MODAL.MODAL_DESCRIPTION}
           buttonText={props.labels.CONFIRMATION_MODAL.MODAL_CONFIRMATION_BUTTON}
           isOpen={isConfirmationPopupOpen}
           closePopup={closeConfirmation}
           confirm={deleteConfirmed}
           />
    </div>
  );
}

export default ConfigComponentList;
