import { ClimateScenarioComponentLabels } from "../../../common/labels/climate-scenario-component.labels";
import { saveScenario, selectClimateChangeState } from "../../../common/store/climate-change/climate.slice";
import { useAppDispatch, useAppSelector } from "../../../common/store/hooks";
import Dropdown, { Option } from 'react-dropdown';
import "./climate-scenario-modal-component.scss";
import { getClimateScenarioOptions } from "../climate-scenario-component/climate-scenario-common";
import { useEffect, useState } from "react";
import { ClimateScenarioIconCardComponent } from "../icon-card-component/Climate-scenario-icon-card-component";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

export interface ClimateScenarioModalComponentProps {
    labels: ClimateScenarioComponentLabels;
    onRequiresUpdate?: () => void;
    onClose?: () => void;
}

export function ClimateScenarioModalComponent(props: ClimateScenarioModalComponentProps) {
  const climateChange = useAppSelector(selectClimateChangeState);
  const dispatch = useAppDispatch();
  const climateScenarioOptions = getClimateScenarioOptions(props.labels);
  const [climateScenario,setClimateScenario] = useState<string | undefined>(climateChange.scenario);
  const [previousClimateScenario,setPreviousClimateScenario] = useState<string | undefined>(climateChange.scenario);

  const onClimateScenarioSelected = (newclimateScenario: string) => {
    setPreviousClimateScenario(climateScenario);
    setClimateScenario(newclimateScenario);
  }

  const saveClimateScenarioSelected = () => {
      if (props.onRequiresUpdate) {
        props.onRequiresUpdate();
      }
      if(props.onClose)
      {
        props.onClose();
      }
  }

  const onClose = () => {
      dispatch(saveScenario(previousClimateScenario));
      if (props.onRequiresUpdate) {
        props.onRequiresUpdate();
      }
      if(props.onClose)
      {
        props.onClose();
      }
  }

  useEffect(() => {
    if (climateScenario !== climateChange.scenario) {
      dispatch(saveScenario(climateScenario));
    }
    if (props.onRequiresUpdate) {
      props.onRequiresUpdate();
    }
  }, [climateScenario]);
    
  return <div className="climate-scenario-modal">
    <div className="climate-scenario-modal-title-bar">
        <span className="climate-scenario-modal-title">{props.labels.TITLE}</span>
        <a href={props.labels.INFO_LINK} target='_blank'><div className="climate-scenario-component-info icon-info"></div></a>
        <span className="climate-scenario-modal-title-icon button-close icon-times" onClick={props.onClose} />
    </div>
    <span className="climate-scenario-modal-desc" dangerouslySetInnerHTML={{ __html: props.labels.DESC }}></span>
    <span className="climate-scenario-modal-desc">{props.labels.SELECTION}</span>
    { <div className="widget--slider">
            <div className="swiper-component">
                <Swiper
                    spaceBetween={4}
                    slidesPerView={4}
                    breakpoints={{
                        768: {
                        slidesPerView: 4,
                        pagination: { clickable: true, enabled: true }
                        },
                        1440: {
                        slidesPerView: 5,
                        pagination: { clickable: true, enabled: true }
                        }
                    }}
                    pagination={{ clickable: true, enabled: true }}
                    modules={[Pagination]}
                >
                {
                  climateScenarioOptions.map((c,i)=> {
                    return (
                    <SwiperSlide key={i}>
                    <ClimateScenarioIconCardComponent 
                              labels={props.labels} 
                              selectedClimateScenario={climateScenario} 
                              climateScenario={c.value} 
                              onClimateScenarioSelected={onClimateScenarioSelected} />
                    </SwiperSlide>)
                  })
                }
            </Swiper>
          </div>
    </div> }
    <div>
     {climateScenario!=undefined ?
    <div className="climate-scenario-modal-desc" dangerouslySetInnerHTML={{ __html: props.labels.SCENARIOS[climateScenario].DESC}}></div>
    : ''}           
    </div>
    <div className="climate-scenario-modal-footer">
          <div>
            <button className="button  button--solid" onClick={saveClimateScenarioSelected}>
                {props.labels.SAVE}
            </button>
            <span>&nbsp;</span>
            <button className="button" onClick={onClose}>
                {props.labels.CANCEL}
            </button>
          </div>
      </div>
    
</div>;
}