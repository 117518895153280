import { Asset, ClimateChange, Goals, GoalSection, Liability, MaxPensionPayout, Personal, WealthSection } from "@flexfront/models";
import { Guid } from "guid-typescript";

export interface FetchSimulationPayload {
  personal: Personal;
  goals: Goals;
  assets: Asset[];
  liabilities: Liability[];
  monthlySavings: number;
  monthlySavingsYear: number;
  lumpsum: number;
  lumpsumYear: number;
  climateChange: ClimateChange;
  includeDebug: boolean;
  shortfallEliminationType: string | undefined;
}

export interface FetchSimulationResponse {
  ageAtQuarter: number[],
  totalWealth: WealthSection;
  illiquid: WealthSection;
  goals: GoalSection[];
  leverageQuarter: number;
  totalQuarters: number;
  activeQuarters: number;
  shortfallElimination: number;
  maxPensionPayout?: MaxPensionPayout;
  request: any;
  response: any;
  invalidDateGoals: string[];
  invalidDateContributions: string[]
;}

const requestId = Guid.create().toString();

export function fetchSimulationResults(
  baseUrl: string,
  templateId: number,
  payload: FetchSimulationPayload,
  abortSignal: AbortSignal
): Promise<FetchSimulationResponse> {
  return new Promise((resolve, reject) => {
    const endpoint = `${baseUrl}/alphasimulate/${templateId}`;
    fetch(endpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      signal: abortSignal,
      body: JSON.stringify({
        requestId: requestId,
        ...payload.personal,
        ...payload.goals,
        assets: [...payload.assets],
        liabilities: [...payload.liabilities],
        monthlySavings: payload.monthlySavings,
        monthlySavingsYear: payload.monthlySavingsYear,
        lumpsum: payload.lumpsum,
        lumpsumYear: payload.lumpsumYear,
        climateChange: payload.climateChange,
        includeDebug: payload.includeDebug,
        shortfallEliminationType: payload.shortfallEliminationType
      }),
    }).then((result) => {
      result.json().then((json) => {
        resolve(json as FetchSimulationResponse);
      });
    })
    .catch((err) => {
      if (err.name !== 'AbortError') {
        throw err;
      }
    });;
  });
}
