import { useEffect, useState } from "react";
import { useAppSelector } from "../store/hooks";
import { selectTenantConfig } from "../store/tenant-config/tenant-config.slice";
import { DashboardLabels } from "../labels/dashboard.labels";
import { selectLayouts } from "../store/layout/layouts.slice";
import _ from "lodash";

export const useLayoutLabels = <TLabels>(dashboardLabels: DashboardLabels, labelsKey: string) => {
    const layouts = useAppSelector(selectLayouts);
    const tenantConfig = useAppSelector(selectTenantConfig);

    const getLabels = () => {
        if (!tenantConfig?.layout || !dashboardLabels) {
            return (dashboardLabels as any)[labelsKey];
        }
        const layout=layouts.layouts.find((l:any) => l.layoutId==tenantConfig.layoutId);
        const currentLayoutLabels = dashboardLabels.LAYOUT_SPECIFIC[layout?.name ?? ""];
        if (!currentLayoutLabels || !currentLayoutLabels[labelsKey]) {
            return (dashboardLabels as any)[labelsKey];
        }

        const defaultLabels = _.cloneDeep((dashboardLabels as any)[labelsKey]);
        const specificLabels = currentLayoutLabels[labelsKey] as TLabels;

        if (!defaultLabels) {
            return specificLabels;
        }

        return _.merge(defaultLabels, specificLabels);
    };

    const [labels, setLabels] = useState<TLabels>(getLabels());

    useEffect(() => {
        setLabels(getLabels());
    }, [dashboardLabels, tenantConfig]);

    return labels;
}