import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { AuthState } from "./auth.state";
import { environment } from "../../../../environments/environment";
import { UserLogin } from "./login.model";

export const AUTH_FEATURE_KEY = "auth";

const initialState: AuthState = {
  token: localStorage.getItem("token"),
  isLoggedIn: !!localStorage.getItem("token")
};

export const authSlice = createSlice({
  name: AUTH_FEATURE_KEY,
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null;
      state.isLoggedIn = false;
      localStorage.removeItem("token");
      return state;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      state.isLoggedIn = !!action.payload;
      localStorage.setItem("token", action.payload);
      return state;
    },
  },
});

export const login = createAsyncThunk(
  "user/authenticate",
  async (userLogin: UserLogin, thunkApi) => {
    if (!userLogin.username || !userLogin.password) {
      return;
    }

    const configUrl = `${environment.apiBaseUrl}/login`;
    return fetch(configUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(userLogin),
    }).then((result) => {
      if (result.ok) {
        result.json().then((json) => {
          thunkApi.dispatch(setToken(json as string));
        });
      }      
    });
  }
);

export const { logout, setToken } = authSlice.actions;

export const selectAuth = createSelector(
  (state: RootState) => state.auth,
  (auth) => auth
);

export default authSlice;
