import './edit-container-component.scss';
import { ReactNode } from 'react';

export interface EditContainerComponentProps {
  title?: string;
  itemTitle?: string;
  itemConfirmLabel?: string;
  children: ReactNode;
  hasError?: boolean;
  errorItemType?: string;
  onClose?: () => void;
}

export function EditContainerComponent(props: EditContainerComponentProps) {
  function handleOnClose() {
    if (props.onClose && !props.hasError) {
      props.onClose();
    }
  }

  return (
    <div className="edit-container">
      <div className="edit-container-header">
        <div className={`edit-container-title ${props.hasError && props.itemTitle===props.errorItemType ? 'edit-container-title-error': ''}`} dangerouslySetInnerHTML={{ __html: props.itemTitle ?? '' }}></div>
        <span className="edit-container-close button-close icon-times" onClick={handleOnClose}>
        </span>
      </div>
      <div className="edit-container-item-children">
        {props.children}
      </div>
    </div>
  );
}

export default EditContainerComponent;
