import { getQuarterDate } from "@flexfront/ui/react";

export function getRetirementPlotBands(
  label: string,
  totalQuarters: number,
  activeQuarters: number,
  plotBandStart?: number
): Highcharts.XAxisPlotBandsOptions[] {

if (totalQuarters < 1 || activeQuarters < 1) {
  return [];
}

  const currentDate = getQuarterDate(new Date());
  plotBandStart = plotBandStart ?? Date.UTC(
    currentDate.getFullYear(),
    currentDate.getMonth() + (activeQuarters - 2) * 3,
    1
  );
  const plotBandEnd = Date.UTC(
    currentDate.getFullYear(),
    currentDate.getMonth() + totalQuarters * 3,
    1
  );

  return [
    {
      id: "retirementPlotBand",
      className: "retirement-plotband",
      from: plotBandStart,
      to: plotBandEnd,
      zIndex: 2
    }
  ];
}
