import HighchartsReact from 'highcharts-react-official';
import './retirement-chart-component.scss';
import { GridLoader } from 'react-spinners';
import { useChartContext } from '../../../common/providers/chart-provider';
import _ from 'lodash';
import { useEffect } from 'react';


export function RetirementChartComponent() {

  const {
    simulationState,
    chartOptions,
    loaderColor,
    Highcharts,
    chartRef,
    containerProps,
    height,
    isTabletLandscape,
    isTabletPortrait,
    isHorizontallyCompact,
    setContainerProps,
    heightOverride,
    widthOverride,
    isLogoAvailable
  } = useChartContext();

  useEffect(() => {
    let highChartsContainerHeight = height - (isLogoAvailable ? 430 : 400);

    if (isTabletLandscape) {
      highChartsContainerHeight -= 30;
    } else if (isTabletPortrait) {
      highChartsContainerHeight = 378;
    }

    if (isHorizontallyCompact) {
      highChartsContainerHeight = 307;
    }

    let newContainerProps: { style: { height?:string, width?:string } } = {
      style: {
        height: `${highChartsContainerHeight}px`
      }
    };

    if (heightOverride) {
      newContainerProps = {
        ...newContainerProps,
        style: {
          ...newContainerProps.style,
          height: `${heightOverride}px`
        }
      };
    }

    if (widthOverride) {
      newContainerProps = {
        ...newContainerProps,
        style: {
          ...newContainerProps.style,
          width: `${widthOverride}px`
        }
      };
    }

    if (!_.isEqual(containerProps, newContainerProps)) {
      setContainerProps(newContainerProps);
    }
  }, [
    height,
    heightOverride,
    widthOverride,
    isTabletLandscape,
    isTabletPortrait,
    isHorizontallyCompact,
    isLogoAvailable,
    containerProps,
    setContainerProps,
    chartRef
  ]);
  
  return (
    <div className="retirement-chart-container">
      <GridLoader
        className="spinner spinner--chart"
        loading={simulationState.isLoading}
        color={loaderColor} />
      {chartOptions && <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        ref={chartRef}
        containerProps={containerProps} />}
    </div>
  );
}
