import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { RetirementChartHeaderComponentLabels } from "../labels/retirement-chart-header-component.labels";
import { useTenantLogo } from "../hooks/useTenantLogo";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectTenantConfig } from "../store/tenant-config/tenant-config.slice";
import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from "../hooks/useTrackEvent";
import { saveGoals, selectGoalsState } from "../store/goals/goals.slice";
import { selectClimateChangeState } from "../store/climate-change/climate.slice";
import { InflationOptions } from "@flexfront/models";
import { ModalSelectionComponent } from "../../default/components/retirement-chart-header-component/modal-selection-component";
import { setIsInfoModalOpen } from "../store/info-modal/info-modal.slice";
import { TenantConfigState } from "../store/tenant-config/tenant-config.state";


export interface ChartHerderContextProps {
    children: ReactNode;
    labels: RetirementChartHeaderComponentLabels;
    onRequiresUpdate?: () => void;
}

interface ChartHeaderFunctionality {
    labels: RetirementChartHeaderComponentLabels;
    onRequiresUpdate?: () => void;
    tenantLogo: string | undefined;
    openInfoModal: () => void;
    isClimateScenarioHidden: boolean;
    getSelectedClimateScenario: () => string;
    isClimateSelectionOpen: boolean;
    openClimateSelectionModal(isOpen: boolean): void;
    isInflationSelectionOpen: boolean;
    setIsInflationSelectionOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsClimateSelectionOpen: React.Dispatch<React.SetStateAction<boolean>>;
    tenantConfig: TenantConfigState;
    openInflationSelectionModal(isOpen: boolean): void;
    isInflationToggleSelected: boolean;
    setisInflationToggleSelected: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChartHeaderContext = createContext<ChartHeaderFunctionality | undefined>(undefined);

export const useChartHeaderContext = (): ChartHeaderFunctionality => {
    const context = useContext(ChartHeaderContext);
    if (!context) {
        throw new Error('useChartHeaderContext must be used within a ChartHeaderProvider');
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useContext(ChartHeaderContext)!;
};

export const ChartHeaderProvider: React.FC<ChartHerderContextProps> = (props: ChartHerderContextProps) => {

    const tenantLogo = useTenantLogo();
    const  tenantConfig= useAppSelector(selectTenantConfig);
    const [isInflationSelectionOpen, setIsInflationSelectionOpen] = useState<boolean>(false);
    const [isClimateSelectionOpen, setIsClimateSelectionOpen] = useState<boolean>(false);
    const [isInflationToggleSelected, setisInflationToggleSelected] = useState<boolean>(false);
    const [isClimateScenarioHidden, setIsClimateScenarioHidden] = useState<boolean>(tenantConfig.isClimateScenarioHidden ?? false);
    
    const dispatch = useAppDispatch();
  
    const trackEvent = useTrackEvent();
  
    const goals = useAppSelector(selectGoalsState);
    const climateChange = useAppSelector(selectClimateChangeState);
  
  
    const getSelectedClimateScenario = () => {
        if (climateChange.scenario) {
            return props.labels.CLIMATE_SCENARIO.SCENARIOS[climateChange.scenario].TITLE;
        }
  
        return props.labels.CLIMATE_SCENARIO.PLACEHOLDER;
    }
  
    const OnApplyInflationOnAllGoals = (isSelected: boolean) => {
      dispatch(saveGoals({...goals, retirement: {...goals.retirement, nominal: !isSelected},
              legacy:{...goals.legacy, nominal: !isSelected},
              dream:{...goals.dream, nominal: !isSelected},
              preserveCapital:{...goals.preserveCapital, nominal: !isSelected},
              secureFamily:{...goals.secureFamily, nominal: !isSelected}}));
      if (props.onRequiresUpdate) props.onRequiresUpdate();
    }
  
    
  
    function openInfoModal() {
      trackEvent({ category: trackEventCategories.INFO, action: trackEventActions.CLICK, name: trackEventNames.INFO_MODAL });
      dispatch(setIsInfoModalOpen(true));
    }
  
    function openInflationSelectionModal(isOpen: boolean) {
      trackEvent({ category: trackEventCategories.INFLATION, action: trackEventActions.CLICK, name: trackEventNames.INFLATION_MODAL });
      setIsClimateSelectionOpen(false);
      setIsInflationSelectionOpen(isOpen);
    }
  
    function openClimateSelectionModal(isOpen: boolean) {
      trackEvent({ category: trackEventCategories.INFLATION, action: trackEventActions.CLICK, name: trackEventNames.INFLATION_MODAL });
      setIsInflationSelectionOpen(false);
      setIsClimateSelectionOpen(isOpen);
    }
  
    useEffect(() => {
      OnApplyInflationOnAllGoals(isInflationToggleSelected);
    }, [isInflationToggleSelected]);
  
    useEffect(() => {
      setIsClimateScenarioHidden(tenantConfig.isClimateScenarioHidden);
    }, [tenantConfig.isClimateScenarioHidden]);
  

    const contextValue = {
        children: props.children,
        labels: props.labels,
        onRequiresUpdate: props.onRequiresUpdate,
        tenantLogo: tenantLogo,
        openInfoModal: openInfoModal,
        isClimateScenarioHidden: isClimateScenarioHidden,
        getSelectedClimateScenario: getSelectedClimateScenario,
        isClimateSelectionOpen: isClimateSelectionOpen,
        openClimateSelectionModal: openClimateSelectionModal,
        isInflationSelectionOpen: isInflationSelectionOpen,
        setIsInflationSelectionOpen: setIsInflationSelectionOpen,
        setIsClimateSelectionOpen: setIsClimateSelectionOpen,
        tenantConfig: tenantConfig,
        openInflationSelectionModal: openInflationSelectionModal,
        isInflationToggleSelected: isInflationToggleSelected,
        setisInflationToggleSelected: setisInflationToggleSelected,
    };

    return (
        <ChartHeaderContext.Provider value={contextValue}>{props.children}</ChartHeaderContext.Provider>
      );
};