import ThisIsMeComponent from '../this-is-me-component/this-is-me-component';
import IHaveComponent from '../i-have-component/i-have-component';
import IWantComponent from '../i-want-component/i-want-component';
import ICanComponent from '../i-can-component/i-can-component';
import { ErrorBoundaryComponent, WidgetComponent } from '@flexfront/ui/react';
import { GridLoader } from 'react-spinners';
import ConfigComponentList from '../../../common/components/config-component-list/config-component-list';
import LogoutComponent from '../../../common/components/logout-component/logout-component';
import ConfigComponent from '../../../common/components/config-component/config-component';
import InfoModalComponent from '../../../default/components/info-modal-component/info-modal-component';
import CtaComponent from '../cta-component/cta-component';
import { NotificationComponent } from '../../../default/components/notification-component/notification.component';
import { removeErrorMessage, removeInfoMessage } from '../../../common/store/notifications/notifications.slice';
import DebugComponent from '../../../common/components/debug-component/debug-component';
import TimelineContainerComponent from '../../../default/components/timeline-container-component/timeline-container-component';
import TimelineRangeComponent from '../../../default/components/timeline-range-component/timeline-range-component';
import TimelineMarkerComponent from '../../../default/components//timeline-marker-component/timeline-marker-component';
import { fromCtaAndPersonaLabels } from '../../../common/labels/cta-component.labels';
import CtaModalComponent from '../cta-modal-component/cta-modal-component';
import { PersonalInfoProvider } from '../../../common/providers/personal-info-provider';
import { useDashboardContext } from '../../../common/providers/dashboard-provider';
import { useAppDispatch } from '../../../common/store/hooks';
import './dashboard.scss';
import DashboardCompact from '../dashboard-compact/dashboard-compact';
import { GoalsProvider } from '../../../common/providers/goals-provider';
import { AssetsProvider } from '../../../common/providers/assets-provider';
import { SavingsProvider } from '../../../common/providers/savings-provider';
import { NudgeProvider } from '../../../common/providers/nudge-provider';
import NudgeComponent from '../nudge-component/nudge-component';
import { ResultProvider } from '../../../common/providers/result-provider';
import ResultsComponent from '../results-component/resultsComponent';
import RetirementChartWrapperComponent from '../retirement-chart-component/retirement-chart-wrapper-component';
import { ChartHeaderProvider } from '../../../common/providers/chart-header-provider';
import RetirementChartHeaderComponent from '../retirement-chart-header-component/retirement-chart-header-component';
import { LogoComponent } from '../../../default/components/logo-component/logo-component';
import { useEffect, useState } from 'react';
import { DownloadReportComponent } from '../../../default/components/download-report-component/download-report-component';

export function Dashboard() {

  const dispatch = useAppDispatch();
  
  const {
    labels,
    isHorizontallyCompact,
    isInsideIframe,
    currentDate,
    listParam,
    configParam,
    loaderColor,
    ctaIsOpen,
    isLogoAvailable,

    realEstateIcon,
    mortgageIcon,
    retirementIcon,
    dreamIcon,

    iWantOrder,
    iHaveOrder,
    iCanOrder,
    thisIsMeOrder,

    thisIsMeLabels,
    iWantLabels,
    ihaveLabels,
    iCanLabels,
    resultLabels,
    nudgeLabels,
    disclaimerLabels,
    infoLabels,
    ctaLabels,
    persona,
    menuLabels,

    auth,
    goals,
    simulation,
    assets,
    userSettings,
    notifications,
    tenantConfig,

    debounceSimulationUpdate,
    getQuarterSpanFromString,
    getQuarterFromYear,
    getQuarterSpanFromCurrentDate,
    onCtaSubmit,

    isReportDownloading,
    onReportDowloading
  } = useDashboardContext();

  const [dashboardClassName, setDashboardClassName] = useState<string>('quirion-dashboard');

  useEffect(() => {
    let newDashboardClassName = 'quirion-dashboard';
    if (isHorizontallyCompact && isInsideIframe) {
      newDashboardClassName += ' quirion-dashboard--iframe';
    }

    if ((configParam || listParam) && auth.isLoggedIn) {
      newDashboardClassName += ' quirion-dashboard--config';
    }

    if ((configParam || listParam) && isLogoAvailable && auth.isLoggedIn) {
      newDashboardClassName += ' quirion-dashboard--config-with_logo';
    }

    if (isLogoAvailable) {
      newDashboardClassName += ' quirion-dashboard--with_logo';
    }

    setDashboardClassName(newDashboardClassName);

  }, [
    isHorizontallyCompact,
    isInsideIframe,
    configParam,
    listParam,
    auth.isLoggedIn,
    isLogoAvailable]);

  const dashboardLayout = isHorizontallyCompact ? (
    <DashboardCompact />
  ) : (
    <>
      {isLogoAvailable ? 
        <div className="quirion-dashboard__header">
          <LogoComponent />
        </div>
      : null}
      <div className={`quirion-dashboard__personal quirion-dashboard__widget-${thisIsMeOrder ?? 0}`} style={{gridArea: `widget_${thisIsMeOrder ?? 0}`}}>
        <ErrorBoundaryComponent>
          <PersonalInfoProvider
            labels={thisIsMeLabels}
            onRequiresUpdate={debounceSimulationUpdate}
            tabOrder={thisIsMeOrder ?? 0}>
          <ThisIsMeComponent />
          </PersonalInfoProvider>
        </ErrorBoundaryComponent>
      </div>
      <div className={`quirion-dashboard__assets quirion-dashboard__widget-${iHaveOrder ?? 1}`} style={{gridArea: `widget_${iHaveOrder ?? 1}`}}>
        <ErrorBoundaryComponent>
          <AssetsProvider
            labels={ihaveLabels}
            onRequiresUpdate={debounceSimulationUpdate}
            tabOrder={iHaveOrder ?? 1}>
              <IHaveComponent />
          </AssetsProvider>
        </ErrorBoundaryComponent>
      </div>
      <div className={`quirion-dashboard__goals quirion-dashboard__widget-${iWantOrder ?? 2}`} style={{gridArea: `widget_${iWantOrder ?? 2}`}}>
        <ErrorBoundaryComponent>
          <GoalsProvider
            labels={iWantLabels}
            onRequiresUpdate={debounceSimulationUpdate}
            tabOrder={iWantOrder ?? 2}>
            <IWantComponent />
          </GoalsProvider>
        </ErrorBoundaryComponent>
      </div>
      <div className={`quirion-dashboard__savings quirion-dashboard__widget-${iCanOrder ?? 3}`} style={{gridArea: `widget_${iCanOrder ?? 3}`}}>
        <ErrorBoundaryComponent>
          <SavingsProvider
            labels={iCanLabels}
            onRequiresUpdate={debounceSimulationUpdate}
            tabOrder={iCanOrder ?? 3}>
              <ICanComponent />
          </SavingsProvider>
        </ErrorBoundaryComponent>
      </div>
      <div className="quirion-dashboard__chart">
        <ErrorBoundaryComponent>
          <div>
            <ChartHeaderProvider 
              labels={{
                TITLE: labels.RETIREMENT_CHART.TITLE,
                MENU: menuLabels,
                CLIMATE_SCENARIO: labels.CLIMATE_SCENARIO,
                INFLATION: {
                  ...iWantLabels,
                  OPEN_BUTTON: labels.INFLATION?.OPEN_BUTTON,
                  TITLE: labels.INFLATION?.TITLE,
                  DESC: labels.INFLATION?.DESC,
                  SELECTION: labels.INFLATION?.SELECTION,
                  EMPTY: labels.INFLATION?.EMPTY,
                },
              }}
              onRequiresUpdate={debounceSimulationUpdate}>
                  <RetirementChartHeaderComponent />
            </ChartHeaderProvider>
          </div>
          <div className="quirion-chart-container">
          <RetirementChartWrapperComponent labels={labels.RETIREMENT_CHART} onRequiresUpdate={debounceSimulationUpdate}/>
          <div className="timeline">
            <TimelineContainerComponent isRangeContainer={true}>
              {goals.preserveCapital.amount > 0 && (
                <TimelineRangeComponent
                  name="preserve-capital"
                  className="preserve-capital-timeline"
                  startQuarter={getQuarterSpanFromString(goals.preserveCapital.startDate)}
                  endQuarter={getQuarterSpanFromString(goals.preserveCapital.endDate)}
                />
              )}
              <TimelineRangeComponent
                name="retirement"
                className="retirement-timeline"
                startQuarter={simulation.activeQuarters - 1}
                endQuarter={simulation.totalQuarters}
              />
            </TimelineContainerComponent>
            <TimelineContainerComponent>
              {userSettings.includeIlliquid && assets.realEstate > 0 && (
                <TimelineMarkerComponent
                  name="property"
                  quarter={0}
                  icon={realEstateIcon}
                  iconClassName="icon-home"
                  order={0} />
              )}
              {userSettings.includeIlliquid && simulation.leverageQuarter <= simulation.totalQuarters && simulation.leverageQuarter > 0 && assets.realEstate > 0 && (
                <TimelineMarkerComponent
                  name="leverage"
                  quarter={simulation.leverageQuarter}
                  icon={mortgageIcon}
                  iconClassName="icon-leverage"
                  order={2}
                />
              )}
              <TimelineMarkerComponent
                name="retirement"
                rangeName="retirement"
                quarter={simulation.activeQuarters - 1}
                icon={retirementIcon}
                iconClassName="icon-pension"
                order={0}
              />
              {goals.legacy.amount > 0 && (
                <TimelineMarkerComponent
                  name="legacy"
                  quarter={simulation.totalQuarters - 1}
                  iconClassName="icon-legacy"
                  order={1}
                />
              )}
              {goals.dream.amount > 0 && goals.dream.year > currentDate.getFullYear() && (
                <TimelineMarkerComponent
                  name="dream"
                  quarter={getQuarterFromYear(goals.dream.year)}
                  icon={dreamIcon}
                  iconClassName="icon-dream"
                  order={3}
                />
              )}
              {goals.preserveCapital.amount > 0 && goals.preserveCapital.startDate && (
                <TimelineMarkerComponent
                  name="preserve-capital"
                  quarter={getQuarterSpanFromCurrentDate(new Date(goals.preserveCapital.startDate))}
                  iconClassName="icon-piggy-bank"
                  order={4}
                />
              )}
              {goals.secureFamily.amount > 0 && goals.secureFamily.year > currentDate.getFullYear() && (
                <TimelineMarkerComponent
                  name="secure-family"
                  quarter={getQuarterFromYear(goals.secureFamily.year)}
                  iconClassName="icon-secure-family"
                  order={5}
                />
              )}
            </TimelineContainerComponent>
          </div>
          </div>
        </ErrorBoundaryComponent>
      </div>
      <div className="quirion-dashboard__results">
        <ErrorBoundaryComponent>
          <>
            <label
              className="quirion-widget-title">
                {resultLabels.TITLE}
            </label>
            <WidgetComponent className="quirion-results-component-widget">
              <div className="quirion-results-content">
                <ResultProvider labels={resultLabels}>
                  <ResultsComponent/>
                </ResultProvider>
                <NudgeProvider 
                    labels={nudgeLabels} >
                    <NudgeComponent />
                </NudgeProvider>
              </div>
              <div className="quirion-results-footer">
                <DebugComponent />
                {tenantConfig.isReportDownloadEnabled ? <DownloadReportComponent
                        title={menuLabels.DOWNLOAD}
                        reportTitle={menuLabels.REPORT_TITLE}
                        reportErrorMessage={menuLabels.REPORT_ERROR_MESSAGE}
                        reportInfoMessage={menuLabels.REPORT_INFO_MESSAGE.replace('{text}', menuLabels.CTA_TEXT)} 
                        isReportDownloading={isReportDownloading}
                        onReportDowloading={onReportDowloading}
                        isInsideMenu={false} /> : ''}
                <CtaModalComponent
                  labels={fromCtaAndPersonaLabels(ctaLabels, persona)}
                  isOpen={ctaIsOpen}
                  renderChildrenAsHiddenContent={false}>
                  <CtaComponent />
                </CtaModalComponent>
              </div>
            </WidgetComponent>
          </>
        </ErrorBoundaryComponent>
      </div>
      <div className="quirion-dashboard__notify">
              <NotificationComponent
                isOpen={notifications.errorMessages.length > 0}
                type="error"
                notification={notifications.errorMessages[0]}
                onClose={(notification) => dispatch(removeErrorMessage(notification?.type))}
              />
              <span></span>
              <NotificationComponent
                isOpen={notifications.warningMessages.length > 0}
                type="warning"
                notification={notifications.warningMessages[0]}
                onClose={(notification) => dispatch(removeInfoMessage(notification?.type))}
              />
              <span></span>
              <NotificationComponent
                isOpen={notifications.infoMessages.length > 0}
                type="inform"
                notification={notifications.infoMessages[0]}
                onClose={(notification) => dispatch(removeInfoMessage(notification?.type))}
              />
            </div>
      {configParam && auth.isLoggedIn && (
        <div className="quirion-dashboard__config">
          <LogoutComponent />
          <ConfigComponent />
        </div>
      )}
      {listParam && auth.isLoggedIn && (
        <div className="quirion-dashboard__config">
          <LogoutComponent />
          <ConfigComponentList labels={labels.CONFIG_LIST} />
        </div>
      )}
    </>
  );

  return tenantConfig.isLoading ? (
    <GridLoader className="spinner" loading={tenantConfig.isLoading} color={loaderColor} />
  ) : (
    <>
      <div id='dashboard' className={dashboardClassName}>
        {dashboardLayout}
      </div>
      <InfoModalComponent labels={infoLabels} />
    </>
  );
}

export default Dashboard;
