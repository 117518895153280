import { DisclaimerComponentLabels } from "./dashboard.labels";

export interface LandingComponentLabels {
  WELCOME_TITLE: string;
  WELCOME_BODY: string;
  CTA: string;
  DISCLAIMER: string;
}

export function fromLandingLabels(landingLabels: LandingComponentLabels, disclaimerLabels: DisclaimerComponentLabels) {
  return {
    ...landingLabels,
    DISCLAIMER: disclaimerLabels.TEXT
  };
}
