import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { revertAll } from "../hooks";
import { RootState } from "../store";
import { Dream, GoalsState, Legacy, PreserveCapital, Retirement, SecureFamily } from "./goals.state";

export const GOALS_FEATURE_KEY = "goals";
export const DEFAULT_RETIREMENT_AGE = 67;

const initialState: GoalsState = {
  retirement: { nominal: true, retirementAge: DEFAULT_RETIREMENT_AGE, retirementMonthlyPayout: 0 },
  legacy: { nominal: true, amount: 0 },
  dream: { nominal: true, year: 0, amount: 0, hasInvalidDate: false },
  secureFamily: { nominal: true, year: 0, amount: 0, hasInvalidDate: false },
  preserveCapital: { nominal: true, startDate: undefined, endDate: undefined, amount: 0, hasInvalidDate: false }
};

export const goalsSlice = createSlice({
  name: GOALS_FEATURE_KEY,
  initialState,
  reducers: {
    saveGoals: (state, action: PayloadAction<GoalsState>) => {
      state = { ...action.payload };
      return state;
    },
    saveRetirement: (state, action: PayloadAction<Retirement>) => {
      state.retirement =  { ...action.payload };
      return state;
    },
    saveLegacy: (state, action: PayloadAction<Legacy>) => {
      state.legacy =  { ...action.payload };
      return state;
    },
    saveDream: (state, action: PayloadAction<Dream>) => {
      state.dream =  { ...action.payload};
      return state;
    },
    setDreamHasInvalidDate: (state, action: PayloadAction<boolean>) => {
      state.dream = { ...state.dream, hasInvalidDate: action.payload };
      return state;
    },
    saveSecureFamily: (state, action: PayloadAction<SecureFamily>) => {
      state.secureFamily =  { ...action.payload};
      return state;
    },
    setSecureFamilyHasInvalidDate: (state, action: PayloadAction<boolean>) => {
      state.secureFamily = { ...state.secureFamily, hasInvalidDate: action.payload };
      return state;
    },
    savePreserveCapital: (state, action: PayloadAction<PreserveCapital>) => {
      state.preserveCapital =  { ...action.payload };
      return state;
    },
    setPreserveCapitalHasInvalidDate: (state, action: PayloadAction<boolean>) => {
      state.preserveCapital = { ...state.preserveCapital, hasInvalidDate: action.payload };
      return state;
    },
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const {
  saveGoals,
  saveRetirement,
  saveLegacy,
  saveDream,
  setDreamHasInvalidDate,
  saveSecureFamily,
  setSecureFamilyHasInvalidDate,
  savePreserveCapital,
  setPreserveCapitalHasInvalidDate
} = goalsSlice.actions;

export const selectGoalsState = createSelector(
  (state: RootState) => state.goals,
  (goals) => goals
);

export const selectRetirementState = createSelector(
  (state: RootState) => state.goals.retirement,
  (retirement) => retirement
);

export const selectLegacyState = createSelector(
  (state: RootState) => state.goals.legacy,
  (legacy) => legacy
);

export const selectDreamState = createSelector(
  (state: RootState) => state.goals.dream,
  (dream) => dream
);

export const selectSecureFamilyState = createSelector(
  (state: RootState) => state.goals.secureFamily,
  (secureFamily) => secureFamily
);

export const selectPreserveCapitalState = createSelector(
  (state: RootState) => state.goals.preserveCapital,
  (preserveCapital) => preserveCapital
);

export default goalsSlice;
