import { ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";
import { IHaveComponentLabels } from "../labels/i-have-component.labels";
import { AssetTypeItem, AssetTypeLabel, LiabilityTypeItem, LiabilityTypeLabel } from "@flexfront/models";
import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from "../hooks/useTrackEvent";
import { saveLiabilities, selectLiabilitiesState } from "../store/liabilities/liabilities.slice";
import { saveAssets, selectAssetsState } from "../store/assets/assets.slice";
import { GetAssetItems, GetAssetItemsInOrder, GetChildPropertyMap, GetLiabilityItems } from "@flexfront/utils";
import { useLayoutAssetsOrder } from "../hooks/useLayoutAssetsOrder";
import { useComponentVisibility } from "../hooks/useComponentVisibility";
import { useComponentIcon } from "../hooks/useComponentIcon";
import { useCurrencyConfig } from "../hooks/useCurrencyConfig";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { addErrorMessage, removeErrorMessage } from "../store/notifications/notifications.slice";
import { NotificationType } from "../store/notifications/notifications.state";
import { CurrencyConfig } from "@flexfront/ui/react";
import { SwiperClass } from "swiper/react";

export interface AssetsContextProps {
    children: ReactNode;
    labels: IHaveComponentLabels;
    tabOrder?: number;
    onRequiresUpdate?: () => void;
}

interface AssetsFunctionality {
    labels: IHaveComponentLabels;
    tabOrder?: number;
    onRequiresUpdate?: () => void;
    swiperContainerRef: React.RefObject<HTMLDivElement>;
    onSwiperContainerKeyUp: (event: React.KeyboardEvent<HTMLDivElement>) => void;
    setSwiperInstance: React.Dispatch<React.SetStateAction<SwiperClass | null>>;
    swiperInstance: SwiperClass | null;
    liabilityTypes: LiabilityTypeItem[];
    shouldRenderLiability: (liabilityType: LiabilityTypeItem, ignoreAssosiated: boolean) => boolean;
    isAssetVisible: (assetType: string) => boolean | undefined;
    liabilityHasError: (liabilityType: string) => boolean;
    getLiabilityStateValue: (liabilityType: string) => number;
    getAssetIconHidden: (assetType: string) => boolean | undefined;
    getLiabilityChangedFunction: (liabilityType: string) => (amount : number) => void;
    currencyConfig: CurrencyConfig,
    getLiabilityBlurFunction: (liabilityType: string) => (amount : number) => void;
    getAssetStateValue: (assetType: string) => number;
    getAssetsChangedFunction: (assetType: string) => (amount : number) => void;
    getAssetsBlurFunction: (assetType: string) => (amount : number) => void;
    iHaveIcon?: string;
    assetTypes: AssetTypeItem[];
    tabIndex: number;
    cashIcon: string | undefined;
    bondsIcon: string | undefined;
    equityIcon: string | undefined;
    realestateIcon: string | undefined;
    mortgageIcon: string | undefined;
}

const AssetsContext = createContext<AssetsFunctionality | undefined>(undefined);

export const useAssetsContext = (): AssetsFunctionality => {
    const context = useContext(AssetsContext);
    if (!context) {
        throw new Error('useAssetsContext must be used within a AssetsProvider');
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useContext(AssetsContext)!;
};

export const AssetsProvider: React.FC<AssetsContextProps> = (props: AssetsContextProps) => {

    const assets = useAppSelector(selectAssetsState);
    const liabilities = useAppSelector(selectLiabilitiesState);
    const dispatch = useAppDispatch();
    const tabIndex = +`${props.tabOrder}1`;
    const currencyConfig = useCurrencyConfig();
    const trackEvent = useTrackEvent();
  
    const iHaveIcon = useComponentIcon("I_HAVE");
    const cashIcon = useComponentIcon("I_HAVE_CASH_ICON");
    const bondsIcon = useComponentIcon("I_HAVE_BONDS_ICON");
    const equityIcon = useComponentIcon("I_HAVE_EQUITY_ICON");
    const realestateIcon = useComponentIcon("I_HAVE_REAL_ESTATE_ICON");
    const mortgageIcon = useComponentIcon("I_HAVE_LIABILITY_ICON");

    const isCashIconHidden = useComponentVisibility("I_HAVE_CASH_ICON");
    const isRealEstateIconHidden = useComponentVisibility("I_HAVE_REAL_ESTATE_ICON");
    const isLiabilityIconHidden = useComponentVisibility("I_HAVE_LIABILITY_ICON");
    const isBondsIconHidden = useComponentVisibility("I_HAVE_BONDS_ICON");
    const isEquityIconHidden = useComponentVisibility("I_HAVE_EQUITY_ICON");
  
    const isRealEstateHidden = useComponentVisibility("I_HAVE_REAL_ESTATE");
    const isLiabilityHidden = useComponentVisibility("I_HAVE_LIABILITY");
    
    const assetsOrder = useLayoutAssetsOrder();
    const assetItems: AssetTypeItem[] = GetAssetItems(GetChildPropertyMap<AssetTypeLabel>(props.labels.ASSET.ASSET_TYPES));
    const assetTypes: AssetTypeItem[] = GetAssetItemsInOrder(assetItems,assetsOrder);
    const liabilityTypes: LiabilityTypeItem[] = GetLiabilityItems(GetChildPropertyMap<LiabilityTypeLabel>(props.labels.LIABILITY.LIABILITY_TYPES));
    
    function update() {   
      if (props.onRequiresUpdate) {
        props.onRequiresUpdate();
      }
    }
  
    function onRealEstateChanged(amount: number) {
      if (amount === 0) {
        onMortgageChanged(0);
      }
  
      dispatch(saveAssets({ ...assets, realEstate: amount }));
      update();
    }
  
    function onRealEstateBlur(amount: number) {
      trackEvent({ category: trackEventCategories.I_HAVE, action: trackEventActions.INPUT, name: trackEventNames.REAL_ESTATE, value: amount });
    }
  
    function onEquityChanged(amount: number) {
      dispatch(saveAssets({ ...assets, equity: amount }));
      update();
    }
  
    function onEquityBlur(amount: number) {
      trackEvent({ category: trackEventCategories.I_HAVE, action: trackEventActions.INPUT, name: trackEventNames.EQUITY, value: amount });
    }
  
    function onBondsChanged(amount: number) {
      dispatch(saveAssets({ ...assets, bonds: amount }));
      update();
    }
  
    function onBondsBlur(amount: number) {
      trackEvent({ category: trackEventCategories.I_HAVE, action: trackEventActions.INPUT, name: trackEventNames.BONDS, value: amount });
    }
  
    function onCashChanged(amount: number) {
      dispatch(saveAssets({ ...assets, cash: amount }));
      update();
    }
  
    function onCashBlur(amount: number) {
      trackEvent({ category: trackEventCategories.I_HAVE, action: trackEventActions.INPUT, name: trackEventNames.CASH, value: amount });
    }
  
    function onMortgageChanged(amount: number) {
      dispatch(saveLiabilities({ ...liabilities, mortgage: amount }));
      update();
    }
  
    function onMortgageBlur(amount: number) {
      trackEvent({ category: trackEventCategories.I_HAVE, action: trackEventActions.INPUT, name: trackEventNames.MORTGAGE, value: amount });
    }
  
    function getLiabilityChangedFunction(liabilityType: string) : (amount : number) => void {
      switch (liabilityType){
        case "mortgage":
          return onMortgageChanged;
        default:
          throw new Error(`${liabilityType} is not currently handled yet`);
      }
      
    }
  
    function getLiabilityBlurFunction(liabilityType: string) : (amount : number) => void {
      switch (liabilityType){
        case "mortgage":
          return onMortgageBlur;
        default:
          throw new Error(`${liabilityType} is not currently handled yet`);
      }
      
    }
  
    function getAssetsChangedFunction(assetType: string) : (amount : number) => void {
      switch (assetType){
        case "realestate":
          return onRealEstateChanged;
        case "bonds":
          return onBondsChanged;
        case "equity":
          return onEquityChanged;
        case "cash":
          return onCashChanged;
        default:
          throw new Error(`${assetType} is not currently handled yet`);
      }
    }
  
    function getAssetsBlurFunction(assetType: string) : (amount : number) => void {
      switch (assetType){
        case "realestate":
          return onRealEstateBlur;
        case "bonds":
          return onBondsBlur;
        case "equity":
          return onEquityBlur;
        case "cash":
          return onCashBlur;
        default:
          throw new Error(`${assetType} is not currently handled yet`);
      }
    }
  
    function getLiabilityStateValue(liabilityType: string) : number {
      switch (liabilityType){
        case "mortgage":
          return liabilities.mortgage;
        default:
          throw new Error(`${liabilityType} is not currently handled yet`);
      }
    }
  
    function liabilityHasError(liabilityType: string) : boolean {
      switch (liabilityType){
        case "mortgage":
          return isMortgageToBig();
        default:
          return false;
      }
    }
  
    function isMortgageToBig() {
      return liabilities.mortgage > assets.realEstate;
    }
  
    function shouldRenderLiability(liabilityType: LiabilityTypeItem, ignoreAssosiated: boolean) : boolean {
      if (ignoreAssosiated && liabilityType.IsAssosiated) {
        return false
      }
      switch (liabilityType.Type){
        case "mortgage":
          return assets.realEstate > 0;
        default:
          return true;
      }
    }
  
    function getAssetStateValue(assetType: string) : number {
      switch (assetType){
        case "realestate":
          return assets.realEstate;
        case "bonds":
          return assets.bonds;
        case "equity":
          return assets.equity;
        case "cash":
          return assets.cash;
        default:
          throw new Error(`${assetType} is not currently handled yet`);
      }
    }
  
    function getAssetIconHidden(assetType: string) : boolean | undefined {
      switch (assetType){
        case "realestate":
          return isRealEstateIconHidden;
        case "mortgage":
          return isLiabilityIconHidden;
        case "bonds":
          return isBondsIconHidden;
        case "equity":
          return isEquityIconHidden;
        case "cash":
          return isCashIconHidden;
        default:
          throw new Error(`${assetType} is not currently handled yet`);
      }
    }
  
    function isAssetVisible(assetType: string) : boolean | undefined {
      switch (assetType){
        case "realestate":
          return !isRealEstateHidden;
        case "mortgage":
          return !isLiabilityHidden;
        case "bonds":
          return true;
        case "equity":
          return true;
        case "cash":
          return true;
        default:
          throw new Error(`${assetType} is not currently handled yet`);
      }
    }

    const swiperContainerRef = useRef<HTMLDivElement>(null);
    const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null);

    function onSwiperContainerKeyUp(event: React.KeyboardEvent<HTMLDivElement>) {
      if (event.key === "Tab" && swiperContainerRef.current && swiperContainerRef.current.contains(event.target as Node)) {
          const target = event.target as HTMLElement;
          const slide: HTMLElement | null = target.closest('.swiper-slide');
          if (slide && swiperInstance?.slides.includes(slide)) {
              const slideIndex = swiperInstance.slides.indexOf(slide);
              swiperInstance.slideTo(slideIndex);
          }
      }
  }

    useEffect(() => {
        if ((assets.cash===0 && assets.bonds===0 && assets.equity===0) && assets.realEstate!==0) {
          trackEvent({ category: trackEventCategories.I_HAVE, action: trackEventActions.VALIDATION, name: trackEventNames.EQUITY_ERROR });
          dispatch(addErrorMessage({type: NotificationType.LIQUIDITY_ERROR, message: props.labels.ASSET.ERROR_MESSAGE}));
        } else {
          dispatch(removeErrorMessage(NotificationType.LIQUIDITY_ERROR));
        }
      }, [assets,props.labels]);
    
      useEffect(() => {
        const liabilityError = liabilityTypes.find(l => liabilityHasError(l.Type));
        if (liabilityError) {
          trackEvent({ category: trackEventCategories.I_HAVE, action: trackEventActions.VALIDATION, name: trackEventNames.LIABILITY_ERROR });
          dispatch(addErrorMessage({type: NotificationType.MORTGAGE_ERROR, message: liabilityError?.ERROR_MESSAGE}));
        } else {
          dispatch(removeErrorMessage(NotificationType.MORTGAGE_ERROR));
        }
      }, [assets, liabilities,props.labels]);

    const contextValue = {
        children: props.children,
        labels: props.labels,
        tabOrder: props.tabOrder,
        onSwiperContainerKeyUp: onSwiperContainerKeyUp,
        swiperContainerRef: swiperContainerRef,
        setSwiperInstance: setSwiperInstance,
        swiperInstance: swiperInstance,
        onRequiresUpdate: props.onRequiresUpdate,

        liabilityTypes,
        shouldRenderLiability,
        isAssetVisible,
        liabilityHasError,
        getLiabilityStateValue,
        getAssetIconHidden,
        getLiabilityChangedFunction,
        currencyConfig,
        getLiabilityBlurFunction,
        getAssetStateValue,
        getAssetsChangedFunction,
        getAssetsBlurFunction,

        iHaveIcon,
        assetTypes,
        tabIndex,

        cashIcon: cashIcon,
        bondsIcon: bondsIcon,
        equityIcon: equityIcon,
        realestateIcon: realestateIcon,
        mortgageIcon: mortgageIcon,
    };

    return (
        <AssetsContext.Provider value={contextValue}>{props.children}</AssetsContext.Provider>
      );
}