import { defaultCurrencyInputFormatter } from '../../../common/shared/locale';
import { CurrencyConfig, MoneyInput } from '@flexfront/ui/react';
import { useEffect, useState } from 'react';
import { YearRangeInputComponent } from './year-range-input-component';

export interface MoneyInYearRangeEditComponentProps {
  yearLabel: string;
  amountLabel: string;
  fromYear: number;
  toYear: number;
  amount: number;
  stepValue?: number;
  hasError?: boolean;
  minYear: number;
  maxYear: number;
  itemConfirmLabel?: string;
  onRequiresUpdate?: (fromYear: number, toYear: number, value: number) => void;
  onValidate?:(hasError: boolean) => void;
  currencyConfig?: CurrencyConfig;
}

export function MoneyInYearRangeEditComponent(props: MoneyInYearRangeEditComponentProps) {
  const [fromYear, setFromYear] = useState<number>(props.fromYear);
  const [toYear, setToYear] = useState<number>(props.toYear);
  const [amount, setAmount] = useState<number>(props.amount);

  function onYearRangeChange(newFromYear: number, newToYear: number) {
    if (newFromYear !== fromYear) {
      setFromYear(newFromYear);
    }

    if (newToYear !== toYear) {
      setToYear(newToYear);
    }
  }

  function onAmountChange(newAmount: number) {
    setAmount(newAmount);
  }

  useEffect(() => {
    setFromYear(props.minYear);
    setToYear(props.maxYear); 
  }, [props.minYear, props.maxYear]);

  function saveValue(){
    if (props.onRequiresUpdate) {
      props.onRequiresUpdate(fromYear, toYear, amount);
    }
  }

  return (
    <>
      <div className="edit-container-field edit-container-field-label">{props.yearLabel}</div>
      <YearRangeInputComponent
        fromYear={fromYear}
        toYear={toYear}
        hasError={props.hasError}
        minYear={props.minYear}
        maxYear={props.maxYear}
        onRangeChanged={onYearRangeChange}
        onValidate={props.onValidate}
      />
      <div className="money-input-container">
        <div
          className={`money-input-label ${props.hasError ? 'money-input-label-error': ''}`}>
            {props.amountLabel}
        </div>
      </div>
      <MoneyInput
        inputNumber={amount}
        currencyConfig={props.currencyConfig}
        currencyFormatter={defaultCurrencyInputFormatter}
        showCurrencySymbol={true}
        groupedClassNameSuffix="border"
        showIncrementAndDecrement={true}
        stepValue={props.stepValue ? props.stepValue : 1}
        onChange={onAmountChange}
        maxValue={9999999}
      />
      {props.itemConfirmLabel && <button
          onClick={saveValue}
          className='button button--big button--big--solid edit-container-item-confirm'>
            {props.itemConfirmLabel}
        </button>}
    </>
  );
}
