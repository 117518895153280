import { useGoalsContext } from "../../../common/providers/goals-provider";
import { getCurrencyFormattedAmount } from "../../../common/shared/locale";
import { useAppSelector } from "../../../common/store/hooks";
import { selectSimulationState } from "../../../common/store/simulation/simulation.slice";

export function IWantReportComponent() {

    const simulation = useAppSelector(selectSimulationState);

    const {
        labels,
        currencyConfig,
    
        retirement,
        retirementIcon,
    
        isLegacyHidden,
        legacy,
    
        isDreamHidden,
        dream,
        dreamIcon,
    
        isPreserveCapitalHidden,
        preserveCapital,
    
        isSecureFamilyHidden,
        secureFamily,
    
      } = useGoalsContext();

    function getAchievementPercentage(value?: number): string {
        return Math.round((value ?? 0) * 100) + '%';
    }

    return <div className="report-table">

            {/* Headers */}

            <div className="report-row">

                <div className="report-cell report-cell--header report-cell--title">
                    <label>{labels.TITLE}</label>
                </div>

                <div className="report-cell report-cell--header report-cell--value">
                    <label>{labels.AMOUNT}</label>
                </div>

                <div className="report-cell report-cell--header report-cell--value">
                    <label>{labels.ACHIEVEMENT}</label>
                </div>

                <div className="report-cell report-cell--header report-cell--value">
                    <label>{labels.GAP}</label>
                </div>

            </div>

            {/* Rows */}
            {/* Retirement */}

            <div className="report-row">

                <div className="report-cell">
                    <span
                        className={`report-cell__i-want retirement ${retirementIcon ? '': 'icon-pension'}`}
                        dangerouslySetInnerHTML={{ __html: retirementIcon ?? '' }}></span>
                    {labels.GOALS.RETIREMENT.TITLE}
                </div>

                <div className="report-cell report-cell--value">
                    {getCurrencyFormattedAmount(retirement.retirementMonthlyPayout, currencyConfig)}
                </div>

                <div className="report-cell report-cell--value">
                    {getAchievementPercentage(simulation.retirementResult.achievementFraction)}
                </div>

                <div className="report-cell report-cell--value">
                    {getCurrencyFormattedAmount(Math.max(0, simulation.retirementResult.shortfall), currencyConfig)}
                </div>
            </div>

            {/* Legacy */}

            {!isLegacyHidden && <div className="report-row">

                <div className="report-cell">
                    <span className='report-cell__i-want legacy icon-legacy'></span>
                    {labels.GOALS.LEGACY.TITLE}
                </div>

                <div className="report-cell report-cell--value">
                    {getCurrencyFormattedAmount(legacy.amount, currencyConfig)}
                </div>

                <div className="report-cell report-cell--value">
                    {getAchievementPercentage(simulation.legacyResult.achievementFraction)}
                </div>

                <div className="report-cell report-cell--value">
                    {getCurrencyFormattedAmount(simulation.legacyResult.shortfall, currencyConfig)}
                </div>
            </div>}

            {/* Dream */}

            {!isDreamHidden && <div className="report-row">

                <div className="report-cell">
                    <span
                        className={`report-cell__i-want dream ${dreamIcon ? '': 'icon-dream'}`}
                        dangerouslySetInnerHTML={{ __html: dreamIcon ?? '' }}></span>
                    {labels.GOALS.GOAL.TITLE}
                </div>

                <div className="report-cell report-cell--value">
                    {getCurrencyFormattedAmount(dream.amount, currencyConfig)}
                </div>

                <div className="report-cell report-cell--value">
                    {getAchievementPercentage(simulation.dreamResult.achievementFraction)}
                </div>

                <div className="report-cell report-cell--value">
                    {getCurrencyFormattedAmount(simulation.dreamResult.shortfall, currencyConfig)}
                </div>
            </div>}

            {/* Preservation */}

            {!isPreserveCapitalHidden && <div className="report-row">

                <div className="report-cell">
                    <span className='report-cell__i-want preserve-capital icon-piggy-bank'></span>
                    {labels.GOALS.PRESERVE_CAPITAL.TITLE}
                </div>

                <div className="report-cell report-cell--value">
                    {getCurrencyFormattedAmount(preserveCapital.amount, currencyConfig)}
                </div>

                <div className="report-cell report-cell--value">
                    {getAchievementPercentage(simulation.preserveCapitalResult.achievementFraction)}
                </div>

                <div className="report-cell report-cell--value">
                    {getCurrencyFormattedAmount(simulation.preserveCapitalResult.shortfall, currencyConfig)}
                </div>
            </div>}

            {/* Secure Family */}

            {!isSecureFamilyHidden && <div className="report-row">

                <div className="report-cell">
                    <span className='report-cell__i-want secure-family icon-secure-family'></span>
                    {labels.GOALS.SECURE_FAMILY.TITLE}
                </div>

                <div className="report-cell report-cell--value">
                    {getCurrencyFormattedAmount(secureFamily.amount, currencyConfig)}
                </div>

                <div className="report-cell report-cell--value">
                    {getAchievementPercentage(simulation.secureFamilyResult.achievementFraction)}
                </div>

                <div className="report-cell report-cell--value">
                    {getCurrencyFormattedAmount(simulation.secureFamilyResult.shortfall, currencyConfig)}
                </div>
            </div>}

    </div>;
}