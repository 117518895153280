import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { revertAll } from "../hooks";
import { GoalsSliderIndex } from "./goalsSliderIndex.state";

export const GOALS_SLIDER_INDEX_FEATURE_KEY = "goalsSliderIndex";

export const initialState: GoalsSliderIndex = {
    activeSlideindex: 0
};

export const goalsSliderSlice = createSlice({
  name: GOALS_SLIDER_INDEX_FEATURE_KEY,
  initialState,
  reducers: {
    saveGoalsSliderIndex: (state, action: PayloadAction<GoalsSliderIndex>) => {
      state = { ...action.payload };
      return state;
    },
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const { saveGoalsSliderIndex } = goalsSliderSlice.actions;

export const selectGoalsSlideIndexState = createSelector(
  (state: RootState) => state.goalsSlideIndex,
  (slideIndex) => slideIndex
);

export default goalsSliderSlice;
