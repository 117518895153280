import { useEffect } from "react";
import { useNudgeContext } from "../../../common/providers/nudge-provider";
import './nudge-component.scss';

export function NudgeComponent() {

  const {
    labels,
    className,
    simulation,
    shortfallElimination,
    nudgeText,
    isNudgeVisible,
    needsShortfallElimination,
    hasGoals,
    onApplySuggestions,
    onDiscardSuggestions,
    setIsNudgeVisible,
    currencyConfig,
    assets,
    getSavingsText,
    getGoalText,
    getMaxPensionText,
    setNudgeText,
    hasSavingsAndShortfall,
    setNewDream,
    setNewRetirement,
    setNewLegacy,
    setNewSecureFamily,
    setNewPreserveCapital,
    setNewSavings,
    setNewPension,
    onHighGoalAchievementClose,
    isInsideIframe,
    isHorizontallyCompact
  } = useNudgeContext();

  useEffect(() => {
    let newNudgeText: string | undefined;
    if (!simulation.isLoading && !shortfallElimination.isShortfallLoading) {
      setNewRetirement(undefined);
      setNewLegacy(undefined);
      setNewDream(undefined);
      setNewSecureFamily(undefined);
      setNewPreserveCapital(undefined);
      setNewSavings(undefined);
      setNewPension(undefined);

      if (hasSavingsAndShortfall()) {
        newNudgeText = getSavingsText();
      } else if (hasGoals()) {
        newNudgeText = getGoalText();
      }

      if (!newNudgeText) {
        newNudgeText = getMaxPensionText();
      }
    }
    setNudgeText(newNudgeText); 

    if (newNudgeText) {
      setIsNudgeVisible(true);

      if (isInsideIframe && isHorizontallyCompact) {
        setTimeout(() => {
          const element = document.getElementById('nudge');
          element?.classList.add('transform');
        }, 100);
      }
    }

    const hasValidAssets = (assets.cash > 0 || assets.bonds > 0 || assets.equity > 0);
    setIsNudgeVisible(hasValidAssets);

  }, [
    shortfallElimination.shortfallEliminationFactor,
    shortfallElimination.shortfallEliminationSavings,
    simulation.isLoading,
    shortfallElimination.isShortfallLoading,
    labels.RETIREMENT_GOAL,
    currencyConfig]);

  if (needsShortfallElimination() && !simulation.isLoading && !shortfallElimination.isShortfallLoading && nudgeText && isNudgeVisible) {
    return (
      <div id="nudge"  className={`card card--small nudge ` + className + ` ${isInsideIframe ? `nudge-iframe` : ``}`  }>
        <div className="nudge-content">
          <span className="nudge-close button-close icon-times" onClick={onDiscardSuggestions} />
          <label dangerouslySetInnerHTML={{ __html: nudgeText }}></label>
        </div>
        <button className="button button--card-solid nudge-apply" onClick={onApplySuggestions}>
          {labels.APPLY}
        </button>
      </div>
    );
  }

  if(!needsShortfallElimination() && !simulation.isLoading && !shortfallElimination.isShortfallLoading && hasGoals() && isNudgeVisible){
    return (
      <div id="nudge" className={`card card--small nudge ` + className + ` ${isInsideIframe ? `nudge-iframe` : ``}` }>
        <div className="nudge-content">
          <span className="nudge-close button-close icon-times" onClick={onHighGoalAchievementClose} />
          <label dangerouslySetInnerHTML={{ __html: labels.GOALS_ACHIEVEMENT_LABEL}}></label>
        </div>
        <div className="button button--card-solid nudge-apply" onClick={onHighGoalAchievementClose}
            dangerouslySetInnerHTML={{ __html: labels.CLOSE }}>
        </div>
      </div>
    );
  }

  return <></>;
}

export default NudgeComponent;