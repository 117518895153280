import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { environment } from "../../../../environments/environment";
import { RootState } from "../store";
import { Layout, LayoutsState } from "./layouts.state";

export const LAYOUTS_FEATURE_KEY = "layouts";

const initialState: LayoutsState = {
    layouts: [{layoutId: 1, name: "Default",primaryFontType: "",
    secondaryFontType: "",maxAchievementFraction: 0.625}]
};

export const layoutsSlice = createSlice({
    name: LAYOUTS_FEATURE_KEY,
    initialState,
    reducers: {
      setLayouts: (state, action: PayloadAction<Layout[]>) => {
        state = {...state, layouts: action.payload};
        return state;
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchLayouts.pending, (state) => {
          return state;
        })
        .addCase(fetchLayouts.fulfilled, (state) => {
          return state;
        });
    },
  });

export const fetchLayouts = createAsyncThunk<void, AbortSignal>(
  "layouts/fetchLayouts",
  async (abortSignal, thunkApi) => {
    const configUrl = `${environment.apiBaseUrl}/layouts`;
    return fetch(configUrl, {
      signal: abortSignal
    }).then((result) => {
      if (result.ok) {
        result.json().then((json) => {
          thunkApi.dispatch(setLayouts(json as Layout[]));
        });
      }
    });
  }
);

export const { setLayouts } = layoutsSlice.actions;

export const selectLayouts = createSelector(
  (state: RootState) => state.layouts,
  (layouts) => layouts
);

export default layoutsSlice;
