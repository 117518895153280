import { useState } from "react";
import ClimateScenarioComponent from "../climate-scenario-component/climate-scenario-component";
import { ClimateScenarioComponentLabels } from "../../../common/labels/climate-scenario-component.labels";
import { saveScenario, selectClimateChangeState } from "../../../common/store/climate-change/climate.slice";
import { useAppDispatch, useAppSelector } from "../../../common/store/hooks";
import "./climate-scenario-menu-component.scss";
import { ClimateScenarioIconCardComponent } from "../icon-card-component/Climate-scenario-icon-card-component";
import { getClimateScenarioOptions } from "../climate-scenario-component/climate-scenario-common";

export interface ClimateScenarioMenuComponentProps {
    labels: ClimateScenarioComponentLabels;
    onRequiresUpdate?: () => void;
    onClose?: () => void;
}

export function ClimateScenarioMenuComponent(props: ClimateScenarioMenuComponentProps) {
    const climateChange = useAppSelector(selectClimateChangeState);
    const dispatch = useAppDispatch();
    const [selectedClimateScenario, setSelectedClimateScenario] = useState<string | undefined>(climateChange.scenario);
    const climateScenarioOptions = getClimateScenarioOptions(props.labels);

    const onClimateScenarioSelected = (climateScenario: string) => {
        setSelectedClimateScenario(climateScenario);
    }

    const onApply = () => {
        if (selectedClimateScenario !== climateChange.scenario) {
            dispatch(saveScenario(selectedClimateScenario));
            onClose();
            if (props.onRequiresUpdate) {
                props.onRequiresUpdate();
            }
        }
    }

    const onClose = () => {
        if(props.onClose)
        {
          props.onClose();
        }
  }
    
    return <div className="climate-scenario-menu-component">
                <span className="climate-scenario-menu-component-desc" dangerouslySetInnerHTML={{ __html: props.labels.DESC }}></span>
                <span className="climate-scenario-menu-component-desc">{props.labels.SELECTION}</span>
                <div className="climate-scenario-menu-component-cards">
                        {
                        climateScenarioOptions.map((c,i)=> {
                            return (
                            <ClimateScenarioIconCardComponent
                                    key={i}
                                    labels={props.labels} 
                                    selectedClimateScenario={selectedClimateScenario} 
                                    climateScenario={c.value} 
                                    onClimateScenarioSelected={onClimateScenarioSelected} />
                            )
                        })
                        }
                </div>
                {selectedClimateScenario!=undefined ?
                <div className="climate-scenario-menu-component-desc" dangerouslySetInnerHTML={{ __html: props.labels.SCENARIOS[selectedClimateScenario].DESC}}></div>
                : ''}               
        
            <div className="climate-scenario-menu-component--buttons">
                <button className="button button--big button--big--solid" onClick={onApply}>
                    {props.labels.SAVE}
                </button>
                <button className="button button--big" onClick={onClose}>
                    {props.labels.CANCEL}
                </button>
            </div>
        </div>;
}