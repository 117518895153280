import { ReactNode } from "react";

export interface FlyoutMenuItemComponentProps {
    showNavigation?: boolean;
    title?: string;
    showContent?: boolean;
    content?: ReactNode;
    nestedContent?: ReactNode;
}

export function FlyoutMenuItemComponent(props: FlyoutMenuItemComponentProps) {
    return <div className="flyout-menu-item">
        {props.showContent ? (props.content ?? props.title) : (props.title ?? props.content)}
        {props.nestedContent && <span
            className={`flyout-menu-item-open ${!props.showNavigation ? 'icon-chevron-right' : ''}`}>
        </span>}
    </div>
}