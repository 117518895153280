import { useParams } from "react-router-dom";
import "./iframe-component.scss";
import { useEffect, useRef, useState } from "react";

export function IFrameComponent() {

    const { name } = useParams();

    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [iFrameWidth, setIframeWidth] = useState<number | undefined>();
    const [iFrameHeight, setIframeHeight] = useState<number | undefined>();

    const updateIframeDimensions = () => {
      const iframe = iframeRef.current;
      if (iframe) {
        const { width, height } = iframe.getBoundingClientRect();
        setIframeWidth(width - 4);
        setIframeHeight(height - 4);
      }
    };

    useEffect(() => {
      window.addEventListener('resize', updateIframeDimensions);
      return () => {
        window.removeEventListener('resize', updateIframeDimensions);
      };
    }, []);

    return <div className="iframe-container">
        <div className="iframe-dimensions">
          {`width: ${iFrameWidth}px; height: ${iFrameHeight}px; box-sizing: 'content-box'; `}
        </div>
        <iframe
          ref={iframeRef}
          onLoad={updateIframeDimensions}
          className="iframe"
          title="iframe"
          src={`/${name}`}>
        </iframe>
    </div>;
}