import { WealthSection } from "@flexfront/models";
import { getQuarterDate } from "@flexfront/ui/react";

export function getIlliquidSeries(
  illiquid: WealthSection,
  useRange: boolean,
  isHorizontallyCompact: boolean,
  animation?: boolean
): Highcharts.SeriesOptionsType[] {
  const hasValues =
    illiquid.percentile50.filter((value) => value > 0).length > 0;

    if (!hasValues) {
      return [];
    }

  const date = getQuarterDate(new Date());
  const pointStart = Date.UTC(date.getFullYear(), date.getMonth(), 1);

  if (useRange) {
    const goodRangeData = [];
    const adverseRangeData = [];
    const expectedRangeData = [
      {
        y: 0,
        x: pointStart
      },
      ...illiquid.percentile50,
    ];

    for (let index = 0; index < illiquid.percentile50.length - 1; index++) {
      date.setMonth(date.getMonth() + 3);
      goodRangeData.push([
        Date.UTC(date.getFullYear(), date.getMonth(), 1),
        illiquid.percentile50[index],
        illiquid.percentile75[index],
      ]);
      adverseRangeData.push([
        Date.UTC(date.getFullYear(), date.getMonth(), 1),
        illiquid.percentile5[index],
        illiquid.percentile50[index],
      ]);
    }

    return [
      {
        id: "illiquidExpectedSeries",
        animation: animation,
        name: "illiquidExpectedSeries",
        className: "assets-expected-series",
        data: isHorizontallyCompact? [...illiquid.percentile50] :  expectedRangeData,
        pointStart: pointStart,
        zIndex: 4,
        marker: {
          enabled: false,
          symbol: "square"
        },
        type: "line",
        pointIntervalUnit: "month",
        pointInterval: 3,
        dashStyle: "Dash",
        color: "var(--accent-asset)",
      },
      {
        id: "illiquidGoodRangeSeries",
        animation: animation,
        name: "illiquidGoodRangeSeries",
        className: "assets-good-range-series",
        data: goodRangeData,
        pointIntervalUnit: "month",
        pointInterval: 3,
        pointStart: pointStart,
        type: "arearange",
        lineWidth: 0,
        lineColor: "transparent",
        linkedTo: "illiquidExpectedSeries",
        zIndex: 3,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false
            }
          }
        },
      },
      {
        id: "illiquidAdverseRangeSeries",
        animation: animation,
        name: "illiquidAdverseRangeSeries",
        className: "assets-adverse-range-series",
        data: adverseRangeData,
        pointIntervalUnit: "month",
        pointInterval: 3,
        pointStart: pointStart,
        type: "arearange",
        lineWidth: 0,
        lineColor: "transparent",
        linkedTo: "illiquidExpectedSeries",
        zIndex: 3,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false
            }
          }
        },
      },
    ];
  } else {
    return [
      {
        id: "illiquidColumnSeries",
        name: "illiquidColumnSeries",
        type: "column",
        data: [
          {
            x: pointStart,
            y: 0
          },
        ],
        pointWidth: 60,
        color: "var(--accent-asset)",
        pointPlacement: "between",
        zIndex: 3,
        pointStart: pointStart,
      },
    ];
  }
}
