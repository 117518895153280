import { CtaComponentLabels } from "../../../common/labels/cta-component.labels";
import { useComponentVisibility } from "../../../common/hooks/useComponentVisibility";

export interface CtaEditComponentProps {
    labels: CtaComponentLabels;
    onCtaSelected?: () => void;
}

export function CtaEditComponent(props: CtaEditComponentProps) {
  const isCtaHidden = useComponentVisibility("CTA");
  
  const handleOnCtaSelected = ()  => {
    if (props.onCtaSelected) {
      props.onCtaSelected();
    }
  }
  
  return !isCtaHidden ? 
    <button
      type="button"
      onClick={() => handleOnCtaSelected()}
      className="button cta-button">
      {props.labels.PERSONA.TITLE}
    </button>
    : <a
      type="button"
      href={props.labels.SUBMIT_LOCATION}
      target='_blank'
      className="button cta-button"
      rel="noreferrer">
          {props.labels.PERSONA.TITLE}
      </a>;
}
