import { useEffect } from "react";
import { useNudgeContext } from "../../../common/providers/nudge-provider";
import './nudge-component.scss';

export function NudgeComponent() {
  
  const {
    labels,
    className,
    simulation,
    shortfallElimination,
    nudgeText,
    isNudgeVisible,
    needsShortfallElimination,
    hasGoals,
    setIsNudgeVisible,
    currencyConfig,
    assets,
    getSavingsTextWithoutShortfallElimination,
    getGoalTextWithoutshortfallElimination,
    setNudgeText,
    hasSavingsAndShortfall,
    tenantConfig,
    onDiscardSuggestions,
    onHighGoalAchievementClose,
    isInsideIframe,
    isHorizontallyCompact
  } = useNudgeContext();
  
  const classNamePrefix = 'quirion';

  useEffect(() => {
    let newNudgeText: string | undefined;
    if (!simulation.isLoading && !shortfallElimination.isShortfallLoading) {

      if (hasSavingsAndShortfall()) {
        newNudgeText = getSavingsTextWithoutShortfallElimination();
      } else if (hasGoals()) {
        newNudgeText = getGoalTextWithoutshortfallElimination();
      }
    }

    setNudgeText(newNudgeText); 

    if (newNudgeText) {
      setIsNudgeVisible(true);
      if (isInsideIframe && isHorizontallyCompact) {
        setTimeout(() => {
          const element = document.getElementById('nudge');
          element?.classList.add('transform');
        }, 100);
      }
    }

    const hasValidAssets = (assets.cash > 0 || assets.bonds > 0 || assets.equity > 0);
    setIsNudgeVisible(hasValidAssets);

  }, [simulation,labels.RETIREMENT_GOAL,currencyConfig,tenantConfig.layout]);

  if (needsShortfallElimination() && !simulation.isLoading && !shortfallElimination.isShortfallLoading && nudgeText && isNudgeVisible) {
    return (
      <div id="nudge" className={`card card--small ${classNamePrefix}-nudge ` + className  + ` ${isInsideIframe ? `${classNamePrefix}-nudge-iframe` : ``}` } >
        <div className={`${classNamePrefix}-nudge-content`}>
          <span className={`${classNamePrefix}-nudge-close icon-times`} onClick={onDiscardSuggestions} />
          <label dangerouslySetInnerHTML={{ __html: nudgeText }}></label>
        </div>
        <button className={`button cta-button quirion-button button--card-solid ${classNamePrefix}-nudge-apply`}
          onClick={onDiscardSuggestions}>
          {labels.OK}
        </button>
      </div>
    );
  }

  if(!needsShortfallElimination() && !simulation.isLoading && !shortfallElimination.isShortfallLoading && hasGoals() && isNudgeVisible) {
    return (
      <div  id="nudge"  className={`card card--small ${classNamePrefix}-nudge ` + className + ` ${isInsideIframe ? `${classNamePrefix}-nudge-iframe` : ``}` } >
        <div className={`${classNamePrefix}-nudge-content`}>
          <span className={`${classNamePrefix}-nudge-close icon-times`} onClick={onHighGoalAchievementClose} />
          <label dangerouslySetInnerHTML={{ __html: labels.GOALS_ACHIEVEMENT_LABEL}}></label>
        </div>
        <div className={`${classNamePrefix}-nudge-footer`} onClick={onHighGoalAchievementClose}
            dangerouslySetInnerHTML={{ __html: labels.CLOSE }}>
        </div>
      </div>
    );
  }

  return <></>;
}

export default NudgeComponent;