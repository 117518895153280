import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, useParams } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './app/app';
import store, { persistor } from './app/common/store/store';
import { environment } from './environments/environment';
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react';

const matomoInstance = createInstance({
  urlBase: environment.matomoUrl,
  siteId: environment.matomoSiteId,
  linkTracking: false
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persistor}>
        <BrowserRouter>
          <MatomoProvider value={matomoInstance}>
            <App />
          </MatomoProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </StrictMode>
);
