import React, { ReactNode, useState } from 'react';
import "./expand-collapse-component.scss";

export interface ExpandCollapseComponentProps
{
  title:string;
  content:ReactNode;
}

export function ExpandCollapse(props: ExpandCollapseComponentProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpandCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="expand-collapse">
      <div className="expand-collapse-header" onClick={toggleExpandCollapse}>
        <span className={isExpanded ? 'icon-up-arrow' : 'icon-down-arrow'}></span>
        <h4>{props.title}</h4>
      </div>
      {isExpanded && <div className="expand-collapse-content">{props.content}</div>}
    </div>
  );
}

export default ExpandCollapse;