import React, { ReactElement } from "react";
import { ReactNode } from "react";

export interface CustomGridLoaderComponentProps {
    children: ReactNode;
    className?: string;
    isLoading?: boolean;
}

export function CustomLoaderComponent({children, className, isLoading}: CustomGridLoaderComponentProps) {

    const loaderColor = getComputedStyle(document.documentElement).getPropertyValue('--secondary-0');

    const childWithProps = React.cloneElement(children as ReactElement, {
        color: loaderColor
      });
      
    return childWithProps;
}