import { CurrencyConfig } from "@flexfront/ui/react";
import { getYAxisLabelFormatter } from "./getYAxisLabelFormatter";
import '../../../../styles/theme.scss';


export function getInitialChartOptions(
  isHorizontallyCompact: boolean,
  showTooltip: boolean,
  currencyConfig?: CurrencyConfig,): Highcharts.Options {
    
  return {
    accessibility: {
      enabled: false,
    },
    chart: {
      marginTop: 8,
      backgroundColor: "var(--chart-background-color)",
      style: {
        fontSize: "14px",
      },
      spacing: [0, 0, 0, 18],
      reflow: true
    },
    title: {
      text: undefined,
    },
    xAxis: [{
      title: {
        text: null,
      },
      type: "datetime",
      lineWidth: 1,
      minorGridLineWidth: 0,
      labels: {
        enabled: true
      },
      minorTickLength: 0,
      tickLength: 0,
      minPadding: 0,
      maxPadding: 0,
      crosshair:showTooltip
    }],
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        formatter: getYAxisLabelFormatter(isHorizontallyCompact, currencyConfig),
        align: 'left'
      },
      gridLineWidth: 0,
      minPadding: 0,
      maxPadding: 0,
      tickAmount: isHorizontallyCompact ? 3 : undefined,
      softMin: 0,
      opposite: false,
      offset: 32
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip:{
      enabled:showTooltip,
    },
    series: []
  };
}
