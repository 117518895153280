import { useCallback, useEffect } from "react";
import { useAppSelector } from "../store/hooks";
import { selectTenantConfigColors } from "../store/tenant-config/tenant-config.slice";

export const useTenantColors = () => {
    const tenantConfigColors = useAppSelector(selectTenantConfigColors);

    const getColorValue = useCallback((key: string): string => {
        return tenantConfigColors.has(key)
        ? tenantConfigColors.get(key) ?? ""
        : "";
    }, [tenantConfigColors]);

    useEffect(() => {
        document.documentElement.style.setProperty('--white', getColorValue("white"));
        document.documentElement.style.setProperty('--black', getColorValue("black"));
        document.documentElement.style.setProperty('--primary-input', getColorValue("primaryInput"));
        document.documentElement.style.setProperty('--primary-retirement-plotband', getColorValue("primaryRetirementPlotband"));
        document.documentElement.style.setProperty('--primary-widget-0', getColorValue("primaryWidget0"));
        document.documentElement.style.setProperty('--primary-widget-1', getColorValue("primaryWidget1"));
        document.documentElement.style.setProperty('--primary-background', getColorValue("backgroundForMobile"));
        document.documentElement.style.setProperty('--secondary-background', getColorValue("secondaryBackgroundForMobile"));
        document.documentElement.style.setProperty('--primary-button', getColorValue("primaryButton"));
        document.documentElement.style.setProperty('--secondary-0', getColorValue("secondary0"));
        document.documentElement.style.setProperty('--secondary-1', getColorValue("secondary1"));
        document.documentElement.style.setProperty('--accent-input', getColorValue("accentInput"));
        document.documentElement.style.setProperty('--accent-asset', getColorValue("accentAsset"));
        document.documentElement.style.setProperty('--accent-snapshot', getColorValue("accentSnapshot"));
        document.documentElement.style.setProperty('--accent-goal', getColorValue("chartIcons"));
        document.documentElement.style.setProperty('--accent-legacy', getColorValue("legacyIcons"));
        document.documentElement.style.setProperty('--accent-dream', getColorValue("dreamIcons"));
        document.documentElement.style.setProperty('--accent-secure-family', getColorValue("secureMyFamilyIcons"));
        document.documentElement.style.setProperty('--accent-preserve-capital', getColorValue("capitalPreservationIcons"));
        document.documentElement.style.setProperty('--warn', getColorValue("warn"));
        document.documentElement.style.setProperty('--header', getColorValue("header"));
        document.documentElement.style.setProperty('--primary-border-0', getColorValue("primaryBorder0"));
        document.documentElement.style.setProperty('--secoandary-border-1', getColorValue("secondaryBorder1"));
        document.documentElement.style.setProperty('--secondary-button', getColorValue("secondaryButton"));
        document.documentElement.style.setProperty('--email-background', getColorValue("emailBackground"));
        document.documentElement.style.setProperty('--inform', getColorValue("inform"));
        document.documentElement.style.setProperty('--positive', getColorValue("positive"));
        document.documentElement.style.setProperty('--landing-page-text', getColorValue("landingPageText"));
        document.documentElement.style.setProperty('--primary-button-text', getColorValue("primaryButtonText"));
        document.documentElement.style.setProperty('--secondary-button-text', getColorValue("secondaryButtonText"));
        document.documentElement.style.setProperty('--circular-chat', getColorValue("circularChartArea"));
        document.documentElement.style.setProperty('--circular-chat-background', getColorValue("circularChartComponentBackground"));
        document.documentElement.style.setProperty('--icard-type-background', getColorValue("icardBackground"));
        document.documentElement.style.setProperty('--menu-background', getColorValue("menuBackground"));
        document.documentElement.style.setProperty('--info-icon-border', getColorValue("infoIconBorder"));
        document.documentElement.style.setProperty('--info-icon-background', getColorValue("infoIconBackground"));
        document.documentElement.style.setProperty('--slider-dots-background', getColorValue("sliderDotsBackground"));
        document.documentElement.style.setProperty('--header-text', getColorValue("headerText"));
        document.documentElement.style.setProperty('--dropdown-text', getColorValue("dropdownText"));
        document.documentElement.style.setProperty('--menu-text', getColorValue("menuText"));
        document.documentElement.style.setProperty('--legend-text', getColorValue("legendText"));
        document.documentElement.style.setProperty('--accent-leverage', getColorValue("accentLeverage"));
        document.documentElement.style.setProperty('--nudge-border', getColorValue("nudgeBorder"));
        document.documentElement.style.setProperty('--legend-border', getColorValue("legendBorder"));
        document.documentElement.style.setProperty('--menu-border', getColorValue("menuBorder"));
        document.documentElement.style.setProperty('--menu-icon', getColorValue("menuIcon"));
        document.documentElement.style.setProperty('--deactive-tab-background', getColorValue("deactiveTabBackground"));
        document.documentElement.style.setProperty('--deactive-tab-text-color', getColorValue("deactiveTabText"));
        document.documentElement.style.setProperty('--edit-window-background', getColorValue("editWindowBackground"));
        document.documentElement.style.setProperty('--label-color', getColorValue("labelColor"));
        document.documentElement.style.setProperty('--header-text-1', getColorValue("headerText1"));
        document.documentElement.style.setProperty('--header-icon-color', getColorValue("headerIcon"));
        document.documentElement.style.setProperty('--static-cta-color',getColorValue("staticCtaColor"));
        document.documentElement.style.setProperty('--app-background-color',getColorValue("appBackground"));
        document.documentElement.style.setProperty('--nudge-background-color',getColorValue("nudgeBackground"));
        document.documentElement.style.setProperty('--disclaimer-text-color',getColorValue("disclaimerText"));
        document.documentElement.style.setProperty('--chart-background-color',getColorValue("chartBackground"));
        document.documentElement.style.setProperty('--disable-input-background-color',getColorValue("disableInputBackgroung"));
        document.documentElement.style.setProperty('--download-button-text',getColorValue("downloadButtonText"));
        document.documentElement.style.setProperty('--download-button-border-color',getColorValue("downloadButtonBorder"));
        document.documentElement.style.setProperty('--download-button-background-color',getColorValue("downloadButtonBackground"));
    }, [getColorValue]);
}