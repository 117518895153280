import { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { selectDebugState } from '../../store/debug/debug.slice';
import { useAppSelector } from '../../store/hooks';
import './debug-component.scss';

/* eslint-disable-next-line */
export interface DebugComponentProps {}

export function DebugComponent(props: DebugComponentProps) {
  const debug = useAppSelector(selectDebugState);
  const [hasRequest, setHasRequest] = useState<boolean>(false);
  const [hasResponse, setHasResponse] = useState<boolean>(false);
  const [isDebugPopupOpen, setIsDebugPopupOpen] = useState<boolean>(false);
  const [debugContent, setDebugContent] = useState<string>();

  function showRequest() {
    setDebugContent(JSON.stringify(debug.request, null, 2));
    setIsDebugPopupOpen(true);
  }

  function showResponse() {
    setDebugContent(JSON.stringify(debug.response, null, 2));
    setIsDebugPopupOpen(true);
  }

  function closeDebug() {
    setIsDebugPopupOpen(false);
    setDebugContent(undefined);
  }

  function copyDebugToClipboard() {
    if (debugContent) {
      navigator.clipboard.writeText(debugContent);
      alert('Copied to clipboard.');
    }
  }

  useEffect(() => {
    setHasRequest(debug.includeDebug && debug.request);
    setHasResponse(debug.includeDebug && debug.response);
  }, [debug]);

  return (
    <>
      {debug.includeDebug && (
        <div className="debug-button-bar">
          {hasRequest && (
            <button className="debug-button" onClick={showRequest}>
              Request
            </button>
          )}
          {hasResponse && (
            <button className="debug-button" onClick={showResponse}>
              Response
            </button>
          )}
        </div>
      )}
      <Popup
        open={isDebugPopupOpen}
        onClose={closeDebug}
        position="center center"
        arrow={false}
        modal={true}
      >
        <div className="debug-popup">
          <h4>JSON</h4>
          <pre>{debugContent}</pre>
          <button className="button button--card-solid" onClick={closeDebug}>
            Close
          </button>
          <button className="button button--card-solid" onClick={copyDebugToClipboard}>
            Copy to clipboard
          </button>
        </div>
      </Popup>
    </>
  );
}

export default DebugComponent;
