import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ErrorBoundaryComponent } from "@flexfront/ui/react";
import { AssetType, GoalType, LiabilityType, SavingType } from "@flexfront/models";
import { CtaType, fromCtaAndPersonaLabels } from "../../../common/labels/cta-component.labels";
import { useDashboardContext } from '../../../common/providers/dashboard-provider';
import { useAppDispatch } from "../../../common/store/hooks";
import { fromIWantLabels } from "../../../common/labels/i-want-list-component-labels";
import { removeErrorMessage, removeInfoMessage } from "../../../common/store/notifications/notifications.slice";
import { fromDashboardAndFlyoutMenuLabels } from "../../../common/labels/flyout-menu-component.labels";
import IHaveListComponent from "../../../default/components/i-have-list-component/i-have-list-component";
import { NotificationComponent } from "../../../default/components/notification-component/notification.component";
import { CtaEditComponent } from "../../../default/components/edit-components/cta-edit-component";
import EditContainerComponent from "../../../default/components/edit-container-component/edit-container-component";
import { RetirementEditComponent } from "../../../default/components/edit-components/retirement-edit-component";
import { MoneyEditComponent } from "../../../default/components/edit-components/money-edit-component";
import { MoneyInYearEditComponent } from "../../../default/components/edit-components/money-in-year-edit-component";
import { MoneyInYearRangeEditComponent } from "../../../default/components/edit-components/money-in-year-range-edit-component";
import CtaComponent from "../cta-component/cta-component";
import ThisIsMeComponent from "../this-is-me-component/this-is-me-component";
import { PersonalInfoProvider } from "../../../common/providers/personal-info-provider";
import React, { useRef } from "react";
import { NudgeProvider } from "../../../common/providers/nudge-provider";
import NudgeComponent from "../nudge-component/nudge-component";
import { AssetsProvider } from "../../../common/providers/assets-provider";
import { ResultProvider } from "../../../common/providers/result-provider";
import ResultsComponent from "../results-component/resultsComponent";
import RetirementChartWrapperComponent from "../retirement-chart-component/retirement-chart-wrapper-component";
import { FlyoutMenuWrapperProvider } from "../../../common/providers/flyout-menu-wrapper-provider";
import { FlyoutMenuWrapperComponent } from "../flyout-menu-component/flyout-menu-wrapper-component";
import ICanListComponent from "../../../default/components/i-can-list-component/i-can-list-component";
import IWantListComponent from "../../../default/components/i-want-list-component/i-want-list-component";
import { HiddenContentComponent } from "../hidden-content-component/hidden-content-component";

export default function DashboardCompact() {

    const dispatch = useAppDispatch();

    const ctaChildrenRef = useRef<HTMLDivElement | null>(null); 

    const {
        labels,
        currentYear,
        yearOfDeath,
        isInsideIframe,
    
        iWantOrder,
        iHaveOrder,
        iCanOrder,
        thisIsMeOrder,
    
        dashboardInputEditClassName,
        editComponentTitle,
        editItemTitle,
        showMortgageError,
        editComponentConfirmLabel,
    
        flyoutMenuLabels,
        
        thisIsMeLabels,
        iwantListLabels,
        ihaveLabels,
        iHaveListLabels,
        iCanLabels,
        iCanListLabels,
        resultLabels,
        nudgeLabels,
        disclaimerLabels,
        ctaLabels,
        ctaConfirmLabels,
        persona,
    
        goals,
        assets,
        liabilities,
        savings,
        notifications,
        currencyConfig,
    
        closeCurrentlyOpenEditItem,
        openEditComponentForGoal,
        openEditComponentForAsset,
        openEditComponentForLiability,
        openEditComponentForSaving,
        debounceSimulationUpdate,
        getYearFromString,
    
        currentlyOpenEditItem,
        updateRetirement,
        updateLegacy,
        updateDream,
        onDreamValidate,
        updatePreserveCapital,
        onPreserveCapitalValidate,
        updateSecureFamily,
        onSecureFamilyValidate,
        updateRealestate,
        updateBonds,
        updateEquities,
        updateCash,
        updateMortgage,
        updateMonthlySavings,
        onSavingsMonthlyValidate,
        updateLumpsumSavings,
        onSavingsLumpsumValidate,
        openCtaComponent,
        openCtaConfirmComponent,
        onCtaConfirmComponentClose,
        isDreamYearValid,
        isSecureFamilyYearValid,
        isLumpsumYearValid,

      } = useDashboardContext();
      
    function getOrderedTabs() {
        const thisIsMeTab = {
          order: thisIsMeOrder,
          tab: <Tab
            className="quirion-react-tabs__tab"
            selectedClassName="quirion-react-tabs__tab--selected"
            onClick={closeCurrentlyOpenEditItem}>
            <div className="tab-header">
                {thisIsMeLabels.TITLE}
            </div>
          </Tab>
        };

        const iWantTab = {
          order: iWantOrder,
          tab: <Tab
            className="quirion-react-tabs__tab"
            selectedClassName="quirion-react-tabs__tab--selected"
            onClick={closeCurrentlyOpenEditItem}>
            <div className="tab-header">
              {iwantListLabels.TITLE}
            </div>
          </Tab>
        };
    
        const iHaveTab = {
          order: iHaveOrder,
          tab: <Tab
            className="quirion-react-tabs__tab"
            selectedClassName="quirion-react-tabs__tab--selected"
            onClick={closeCurrentlyOpenEditItem}>
            <div className="tab-header">
              {ihaveLabels.TITLE}
            </div>
          </Tab>
        };
    
        const iCanTab = {
          order: iCanOrder,
          tab: <Tab
            className="quirion-react-tabs__tab"
            selectedClassName="quirion-react-tabs__tab--selected"
            onClick={closeCurrentlyOpenEditItem}>
            <div className="tab-header">
              {iCanLabels.TITLE}
            </div>
          </Tab>
        };
    
        const orderedTabs = [thisIsMeTab, iHaveTab, iWantTab, iCanTab].sort((a, b) => a.order!! - b.order!!);
    
        return orderedTabs.map((t, i) => <React.Fragment key={i}>{t.tab}</React.Fragment>);
    }
  
    function getOrderedTabContent() {
      const thisIsMeTab = {
        order: thisIsMeOrder,
        tab: <TabPanel>
          <PersonalInfoProvider
            labels={thisIsMeLabels}
            onRequiresUpdate={debounceSimulationUpdate}
            tabOrder={thisIsMeOrder ?? 0}>
            <ThisIsMeComponent />
          </PersonalInfoProvider>
        </TabPanel>
      };

      const iWantTab = {
        order: iWantOrder,
        tab: <TabPanel>
          <IWantListComponent
            labels={fromIWantLabels(iwantListLabels)}
            onGoalSelected={(goalType) => openEditComponentForGoal(goalType)}
            showTooltip={true}
          />
        </TabPanel>
      };
  
      const iHaveTab = {
        order: iHaveOrder,
        tab: <TabPanel>
          <AssetsProvider labels={iHaveListLabels}>
            <IHaveListComponent
              onAssetSelected={(assetType) => openEditComponentForAsset(assetType)}
              onLiabilitySelected={(liabilityType) => openEditComponentForLiability(liabilityType)}
            />
          </AssetsProvider>
        </TabPanel>
      };
  
      const iCanTab = {
        order: iCanOrder,
        tab: <TabPanel>
          <ICanListComponent
            labels={iCanListLabels}
            onSavingSelected={(savingType) => openEditComponentForSaving(savingType)} 
            showTooltip={true}/>
        </TabPanel>
      };
  
      const orderedTabs = [thisIsMeTab, iHaveTab, iWantTab, iCanTab].sort((a, b) => a.order!! - b.order!!);
  
      return orderedTabs.map((t, i) => <React.Fragment key={i}>{t.tab}</React.Fragment>);
    }
      
    return (
        <>
        {!isInsideIframe && (<>
          <NotificationComponent
            isOpen={notifications.errorMessages.length > 0}
            type="error"
            notification={notifications.errorMessages[0]}
            onClose={(notification) => dispatch(removeErrorMessage(notification?.type))}
        />
        <NotificationComponent
                isOpen={notifications.warningMessages.length > 0}
                type="warning"
                notification={notifications.warningMessages[0]}
                onClose={(notification) => dispatch(removeInfoMessage(notification?.type))}
          />
       <div className={notifications.errorMessages.length>0 ? 'with-error-notification':''} style={{position:"absolute"}}>
          <NotificationComponent
                isOpen={notifications.infoMessages.length > 0}
                type="inform"
                notification={notifications.infoMessages[0]}
                onClose={(notification) => dispatch(removeInfoMessage(notification?.type))}
          />
       </div>
        <NudgeProvider 
            labels={nudgeLabels} 
            className={notifications.errorMessages.length>0 ? 'nudge-with-notification':''} >
            <NudgeComponent />
        </NudgeProvider>
        </>)}
        <div className="dashboard__menu">
            <FlyoutMenuWrapperProvider
               labels={fromDashboardAndFlyoutMenuLabels(labels,iwantListLabels.GOALS, flyoutMenuLabels,ctaLabels)}
               onRequiresUpdate={debounceSimulationUpdate}
            >
                <FlyoutMenuWrapperComponent/>
            </FlyoutMenuWrapperProvider>
        </div>
        <div className="dashboard__chart-title">
          <label>{labels.RETIREMENT_CHART.TITLE}</label>
        </div>
        <div className="dashboard__chart">
            <ErrorBoundaryComponent>
            <RetirementChartWrapperComponent labels={labels.RETIREMENT_CHART} />
            </ErrorBoundaryComponent>
        </div>
        <div className="dashboard__results">
            <ErrorBoundaryComponent>
              <ResultProvider labels={resultLabels}>
                  <ResultsComponent/>
              </ResultProvider>
            </ErrorBoundaryComponent>
        </div>
        {isInsideIframe && (<div className="dashboard__notification">
        <NotificationComponent
            isOpen={notifications.errorMessages.length > 0}
            type="error"
            notification={notifications.errorMessages[0]}
            onClose={(notification) => dispatch(removeErrorMessage(notification?.type))}
        />
        <NotificationComponent
                isOpen={notifications.warningMessages.length > 0}
                type="warning"
                notification={notifications.warningMessages[0]}
                onClose={(notification) => dispatch(removeInfoMessage(notification?.type))}
          />
        <div className={notifications.errorMessages.length>0 ? 'with-error-notification':''}>
        <NotificationComponent
                isOpen={notifications.infoMessages.length > 0}
                type="inform"
                notification={notifications.infoMessages[0]}
                onClose={(notification) => dispatch(removeInfoMessage(notification?.type))}
        />
        </div>
        <NudgeProvider 
            labels={nudgeLabels} 
            className={notifications.errorMessages.length>0 ? 'nudge-with-notification':''} >
            <NudgeComponent />
        </NudgeProvider>
        </div>)}
        <div className="dashboard__input">
            <Tabs className="quirion-react-tabs">
              <TabList className="quirion-react-tabs__tab-list">
                  {getOrderedTabs()}
              </TabList>
              {getOrderedTabContent()}
            </Tabs>
        </div>
        <div className="quirion-dashboard__cta">
          <CtaEditComponent
            labels={fromCtaAndPersonaLabels(ctaLabels, persona)}
            onCtaSelected={() => openCtaComponent(false)} />
        </div>
        {currentlyOpenEditItem !== undefined && (
          <div className={dashboardInputEditClassName}  style={{gridArea: isInsideIframe ? `6 / 1 / span 2` : `4 / 1 / span 2`, height: isInsideIframe ? '230px' : 'unset', overflowY: isInsideIframe ? 'auto' : 'unset'}}>
            <EditContainerComponent
                title={editComponentTitle}
                itemTitle={editItemTitle}
                onClose={closeCurrentlyOpenEditItem}
                hasError={showMortgageError}
                errorItemType={showMortgageError?ihaveLabels.LIABILITY.LIABILITY_TYPES.MORTGAGE:''}
            >
              {currentlyOpenEditItem === GoalType.retirement && (
                <RetirementEditComponent
                    currencyConfig={currencyConfig}
                    itemConfirmLabel={editComponentConfirmLabel}
                    onRequiresUpdate={updateRetirement}
                    ageLabel={iwantListLabels.GOALS.RETIREMENT.AGE}
                    payoutLabel={iwantListLabels.GOALS.RETIREMENT.AMOUNT}
                    minAge={20}
                    maxAge={117}
                />
              )}
              {currentlyOpenEditItem === GoalType.legacy && (
                <MoneyEditComponent
                    currencyConfig={currencyConfig}
                    amount={goals.legacy.amount}
                    itemConfirmLabel={editComponentConfirmLabel}
                    onRequiresUpdate={updateLegacy}
                    amountLabel={iwantListLabels.GOALS.LEGACY.AMOUNT}
                    stepValue={1000}
                />
              )}
              {currentlyOpenEditItem === GoalType.dream && (
                <MoneyInYearEditComponent
                    currencyConfig={currencyConfig}
                    itemConfirmLabel={editComponentConfirmLabel}
                    onRequiresUpdate={updateDream}
                    yearLabel={`${iwantListLabels.GOALS.GOAL.YEAR} (YYYY)`}
                    amountLabel={iwantListLabels.GOALS.GOAL.AMOUNT}
                    year={goals.dream.year}
                    minYear={currentYear}
                    maxYear={yearOfDeath}
                    amount={goals.dream.amount}
                    stepValue={1000}
                    hasError={goals.dream.hasInvalidDate}
                    onValidate={onDreamValidate}
                    isYearValid={isDreamYearValid}
                />
              )}
              {currentlyOpenEditItem === GoalType.preserve_capital && (
                <MoneyInYearRangeEditComponent
                    currencyConfig={currencyConfig}
                    itemConfirmLabel={editComponentConfirmLabel}
                    onRequiresUpdate={updatePreserveCapital}
                    yearLabel={`${iwantListLabels.GOALS.PRESERVE_CAPITAL.FROM} (YYYY) - ${iwantListLabels.GOALS.PRESERVE_CAPITAL.TO} (YYYY)`}
                    amountLabel={iwantListLabels.GOALS.PRESERVE_CAPITAL.AMOUNT}
                    fromYear={getYearFromString(goals.preserveCapital.startDate)}
                    toYear={getYearFromString(goals.preserveCapital.endDate)}
                    amount={goals.preserveCapital.amount}
                    stepValue={1000}
                    minYear={currentYear}
                    maxYear={yearOfDeath}
                    hasError={goals.preserveCapital.hasInvalidDate}
                    onValidate={onPreserveCapitalValidate}
                />
              )}
              {currentlyOpenEditItem === GoalType.secure_family && (
                <MoneyInYearEditComponent
                    currencyConfig={currencyConfig}
                    itemConfirmLabel={editComponentConfirmLabel}
                    onRequiresUpdate={updateSecureFamily}
                    yearLabel={iwantListLabels.GOALS.SECURE_FAMILY.YEAR}
                    amountLabel={iwantListLabels.GOALS.SECURE_FAMILY.AMOUNT}
                    year={goals.secureFamily.year}
                    minYear={currentYear}
                    maxYear={yearOfDeath}
                    amount={goals.secureFamily.amount}
                    stepValue={1000}
                    hasError={goals.secureFamily.hasInvalidDate}
                    onValidate={onSecureFamilyValidate}
                    isYearValid={isSecureFamilyYearValid}
                />
              )}
              {currentlyOpenEditItem === AssetType.realestate && (
                <MoneyEditComponent
                    currencyConfig={currencyConfig}
                    amount={assets.realEstate}
                    itemConfirmLabel={editComponentConfirmLabel}
                    onRequiresUpdate={updateRealestate}
                    amountLabel={ihaveLabels.ASSET_TOTAL}
                    stepValue={10000}
                />
              )}
              {currentlyOpenEditItem === AssetType.bonds && (
                <MoneyEditComponent
                    currencyConfig={currencyConfig}
                    amount={assets.bonds}
                    itemConfirmLabel={editComponentConfirmLabel}
                    onRequiresUpdate={updateBonds}
                    amountLabel={ihaveLabels.ASSET_TOTAL}
                    stepValue={1000}
                />
              )}
              {currentlyOpenEditItem === AssetType.equities && (
                <MoneyEditComponent
                    currencyConfig={currencyConfig}
                    amount={assets.equity}
                    itemConfirmLabel={editComponentConfirmLabel}
                    onRequiresUpdate={updateEquities}
                    amountLabel={ihaveLabels.ASSET_TOTAL}
                    stepValue={1000}
                />
              )}
              {currentlyOpenEditItem === AssetType.cash && (
                <MoneyEditComponent
                    currencyConfig={currencyConfig}
                    amount={assets.cash}
                    itemConfirmLabel={editComponentConfirmLabel}
                    onRequiresUpdate={updateCash}
                    amountLabel={ihaveLabels.ASSET_TOTAL}
                    stepValue={500}
                />
              )}
              {currentlyOpenEditItem === LiabilityType.mortgage && (
                <MoneyEditComponent
                    currencyConfig={currencyConfig}
                    amount={liabilities.mortgage}
                    itemConfirmLabel={editComponentConfirmLabel}
                    onRequiresUpdate={updateMortgage}
                    amountLabel={ihaveLabels.ASSET_TOTAL}
                    stepValue={10000}
                    hasError={assets.realEstate<liabilities.mortgage}
                />
              )}
              {currentlyOpenEditItem === SavingType.monthly && (
                   <MoneyEditComponent
                   currencyConfig={currencyConfig}
                   amount={savings.monthly}
                   itemConfirmLabel={editComponentConfirmLabel}
                   onRequiresUpdate={updateMonthlySavings}
                   amountLabel={iCanListLabels.SAVINGS_AMOUNT_PLACEHOLDER}
                   stepValue={1000}
                   hasError={savings.hasInvalidMonthlyYear}
                 />
              )}
              {currentlyOpenEditItem === SavingType.lumpsum && (
                <MoneyInYearEditComponent
                    currencyConfig={currencyConfig}
                    itemConfirmLabel={editComponentConfirmLabel}
                    year={savings.lumpsumYear}
                    yearLabel={iCanListLabels.LUMPSUM_YEAR_PLACEHOLDER}
                    amount={savings.lumpsum}
                    minYear={currentYear}
                    maxYear={yearOfDeath}
                    onRequiresUpdate={updateLumpsumSavings}
                    amountLabel={iCanListLabels.LUMPSUM_AMOUNT}
                    stepValue={10000}
                    hasError={savings.hasInvalidLumpsumYear}
                    onValidate={onSavingsLumpsumValidate}
                    isYearValid={isLumpsumYearValid}
                />
              )}
              {currentlyOpenEditItem === CtaType.cta &&
                <CtaComponent />}
            </EditContainerComponent>
          </div>
        )}
        </>
    );
}