import GoalAchievementComponent from '../goal-achievement-component/goal-achievement-component';
import './results-component.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { useResultContext } from '../../../common/providers/result-provider';


export function ResultsComponent() {

  const {
    labels,
    isHorizontallyCompact,
    hasRetirement,
    hasLegacy,
    hasDream,
    hasPreserveCapital,
    hasSecureFamily,
    currencyConfig,
    simulation
  } = useResultContext();
  
  const content = (
    <>
      {hasRetirement && (
        <SwiperSlide>
          <GoalAchievementComponent
            title={labels.RETIREMENT_GOAL}
            shortfallLabel={labels.SHORTFALL}
            achievementFraction={simulation.retirementResult.achievementFraction}
            shortfall={simulation.retirementResult.shortfall}
            iconClassName="icon-pension"
            currencyConfig={currencyConfig}
          />
        </SwiperSlide>
      )}
      {hasLegacy && (
        <SwiperSlide>
          <GoalAchievementComponent
            title={labels.LEGACY_GOAL}
            shortfallLabel={labels.SHORTFALL}
            achievementFraction={simulation.legacyResult.achievementFraction}
            shortfall={simulation.legacyResult.shortfall}
            iconClassName="icon-legacy"
            currencyConfig={currencyConfig}
          />
        </SwiperSlide>
      )}
      {hasDream && (
        <SwiperSlide>
          <GoalAchievementComponent
            title={labels.DREAM_GOAL}
            shortfallLabel={labels.SHORTFALL}
            achievementFraction={simulation.dreamResult.achievementFraction}
            shortfall={simulation.dreamResult.shortfall}
            iconClassName="icon-dream"
            currencyConfig={currencyConfig}
          />
        </SwiperSlide>
      )}
      {hasPreserveCapital && (
        <SwiperSlide>
          <GoalAchievementComponent
            title={labels.PRESERVE_CAPITAL_GOAL}
            shortfallLabel={labels.SHORTFALL}
            achievementFraction={simulation.preserveCapitalResult.achievementFraction}
            shortfall={simulation.preserveCapitalResult.shortfall}
            iconClassName="icon-piggy-bank"
            currencyConfig={currencyConfig}
          />
        </SwiperSlide>
      )}
      {hasSecureFamily && (
        <SwiperSlide>
          <GoalAchievementComponent
            title={labels.SECURE_FAMILY_GOAL}
            shortfallLabel={labels.SHORTFALL}
            achievementFraction={simulation.secureFamilyResult.achievementFraction}
            shortfall={simulation.secureFamilyResult.shortfall}
            iconClassName="icon-secure-family"
            currencyConfig={currencyConfig}
          />
        </SwiperSlide>
      )}
      {!hasRetirement && !hasLegacy && !hasDream && !hasPreserveCapital && !hasSecureFamily && (
        <label className="results-empty">{labels.EMPTY}</label>
      )}
    </>
  );

  return (
    <div className="results-component-goals">
      {isHorizontallyCompact ? (
        <div className="swiper-component">
          <Swiper
            spaceBetween={6}
            slidesPerView={3}
            pagination={{ clickable: true, enabled: true }}
            modules={[Pagination]}
          >
            {content}
          </Swiper>
        </div>
      ) : (
        content
      )}
    </div>
  );
}

export default ResultsComponent;
