import { ChangeEvent, ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";
import { IWantComponentLabels } from "../labels/i-want-component.labels";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectPersonalState } from "../store/personal.slice";
import { saveDream, saveLegacy, savePreserveCapital, saveRetirement, saveSecureFamily, selectDreamState, selectLegacyState, selectPreserveCapitalState, selectRetirementState, selectSecureFamilyState, setPreserveCapitalHasInvalidDate } from "../store/goals/goals.slice";
import { selectGoalsSlideIndexState } from "../store/slider/goalsSlider.slice";
import { useCurrencyConfig } from "../hooks/useCurrencyConfig";
import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from "../hooks/useTrackEvent";
import { useComponentIcon } from "../hooks/useComponentIcon";
import { useComponentVisibility } from "../hooks/useComponentVisibility";
import { calculateLifeSpan } from "../shared/calculateLifeSpan";
import { CurrencyConfig, getQuarterDate } from "@flexfront/ui/react";
import { addErrorMessage, removeErrorMessage, selectNotificationsState } from "../store/notifications/notifications.slice";
import { NotificationType } from "../store/notifications/notifications.state";
import { SwiperClass } from "swiper/react";
import { Dream, Legacy, PreserveCapital, Retirement, SecureFamily } from "../store/goals/goals.state";
import moment from "moment";

export interface GoalsContextProps {
    children: ReactNode;
    labels: IWantComponentLabels;
    tabOrder?: number;
    onRequiresUpdate?: () => void;
}

interface GoalsFunctionality {
    children: ReactNode;
    labels: IWantComponentLabels;
    tabOrder?: number;
    onRequiresUpdate?: () => void;

    iWantIcon?: string;
    swiperContainerRef: React.RefObject<HTMLDivElement>;
    onSwiperContainerKeyUp: (event: React.KeyboardEvent<HTMLDivElement>) => void;
    setSwiperInstance: React.Dispatch<React.SetStateAction<SwiperClass | null>>;
    swiperInstance: SwiperClass | null;

    retirementTabindex: number;
    retirement: Retirement;
    isRetirementIconHidden?: boolean;
    onRetirementChange: (retirementAge: number, retirementMonthlyPayout: number) => void;
    onRetirementAgeChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onRetirementMonthlyPayoutChange: (retirementMonthlyPayout: number) => void;
    onRetirementAgeBlur: () => void;
    currencyConfig: CurrencyConfig;
    onRetirementMonthlyPayoutBlur: () => void;
    retirementIcon: string | undefined;
    isRetirementYearValid: boolean;

    isLegacyHidden?: boolean;
    legacyTabindex: number;
    isLegacyIconHidden?: boolean;
    onLegacyChange: (value: number) => void;
    legacy: Legacy;
    onLegacyBlur: () => void;

    isDreamHidden?: boolean;
    dreamTabIndex: number;
    dream: Dream;
    isDreamIconHidden?: boolean;
    onDreamChange: (year: number, amount: number) => void;
    onDreamYearChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onDreamPayoutChange: (dreamAmount: number) => void;
    onDreamYearBlur: () => void;
    onDreamPayoutBlur: () => void;
    dreamIcon: string | undefined;
    isDreamYearValid: boolean;

    isPreserveCapitalHidden?: boolean;
    preserveCapitalTabIndex: number;
    preserveCapital: PreserveCapital;
    isPreserveCapitalIconHidden?: boolean;
    currentYear: number;
    yearOfDeath: number;
    onPreserveCapitalChange: (startDate: string, endDate: string, amount: number) => void;
    onPreserveCapitalValidate: (isValid: boolean) => void;
    onPreserveCapitalFromYearBlur: () => void;
    onPreserveCapitalToYearBlur: () => void;
    onPreserveCapitalAmountBlur: () => void;

    isSecureFamilyHidden?: boolean;
    secureFamilyTabindex: number;
    secureFamily: SecureFamily;
    isSecureFamilyIconHidden?: boolean;
    onSecureFamilyChange: (year: number, amount: number) => void;
    onSecureFamilyYearBlur: () => void;
    onSecureFamilyPayoutBlur: () => void;
    isSecureFamilyYearValid: boolean;
}

const GoalsContext = createContext<GoalsFunctionality | undefined>(undefined);

export const useGoalsContext = (): GoalsFunctionality => {
    const context = useContext(GoalsContext);
    if (!context) {
        throw new Error('useGoalsContext must be used within a PersonalInfoProvider');
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useContext(GoalsContext)!;
};

export const GoalsProvider: React.FC<GoalsContextProps> = (props: GoalsContextProps) => {

    const [isRetirementYearValid, setIsRetirementYearValid] = useState<boolean>(true);
    const [isDreamYearValid, setIsDreamYearValid] = useState<boolean>(true);
    const [isSecureFamilyYearValid, setIsSecureFamilyYearValid] = useState<boolean>(true);

    const personal = useAppSelector(selectPersonalState);
    const retirement = useAppSelector(selectRetirementState);
    const legacy = useAppSelector(selectLegacyState);
    const dream = useAppSelector(selectDreamState);
    const secureFamily = useAppSelector(selectSecureFamilyState);
    const preserveCapital = useAppSelector(selectPreserveCapitalState);
    const goalsActiveSliderIndex = useAppSelector(selectGoalsSlideIndexState);
    const notifications = useAppSelector(selectNotificationsState);
    const dispatch = useAppDispatch();

    const currencyConfig = useCurrencyConfig();
    const trackEvent = useTrackEvent();

    const iWantIcon = useComponentIcon("I_WANT");
    const retirementIcon = useComponentIcon("I_WANT_RETIREMENT_ICON");
    const dreamIcon = useComponentIcon("I_WANT_DREAM_ICON");

    const isRetirementIconHidden = useComponentVisibility("I_WANT_RETIREMENT_ICON");
    const isDreamIconHidden = useComponentVisibility("I_WANT_DREAM_ICON");
    const isLegacyIconHidden = useComponentVisibility("I_WANT_LEGACY_ICON");
    const isPreserveCapitalIconHidden = useComponentVisibility("I_WANT_PRESERVE_CAPITAL_ICON");
    const isSecureFamilyIconHidden = useComponentVisibility("I_WANT_SECURE_FAMILY_ICON");

    const isDreamHidden = useComponentVisibility("I_WANT_DREAM");
    const isLegacyHidden = useComponentVisibility("I_WANT_LEGACY");
    const isSecureFamilyHidden = useComponentVisibility("I_WANT_SECURE_FAMILY");
    const isPreserveCapitalHidden = useComponentVisibility("I_WANT_PRESERVE_CAPITAL");

    const {currentYear, yearOfDeath} = calculateLifeSpan();

    function onRetirementAgeChange(event: ChangeEvent<HTMLInputElement>) {
        onRetirementAgeValidate(event.target.valueAsNumber,retirement.retirementMonthlyPayout);
        if (event.target.value.length <= 4) {
          onRetirementChange(event.target.valueAsNumber, retirement.retirementMonthlyPayout);
        }
      }
    
    function onRetirementMonthlyPayoutChange(retirementMonthlyPayout: number) {
        onRetirementChange(retirement.retirementAge, retirementMonthlyPayout);
    }

    function onRetirementChange(
        retirementAge: number,
        retirementMonthlyPayout: number
    ) {

        let retirementDateUtc: number | undefined = undefined;
        if (retirementAge > personal.age) {
        const retirementDate = getQuarterDate(new Date());
        const yearsUntilRetirement = retirementAge - personal.age;
        retirementDate.setFullYear(retirementDate.getFullYear() + yearsUntilRetirement);
        retirementDateUtc = Date.UTC(
            retirementDate.getFullYear(),
            retirementDate.getMonth(),
            1);
        }

        onRetirementAgeValidate(retirementAge,retirementMonthlyPayout);
        dispatch(
        saveRetirement({
            ...retirement,
            retirementAge: retirementAge,
            retirementMonthlyPayout: retirementMonthlyPayout,
            retirementStartDateUtc: retirementDateUtc
        })
        );
        if (props.onRequiresUpdate) props.onRequiresUpdate();
    }

    function onRetirementAgeValidate( retirementAge: number,
        retirementMonthlyPayout: number
    ){
        if((Number.isNaN(retirementAge)  || retirementAge == 0) && retirementMonthlyPayout != 0){
            setIsRetirementYearValid(false);
            dispatch(
                addErrorMessage({
                    type: NotificationType.YEAR_ERROR,
                    message: notifications.errorMessageLabels.YEAR_ERROR_MESSAGE
                })
            );
        }
        else{
            setIsRetirementYearValid(true);
            dispatch(removeErrorMessage(NotificationType.YEAR_ERROR));
        }
    }

    function onLegacyChange(value: number) {
        dispatch(saveLegacy({ ...legacy, amount: value}));
        if (props.onRequiresUpdate) props.onRequiresUpdate();
    }

    function onDreamYearChange(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.value.length <= 4) {
            onDreamChange(event.target.valueAsNumber, dream.amount);
        }
      }
    
    function onDreamPayoutChange(dreamAmount: number) {
        onDreamChange(dream.year, dreamAmount); 
    }

    function onDreamChange(year: number, amount: number) {
        onDreamYearValidate(year,amount);
        dispatch(saveDream({ ...dream, year, amount, hasInvalidDate: false }));
        if (props.onRequiresUpdate) props.onRequiresUpdate();
    }

    function onDreamYearValidate(year: number, amount: number){
        if((Number.isNaN(year) || year == 0) && amount != 0){
            setIsDreamYearValid(false);
            dispatch(
                addErrorMessage({
                    type: NotificationType.YEAR_ERROR,
                    message: notifications.errorMessageLabels.YEAR_ERROR_MESSAGE
                })
            );
        }
        else{
            setIsDreamYearValid(true);
            dispatch(removeErrorMessage(NotificationType.YEAR_ERROR));
        }
    }
    function onSecureFamilyChange(year: number, amount: number) {
        if((Number.isNaN(year)  || year == 0) && amount != 0){
            setIsSecureFamilyYearValid(false);
            dispatch(
                addErrorMessage({
                    type: NotificationType.YEAR_ERROR,
                    message: notifications.errorMessageLabels.YEAR_ERROR_MESSAGE
                })
            );
        }
        else{
            setIsSecureFamilyYearValid(true);
            dispatch(removeErrorMessage(NotificationType.YEAR_ERROR));
        }
        dispatch(saveSecureFamily({ ...secureFamily, year, amount, hasInvalidDate: false }));
        if (props.onRequiresUpdate) props.onRequiresUpdate();
    }

    function onPreserveCapitalChange(startDate: string, endDate: string, amount: number) {
        dispatch(savePreserveCapital({ ...preserveCapital, startDate, endDate, amount, hasInvalidDate: false }));
        if (props.onRequiresUpdate) props.onRequiresUpdate();
    }

    function onPreserveCapitalValidate(isValid: boolean) {
        dispatch(setPreserveCapitalHasInvalidDate(!isValid));
        if (!isValid) {
        trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.VALIDATION, name: trackEventNames.PRESERVE_CAPITAL_DATE_ERROR });
        }
    }

    function onRetirementAgeBlur() {
        trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.INPUT, name: trackEventNames.RETIREMENT_AGE, value: retirement.retirementAge });
    }

    function onRetirementMonthlyPayoutBlur() {
        trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.INPUT, name: trackEventNames.RETIREMENT_PAYOUT, value: retirement.retirementMonthlyPayout });
    }

    function onLegacyBlur() {
        trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.INPUT, name: trackEventNames.LEGACY_PAYOUT, value: legacy.amount });
    }

    function onDreamYearBlur() {
        trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.INPUT, name: trackEventNames.DREAM_YEAR, value: dream.year });
    }

    function onDreamPayoutBlur() {
        trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.INPUT, name: trackEventNames.DREAM_PAYOUT, value: dream.amount });
    }

    function onSecureFamilyYearBlur() {
        trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.INPUT, name: trackEventNames.SECURE_FAMILY_YEAR, value: secureFamily.year });
    }

    function onSecureFamilyPayoutBlur() {
        trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.INPUT, name: trackEventNames.SECURE_FAMILY_PAYOUT, value: secureFamily.amount });
    }

    function onPreserveCapitalFromYearBlur() {
        trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.INPUT, name: trackEventNames.PRESERVE_CAPITAL_FROM, value: moment(preserveCapital.startDate).year() });
    }

    function onPreserveCapitalToYearBlur() {
        trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.INPUT, name: trackEventNames.PRESERVE_CAPITAL_TO, value: moment(preserveCapital.endDate).year() });
    }

    function onPreserveCapitalAmountBlur() {
        trackEvent({ category: trackEventCategories.I_WANT, action: trackEventActions.INPUT, name: trackEventNames.PRESERVE_CAPITAL_AMOUNT, value: preserveCapital.amount });
    }

    const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null);
    const swiperContainerRef = useRef<HTMLDivElement>(null);

    function onSwiperContainerKeyUp(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.key === "Tab" && swiperContainerRef.current && swiperContainerRef.current.contains(event.target as Node)) {
            const target = event.target as HTMLElement;
            const slide: HTMLElement | null = target.closest('.swiper-slide');
            if (slide && swiperInstance?.slides.includes(slide)) {
                const slideIndex = swiperInstance.slides.indexOf(slide);
                swiperInstance.slideTo(slideIndex);
            }
        }
    }

    useEffect(() => {
        swiperInstance?.slideTo(goalsActiveSliderIndex.activeSlideindex);
    }, [goalsActiveSliderIndex]);

    useEffect(() => {
        if(retirement.retirementMonthlyPayout == 0) setIsRetirementYearValid(true);
    }, [retirement.retirementMonthlyPayout]);
    useEffect(() => {
        if(dream.amount == 0) setIsDreamYearValid(true);
    }, [dream.amount]);
    useEffect(() => {
        if(secureFamily.amount == 0) setIsSecureFamilyYearValid(true);
    }, [secureFamily.amount]);

    const retirementTabindex = +`${props.tabOrder}1`;
    let lastTabIndex = retirementTabindex;
    const legacyTabindex = lastTabIndex + 2;
    lastTabIndex = legacyTabindex;
    const dreamTabIndex = lastTabIndex + 1;
    lastTabIndex = dreamTabIndex;
    const preserveCapitalTabIndex = lastTabIndex + 2;
    lastTabIndex = preserveCapitalTabIndex;
    const secureFamilyTabindex =lastTabIndex + 3;

    const contextValue = {
        children: props.children,
        labels: props.labels,
        tabOrder: props.tabOrder,
        onRequiresUpdate: props.onRequiresUpdate,
        
        iWantIcon,
        swiperContainerRef,
        onSwiperContainerKeyUp,
        setSwiperInstance,
        swiperInstance,
    
        retirementTabindex,
        retirement,
        isRetirementIconHidden,
        onRetirementChange,
        onRetirementAgeChange,
        onRetirementAgeBlur,
        currencyConfig,
        onRetirementMonthlyPayoutChange,
        onRetirementMonthlyPayoutBlur,
        retirementIcon: retirementIcon,
        isRetirementYearValid,
    
        isLegacyHidden,
        legacyTabindex,
        isLegacyIconHidden,
        onLegacyChange,
        legacy,
        onLegacyBlur,
    
        isDreamHidden,
        dreamTabIndex,
        dream,
        isDreamIconHidden,
        onDreamChange,
        onDreamYearChange,
        onDreamYearBlur,
        onDreamPayoutChange,
        onDreamPayoutBlur,
        dreamIcon: dreamIcon,
        isDreamYearValid,
    
        isPreserveCapitalHidden,
        preserveCapitalTabIndex,
        preserveCapital,
        isPreserveCapitalIconHidden,
        currentYear,
        yearOfDeath,
        onPreserveCapitalChange,
        onPreserveCapitalValidate,
        onPreserveCapitalFromYearBlur,
        onPreserveCapitalToYearBlur,
        onPreserveCapitalAmountBlur,
    
        isSecureFamilyHidden,
        secureFamilyTabindex,
        secureFamily,
        isSecureFamilyIconHidden,
        onSecureFamilyChange,
        onSecureFamilyYearBlur,
        onSecureFamilyPayoutBlur,
        isSecureFamilyYearValid
    };

    return (
        <GoalsContext.Provider value={contextValue}>{props.children}</GoalsContext.Provider>
      );
};