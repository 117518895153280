import { useComponentVisibility } from '../../../common/hooks/useComponentVisibility';
import moment from 'moment';
import MoneyComponent from '../money-component/money-component';
import {
  selectDreamState,
  selectLegacyState,
  selectPreserveCapitalState,
  selectRetirementState,
  selectSecureFamilyState,
} from '../../../common/store/goals/goals.slice';
import { useAppSelector } from '../../../common/store/hooks';
import { IWantListComponentLabels } from '../../../common/labels/i-want-list-component-labels';
import { GoalType } from '@flexfront/models';
import { calculateLifeSpan } from '../../../common/shared/calculateLifeSpan';
import { useCurrencyConfig } from '../../../common/hooks/useCurrencyConfig';
import InfoIcon from '../../../quirion/assets/images/info.svg';

export interface IWantListComponentProps {
  labels: IWantListComponentLabels;
  onGoalSelected?: (goalType: GoalType) => void; 
  showTooltip?: boolean;
}

export function IWantListComponent(props: IWantListComponentProps) {
  const retirement = useAppSelector(selectRetirementState);
  const legacy = useAppSelector(selectLegacyState);
  const dream = useAppSelector(selectDreamState);
  const preserveCapital = useAppSelector(selectPreserveCapitalState);
  const secureFamily = useAppSelector(selectSecureFamilyState);

  const currencyConfig = useCurrencyConfig();

  const isRetirementIconHidden = useComponentVisibility("I_WANT_RETIREMENT_ICON");
  const isDreamIconHidden = useComponentVisibility("I_WANT_DREAM_ICON");
  const isLegacyIconHidden = useComponentVisibility("I_WANT_LEGACY_ICON");
  const isPreserveCapitalIconHidden = useComponentVisibility("I_WANT_PRESERVE_CAPITAL_ICON");
  const isSecureFamilyIconHidden = useComponentVisibility("I_WANT_SECURE_FAMILY_ICON");

  const isDreamHidden = useComponentVisibility("I_WANT_DREAM");
  const isLegacyHidden = useComponentVisibility("I_WANT_LEGACY");
  const isSecureFamilyHidden = useComponentVisibility("I_WANT_SECURE_FAMILY");
  const isPreserveCapitalHidden = useComponentVisibility("I_WANT_PRESERVE_CAPITAL");

  const {currentYear,yearOfDeath} = calculateLifeSpan();

  function handleOnGoalSelected(goalType: GoalType) {
    if (props.onGoalSelected) {
      props.onGoalSelected(goalType);
    }
  }

  return (
    <div className="mobile-list">
      <MoneyComponent
        iconClassName="icon-pension"
        hideIcon={isRetirementIconHidden}
        title={props.labels.RETIREMENT}
        titleValue={retirement.retirementAge}
        amountLabel={`${props.labels.RETIREMENT_AMOUNT}:`}
        amountValue={retirement.retirementMonthlyPayout}
        titleValuePlaceholder={props.labels.RETIREMENT_AGE_PLACEHOLDER}
        amountValuePlaceholder={props.labels.RETIREMENT_AMOUNT_PLACEHOLDER}
        onClick={() => handleOnGoalSelected(GoalType.retirement)}
        currencyConfig={currencyConfig}
        showTooltip={props.showTooltip}
        infoIcon={<InfoIcon />}
        tooltip={props.labels.RETIREMENT_TOOLTIP}
        tooltipPosition="right center"
      />

    {!isLegacyHidden && <>
      <div className="list-separator" />

      <MoneyComponent
        iconClassName="icon-legacy"
        hideIcon={isLegacyIconHidden}
        title={props.labels.LEGACY}
        amountLabel={`${props.labels.LEGACY_AMOUNT}:`}
        amountValue={legacy.amount}
        amountValuePlaceholder={props.labels.LEGACY_AMOUNT_PLACEHOLDER}
        onClick={() => handleOnGoalSelected(GoalType.legacy)}
        currencyConfig={currencyConfig}
      />
    </>}

    {!isDreamHidden && <>
      <div className="list-separator" />

      <MoneyComponent
        iconClassName="icon-dream"
        hideIcon={isDreamIconHidden}
        title={props.labels.DREAM}
        titleValue={dream.year}
        amountLabel={`${props.labels.DREAM_AMOUNT}:`}
        amountValue={dream.amount}
        titleValuePlaceholder={props.labels.DREAM_YEAR_PLACEHOLDER}
        amountValuePlaceholder={props.labels.DREAM_AMOUNT_PLACEHOLDER}
        onClick={() => handleOnGoalSelected(GoalType.dream)}
        currencyConfig={currencyConfig}
        hasError={dream.hasInvalidDate}
        showTooltip={props.showTooltip}
        infoIcon={<InfoIcon />}
        tooltip={props.labels.DREAM_TOOLTIP}
        tooltipPosition="right center"
      />
    </>}
      {!isPreserveCapitalHidden && <>
        <div className="list-separator" />

        <MoneyComponent
          iconClassName="icon-piggy-bank"
          hideIcon={isPreserveCapitalIconHidden}
          title={props.labels.PRESERVE_CAPITAL}
          titleValue={`${moment(preserveCapital.startDate).format("YYYY") ?? props.labels.PRESERVE_CAPITAL_DATE_PLACEHOLDER} - ${(moment(preserveCapital.endDate).format("YYYY")==currentYear.toString()?yearOfDeath:moment(preserveCapital.endDate).format("YYYY")) ?? props.labels.PRESERVE_CAPITAL_DATE_PLACEHOLDER}`}
          amountLabel={`${props.labels.PRESERVE_CAPITAL_AMOUNT}:`}
          amountValue={preserveCapital.amount}
          amountValuePlaceholder={props.labels.PRESERVE_CAPITAL_AMOUNT_PLACEHOLDER}
          onClick={() => handleOnGoalSelected(GoalType.preserve_capital)}
          currencyConfig={currencyConfig}
          hasError={preserveCapital.hasInvalidDate}
      />
      </>}
      {!isSecureFamilyHidden && <>
        <div className="list-separator" />
        <MoneyComponent
          iconClassName="icon-secure-family"
          hideIcon={isSecureFamilyIconHidden}
          title={props.labels.SECURE_FAMILY}
          titleValue={secureFamily.year}
          amountLabel={`${props.labels.SECURE_FAMILY_AMOUNT}:`}
          amountValue={secureFamily.amount}
          titleValuePlaceholder={props.labels.SECURE_FAMILY_YEAR_PLACEHOLDER}
          amountValuePlaceholder={props.labels.SECURE_FAMILY_AMOUNT_PLACEHOLDER}
          onClick={() => handleOnGoalSelected(GoalType.secure_family)}
          currencyConfig={currencyConfig}
          hasError={secureFamily.hasInvalidDate}
        />
      </>}
    </div>
  );
}

export default IWantListComponent;
