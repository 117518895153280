import { ChangeEvent, ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";
import { ICanComponentLabels } from "../labels/i-can-component.labels";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectPersonalState } from "../store/personal.slice";
import { selectRetirementState } from "../store/goals/goals.slice";
import { selectAssetsState } from "../store/assets/assets.slice";
import { saveLumpsumSavings, saveMonthlySavings, selectSavingsState } from "../store/savings.slice";
import { useCurrencyConfig } from "../hooks/useCurrencyConfig";
import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from "../hooks/useTrackEvent";
import { useComponentIcon } from "../hooks/useComponentIcon";
import { useComponentVisibility } from "../hooks/useComponentVisibility";
import { Savings } from "@flexfront/models";
import { CurrencyConfig } from "@flexfront/ui/react";
import { SwiperClass } from "swiper/react";
import { addErrorMessage, removeErrorMessage, selectNotificationsState } from "../store/notifications/notifications.slice";
import { NotificationType } from "../store/notifications/notifications.state";

export interface SavingsContextProps {
    children: ReactNode;
    labels: ICanComponentLabels;
    tabOrder?: number;
    onRequiresUpdate?: () => void;
}

interface SavingsFunctionality {
    children: ReactNode;
    labels: ICanComponentLabels;
    tabOrder?: number;
    onRequiresUpdate?: () => void;
    
    iCanIcon?: string;
    savings: Savings;
    currencyConfig: CurrencyConfig;

    swiperContainerRef: React.RefObject<HTMLDivElement>;
    onSwiperContainerKeyUp: (event: React.KeyboardEvent<HTMLDivElement>) => void;
    setSwiperInstance: React.Dispatch<React.SetStateAction<SwiperClass | null>>;
    swiperInstance: SwiperClass | null;

    savingTabindex: number;
    totalSavings: number;
    isSavingsIconHidden?: boolean;
    savingIcon: string | undefined;

    onMonthlySavingsChanged: (year: number, amount: number) => void;
    onMonthlySavingsAmountChange: (amount: number) => void;
    onMonthlySavingsAmountBlur: () => void;
    onMonthlySavingsYearBlur: () => void;

    lumpsumTabindex: number;
    isLumpsumIconHidden?: boolean;
    lumpsunIcon: string | undefined
    onLumpsumSavingsChanged: (year: number, amount: number) => void;
    onLumpsumSavingsYearChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onLumpsumSavingsYearBlur: () => void;
    onLumpsumSavingsAmountChange: (amount: number) => void;
    onLumpsumSavingsAmountBlur: () => void;
    isLumpsumYearValid: boolean;
}

const SavingsContext = createContext<SavingsFunctionality | undefined>(undefined);

export const useSavingsContext = (): SavingsFunctionality => {
    const context = useContext(SavingsContext);
    if (!context) {
        throw new Error('useSavingsContext must be used within a SavingsProvider');
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useContext(SavingsContext)!;
};

export const SavingsProvider: React.FC<SavingsContextProps> = (props: SavingsContextProps) => {

    const [isLumpsumYearValid, setIsLumpsumYearValid] = useState<boolean>(true);

    const personal = useAppSelector(selectPersonalState);
    const retirement = useAppSelector(selectRetirementState);
    const assets = useAppSelector(selectAssetsState);
    const notifications = useAppSelector(selectNotificationsState);
    const savings = useAppSelector(selectSavingsState);
    const dispatch = useAppDispatch();
  
    const currencyConfig = useCurrencyConfig();
    const trackEvent = useTrackEvent();
  
    const iCanIcon = useComponentIcon("I_CAN");
    const savingIcon = useComponentIcon("I_CAN_SAVINGS_ICON");
    const lumpsunIcon = useComponentIcon("I_CAN_LUMPSUM_ICON");

    const isSavingsIconHidden = useComponentVisibility("I_CAN_SAVINGS_ICON");
    const isLumpsumIconHidden = useComponentVisibility("I_CAN_LUMPSUM_ICON");
  
    const currentYear = new Date().getFullYear();
    const [totalSavings, setTotalSavings] = useState<number>(0);

    function onMonthlySavingsAmountChange(monthlySavings: number) {
      onMonthlySavingsChanged(savings.monthlyYear, monthlySavings);
    }
  
    function onMonthlySavingsChanged(year: number, amount: number) {
      dispatch(saveMonthlySavings({ year, amount }));
      if (props.onRequiresUpdate) {
        props.onRequiresUpdate();
      }
    }
  
    function onLumpsumSavingsChanged(year: number, amount: number) {
      onLumpsumYearValidate(year,amount);
      dispatch(saveLumpsumSavings({ year, amount }));
        if (props.onRequiresUpdate) {
          props.onRequiresUpdate();
        }
    }
  
    function onMonthlySavingsYearBlur() {
      trackEvent({ category: trackEventCategories.I_CAN, action: trackEventActions.INPUT, name: trackEventNames.MONTHLY_SAVINGS_YEAR, value: savings.monthlyYear });
    }
  
    function onMonthlySavingsAmountBlur() {
      trackEvent({ category: trackEventCategories.I_CAN, action: trackEventActions.INPUT, name: trackEventNames.MONTHLY_SAVINGS_AMOUNT, value: savings.monthly });
    }

    function onLumpsumSavingsYearChange(event: ChangeEvent<HTMLInputElement>) {
      onLumpsumYearValidate(event.target.valueAsNumber,savings.lumpsum);
      onLumpsumSavingsChanged(event.target.valueAsNumber, savings.lumpsum);
    }
  
    function onLumpsumSavingsYearBlur() {
      trackEvent({ category: trackEventCategories.I_CAN, action: trackEventActions.INPUT, name: trackEventNames.LUMPSUM_SAVINGS_YEAR, value: savings.lumpsumYear });
    }

    function onLumpsumSavingsAmountChange(amount: number) {
      onLumpsumYearValidate(savings.lumpsumYear,amount);
      onLumpsumSavingsChanged(savings.lumpsumYear, amount);
    }
  
  
    function onLumpsumSavingsAmountBlur() {
      trackEvent({ category: trackEventCategories.I_CAN, action: trackEventActions.INPUT, name: trackEventNames.LUMPSUM_SAVINGS_AMOUNT, value: savings.lumpsum });
    }
  
    function onLumpsumYearValidate(year: number, amount: number) {
        if((Number.isNaN(year) || year == 0)  && amount != 0){
          setIsLumpsumYearValid(false);
          dispatch(
              addErrorMessage({
                  type: NotificationType.YEAR_ERROR,
                  message: notifications.errorMessageLabels.YEAR_ERROR_MESSAGE
              })
          );
      }
      else{
        setIsLumpsumYearValid(true);
          dispatch(removeErrorMessage(NotificationType.YEAR_ERROR));
      }
    }
    useEffect(() => {
      const activeYears = savings.monthlyYear - currentYear;
      setTotalSavings(activeYears * 12 * savings.monthly);
    }, [savings.monthlyYear, currentYear, savings.monthly]);
  
    useEffect(() => {
      const monthlyYear = currentYear + (retirement.retirementAge - personal.age);
      dispatch(saveMonthlySavings({amount: savings.monthly, year: monthlyYear}));
    }, [personal.age, retirement.retirementAge]);

    useEffect(() => {
      if(savings.lumpsum == 0) setIsLumpsumYearValid(true);
    }, [savings.lumpsum]);
    
    const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null);
    const swiperContainerRef = useRef<HTMLDivElement>(null);

    function onSwiperContainerKeyUp(event: React.KeyboardEvent<HTMLDivElement>) {
      if (event.key === "Tab" && swiperContainerRef.current && swiperContainerRef.current.contains(event.target as Node)) {
          const target = event.target as HTMLElement;
          const slide: HTMLElement | null = target.closest('.swiper-slide');
          if (slide && swiperInstance?.slides.includes(slide)) {
              const slideIndex = swiperInstance.slides.indexOf(slide);
              swiperInstance.slideTo(slideIndex);
          }
      }
  }
    
    const savingTabindex = +`${props.tabOrder}1`;
    const lastTabIndex = savingTabindex;
    const lumpsumTabindex = lastTabIndex + 1;

    const contextValue = {
        children: props.children,
        labels: props.labels,
        tabOrder: props.tabOrder,
        onRequiresUpdate: props.onRequiresUpdate,

        iCanIcon,
        savings,
        currencyConfig,

        swiperContainerRef,
        onSwiperContainerKeyUp,
        setSwiperInstance,
        swiperInstance,

        savingTabindex,
        totalSavings,
        isSavingsIconHidden,
        savingIcon: savingIcon,
        
        onMonthlySavingsChanged,
        onMonthlySavingsAmountChange,
        onMonthlySavingsAmountBlur,
        onMonthlySavingsYearBlur,

        lumpsumTabindex,
        isLumpsumIconHidden,
        lumpsunIcon: lumpsunIcon,
        onLumpsumSavingsChanged,
        onLumpsumSavingsYearChange,
        onLumpsumSavingsYearBlur,
        onLumpsumSavingsAmountChange,
        onLumpsumSavingsAmountBlur,
        isLumpsumYearValid
    };

    return (
        <SavingsContext.Provider value={contextValue}>{props.children}</SavingsContext.Provider>
    );
}