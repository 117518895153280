import { useEffect, useState } from "react";
import { useAppSelector } from "../store/hooks";
import { selectTenantConfig } from "../store/tenant-config/tenant-config.slice";
import { DomainLayoutConfig } from "../store/tenant-config/tenant-config.state";

export const useDomainLayout = (): DomainLayoutConfig | undefined => {
    const host = window.location.hostname;
    const tenantConfig = useAppSelector(selectTenantConfig);
    
    const getDomainLayout = () => {
        if (tenantConfig.layout?.domainLayouts && tenantConfig.layout.domainLayouts.length > 0) {
            return tenantConfig.layout.domainLayouts.find((d: any) => d.domain == host);
        }

        return undefined;
    }

    const [domainLayout, setDomainLayout] = useState<DomainLayoutConfig | undefined>(getDomainLayout());

    useEffect(() => {
        setDomainLayout(getDomainLayout())
    }, [tenantConfig]);

    return domainLayout;
}