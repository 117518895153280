import { selectAllText } from '@flexfront/ui/react';
import { ChangeEvent, FocusEvent, useEffect, useMemo, useRef, useState } from 'react';

export interface YearInputComponentProps {
  year: number;
  minYear?: number;
  maxYear?: number;
  placeholder?: string;
  hasError?: boolean;
  onYearChanged?: (year: number) => void;
}

export function YearInputComponent(props: YearInputComponentProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const currentYear = useMemo(() => new Date().getFullYear(), []);
  const minYear = props.minYear ?? currentYear - 100;
  const maxYear = props.maxYear ?? currentYear + 100;
  const [year, setYear] = useState<number>(props.year);
  const [hasError, setHasError] = useState<boolean>(props.hasError ?? false);


  function onYearChange(event: ChangeEvent<HTMLInputElement>) {
    if(event.target.value.length <= 4) {
      const newYear = event.target.valueAsNumber;
      setYear(newYear);
      if (props.onYearChanged) {
        props.onYearChanged(newYear);
      }
    }
  }

  function onInputFocussed(event: FocusEvent<HTMLInputElement>) {
    if (event.target.value.length > 0) {
      selectAllText(event);
    }
  }

  function focusInput() {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }

  useEffect(() => {
    setHasError(props.hasError ?? false);
  }, [props.hasError]);


  return (
    <div className={`year-input-container ${hasError ? 'error error-border' : ''}`} onClick={focusInput}>
      <input
        ref={inputRef}
        onFocus={onInputFocussed}
        className="year-input"
        type="number"
        min={minYear}
        max={maxYear}
        step={1}
        value={year > 0 ? year : ""}
        placeholder={props.placeholder ?? 'YYYY'}
        onChange={onYearChange}
      />
      <div className="icon-calendar" />
    </div>
  );
}
