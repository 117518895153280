import { ReactNode, useEffect, useState } from "react";
import './quirion-icon-card-component.scss';
import Popup from "reactjs-popup";
import { PopupPosition } from "reactjs-popup/dist/types";
import { useDeviceBounds } from "../../../common/hooks/useDeviceBounds";

export interface QuirionIconCardComponentProps {
    icon?: string;
    title?: string;
    info?: ReactNode;
    inputs: ReactNode;
    tooltip?: string;
    tooltipPosition?: PopupPosition;
}

export function QuirionIconCardComponent(props: QuirionIconCardComponentProps) {

    const [isOpen, setIsOpen] = useState(false);
    const { isHorizontallyCompact } = useDeviceBounds();

    function getTooltipContent() {
        return <Popup
            trigger={
                <div className="icon-info info-icon"></div>}
            position={props.tooltipPosition ?? "left center"}
            open={isOpen}
            on={['hover', 'click']}
            closeOnDocumentClick
            mouseLeaveDelay={300}
            mouseEnterDelay={0}
            className="quirion-icon-card-inner-details__tooltip"
            arrow={true}
            onOpen={() => setIsOpen(true)}>
                {props.tooltip}
        </Popup>;
    }

    useEffect(() => {
        const handleScroll = () => {
          setIsOpen(false);
        };
    
        const dashboard = document.getElementById('dashboard');
    
        dashboard?.addEventListener('scroll', handleScroll);
    
        return () => {
          dashboard?.removeEventListener('scroll', handleScroll);
        };
    }, [isOpen]);

    return (
        <div className="quirion-icon-card">

           <div  className="quirion-icon-card-inner">
                {!isHorizontallyCompact && <div>
                    <div className="quirion-icon-card-inner__info">
                    {props.info && !isHorizontallyCompact && getTooltipContent()}
                    </div>
                </div> }

                <div className={`quirion-icon-card-inner-details ${(props.info && !isHorizontallyCompact) ? `quirion-icon-card-inner-details-with-info`: `` }`}>

                    <div className="quirion-icon-card-inner-details__icon-container">
                        {props.icon && <span className="widget-title-icon"
                        dangerouslySetInnerHTML={{ __html: props.icon ?? '' }}></span>}
                    </div>

                    <div className="quirion-icon-card-inner-details__title-container">
                        <label className="quirion-icon-card-inner-details__title">
                            {props.title}
                        </label>
                        <div className="quirion-icon-card-inner__info">
                        {props.info && isHorizontallyCompact && getTooltipContent()}
                        </div>
                    </div>
                </div>
           </div>

            <div className="quirion-icon-card-inputs">

                {props.inputs}

            </div>

        </div>
    );
}