import { useEffect, useState } from "react";
import { useAppSelector } from "../store/hooks";
import { selectTenantConfig } from "../store/tenant-config/tenant-config.slice";

export const useComponentVisibility = (componentName: string) => {
    const tenantConfig = useAppSelector(selectTenantConfig);

    const isComponentHidden = () => {
        if (!tenantConfig?.layout?.componentLayouts) {
            return undefined;
        }

        const componentVisibility = tenantConfig.layout.componentLayouts
            .find((cp: any) => cp.component.name?.toLowerCase() === componentName?.toLowerCase());
        return componentVisibility?.isHidden;
    };

    const [isHidden, setIsHidden] = useState<boolean | undefined>(isComponentHidden());

    useEffect(() => {
        setIsHidden(isComponentHidden());
    }, [tenantConfig]);

    return isHidden;
}