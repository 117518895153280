import { CurrencyConfig } from "@flexfront/ui/react";
import { getCurrencySymbol } from "../../../common/shared/locale";

export function getYAxisLabelFormatter(
    isHorizontallyCompact: boolean,
    currencyConfig?: CurrencyConfig) {

    const formatter = (context: Highcharts.AxisLabelsFormatterContextObject) => {
        if (!isHorizontallyCompact && context.isLast) {
            return '';
        }

        return currencyConfig?.hasSymbolSuffix
            ? `${context.axis.defaultLabelFormatter.call(context)} ${getCurrencySymbol(currencyConfig)}`
            : `${getCurrencySymbol(currencyConfig)}${context.axis.defaultLabelFormatter.call(context)}`;
    }

    return formatter;
}
