import { WealthSection } from "@flexfront/models";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../hooks";
import { RootState } from "../store";
import { Snapshots } from "./snapshots.state";

export const SNAPSHOTS_FEATURE_KEY = "snapshots";

const initialState: Snapshots = {};

export const snapshotsSlice = createSlice({
  name: SNAPSHOTS_FEATURE_KEY,
  initialState,
  reducers: {
    saveSnapshots: (state, action: PayloadAction<Snapshots>) => {
      return {...state,...action.payload};
    },
    saveTotalWealthSnapshot: (state, action: PayloadAction<WealthSection>) => {
      state = { ...state, totalWealthSnapshot: action.payload };
      return state;
    },
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const { saveSnapshots, saveTotalWealthSnapshot } = snapshotsSlice.actions;

export const selectSnapshotsState = createSelector(
  (state: RootState) => state.snapshots,
  (snapshots) => snapshots
);

export default snapshotsSlice;
