import { getQuarterDate } from "@flexfront/ui/react";

export function getTimelineSeries(
  totalQuarters: number
): Highcharts.SeriesOptionsType[] {
  const currentDate = getQuarterDate(new Date());
  const pointStart = Date.UTC(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  let quarterDate = getQuarterDate(new Date());
  let quarterDateUTC = Date.UTC(
      quarterDate.getFullYear(),
      quarterDate.getMonth(),
      1
  );;

  const timelineValues = [];
  for (let quarter = 0; quarter < totalQuarters - 1; quarter++) {
    timelineValues.push(quarterDateUTC);
    quarterDate = new Date(quarterDate.setMonth(quarterDate.getMonth() + 3));
    quarterDateUTC = Date.UTC(
      quarterDate.getFullYear(),
      quarterDate.getMonth(),
      1
    );
  }

  return [
    {
      id: "timelineSeries",
      name: "timelineSeries",
      type: "line",
      data: timelineValues,
      pointStart: pointStart,
      color: "transparent",
      pointIntervalUnit: "month",
      pointInterval: 3,
      enableMouseTracking: false
      
    },
  ];
}
