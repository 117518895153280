import { AssetType, AssetTypeItem, LiabilityType, LiabilityTypeItem } from '@flexfront/models';
import MoneyComponent from '../money-component/money-component';
import { useAssetsContext } from "../../../common/providers/assets-provider";

/* eslint-disable-next-line */
export interface IHaveListComponentProps {
  onAssetSelected?: (assetType: AssetType) => void
  onLiabilitySelected?: (liabilityType: LiabilityType) => void
}

export function IHaveListComponent(props: IHaveListComponentProps) {

  const {
    labels,
    liabilityTypes,
    shouldRenderLiability,
    isAssetVisible,
    getLiabilityStateValue,
    getAssetIconHidden,
    currencyConfig,
    getAssetStateValue,

    assetTypes

  } = useAssetsContext();
  

  function handleOnAssetSelected(assetType: string) {
    if (props.onAssetSelected) {
      switch (assetType){
        case "realestate":
          return props.onAssetSelected(AssetType.realestate);
        case "bonds":
          return props.onAssetSelected(AssetType.bonds);
        case "equity":
          return props.onAssetSelected(AssetType.equities);
        case "cash":
          return props.onAssetSelected(AssetType.cash);
        default:
          throw new Error(`${assetType} is not currently handled yet`);
      }
    }
  }

  function handleOnLiabilitySelected(liabilityType: string) {
    if (props.onLiabilitySelected) {
      switch (liabilityType){
        case "mortgage":
          return props.onLiabilitySelected(LiabilityType.mortgage);
        default:
          throw new Error(`${liabilityType} is not currently handled yet`);
      }
    }
  }

  function renderAssosiatedLiability(assetType: string) {
    if (assetType === "realestate") {
      const liabilityType = liabilityTypes.find(liability => liability.Type === "mortgage");

      if (liabilityType){
        return renderLiability(liabilityType, false);
      }
    }
    return;
  }

  function renderLiability(liabilityType: LiabilityTypeItem, ignoreAssosiated: boolean) {
    if (shouldRenderLiability(liabilityType, ignoreAssosiated) && isAssetVisible(liabilityType.Type)) {
      return (<>
        <MoneyComponent
        iconClassName={liabilityType.Icon}
        hideIcon={getAssetIconHidden(liabilityType.Type)}
        title={liabilityType.DISPLAY}
        amountLabel={`${labels.ASSET_TOTAL}:`}
        amountValue={getLiabilityStateValue(liabilityType.Type)}
        amountValuePlaceholder={labels.ASSET.AMOUNT}
        onClick={() => handleOnLiabilitySelected(liabilityType.Type)}
        currencyConfig={currencyConfig}
      /><div className="list-separator" /></>
      )
    }
    return;       
  }

  function renderAsset(assetType : AssetTypeItem) {
    if (isAssetVisible(assetType.Type)) {
      return (<>
        <MoneyComponent
        iconClassName={assetType.Icon}
        hideIcon={getAssetIconHidden(assetType.Type)}
        title={assetType.DISPLAY}
        amountLabel={`${labels.ASSET_TOTAL}:`}
        amountValue={getAssetStateValue(assetType.Type)}
        amountValuePlaceholder={labels.ASSET.AMOUNT}
        onClick={() => handleOnAssetSelected(assetType.Type)}
        currencyConfig={currencyConfig}
      /><div className="list-separator" /></>
      )
    }
    return;
  }

  return (
    <div className="mobile-list">
      {
            assetTypes.map((assetType) => {
              const asset = renderAsset(assetType);
              const assosiatedLiability = renderAssosiatedLiability(assetType.Type);
              
              return (
                <div key={assetType.Type}>
                  {asset}
                  {assosiatedLiability}
                </div>
              )             
            })
      }
      {
            liabilityTypes.map((liabilityType) => {
              return renderLiability(liabilityType, true);          
            })
      }
    </div>
  );
}

export default IHaveListComponent;
