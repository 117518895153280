import { ICanComponentLabels } from "./i-can-component.labels";

export interface ICanListComponentLabels {
    SAVINGS: string,
    SAVINGS_AMOUNT: string;
    SAVINGS_AMOUNT_PLACEHOLDER: string;
    SAVINGS_YEAR_PLACEHOLDER: string;
    LUMPSUM: string,
    LUMPSUM_YEAR: string,
    LUMPSUM_AMOUNT: string;
    LUMPSUM_AMOUNT_PLACEHOLDER: string;
    LUMPSUM_YEAR_PLACEHOLDER: string;
    LUMPSUM_TOOLTIP: string;
    SAVINGS_TOOLTIP: string;
}

export function fromICanLabels(labels: ICanComponentLabels): ICanListComponentLabels {
    return {
        SAVINGS: labels.SAVINGS.TOTAL_SAVINGS,
        SAVINGS_AMOUNT: labels.SAVINGS.MONTHLY_SAVINGS,
        SAVINGS_TOOLTIP: labels.SAVINGS.SAVINGS_TOOLTIP,
        SAVINGS_AMOUNT_PLACEHOLDER: labels.SAVINGS.MONTHLY_SAVINGS_PLACEHOLDER,
        SAVINGS_YEAR_PLACEHOLDER: labels.SAVINGS.MONTHLY_YEAR_PLACEHOLDER,
        LUMPSUM: labels.LUMPSUM,
        LUMPSUM_YEAR: labels.LUMPSUM_YEAR,
        LUMPSUM_AMOUNT: labels.LUMPSUM_AMOUNT,
        LUMPSUM_AMOUNT_PLACEHOLDER: labels.LUMPSUM_AMOUNT_PLACEHOLDER,
        LUMPSUM_YEAR_PLACEHOLDER: labels.LUMPSUM_YEAR_PLACEHOLDER,
        LUMPSUM_TOOLTIP: labels.LUMPSUM_TOOLTIP,
    };
}