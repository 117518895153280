import { useAppSelector } from "../store/hooks";
import { selectLayouts } from "../store/layout/layouts.slice";
import { selectTenantConfig } from "../store/tenant-config/tenant-config.slice";

export const useTenantFontType = () => {
    const layouts = useAppSelector(selectLayouts);
    const tenantConfig = useAppSelector(selectTenantConfig);

    const layout = layouts.layouts.find((l:any) => l.layoutId === tenantConfig.layoutId);

    document.documentElement.style.setProperty('--primary-font-type', layout?.primaryFontType ?? "");
    document.documentElement.style.setProperty('--secondary-font-type', layout?.secondaryFontType ?? "");
    
}