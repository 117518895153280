import { selectAllText } from '@flexfront/ui/react';
import { ChangeEvent, FocusEvent, useEffect, useMemo, useRef, useState } from 'react';

export interface YearRangeInputComponentProps {
  fromYear: number;
  toYear: number;
  minYear: number;
  maxYear: number;
  placeholder?: string;
  hasError?: boolean;
  onRangeChanged?: (fromYear: number, toYear: number) => void;
  onValidate?:(hasError: boolean) => void;
}

export function YearRangeInputComponent(props: YearRangeInputComponentProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const currentYear = useMemo(() => new Date().getFullYear(), []);
  const minYear = props.minYear ?? currentYear - 100;
  const maxYear = props.maxYear ?? currentYear + 100;
  const [fromYear, setFromYear] = useState<number>(props.fromYear);
  const [toYear, setToYear] = useState<number>(props.toYear);

  function validate(fromYear?: number, toYear?: number) {
    let isValid = true;

    if (fromYear && toYear && fromYear > toYear) {
      isValid = false;
    }

    if (fromYear && minYear && fromYear < minYear) {
      isValid = false;
    }

    if (toYear && maxYear && toYear > maxYear) {
      isValid = false;
    }

    return isValid;
  }

  function validateYear() {
    const isValid = validate(fromYear, toYear);
    if (props.onValidate) {
      props.onValidate(isValid);
    }
  }

  function onFromYearChange(event: ChangeEvent<HTMLInputElement>) {
    if(event.target.value.length <= 4) {
      setFromYear(event.target.valueAsNumber);
    }
  }

  function onToYearChange(event: ChangeEvent<HTMLInputElement>) {
    if(event.target.value.length <= 4){
      setToYear(event.target.valueAsNumber);
    }
  }

  function onInputFocussed(event: FocusEvent<HTMLInputElement>) {
    if (event.target.value.length > 0) {
      selectAllText(event);
    }
  }

  function focusInput() {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }

  useEffect(() => {
    if (props.onRangeChanged && validate(fromYear, toYear)) {
      props.onRangeChanged(fromYear, toYear);
    }
  }, [fromYear, toYear]);

  useEffect(() => {
    setFromYear(props.minYear);
    setToYear(props.maxYear); 
  }, [props.minYear, props.maxYear]);

  return (
    <div className={`year-input-container ${props.hasError ? 'error error-border' : ''}`} onClick={focusInput}>
      <input
        ref={inputRef}
        onFocus={onInputFocussed}
        className="year-input"
        type="number"
        min={minYear}
        max={maxYear}
        step={1}
        value={fromYear > 0 ? fromYear : ""}
        placeholder={props.placeholder ?? 'YYYY'}
        onChange={onFromYearChange}
        onBlur={validateYear}
      />
      <div className="year-range-separator">-</div>
      <input
        onFocus={onInputFocussed}
        className="year-input"
        type="number"
        min={minYear}
        max={maxYear}
        step={1}
        value={toYear > 0 ? toYear : ""}
        placeholder={props.placeholder ?? 'YYYY'}
        onChange={onToYearChange}
        onBlur={validateYear}
      />
      <div className="icon-calendar" />
    </div>
  );
}
