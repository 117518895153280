import { CurrencyConfig, MoneyInput, selectAllText } from "@flexfront/ui/react";
import { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from "react";
import { defaultCurrencyInputFormatter } from "../../../common/shared/locale";

export interface RetirementIconCardComponentProps {
  cardClassName?: string;
  iconClassName: string;
  hideIcon?: boolean;
  tabIndex?: number;
  titleLabel: string;
  valueLabel: string;
  valuePlaceholder?: string;
  totalLabel: string;
  retirementAge: number;
  agePlaceholder: string;
  retirementMonthlyPayout: number;
  onRetirementChange?: (
    retirementAge: number,
    retirementMonthlyPayout: number
  ) => void;
  allowSelection?: boolean;
  isSelected?: boolean;
  displaySimple?: boolean;
  onSelectionChange?: (isSelected: boolean) => void;
  currencyConfig?: CurrencyConfig;
  onRetirementAgeBlur?: () => void;
  onRetirementMonthlyPayoutBlur?: () => void;
  isRetirementYearValid?: boolean;
}

// NOTE: This component should be renamed and it's variables refactored as it is not only used for retirement.

export function RetirementIconCardComponent(
  props: RetirementIconCardComponentProps
) {
  const [isSelected, setIsSelected] = useState<boolean | undefined>(props.isSelected);
  const [isYearValid, setIsYearValid] = useState<boolean | undefined>(props.isRetirementYearValid);
  const [retirementAge, setRetirementAge] = useState<number>(
    props.retirementAge
  );
  const [retirementMonthlyPayout, setRetirementMonthlyPayout] =
    useState<number>(props.retirementMonthlyPayout);
  const cardInputRef = useRef<HTMLInputElement>(null);
  const moneyInputRef = useRef<HTMLInputElement>(null);

  function focusCardInput(event: SyntheticEvent) {
    event.stopPropagation();
    if (cardInputRef.current) {
      cardInputRef.current.focus();
    }
  }

  function focusMoneyInput() {
    if (moneyInputRef.current) {
      moneyInputRef.current.focus();
    }
  }

  function onRetirementAgeChange(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.value.length <= 4) {
      if (props.onRetirementChange) {
        props.onRetirementChange(event.target.valueAsNumber, retirementMonthlyPayout);
      }
    }
  }

  function onRetirementMonthlyPayoutChange(monthlyPayout: number) {
    if (props.onRetirementChange) {
      props.onRetirementChange(retirementAge, monthlyPayout);
    }
  }

  const onSelectionChange = () => {
    setIsSelected(!isSelected);
    if (props.allowSelection && props.onSelectionChange) {
      props.onSelectionChange(!isSelected)
    }
  }

  useEffect(() => {
    setRetirementAge(props.retirementAge);
  }, [props.retirementAge]);

  useEffect(() => {
    setIsYearValid(props.isRetirementYearValid);
  }, [props.isRetirementYearValid]);

  useEffect(() => {
    setRetirementMonthlyPayout(props.retirementMonthlyPayout);
  }, [props.retirementMonthlyPayout]);

  let cardClassName = "icon-card"
  if (props.allowSelection) {
    cardClassName += " icon-card-selection";
  }
  if (props.allowSelection && isSelected) {
    cardClassName += " icon-card--selected";
  }
  cardClassName += ` ${props.cardClassName}`;

  let emptyClassName = "";
  if (!retirementMonthlyPayout || retirementMonthlyPayout === 0) {
    emptyClassName = "empty";
  }

  return (
    <div
      className={cardClassName}
      onMouseUp={focusCardInput}
      onClick={onSelectionChange}>
      <div className="icon-card-type">
        {!props.hideIcon && <label className={`icon-card-icon ${props.iconClassName}`}></label>}
        <div className="icon-card-value">
          <span dangerouslySetInnerHTML={{ __html: props.titleLabel }}></span>
        </div>
      </div>
      {!props.displaySimple && <>
        <span className="icon-card-total" dangerouslySetInnerHTML={{ __html: props.valueLabel }}></span>
        <input
          tabIndex={props.tabIndex}
          className={`icon-card-input icon-card-input-retirement-age ${!isYearValid ? 'error' : '' }`}
          type="number"
          placeholder={props.agePlaceholder}
          value={retirementAge === 0 ? "" : retirementAge}
          onFocus={selectAllText}
          onChange={onRetirementAgeChange}
          onBlur={props.onRetirementAgeBlur}
          ref={cardInputRef}
        />
        <label className="icon-card-total">{props.totalLabel}</label>
        <MoneyInput
          tabIndex={props.tabIndex ? props.tabIndex + 1 : -1}
          placeholder={props.valuePlaceholder}
          className={`icon-card-input ${emptyClassName}`}
          inputNumber={retirementMonthlyPayout}
          currencyConfig={props.currencyConfig}
          currencyFormatter={defaultCurrencyInputFormatter}
          showCurrencySymbol={true}
          onChange={onRetirementMonthlyPayoutChange}
          onBlur={props.onRetirementMonthlyPayoutBlur}
          ref={moneyInputRef}
          autoWidth={true}
          maxValue={9999999}
        />
      </>}
    </div>
  );
}

export default RetirementIconCardComponent;
