import { DashboardLabels } from "./dashboard.labels";

export enum CtaType {
    cta = 40,
    cta_confirm = 41,
}
export interface Persona {
    TITLE: string;
    BODY: string;
    FOOTER_TEXT: string;
}

export interface CtaComponentLabels {
    PLACEHOLDER: string;
    SUBMITTED: string;
    SUBMIT: string;
    RESET: string;
    SUBMIT_LOCATION: string;
    PERSONA: Persona;
}

export function fromCtaAndPersonaLabels(ctaLabels: CtaComponentLabels, persona: Persona): CtaComponentLabels {
    return {
        ...ctaLabels,
        PERSONA: {
            TITLE: ctaLabels.PERSONA.TITLE,
            BODY: persona.BODY,
            FOOTER_TEXT: persona.FOOTER_TEXT
        }
    };
}