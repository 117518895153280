import Popup from "reactjs-popup";
import { ReactNode, useEffect, useState } from "react";
import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from "../../../common/hooks/useTrackEvent";
import { CtaConfirmComponentLabels } from "../../../common/labels/cta-confirm-component.labels";

export interface CtaConfirmComponentProps {
  labels: CtaConfirmComponentLabels;
  isOpen?: boolean;
  children: ReactNode;
}

export function CtaConfirmModalComponent(props: CtaConfirmComponentProps) {
    const [isCtaPopupOpen, setIsCtaPopupOpen] = useState<boolean>(props.isOpen ?? false);
    const trackEvent = useTrackEvent();

    const close = () => {
      trackEvent({ category: trackEventCategories.CTA_FINAL_CONFIRM_MODAL, action: trackEventActions.CLICK, name: trackEventNames.CLOSE });
      setIsCtaPopupOpen(false);
    }

    useEffect(() => {
      setIsCtaPopupOpen(props.isOpen ?? false); 
    }, [props.isOpen]);
    
    return <Popup
        open={isCtaPopupOpen}
        position="center center"
        arrow={false}
        modal={true}>
        <div className="cta-component">
          <div className="modal-title">
            <span className="header-title">
              {props.labels.TITLE}
            </span>
            <span
              className="modal-title-icon button-close icon-times"
              style={{ alignSelf: 'center', textAlign: 'center', marginRight: 0 }}
              onClick={close}
            />
          </div>
          <div className="row" style={{ display: 'flex', height: '1px', background: 'lightgray' }} />
          {props.children}
        </div>
    </Popup>;
}