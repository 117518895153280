export function GetChildProperties<TOutput>(obj: any): TOutput[] {
  const result: TOutput[] = [];

  const itemNames = Object.getOwnPropertyNames(obj);

  itemNames.forEach((itemName) => {
    const item = obj[itemName];
    const outputItem = item as TOutput;
    result.push(outputItem);
  });

  return result;
}

export function GetChildPropertyMap<TOutput>(obj: any): Map<string,TOutput> {
  const result: Map<string,TOutput> = new Map<string,TOutput>();

  const itemNames = Object.getOwnPropertyNames(obj);

  itemNames.forEach((itemName) => {
    const item = obj[itemName];
    const outputItem = item as TOutput;
    result.set(itemName, outputItem);
  });

  return result;
}
