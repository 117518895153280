import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { revertAll } from '../hooks';
import { RootState } from '../store';
import { MarkerPosition, QuarterRange, TimelineState } from './timeline.state';

export const TIMELINE_FEATURE_KEY = 'timeline';

const initialState: TimelineState = {
  timelineWidth: 0,
  widthPerQuarter: 0,
  orderedQuarterRanges: [],
  rangeOffsets: [],
  markerPositions: [],
  xOffset: 0
};

export const timelineSlice = createSlice({
  name: TIMELINE_FEATURE_KEY,
  initialState,
  reducers: {
    setTimelineWidth: (state, action: PayloadAction<number>) => {
      state = { ...state, timelineWidth: action.payload };
      return state;
    },
    setXOffset: (state, action: PayloadAction<number>) => {
      state = { ...state, xOffset: action.payload };
      return state;
    }, 
    setTimelineWidthPerQuarter: (state, action: PayloadAction<number>) => {
      state = { ...state, widthPerQuarter: action.payload };
      return state;
    },
    setTimelineQuarterRange: (state, action: PayloadAction<QuarterRange>) => {
      state = {
        ...state,
        orderedQuarterRanges: getOrderedRangeLookup(action.payload, [...state.orderedQuarterRanges]),
      };

      return state;
    },
    setTimelineRangeOffsets: (state, action: PayloadAction<number[]>) => {
      state = { ...state, rangeOffsets: action.payload };
      return state;
    },
    setMarkerPosition: (state, action: PayloadAction<MarkerPosition>) => {
      state = {
        ...state,
        markerPositions: [
          ...state.markerPositions.filter((_) => _.name !== action.payload.name),
          action.payload
        ],
      };

      return state;
    },
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const { setTimelineWidth, setXOffset, setTimelineWidthPerQuarter, setTimelineQuarterRange, setTimelineRangeOffsets, setMarkerPosition } = timelineSlice.actions;

export const selectTimelineState = createSelector(
  (state: RootState) => state.timeline,
  (chart) => chart
);

export const selectTimelineWidth = createSelector(
  (state: RootState) => state.timeline.timelineWidth,
  (timelineWidth) => timelineWidth
);

export const selectXOffset = createSelector(
  (state: RootState) => state.timeline.xOffset,
  (xOffset) => xOffset
);

export const selectTimelineWidthPerQuarter = createSelector(
  (state: RootState) => state.timeline.widthPerQuarter,
  (widthPerQuarter) => widthPerQuarter
);

export const selectTimelineOrderedQuarterRanges = createSelector(
  (state: RootState) => state.timeline.orderedQuarterRanges,
  (orderedQuarterRanges) => orderedQuarterRanges
);

export const selectTimelineRangeOffsets = createSelector(
  (state: RootState) => state.timeline.rangeOffsets,
  (rangeOffsets) => rangeOffsets
);

export const selectTimelineMarkerPositions = createSelector(
  (state: RootState) => state.timeline.markerPositions,
  (markerPositions) => markerPositions
);

function getOrderedRangeLookup(quarterRange: QuarterRange, lookup: QuarterRange[]) {
  let unsortedEntries: QuarterRange[] = lookup.filter((_) => _.name !== quarterRange.name);
  unsortedEntries = [...unsortedEntries, quarterRange].sort((a, b) => {
    if (a.quarters < b.quarters) {
      return 1;
    }

    if (a.quarters > b.quarters) {
      return -1;
    }

    return 0;
  });

  return unsortedEntries;
}

export default timelineSlice;
