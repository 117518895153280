import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { revertAll } from "./hooks";
import { RootState } from "./store";
import { UserSettings } from "@flexfront/models";

export const USER_SETTINGS_FEATURE_KEY = "userSettings";

export const initialState: UserSettings = {
  includeIlliquid: false,
  includePrevious: false,
  lang: undefined
};

export const userSettingsSlice = createSlice({
  name: USER_SETTINGS_FEATURE_KEY,
  initialState,
  reducers: {
    saveUserSettings: (state, action: PayloadAction<UserSettings>) => {
      return {...state, ...action.payload};
    },
    setIncludeIlliquid: (state, action: PayloadAction<boolean>) => {
      state = { ...state, includeIlliquid: action.payload };
      return state;
    },
    setIncludePrevious: (state, action: PayloadAction<boolean>) => {
      state = { ...state, includePrevious: action.payload };
      return state;
    },
    setUserSettingsLang: (state, action: PayloadAction<string | undefined>) => {
      state = { ...state, lang: action.payload };
      return state;
    },
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
});

export const { saveUserSettings, setIncludeIlliquid, setIncludePrevious, setUserSettingsLang } = userSettingsSlice.actions;

export const selectUserSettingsState = createSelector(
  (state: RootState) => state.userSettings,
  (userSettings) => userSettings
);

export default userSettingsSlice;