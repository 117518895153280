import { WidgetComponent } from '@flexfront/ui/react';
import RetirementIconCardComponent from '../icon-card-component/retirement-icon-card-component';
import SavingsIconCardComponent from '../icon-card-component/savings-icon-card-component';
import './i-can-component.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { useSavingsContext } from '../../../common/providers/savings-provider';

export function ICanComponent() {

  const {
    labels,
    iCanIcon,
    savings,
    currencyConfig,

    swiperContainerRef,
    onSwiperContainerKeyUp,
    setSwiperInstance,

    savingTabindex,
    totalSavings,
    isSavingsIconHidden,
    onMonthlySavingsChanged,
    onMonthlySavingsAmountBlur,
    onMonthlySavingsYearBlur,

    lumpsumTabindex,
    isLumpsumIconHidden,
    onLumpsumSavingsChanged,
    onLumpsumSavingsYearBlur,
    onLumpsumSavingsAmountBlur,
    isLumpsumYearValid
  } = useSavingsContext();


  return (
    <WidgetComponent className="i-can-component widget--slider" isExpanded={false}>
      <div className="widget-title-container">
        {iCanIcon && <span className="widget-title-icon"
          dangerouslySetInnerHTML={{ __html: iCanIcon ?? '' }}></span>}
        <h3 className="widget-title">{labels.TITLE}</h3>
      </div>
      <div
        className="savings-component swiper-component"
        ref={swiperContainerRef}
        onKeyUp={onSwiperContainerKeyUp}>
        <Swiper
          onSwiper={setSwiperInstance}
          spaceBetween={4}
          slidesPerView={1}
          breakpoints={{
            768: {
              slidesPerView: 1,
              pagination: { clickable: true, enabled: true },
            },
            1440: {
              slidesPerView: 2,
              pagination: { clickable: true, enabled: true },
            },
          }}
          pagination={{ clickable: true, enabled: true }}
          modules={[Pagination]}
        >
          <SwiperSlide>
            <SavingsIconCardComponent
              tabIndex={savingTabindex}
              cardClassName={savings.hasInvalidMonthlyYear ? 'error error-border' : ''}
              titleLabel={labels.SAVINGS.MONTHLY_SAVINGS}
              valueLabel={labels.SAVINGS.TOTAL_SAVINGS_AMOUNT_INPUT_TITLE}
              valuePlaceholder={labels.SAVINGS.MONTHLY_SAVINGS_PLACEHOLDER}
              yearPlaceholder={labels.SAVINGS.MONTHLY_YEAR_PLACEHOLDER}
              totalLabel={labels.SAVINGS.TOTAL_SAVINGS}
              monthlySavings={savings.monthly}
              year={savings.monthlyYear}
              totalSavings={totalSavings}
              iconClassName="icon-piggy-bank"
              hideIcon={isSavingsIconHidden}
              onChange={onMonthlySavingsChanged}
              currencyConfig={currencyConfig}
              onMonthlySavingsAmountBlur={onMonthlySavingsAmountBlur}
              onMonthlySavingsYearBlur={onMonthlySavingsYearBlur}
            />
          </SwiperSlide>
          <SwiperSlide>
            <RetirementIconCardComponent
              tabIndex={lumpsumTabindex}
              cardClassName={savings.hasInvalidLumpsumYear ? 'error error-border' : ''}
              titleLabel={labels.LUMPSUM_YEAR}
              valueLabel={labels.WHEN}
              valuePlaceholder={labels.LUMPSUM_YEAR}
              agePlaceholder={labels.LUMPSUM_YEAR_PLACEHOLDER}
              totalLabel={labels.LUMPSUM}
              retirementAge={savings.lumpsumYear}
              retirementMonthlyPayout={savings.lumpsum}
              iconClassName="icon-lumpsum"
              hideIcon={isLumpsumIconHidden}
              onRetirementChange={onLumpsumSavingsChanged}
              currencyConfig={currencyConfig}
              onRetirementAgeBlur={onLumpsumSavingsYearBlur}
              onRetirementMonthlyPayoutBlur={onLumpsumSavingsAmountBlur}
              isRetirementYearValid={isLumpsumYearValid}
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </WidgetComponent>
  );
}

export default ICanComponent;
