import { Dashboard } from "../../../default/components/dashboard/dashboard";
import { useAppSelector } from "../../store/hooks";
import { selectTenantConfig } from "../../store/tenant-config/tenant-config.slice";
import { Dashboard as QuirionDashboard} from "../../../quirion/components/dashboard/dashboard";
import { DashboardProvider } from "../../providers/dashboard-provider";
import { GridLoader } from "react-spinners";
import { useEffect, useState } from "react";
import { CustomLoaderComponent } from "../../../default/components/custom-loader-component/custom-loader-component";

export const DashboardSelectorComponent = () => {
    const tenantConfig = useAppSelector(selectTenantConfig);

    const loader = <CustomLoaderComponent>
        <GridLoader
          className="spinner"
          loading={tenantConfig.isLoading}
        />
      </CustomLoaderComponent>;

    const [isLoading, setIsLoading] = useState<boolean>(tenantConfig.isLoading);
    const [layoutName, setLayoutName] = useState<string | undefined>(tenantConfig.layout?.name);

    useEffect(() => {
        setIsLoading(tenantConfig.isLoading);
    }, [tenantConfig.isLoading]);

    useEffect(() => {
        setLayoutName(tenantConfig.layout?.name);
    }, [tenantConfig.layout]);

    if (isLoading) {
        return loader;
    }

    let content = <Dashboard />;

    let hasLandingModal = true;
    if (!isLoading && layoutName && ['QUIR', 'QUI2'].includes(layoutName)) {
        hasLandingModal = false;
        content = <QuirionDashboard />;
    }
    
    return <DashboardProvider hasLandingModal={hasLandingModal}>
        {content}
    </DashboardProvider>;

}