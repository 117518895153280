import { ErrorMessages } from "../../../app.labels";

export enum NotificationType
{
    AGE_ERROR,
    YEAR_ERROR,
    ON_AGE_CHANGE_ERROR,
    LIQUIDITY_ERROR,
    MORTGAGE_ERROR,
    MORTGAGE_INFO,
    DREAM_ERROR,
    SECURE_FAMILY_ERROR,
    PRESERVE_CAPITAL_ERROR,
    SAVINGS_MONTHLY_ERROR,
    SAVINGS_LUMPSUM_ERROR,
    CONFIG_CHART,
    REPORT_DOWNLOAD_ERROR,
    REPORT_DOWNLOADED_SUCCESSFULLY
}

export interface NotificationMessage {
    type: NotificationType;
    message: string;
}

export interface Notifications {
    errorMessageLabels: ErrorMessages;
    errorMessages: NotificationMessage[],
    infoMessages: NotificationMessage[],
    warningMessages: NotificationMessage[]
}