import { CtaComponentLabels } from "./cta-component.labels";

export interface LanguageItem {
    DESC: string;
    VALUE: string;
}

export interface Language {
    TITLE:string;
    ENG:LanguageItem;
    DE:LanguageItem;
}

export interface MenuComponentLabels {
    LEGEND: string;
    PREVIOUS: string;
    PROPERTY: string;
    RESET: string;
    LANGUAGE: Language;
    RELOAD_CONFIRM: string;
    CONFIRM: string;
    RELOAD: string;
    DOWNLOAD: string;
    REPORT_TITLE: string;
    REPORT_ERROR_MESSAGE: string;
    REPORT_INFO_MESSAGE: string;
    CTA_TEXT: string;
}

export function fromMenuLabels(menuComponentLabels: MenuComponentLabels ,ctaLabels: CtaComponentLabels ) {
    return {
      ...menuComponentLabels,
      CTA_TEXT: ctaLabels.PERSONA.TITLE
    };
  }