import { LiabilityTypeItem, LiabilityTypeLabel } from "@flexfront/models";


const liabilityTypeLookup : Map<string, string> = new Map<string, string>([
    ["MORTGAGE", "mortgage"],
])
  
const liabilityIconLookup : Map<string, string> = new Map<string, string>([
    ["MORTGAGE", "icon-leverage"],
])

const liabilityAssosiatedLookup : Map<string, boolean> = new Map<string, boolean>([
    ["MORTGAGE", true],
])
  
export function GetLiabilityItems(properties: Map<string, LiabilityTypeLabel>): LiabilityTypeItem[] {
    const results: LiabilityTypeItem[] = [];
  
    properties.forEach((value, key) => {
      results.push({...value, Type: liabilityTypeLookup.get(key) ?? key.toLowerCase(), Icon: liabilityIconLookup.get(key) ?? "", IsAssosiated: liabilityAssosiatedLookup.get(key) ?? false});
    });
  
    return results;
}