import { WidgetComponent } from "@flexfront/ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { AssetTypeItem, LiabilityTypeItem } from "@flexfront/models";
import { useAssetsContext } from "../../../common/providers/assets-provider";
import CurrencyInputComponent from "../../../common/components/currency-input-component/currency-input-component";
import { QuirionIconCardComponent } from "../quirion-icon-card-component/quirion-icon-card-component";

export function IHaveComponent() {

  const {
    labels,
    liabilityTypes,
    shouldRenderLiability,
    isAssetVisible,
    liabilityHasError,
    getLiabilityStateValue,
    getLiabilityChangedFunction,
    currencyConfig,
    getLiabilityBlurFunction,
    getAssetStateValue,
    getAssetsChangedFunction,
    getAssetsBlurFunction,
    swiperContainerRef,
    onSwiperContainerKeyUp,
    setSwiperInstance,

    assetTypes,
    tabIndex,

    cashIcon,
    bondsIcon,
    equityIcon,
    realestateIcon,
    mortgageIcon,

  } = useAssetsContext();

  function renderAssosiatedLiability(assetType: string,assetTabIndex : number) {
    if (assetType === "realestate") {
      const liabilityType = liabilityTypes.find(liability => liability.Type === "mortgage");

      if (liabilityType){
        return renderLiability(liabilityType, false,assetTabIndex);
      }
    }

    return;
  }

  function renderLiability(liabilityType: LiabilityTypeItem, ignoreAssosiated: boolean, tabIndex : number) {
    if (shouldRenderLiability(liabilityType, ignoreAssosiated) && isAssetVisible(liabilityType.Type)) {
      return (
        <SwiperSlide key={liabilityType.Type}>
          <QuirionIconCardComponent
            title={liabilityType.DISPLAY}
            icon={getLiabilityIcon(liabilityType.Type)}
            inputs={<CurrencyInputComponent
              title={labels.ASSET_TOTAL}
              classNamePrefix={classNamePrefix}
              value={getLiabilityStateValue(liabilityType.Type)}
              tabIndex={tabIndex}
              placeholder={labels.ASSET.AMOUNT}
              currencyConfig={currencyConfig}
              onChange={getLiabilityChangedFunction(liabilityType.Type)}
              onBlur={getLiabilityBlurFunction(liabilityType.Type)}
              max={9999999}
            />}
          />
        </SwiperSlide>
      )
    }
    else{
      return;  
    }     
  }

  function renderAsset(assetType : AssetTypeItem, assetTabIndex : number) {
    if (isAssetVisible(assetType.Type)) {
      return (
        <SwiperSlide key={assetType.Type}>
          <QuirionIconCardComponent
            title={assetType.DISPLAY}
            icon={getAssetIcon(assetType.Type)}
            inputs={<CurrencyInputComponent
              title={labels.ASSET_TOTAL}
              classNamePrefix={classNamePrefix}
              value={getAssetStateValue(assetType.Type)}
              tabIndex={assetTabIndex}
              placeholder={labels.ASSET.AMOUNT}
              currencyConfig={currencyConfig}
              onChange={getAssetsChangedFunction(assetType.Type)}
              onBlur={getAssetsBlurFunction(assetType.Type)}
              max={9999999}
            />}
          />
        </SwiperSlide>
      )
    }
    return;
  }

  function getAssetIcon(assetType: string) {
    switch (assetType){
      case "realestate":
        return realestateIcon;
      case "bonds":
        return bondsIcon;
      case "equity":
        return equityIcon;
      case "cash":
        return cashIcon;
      default:
        throw new Error(`${assetType} is not currently handled yet`);
    }
  }

  function getLiabilityIcon(liabilityType: string) {
    switch (liabilityType){
      case "mortgage":
        return mortgageIcon;
      default:
        throw new Error(`${liabilityType} is not currently handled yet`);
    }
  }

  let localTabIndex = tabIndex;
  const classNamePrefix = 'quirion';
  
  return (
    <div>
      <label
        className={`${classNamePrefix}-widget-title`}>
          {labels.TITLE}
      </label>
      <WidgetComponent
        isExpanded={false}
        className={`${classNamePrefix}-widget`}>
        <div
          className={`${classNamePrefix}-swiper-component`}
          ref={swiperContainerRef}
          onKeyUp={onSwiperContainerKeyUp}>

          <Swiper
            onSwiper={setSwiperInstance}
            slidesPerView={2}
            spaceBetween={10}
            pagination={{ clickable: true, enabled: true }}
            modules={[Pagination]}
          >
            {
              assetTypes.map((assetType) => {
                const asset = renderAsset(assetType, localTabIndex);
                localTabIndex += 1;
                const assosiatedLiability = renderAssosiatedLiability(assetType.Type, localTabIndex);
                if (assosiatedLiability) {
                  localTabIndex += 1;
                }

                return (
                  <div key={assetType.Type}>
                    {asset}
                    {assosiatedLiability}
                  </div>
                )              
              })
            }
            {
              liabilityTypes.map((liabilityType) => {
                return renderLiability(liabilityType, true, localTabIndex++); 
              })
            }
          </Swiper>
        </div>
      </WidgetComponent>
    </div>
  );
}

export default IHaveComponent;
