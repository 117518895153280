import { useEffect, useState } from "react";
import "./error-notification.component.scss";
import "./info-notification.component.scss";
import "./warning-notification.component.scss";
import { NotificationMessage } from "../../../common/store/notifications/notifications.state";
import { useDeviceBounds } from "../../../common/hooks/useDeviceBounds";
import { useIsInIframe } from "../../../common/hooks/useIsInIframe";

export interface NotificationComponentProps {
    type: string;
    notification?: NotificationMessage;
    isOpen: boolean;
    onClose?: (notification?: NotificationMessage) => void;
}

export const NotificationComponent = (props: NotificationComponentProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(props.isOpen);
    const isInsideIframe = useIsInIframe();
    const { isHorizontallyCompact, } = useDeviceBounds();
    const close = (notification?: NotificationMessage) => {
        if(isInsideIframe && isHorizontallyCompact){
            var element = document.getElementById('notification');
            element?.classList.remove(`show-${props.type}-notification`);
        }
        setIsOpen(false);
        if (props?.onClose) {
            props.onClose(notification);
        }
    };

    useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props.isOpen]);

    useEffect(() => {
        if(isInsideIframe && isHorizontallyCompact){
            setTimeout(() => {
              var element = document.getElementById('notification');
              element?.classList.add(`show-${props.type}-notification`);
             }, 0);
        }
    },[isOpen]);

    return (<>
        {isOpen && <div id="notification" className={`${props.type}-notification-container` + ` ${isInsideIframe ? `${props.type}-notification-container-iframe` : ``}` }>
            <div className={`${props.type}-notification-content`}>
                <i className={`${props.type}-notification-icon ${props.type}-notification-icon--${props.type} icon-${props.type}`}></i>
                {props.notification ?
                <span className={`${props.type}-notification-content-message`} dangerouslySetInnerHTML={{ __html: props.notification.message }}></span>
                :''
                }
                <span className={`${props.type}-notification-icon button-close button-close--notification icon-times`} onClick={() => close(props.notification)} />
            </div>
        </div>}
    </>);
}