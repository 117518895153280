import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../common/store/hooks';
import {
  selectTimelineMarkerPositions,
  selectTimelineOrderedQuarterRanges,
  selectTimelineRangeOffsets,
  selectTimelineWidthPerQuarter,
  setMarkerPosition,
} from '../../../common/store/timeline/timeline.slice';
import { MarkerPosition } from '../../../common/store/timeline/timeline.state';

export interface TimelineMarkerComponentProps {
  name: string;
  quarter: number;
  iconClassName: string;
  icon?: string;
  rangeName?: string;
  order?: number;
}

export function TimelineMarkerComponent(props: TimelineMarkerComponentProps) {
  const standardLineHeight = 10;
  const widthPerQuarter = useAppSelector(selectTimelineWidthPerQuarter);
  const orderedQuarterRanges = useAppSelector(selectTimelineOrderedQuarterRanges);
  const rangeOffsets = useAppSelector(selectTimelineRangeOffsets);
  const markerPositions = useAppSelector(selectTimelineMarkerPositions);
  const dispatch = useAppDispatch();
  const [topOffset, setTopOffset] = useState<number>(0);
  const [leftOffset, setLeftOffset] = useState<number>(0);
  const [lineHeight, setLineHeight] = useState<number>(standardLineHeight);
  const [lineZIndex, setLineZIndex] = useState<number>(0);

  useEffect(() => {
    let top = 0;
    if (props.rangeName) {
      const rangeIndex = orderedQuarterRanges.findIndex((_:any) => _.name === props.rangeName);
      if (rangeIndex > -1) {
        top = rangeOffsets[rangeIndex];
      }
    } else if (rangeOffsets.length > 0) {
      top = rangeOffsets[0];
    }

    setTopOffset(top);

    const left = props.quarter * widthPerQuarter;
    const order = props.order ?? 0;
    setLeftOffset(left);
    dispatch(setMarkerPosition({ name: props.name, left: left, order: order }));
  }, [
    props.quarter,
    widthPerQuarter,
    orderedQuarterRanges,
    rangeOffsets,
    props.rangeName,
    props.name,
    props.order,
    dispatch,
  ]);

  useEffect(() => {
    if (props.order) {
      setLineZIndex(props.order * -1);
      dispatch(setMarkerPosition({ name: props.name, left: leftOffset, order: props.order }));
    }
  }, [props.order, leftOffset, dispatch, props.name]);

  useEffect(() => {
    let height = standardLineHeight + Math.abs(topOffset);
    const marker = markerPositions.find((_:any) => _.name === props.name);
    if (marker) {
      const index = marker.order === 0 ? 0 : getHeightIndex(marker, markerPositions);
      const extraHeight = index * 25;
      height = height + extraHeight;
    }

    setLineHeight(height);
  }, [leftOffset, markerPositions, props.name, props.order]);

  function getHeightIndex(marker: MarkerPosition, markers: MarkerPosition[]) {
    let calculatedIndex = 0;
    let minLeft = marker.left - 25;
    if (minLeft < 0) {
      minLeft = 0;
    }
    const maxLeft = marker.left + 25;

    const validMarkers = markers.filter((_) => _.name !== marker.name);
    const markersWithinRange = validMarkers.filter((_) => _.left >= minLeft && _.left <= maxLeft).sort((_) => _.order);
    const isSharingSpace = markersWithinRange.length > 0;

    if (isSharingSpace) {
      const markersAboveCurrent = markersWithinRange.filter((_) => _.order < marker.order);

      if (markersAboveCurrent.length === 1) {
        calculatedIndex = 1;
      } else if (markersAboveCurrent.length > 1) {
        let markersToInclude = [...markersAboveCurrent];

        for (const markerWithinRange of markersAboveCurrent) {
          if (markersToInclude.length === 1) break;
          const isNotInRangeWithRemainingMarkers =
            markersAboveCurrent.filter(
              (_) => _.name !== markerWithinRange.name && !isWithinRange(markerWithinRange.left, _.left)
            ).length > 0;
          if (isNotInRangeWithRemainingMarkers) {
            markersToInclude = [...markersToInclude.filter((_) => _.name !== markerWithinRange.name)];
          }
        }

        calculatedIndex = markersToInclude.length;
      }

      return calculatedIndex;
    }

    return 0;
  }

  function isWithinRange(leftA: number, leftB: number) {
    let minLeft = leftA - 25;
    if (minLeft < 0) {
      minLeft = 0;
    }
    const maxLeft = leftA + 25;

    return leftB >= minLeft && leftB <= maxLeft;
  }

  return (
    <div
      className={`timeline-marker ${props.name}-timeline-marker`}
      style={{ left: leftOffset, top: topOffset, zIndex: lineZIndex }}
    >
      <div
        className={`timeline-marker-line ${props.name}-timeline-marker-line ${
          props.rangeName ? '' : `timeline-marker-line-dashed ${props.name}-timeline-marker-line-dashed`
        }`}
        style={{ height: lineHeight }}
      />
      <div className={`timeline-marker-icon-container ${props.name}-timeline-marker-icon-container`}>
        <div
          className={`timeline-marker-icon ${props.icon ? '' : props.iconClassName} ${props.name}-timeline-marker-icon`}
          dangerouslySetInnerHTML={{ __html: props.icon ?? '' }}/>
      </div>
    </div>
  );
}

export default TimelineMarkerComponent;
