import { ChangeEvent, useState } from 'react';
import { defaultCurrencyInputFormatter } from '../../../common/shared/locale';
import { saveRetirement, selectRetirementState } from '../../../common/store/goals/goals.slice';
import { useAppDispatch, useAppSelector } from '../../../common/store/hooks';
import { CurrencyConfig, MoneyInput } from '@flexfront/ui/react';

export interface RetirementEditComponentProps {
    ageLabel: string;
    payoutLabel: string;
    minAge: number;
    maxAge: number;
    itemConfirmLabel?: string;
    onRequiresUpdate?:  (age: number,amount: number) => void;
    currencyConfig?: CurrencyConfig;
}

export function RetirementEditComponent(props: RetirementEditComponentProps) {
  const retirement = useAppSelector(selectRetirementState);
  const [retirementAge, setRetirementAge] = useState<number>(retirement.retirementAge);
  const [retirementMonthlyPayout, setRetirementMonthlyPayout] = useState<number>(retirement.retirementMonthlyPayout);
  const dispatch = useAppDispatch();

  function onRetirementAgeChange(event: ChangeEvent<HTMLInputElement>): void {
    setRetirementAge(event.target.valueAsNumber);
  }

  function onRetirementMonthlyPayoutChange(value: number) {
    setRetirementMonthlyPayout(value);
  }

  function SaveValues(){
    if (props.onRequiresUpdate) {
      props.onRequiresUpdate(retirementAge,retirementMonthlyPayout);
    }
  }

  return (
    <>
      <div className="edit-container-field-group">
        <div className="edit-container-field edit-container-field-label">{props.ageLabel}</div>
        <div className="edit-container-field edit-container-field-value">{retirementAge}</div>
      </div>
      <div className="edit-container-slider-container">
        <div className="edit-container-slider-label">{props.minAge}</div>
        <input
          className="edit-container-slider-slider"
          type="range"
          min={props.minAge}
          max={props.maxAge}
          step={1}
          value={retirementAge}
          onChange={onRetirementAgeChange}
        />
        <div className="edit-container-slider-label">{props.maxAge}</div>
      </div>
      <div className="money-input-container">
        <div className="money-input-label">{props.payoutLabel}</div>
      </div>
      <MoneyInput
        inputNumber={retirementMonthlyPayout}
        currencyConfig={props.currencyConfig}
        currencyFormatter={defaultCurrencyInputFormatter}
        showCurrencySymbol={true}
        groupedClassNameSuffix="border"
        showIncrementAndDecrement={true}
        stepValue={500}
        onChange={onRetirementMonthlyPayoutChange}
        maxValue={9999999}
      />
      {props.itemConfirmLabel && <button
          onClick={SaveValues}
          className='button button--big button--big--solid edit-container-item-confirm'>
            {props.itemConfirmLabel}
        </button>}
    </>
  );
}
