import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorMessages } from "../../../app.labels";
import { revertAll } from "../hooks";
import { RootState } from "../store";
import { NotificationMessage, Notifications, NotificationType } from "./notifications.state";

export const NOTIFICATIONS_FEATURE_KEY = "notifications";

const initialState: Notifications = {
    errorMessageLabels: {
      GOAL_OR_CONTRIBUTION_AGE_CONFLICT_ERROR_MESSAGE: "",
      YEAR_ERROR_MESSAGE: ""
    },
    errorMessages: [],
    infoMessages: [],
    warningMessages: []
};

export const notificationsSlice = createSlice({
    name: NOTIFICATIONS_FEATURE_KEY,
    initialState,
    reducers: {
      addErrorMessage: (state, action: PayloadAction<NotificationMessage>) => {
        const errorMessages = [...state.errorMessages];
        const errorMessageIndex = errorMessages.findIndex(n => n.type === action.payload.type);
        if (errorMessageIndex > -1) {
          errorMessages.splice(errorMessageIndex, 1);
        }
        errorMessages.unshift(action.payload);

        state = {
          ...state,
          errorMessages: errorMessages
        };
        return state;
      },
      removeErrorMessage: (state, action: PayloadAction<NotificationType | undefined>) => {
        const errorMessages = [...state.errorMessages];
        const errorMessageIndex = errorMessages.findIndex(n => n.type === action.payload);
        if (errorMessageIndex > -1) {
          errorMessages.splice(errorMessageIndex, 1);
        }
        
        state = {
          ...state,
          errorMessages: errorMessages
        };
        return state;
      },
      addInfoMessage: (state, action: PayloadAction<NotificationMessage>) => {
        const infoMessages = [...state.infoMessages];
        const infoMessageIndex = infoMessages.findIndex(n => n.type === action.payload.type);
        if (infoMessageIndex > -1) {
          infoMessages.splice(infoMessageIndex, 1);
        }
        infoMessages.unshift(action.payload);

        state = {
          ...state,
          infoMessages: infoMessages
        };
        return state;
      },
      removeInfoMessage: (state, action: PayloadAction<NotificationType | undefined>) => {
        const infoMessages = [...state.infoMessages];
        const infoMessageIndex = infoMessages.findIndex(n => n.type === action.payload);
        if (infoMessageIndex > -1) {
          infoMessages.splice(infoMessageIndex, 1);
        }

        state = {
          ...state,
          infoMessages: infoMessages
        };
        return state;
      },
      addwarningMessage: (state, action: PayloadAction<NotificationMessage>) => {
        const warningMessages = [...state.warningMessages];
        const warningMessagesIndex = warningMessages.findIndex(n => n.type === action.payload.type);
        if (warningMessagesIndex > -1) {
          warningMessages.splice(warningMessagesIndex, 1);
        }
        warningMessages.unshift(action.payload);

        state = {
          ...state,
          warningMessages: warningMessages
        };
        return state;
      },
      removewarningMessage: (state, action: PayloadAction<NotificationType | undefined>) => {
        const warningMessages = [...state.warningMessages];
        const warningMessagesIndex = warningMessages.findIndex(n => n.type === action.payload);
        if (warningMessagesIndex > -1) {
          warningMessages.splice(warningMessagesIndex, 1);
        }

        state = {
          ...state,
          warningMessages: warningMessages
        };
        return state;
      },
      setErrorMessageLabels: (state, action: PayloadAction<ErrorMessages>) => {
        state = { ...state, errorMessageLabels: action.payload };
        return state;
      },
    },
    extraReducers: (builder) => builder.addCase(revertAll, (state) => {
      state.errorMessages = initialState.errorMessages;
      state.infoMessages = initialState.infoMessages;
      return state;
    }),
  });
  
  export const { addErrorMessage, removeErrorMessage, setErrorMessageLabels, addInfoMessage, removeInfoMessage, addwarningMessage, removewarningMessage } = notificationsSlice.actions;
  
  export const selectNotificationsState = createSelector(
    (state: RootState) => state.notifications,
    (notifications) => notifications
  );
  
  export default notificationsSlice;