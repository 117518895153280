import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { getCurrencyFormattedAmount } from '../../../common/shared/locale';
import { useDeviceBounds } from '../../../common/hooks/useDeviceBounds';
import { CurrencyConfig } from '@flexfront/ui/react';

export interface GoalAchievementComponentProps {
  title: string;
  shortfallLabel: string;
  achievementFraction: number;
  shortfall: number;
  iconClassName: string;
  currencyConfig?: CurrencyConfig;
}

export function GoalAchievementComponent(props: GoalAchievementComponentProps) {
  const { isHorizontallyCompact } = useDeviceBounds();

  const layout = isHorizontallyCompact ? (
    <div className="results-component-goal">
      <span className="results-component-goal-name">{props.title}</span>
      <div className="results-component-goal-progress">
        <CircularProgressbarWithChildren
          minValue={0}
          maxValue={1}
          value={props.achievementFraction}
          text={`${(props.achievementFraction * 100).toFixed()}%`}
        />
      </div>
      {props.shortfall > 0 && props.achievementFraction < 0.99 && (
        <>
        <span className="results-component-goal-shortfall">
          {props.shortfallLabel}
        </span>
        <span className="results-component-goal-shortfall">
          {getCurrencyFormattedAmount(props.shortfall, props.currencyConfig)}
        </span>
        </>
      )}
    </div>
  ) : (
    <div className="results-component-goal">
      <div className="results-component-goal-progress">
        <CircularProgressbarWithChildren
          minValue={0}
          maxValue={1}
          value={props.achievementFraction}
          text={`${(props.achievementFraction * 100).toFixed()}%`}
        />
      </div>
      <div className="results-component-goal-group">
        <i className={`results-component-goal-icon ${props.iconClassName}`} />
        <span className="results-component-goal-name">{props.title}</span>
        {props.shortfall > 0 && props.achievementFraction < 0.99 && (
          <span className="results-component-goal-shortfall">
            {props.shortfallLabel}: {getCurrencyFormattedAmount(props.shortfall, props.currencyConfig)}
          </span>
        )}
      </div>
    </div>
  );

  return layout;
}

export default GoalAchievementComponent;
