import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import {
  fetchTenantConfig,
  fetchTenantIconConfig,
  fetchTenantLayoutConfig,
  fetchTenantOrderConfig,
  selectTenantConfig,
  selectTenantConfigColors,
  setTenantColors,
  setTenantConfig,
  updateTenantConfig,
} from "../../store/tenant-config/tenant-config.slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import "./config-component.scss";
import { TenantColorConfig, TenantConfigState } from "../../store/tenant-config/tenant-config.state";
import { selectLayouts } from "../../store/layout/layouts.slice";
import Dropdown from "react-dropdown";
import { currencies } from "../../shared/locale";
import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from "../../hooks/useTrackEvent";
import { useComponentVisibility } from "../../hooks/useComponentVisibility";
import { GetInflationOptions } from "@flexfront/utils";
import { InflationOptions } from "@flexfront/models";
import ExpandCollapse from "../expand-collapse-component/expand-collapse-component";

export interface ConfigComponentProps {
  onDelete?: (template: TenantConfigState) => void;
}

export function ConfigComponent(props: ConfigComponentProps) {
  const abortController = new AbortController();
  const dispatch = useAppDispatch();
  const layouts = useAppSelector(selectLayouts);
  const tenantConfig = useAppSelector(selectTenantConfig);
  const tenantConfigColors = useAppSelector(selectTenantConfigColors);

  const [selectedTemplateFile, setSelectedTemplateFile] = useState<File | null>(null);
  const [selectedLogoFile, setSelectedLogoFile] = useState<File>();
  const [previewLogo, setPreviewLogo] = useState<string | undefined>(
    tenantConfig.logo
  );
  
  const [selectedThisIsMeMobileLogoFile, setSelectedThisIsMeMobileLogoFile] = useState<File>();
  const [previewThisIsMeMobileLogo, setPreviewThisIsMeMobileLogo] = useState<string | undefined>(
    tenantConfig.thisIsMeMobileLogo
  );

  const [selectedMobileLogoFile, setSelectedMobileLogoFile] = useState<File>();
  const [previewMobileLogo, setPreviewMobileLogo] = useState<string | undefined>(
    tenantConfig.mobileLogo
  );
  
  const [selectedReportLogoFile, setSelectedReportLogoFile] = useState<File>();
  const [previewReportLogo, setPreviewReportLogo] = useState<string | undefined>(
    tenantConfig.reportLogo
  );

  const inflationOption = GetInflationOptions().map((option) => ({
    value: option.TYPE,
    label: option.DISPLAY,
  }));

  const isDreamHidden = useComponentVisibility("I_WANT_DREAM");
  const isLegacyHidden = useComponentVisibility("I_WANT_LEGACY");
  const isSecureFamilyHidden = useComponentVisibility("I_WANT_SECURE_FAMILY");

  const trackEvent = useTrackEvent();

  const reset = (guid: string | undefined) => {
    dispatch(fetchTenantConfig({name:guid, abortSignal: abortController.signal}));
  };

  const getIsClimateSenarioHidden = () => {
    return tenantConfig.isClimateScenarioHidden == true ? {value: "true", label: "Disable"} : {value: "false", label: "Enable"};
 }

 const getIsReportDownloadEnabled = () => {
  return tenantConfig.isReportDownloadEnabled ? {value: "true", label: "Activate"} : {value: "false", label: "Deactivate"};
 }

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    trackEvent({ category: trackEventCategories.CONFIG, action: trackEventActions.CLICK, name: trackEventNames.SUBMIT, value: tenantConfig.templateId });
    event.preventDefault();
    dispatch(updateTenantConfig(tenantConfig));
  };

  const onIsActiveChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isActive = event.target.checked;
    dispatch(setTenantConfig({ ...tenantConfig, isActive: isActive }));
  };

  const onTenantNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setTenantConfig({ ...tenantConfig, name: event.target.value }));
  };

  const onCompanyNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setTenantConfig({ ...tenantConfig, companyName: event.target.value }));
  };

  const onAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setTenantConfig({ ...tenantConfig, address: event.target.value }));
  };

  const onPostalCodeAndCityChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setTenantConfig({ ...tenantConfig, postalCodeAndCity: event.target.value }));
  };

  const onwebsiteChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setTenantConfig({ ...tenantConfig, website: event.target.value }));
  };

  const onApiKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setTenantConfig({ ...tenantConfig, apiKey: event.target.value }));
  };

  const onScenarioSpaceAndVersionChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setTenantConfig({ ...tenantConfig, scenarioSpaceAndVersion: event.target.value }));
  };

  const onTenantSiteIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setTenantConfig({ ...tenantConfig, siteId: event.target.valueAsNumber }));
  };

  const onTenantDefaultLangChange = (newLang: any) => {
    dispatch(setTenantConfig({ ...tenantConfig, defaultLang: newLang.value }));
  };

  const onTenantCurrencySymbolChange = (newCurrencySymbol: any) => {
    dispatch(setTenantConfig({ ...tenantConfig, currency: newCurrencySymbol.value }));
  };

  const onInflationOptionChange = (newInflationOprtion: any) => {
    dispatch(setTenantConfig({ ...tenantConfig, inflation: newInflationOprtion.value }));
  };

  const onClimateSenarioChange = (isHidden: any) => {
    const value = isHidden.value === "true";
    dispatch(setTenantConfig({ ...tenantConfig, isClimateScenarioHidden:  value }));
  };

  const onTenantIsReportDownloadEnabledChange = (isActive: any) => {
    const value = isActive.value === "true";
    dispatch(setTenantConfig({ ...tenantConfig, isReportDownloadEnabled:  value }));
  };

  const onColorChange = (
    property: string,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const colorMap = new Map<string, string>();
    for (const [key, value] of tenantConfigColors) {
      colorMap.set(key, value);
    }
    colorMap.set(property, event.target.value);

    const colors: TenantColorConfig[] = [];
    for (const [key, value] of colorMap) {
      colors.push({ name: key, value: value });
    }

    dispatch(setTenantColors(colors));
  };

  const rgbToHex = (value: string, forceRemoveAlpha: boolean) => {
    const hex = value.replace(/^rgba?\(|\s+|\)$/g, '') 
      .split(',') 
      .filter((string, index) => !forceRemoveAlpha || index !== 3)
      .map(string => parseFloat(string)) 
      .map((number, index) => index === 3 ? Math.round(number * 255) : number) 
      .map(number => number.toString(16)) 
      .map(string => string.length === 1 ? "0" + string : string) 
      .join("");
    return "#" + hex;
  };

  const getColor = (key: string) => {
    const value = tenantConfigColors.get(key);
    if (value?.startsWith('rgb(')) {
      return rgbToHex(value,false);
    }
    else if (value?.startsWith('rgba(')){
      return rgbToHex(value, true);
    }
    return value;
  }

  const onLayoutChange = (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(fetchTenantLayoutConfig(+event.target.value));
    dispatch(fetchTenantIconConfig(+event.target.value));
    dispatch(fetchTenantOrderConfig(+event.target.value));
  }

  const logoInputRef = useRef<HTMLInputElement>(null);

  const onLogoChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedLogoFile(event.target.files[0]);
    }
  };

  const onLogoClear = () => {
    if (logoInputRef.current) {
      logoInputRef.current.value = '';
      setSelectedLogoFile(undefined);
      setPreviewLogo(undefined);
      dispatch(setTenantConfig({ ...tenantConfig, logo: undefined }));
    }
  };

  const thisIsMeMobileLogoInputRef = useRef<HTMLInputElement>(null);

  const onThisIsMeMobileLogoChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedThisIsMeMobileLogoFile(event.target.files[0]);
    }
  };

  const onThisIsMeMobileLogoClear = () => {
    if (thisIsMeMobileLogoInputRef.current) {
      thisIsMeMobileLogoInputRef.current.value = '';
      setSelectedThisIsMeMobileLogoFile(undefined);
      setPreviewThisIsMeMobileLogo(undefined);
      dispatch(setTenantConfig({ ...tenantConfig, thisIsMeMobileLogo: undefined }));
    }
  };

  const mobileLogoInputRef = useRef<HTMLInputElement>(null);

  const onMobileLogoChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedMobileLogoFile(event.target.files[0]);
    }
  };

  const onMobileLogoClear = () => {
    if (mobileLogoInputRef.current) {
      mobileLogoInputRef.current.value = '';
      setSelectedMobileLogoFile(undefined);
      setPreviewMobileLogo(undefined);
      dispatch(setTenantConfig({ ...tenantConfig, mobileLogo: undefined }));
    }
  };

  const reportLogoInputRef = useRef<HTMLInputElement>(null);

  const onReportLogoChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedReportLogoFile(event.target.files[0]);
    }
  };

  const onReportLogoClear = () => {
    if (reportLogoInputRef.current) {
      reportLogoInputRef.current.value = '';
      setSelectedReportLogoFile(undefined);
      setPreviewReportLogo(undefined);
      dispatch(setTenantConfig({ ...tenantConfig, reportLogo: undefined }));
    }
  };

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    if (!selectedLogoFile) {
      return;
    }

    const objectUrl = URL.createObjectURL(selectedLogoFile);
    setPreviewLogo(objectUrl);

    toBase64(selectedLogoFile).then((result) => {
      dispatch(setTenantConfig({ ...tenantConfig, logo: result as string }));
    });

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedLogoFile]);

  useEffect(() => {
    if (!selectedThisIsMeMobileLogoFile) {
      return;
    }

    const objectUrl = URL.createObjectURL(selectedThisIsMeMobileLogoFile);
    setPreviewThisIsMeMobileLogo(objectUrl);

    toBase64(selectedThisIsMeMobileLogoFile).then((result) => {
      dispatch(setTenantConfig({ ...tenantConfig, thisIsMeMobileLogo: result as string }));
    });

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedThisIsMeMobileLogoFile]);

  useEffect(() => {
    if (!selectedMobileLogoFile) {
      return;
    }

    const objectUrl = URL.createObjectURL(selectedMobileLogoFile);
    setPreviewMobileLogo(objectUrl);

    toBase64(selectedMobileLogoFile).then((result) => {
      dispatch(setTenantConfig({ ...tenantConfig, mobileLogo: result as string }));
    });

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedMobileLogoFile]);

  useEffect(() => {
    if (!selectedReportLogoFile) {
      return;
    }

    const objectUrl = URL.createObjectURL(selectedReportLogoFile);
    setPreviewLogo(objectUrl);

    toBase64(selectedReportLogoFile).then((result) => {
      dispatch(setTenantConfig({ ...tenantConfig, reportLogo: result as string }));
    });

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedReportLogoFile]);

  useEffect(() => {
    setPreviewLogo(tenantConfig.logo);
  }, [tenantConfig.logo]);

  useEffect(() => {
    setPreviewThisIsMeMobileLogo(tenantConfig.thisIsMeMobileLogo);
  }, [tenantConfig.thisIsMeMobileLogo]);

  useEffect(() => {
    setPreviewMobileLogo(tenantConfig.mobileLogo);
  }, [tenantConfig.mobileLogo]);

  useEffect(() => {
    setPreviewReportLogo(tenantConfig.reportLogo);
  }, [tenantConfig.reportLogo]);

  function onDeleteClicked() {
    if (props.onDelete)
    {
      props.onDelete(tenantConfig);
    }
  }

  const handleTemplateUploadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      setSelectedTemplateFile(files[0]);
    }
  };

  const onUploadTemplateClicked = async () => {
    if (selectedTemplateFile) {
      const fileReader = new FileReader();
      fileReader.onload = async (e) => {
        try {
          const templateJsonData = JSON.parse(e.target?.result as string);
          const uploadedTenantConfig = templateJsonData as TenantConfigState;
          await dispatch(updateTenantConfig({...uploadedTenantConfig, templateId: tenantConfig.templateId }));
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      };

      fileReader.readAsText(selectedTemplateFile);
    }
  }

  const templateIdReplacer = (key: string, value: any) => {
    return ['templateId','layout','colorId'].includes(key) ? undefined : value;
  };

  const onDownloadTemplateClicked = async () => {
    const tenantConfigClone = {...tenantConfig};

    const jsonString = JSON.stringify(tenantConfigClone, templateIdReplacer, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });

    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = `${tenantConfig.name}-template.json`;
    downloadLink.click();
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="control-group">
        <div className="config-title">
          <span className="black">Update via Upload</span>
        </div>
        <div className="config-tools">
          <input
              type="file"
              onChange={handleTemplateUploadChange} />
          {tenantConfig && <span className="icon-upload" onClick={onUploadTemplateClicked}/>}
        </div>
      </div>
      <div className="control-group">
        <div className="config-title">
          <span className="black">Tenant name</span>
          <div className="config-tools">
            {tenantConfig && <span className="icon-download" onClick={onDownloadTemplateClicked}/>}
            {props.onDelete && tenantConfig.templateId > 0 && <span className="icon-trash-can" onClick={onDeleteClicked}/>}
          </div>
        </div>
        <input
            className="config-input"
            value={tenantConfig.name}
            onChange={onTenantNameChange}
          />
      </div>
      <hr></hr>
      <ExpandCollapse title={"Settings"} content={
        <>
          <div className="control-group">
            <div className="config-title">
              <span className="black">Company name</span>
            </div>
            <input
                className="config-input"
                value={tenantConfig.companyName}
                onChange={onCompanyNameChange}
              />
          </div>
          <div className="control-group">
            <div className="config-title">
              <span className="black">Address</span>
            </div>
            <input
                className="config-input"
                value={tenantConfig.address}
                onChange={onAddressChange}
              />
          </div>
          <div className="control-group">
            <div className="config-title">
              <span className="black">PostalCode and City</span>
            </div>
            <input
                className="config-input"
                value={tenantConfig.postalCodeAndCity}
                onChange={onPostalCodeAndCityChange}
              />
          </div>
          <div className="control-group">
            <div className="config-title">
              <span className="black">Website</span>
            </div>
            <input
                className="config-input"
                value={tenantConfig.website}
                onChange={onwebsiteChange}
              />
          </div>
          <div className="control-group">
            <div className="config-title">
              <span className="black">Scenariospace and Version</span>
            </div>
            <input
              type="text"
              className="config-input"
              value={tenantConfig.scenarioSpaceAndVersion}
              onChange={onScenarioSpaceAndVersionChange} />
          </div>
          <div className="control-group">
            <div className="config-title">
              <span className="black">API Key</span>
            </div>
            <input
              type="text"
              className="config-input"
              value={tenantConfig.apiKey}
              onChange={onApiKeyChange} />
          </div>
          <div className="control-group">
            <div className="config-title">
              <span className="black">Matomo Site ID</span>
            </div>
            <input
              type="number"
              className="config-input"
              value={tenantConfig.siteId}
              onChange={onTenantSiteIdChange} />
          </div>
          <div className="control-group">
            <div className="config-title">
              <span className="black">Default language</span>
            </div>
            <Dropdown
              className="config-input"
              arrowClosed={<span className="icon-down-arrow" />}
              arrowOpen={<span className="icon-up-arrow" />}
              baseClassName="Dropdown"
              options={[{value: "en", label: "English"}, {value: "de", label: "German"}]} 
              value={tenantConfig.defaultLang} 
              onChange={onTenantDefaultLangChange}/>
          </div>
          <br />
          <div className="control-group">
            <div className="config-title">
              <span className="black">Currency</span>
            </div>
            <Dropdown
              className="config-input"
              arrowClosed={<span className="icon-down-arrow" />}
              arrowOpen={<span className="icon-up-arrow" />}
              baseClassName="Dropdown"
              options={currencies} 
              value={tenantConfig.currency} 
              onChange={onTenantCurrencySymbolChange}/>
          </div>
          <br />
          <div className="control-group">
            <div className="config-title">
              <span className="black">Download PDF</span>
            </div>
            <Dropdown
              className="config-input"
              arrowClosed={<span className="icon-down-arrow" />}
              arrowOpen={<span className="icon-up-arrow" />}
              baseClassName="Dropdown"
              options={[{value: "false", label: "Deactivate"}, {value: "true", label: "Activate"}]} 
              value={getIsReportDownloadEnabled()} 
              onChange={onTenantIsReportDownloadEnabledChange}/>
          </div>
          <br />
          <div className="control-group">
            <div className="config-title">
              <span className="black">Inflation</span>
            </div>
            <Dropdown
              className="config-input"
              arrowClosed={<span className="icon-down-arrow" />}
              arrowOpen={<span className="icon-up-arrow" />}
              baseClassName="Dropdown"
              options={inflationOption} 
              value={tenantConfig.inflation!=null ? tenantConfig.inflation : InflationOptions.enable_per_individual_goal } 
              onChange={onInflationOptionChange}/>
          </div>
          <br />
          <div className="control-group">
            <div className="config-title">
              <span className="black">Climate Senario</span>
            </div>
            <Dropdown
              className="config-input"
              arrowClosed={<span className="icon-down-arrow" />}
              arrowOpen={<span className="icon-up-arrow" />}
              baseClassName="Dropdown"
              options={[{value: "false", label: "Enable"}, {value: "true", label: "Disable"}]} 
              value={getIsClimateSenarioHidden()} 
              onChange={onClimateSenarioChange}/>
          </div>
          <br />
          <div className="control-group active-status">
              <input
                  type="checkbox"
                  className="config-input"
                  checked={tenantConfig.isActive}
                  onChange={onIsActiveChange}
                />
                <label className="black">
                De-/activate{" "}
                <i className="fa-solid fa-circle-info" title="By default, tenant is checked, hence active and accessible. If unchecked, the app is not accessible anymore"></i>
              </label>  
          </div>
      <hr></hr>
        </>
      } />
      <hr></hr>
      <ExpandCollapse title={"Logo"} content={
        <>
          <div className="control-group">
            <label className="black">Logo For Tablet  <i className="fa-solid fa-circle-info" title="please upload logo of size 200x43"></i></label>
            {(selectedLogoFile || previewLogo) && (
              <img alt="logo" src={previewLogo} />
            )}
            <div className="config-input-file-group">
              <input
                ref={logoInputRef}
                title=""
                className={previewLogo? 'config-input-file':''}
                accept="image/png, image/jpg, image/jpeg, image/bmp, image/gif, image/webp, .ico, .svg"
                type="file"
                name="file"
                multiple={false}
                onChange={onLogoChange}
              />
              <button type="button" onClick={onLogoClear}>Clear</button>
            </div>
          </div>
          <div className="control-group">
            <label className="black">Logo1 For Mobile  <i className="fa-solid fa-circle-info" title="please upload logo of size 276x50.95"></i></label>
            {(selectedThisIsMeMobileLogoFile || previewThisIsMeMobileLogo) && (
              <img alt="logo1" src={previewThisIsMeMobileLogo} />
            )}
            <div className="config-input-file-group">
              <input
                ref={thisIsMeMobileLogoInputRef}
                title=""
                className={previewThisIsMeMobileLogo? 'config-input-file':''}
                accept="image/png, image/jpg, image/jpeg, image/bmp, image/gif, image/webp, .ico, .svg"
                type="file"
                name="file"
                multiple={false}
                onChange={onThisIsMeMobileLogoChange}
              />
              <button type="button" onClick={onThisIsMeMobileLogoClear}>Clear</button>
            </div>
          </div>
          <div className="control-group">
            <label className="black">Logo2 For Mobile  <i className="fa-solid fa-circle-info" title="please upload logo of size 44.92x24"></i></label>
            {(selectedMobileLogoFile || previewMobileLogo) && (
              <img alt="logo2" src={previewMobileLogo} />
            )}
            <div className="config-input-file-group">
              <input
                ref={mobileLogoInputRef}
                title=""
                className={previewMobileLogo? 'config-input-file':''}
                accept="image/png, image/jpg, image/jpeg, image/bmp, image/gif, image/webp, .ico, .svg"
                type="file"
                name="file"
                multiple={false}
                onChange={onMobileLogoChange}
              />
              <button type="button" onClick={onMobileLogoClear}>Clear</button>
            </div>
          </div>
          <div className="control-group">
            <label className="black">Logo For Report  <i className="fa-solid fa-circle-info" title="please upload logo of size 200x43"></i></label>
            {(selectedReportLogoFile || previewReportLogo) && (
              <img alt="logo" src={previewReportLogo} />
            )}
            <div className="config-input-file-group">
              <input
                ref={reportLogoInputRef}
                title=""
                className={previewLogo? 'config-input-file':''}
                accept="image/png, image/jpg, image/jpeg, image/bmp, image/gif, image/webp, .ico, .svg"
                type="file"
                name="file"
                multiple={false}
                onChange={onReportLogoChange}
              />
              <button type="button" onClick={onReportLogoClear}>Clear</button>
            </div>
          </div>
        </>
      } />
      <hr></hr>
      <ExpandCollapse title={"Text Color"} content={
        <>
          <div className="control-group">
            <label className="black">Fix color (white)</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("white")}
                onChange={(event) => onColorChange("white", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("white")}
                onChange={(event) => onColorChange("white", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Fix color (mobile only)</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("labelColor")}
                onChange={(event) => onColorChange("labelColor", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("labelColor")}
                onChange={(event) => onColorChange("labelColor", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Header text</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("headerText")}
                onChange={(event) => onColorChange("headerText", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("headerText")}
                onChange={(event) => onColorChange("headerText", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Header text(mobile only)</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("headerText1")}
                onChange={(event) => onColorChange("headerText1", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("headerText1")}
                onChange={(event) => onColorChange("headerText1", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Nudge</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("black")}
                onChange={(event) => onColorChange("black", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("black")}
                onChange={(event) => onColorChange("black", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Input color</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("accentInput")}
                onChange={(event) => onColorChange("accentInput", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("accentInput")}
                onChange={(event) => onColorChange("accentInput", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Landing Page</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("landingPageText")}
                onChange={(event) => onColorChange("landingPageText", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("landingPageText")}
                onChange={(event) => onColorChange("landingPageText", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Primary Button text</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("primaryButtonText")}
                onChange={(event) => onColorChange("primaryButtonText", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("primaryButtonText")}
                onChange={(event) => onColorChange("primaryButtonText", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Secondary Button text</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("secondaryButtonText")}
                onChange={(event) => onColorChange("secondaryButtonText", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("secondaryButtonText")}
                onChange={(event) => onColorChange("secondaryButtonText", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">DropDown text</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("dropdownText")}
                onChange={(event) => onColorChange("dropdownText", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("dropdownText")}
                onChange={(event) => onColorChange("dropdownText", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Menu text</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("menuText")}
                onChange={(event) => onColorChange("menuText", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("menuText")}
                onChange={(event) => onColorChange("menuText", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Legend text</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("legendText")}
                onChange={(event) => onColorChange("legendText", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("legendText")}
                onChange={(event) => onColorChange("legendText", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Disclaimer text</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("disclaimerText")}
                onChange={(event) => onColorChange("disclaimerText", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("disclaimerText")}
                onChange={(event) => onColorChange("disclaimerText", event)}
              />
            </div>
          </div>
        </>
      } />
      <hr></hr>
      <ExpandCollapse title={"Chart Components"} content={
        <>
          <div className="control-group">
            <label className="black">Chart Background</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("chartBackground")}
                onChange={(event) =>
                  onColorChange("chartBackground", event)
                }
              />
              <input
                type="color"
                className="config-color"
                value={getColor("chartBackground")}
                onChange={(event) =>
                  onColorChange("chartBackground", event)
                }
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Retirement area</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("primaryRetirementPlotband")}
                onChange={(event) =>
                  onColorChange("primaryRetirementPlotband", event)
                }
              />
              <input
                type="color"
                className="config-color"
                value={getColor("primaryRetirementPlotband")}
                onChange={(event) =>
                  onColorChange("primaryRetirementPlotband", event)
                }
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Wealth chart area</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("secondary0")}
                onChange={(event) => onColorChange("secondary0", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("secondary0")}
                onChange={(event) => onColorChange("secondary0", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Snapshot(previous values) area</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("accentSnapshot")}
                onChange={(event) => onColorChange("accentSnapshot", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("accentSnapshot")}
                onChange={(event) => onColorChange("accentSnapshot", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Circular chart area</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("circularChartArea")}
                onChange={(event) => onColorChange("circularChartArea", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("circularChartArea")}
                onChange={(event) => onColorChange("circularChartArea", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Circular chart component Background</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("circularChartComponentBackground")}
                onChange={(event) => onColorChange("circularChartComponentBackground", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("circularChartComponentBackground")}
                onChange={(event) => onColorChange("circularChartComponentBackground", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Asset Icons(Real estate)</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("accentAsset")}
                onChange={(event) => onColorChange("accentAsset", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("accentAsset")}
                onChange={(event) => onColorChange("accentAsset", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Mortgage Icons</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("accentLeverage")}
                onChange={(event) => onColorChange("accentLeverage", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("accentLeverage")}
                onChange={(event) => onColorChange("accentLeverage", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Retirement Icons</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("chartIcons")}
                onChange={(event) => onColorChange("chartIcons", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("chartIcons")}
                onChange={(event) => onColorChange("chartIcons", event)}
              />
            </div>
          </div>
          {!isLegacyHidden && <div className="control-group">
            <label className="black">Legacy Icons</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("legacyIcons")}
                onChange={(event) => onColorChange("legacyIcons", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("legacyIcons")}
                onChange={(event) => onColorChange("legacyIcons", event)}
              />
            </div>
          </div>}
          {!isDreamHidden && <div className="control-group">
            <label className="black">Dream Icons</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("dreamIcons")}
                onChange={(event) => onColorChange("dreamIcons", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("dreamIcons")}
                onChange={(event) => onColorChange("dreamIcons", event)}
              />
            </div>
          </div>}
          <div className="control-group">
            <label className="black">Capital preservation Icons</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("capitalPreservationIcons")}
                onChange={(event) => onColorChange("capitalPreservationIcons", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("capitalPreservationIcons")}
                onChange={(event) => onColorChange("capitalPreservationIcons", event)}
              />
            </div>
          </div>
          {!isSecureFamilyHidden && <div className="control-group">
            <label className="black">Secure my family Icons</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("secureMyFamilyIcons")}
                onChange={(event) => onColorChange("secureMyFamilyIcons", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("secureMyFamilyIcons")}
                onChange={(event) => onColorChange("secureMyFamilyIcons", event)}
              />
            </div>
          </div>}
        </>
      } />
      <hr></hr>
      <ExpandCollapse title={"Modals Colors"} content={
        <>
          <div className="control-group">
            <label className="black">App Background Color</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("appBackground")}
                onChange={(event) => onColorChange("appBackground", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("appBackground")}
                onChange={(event) => onColorChange("appBackground", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Header Icon Color</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("headerIcon")}
                onChange={(event) => onColorChange("headerIcon", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("headerIcon")}
                onChange={(event) => onColorChange("headerIcon", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Header Background</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("header")}
                onChange={(event) => onColorChange("header", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("header")}
                onChange={(event) => onColorChange("header", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Upper Background</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("primaryWidget0")}
                onChange={(event) => onColorChange("primaryWidget0", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("primaryWidget0")}
                onChange={(event) => onColorChange("primaryWidget0", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Lower Background</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("primaryWidget1")}
                onChange={(event) => onColorChange("primaryWidget1", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("primaryWidget1")}
                onChange={(event) => onColorChange("primaryWidget1", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Primary Background (mobile only)</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("backgroundForMobile")}
                onChange={(event) => onColorChange("backgroundForMobile", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("backgroundForMobile")}
                onChange={(event) => onColorChange("backgroundForMobile", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Secondary Background (mobile only)</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("secondaryBackgroundForMobile")}
                onChange={(event) => onColorChange("secondaryBackgroundForMobile", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("secondaryBackgroundForMobile")}
                onChange={(event) => onColorChange("secondaryBackgroundForMobile", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Goals Background</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("icardBackground")}
                onChange={(event) => onColorChange("icardBackground", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("icardBackground")}
                onChange={(event) => onColorChange("icardBackground", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Border color per modal, edit fields and dropdown</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("primaryBorder0")}
                onChange={(event) => onColorChange("primaryBorder0", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("primaryBorder0")}
                onChange={(event) => onColorChange("primaryBorder0", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Border color per component (e.g. Retirement)</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("secondaryBorder1")}
                onChange={(event) => onColorChange("secondaryBorder1", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("secondaryBorder1")}
                onChange={(event) => onColorChange("secondaryBorder1", event)}
              />
            </div>
          </div>
        </>
      } />
     <hr></hr>
     <ExpandCollapse title={"Fields Color"} content={
        <>
            <div className="control-group">
              <label className="black">Input Background (Inflation, PAGE)</label>
              <div className="config-input-group">
                <input
                  className="config-input"
                  value={tenantConfigColors.get("primaryInput")}
                  onChange={(event) => onColorChange("primaryInput", event)}
                />
                <input
                  type="color"
                  className="config-color"
                  value={getColor("primaryInput")}
                  onChange={(event) => onColorChange("primaryInput", event)}
                />
              </div>
            </div>
            <div className="control-group">
              <label className="black">Automated edit input field Background (Saving)</label>
              <div className="config-input-group">
                <input
                  className="config-input"
                  value={tenantConfigColors.get("disableInputBackgroung")}
                  onChange={(event) => onColorChange("disableInputBackgroung", event)}
                />
                <input
                  type="color"
                  className="config-color"
                  value={getColor("disableInputBackgroung")}
                  onChange={(event) => onColorChange("disableInputBackgroung", event)}
                />
              </div>
            </div>
            <div className="control-group">
              <label className="black">Primary Button Background</label>
              <div className="config-input-group">
                <input
                  className="config-input"
                  value={tenantConfigColors.get("primaryButton")}
                  onChange={(event) => onColorChange("primaryButton", event)}
                />
                <input
                  type="color"
                  className="config-color"
                  value={getColor("primaryButton")}
                  onChange={(event) => onColorChange("primaryButton", event)}
                />
              </div>
            </div>
            <div className="control-group">
              <label className="black">Secondary Button Background</label>
              <div className="config-input-group">
                <input
                  className="config-input"
                  value={tenantConfigColors.get("secondaryButton")}
                  onChange={(event) => onColorChange("secondaryButton", event)}
                />
                <input
                  type="color"
                  className="config-color"
                  value={getColor("secondaryButton")}
                  onChange={(event) => onColorChange("secondaryButton", event)}
                />
              </div>
            </div>
            <div className="control-group">
              <label className="black">Email Field Background</label>
              <div className="config-input-group">
                <input
                  className="config-input"
                  value={tenantConfigColors.get("emailBackground")}
                  onChange={(event) => onColorChange("emailBackground", event)}
                />
                <input
                  type="color"
                  className="config-color"
                  value={getColor("emailBackground")}
                  onChange={(event) => onColorChange("emailBackground", event)}
                />
              </div>
            </div>
        </>
      } />
      <hr></hr>
      <ExpandCollapse title={"Burger Menu Colors"} content={
        <>
          <div className="control-group">
            <label className="black">Menu Background Color</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("menuBackground")}
                onChange={(event) => onColorChange("menuBackground", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("menuBackground")}
                onChange={(event) => onColorChange("menuBackground", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Menu Border Color</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("menuBorder")}
                onChange={(event) => onColorChange("menuBorder", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("menuBorder")}
                onChange={(event) => onColorChange("menuBorder", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Menu icon Color</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("menuIcon")}
                onChange={(event) => onColorChange("menuIcon", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("menuIcon")}
                onChange={(event) => onColorChange("menuIcon", event)}
              />
            </div>
          </div>
        </>
      } />
      <hr></hr>
      <ExpandCollapse title={"Other Components"} content={
        <>
          <div className="control-group">
            <label className="black">Nudge Border color</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("nudgeBorder")}
                onChange={(event) => onColorChange("nudgeBorder", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("nudgeBorder")}
                onChange={(event) => onColorChange("nudgeBorder", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Nudge Background color</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("nudgeBackground")}
                onChange={(event) => onColorChange("nudgeBackground", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("nudgeBackground")}
                onChange={(event) => onColorChange("nudgeBackground", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Legend Border color</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("legendBorder")}
                onChange={(event) => onColorChange("legendBorder", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("legendBorder")}
                onChange={(event) => onColorChange("legendBorder", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Info Icon border Color</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("infoIconBorder")}
                onChange={(event) => onColorChange("infoIconBorder", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("infoIconBorder")}
                onChange={(event) => onColorChange("infoIconBorder", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Info Icon Color</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("infoIconBackground")}
                onChange={(event) => onColorChange("infoIconBackground", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("infoIconBackground")}
                onChange={(event) => onColorChange("infoIconBackground", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Carousel Background color(for nevigation dots)</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("sliderDotsBackground")}
                onChange={(event) => onColorChange("sliderDotsBackground", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("sliderDotsBackground")}
                onChange={(event) => onColorChange("sliderDotsBackground", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Edit Window background (mobile only)</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("editWindowBackground")}
                onChange={(event) => onColorChange("editWindowBackground", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("editWindowBackground")}
                onChange={(event) => onColorChange("editWindowBackground", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Deactive tab background (mobile only)</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("deactiveTabBackground")}
                onChange={(event) => onColorChange("deactiveTabBackground", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("deactiveTabBackground")}
                onChange={(event) => onColorChange("deactiveTabBackground", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Deactive tab text color (mobile only)</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("deactiveTabText")}
                onChange={(event) => onColorChange("deactiveTabText", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("deactiveTabText")}
                onChange={(event) => onColorChange("deactiveTabText", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Download Pdf Button text color</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("downloadButtonText")}
                onChange={(event) => onColorChange("downloadButtonText", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("downloadButtonText")}
                onChange={(event) => onColorChange("downloadButtonText", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Download Pdf Button Border color</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("downloadButtonBorder")}
                onChange={(event) => onColorChange("downloadButtonBorder", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("downloadButtonBorder")}
                onChange={(event) => onColorChange("downloadButtonBorder", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Download Pdf Button Background color</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("downloadButtonBackground")}
                onChange={(event) => onColorChange("downloadButtonBackground", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("downloadButtonBackground")}
                onChange={(event) => onColorChange("downloadButtonBackground", event)}
              />
            </div>
          </div>
        </>
      } />
      <hr></hr>
      <ExpandCollapse title={"Notifications"} content={
        <>
          <div className="control-group">
            <label className="black">Warning</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("warn")}
                onChange={(event) => onColorChange("warn", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("warn")}
                onChange={(event) => onColorChange("warn", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Inform</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("inform")}
                onChange={(event) => onColorChange("inform", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("inform")}
                onChange={(event) => onColorChange("inform", event)}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="black">Positive</label>
            <div className="config-input-group">
              <input
                className="config-input"
                value={tenantConfigColors.get("positive")}
                onChange={(event) => onColorChange("positive", event)}
              />
              <input
                type="color"
                className="config-color"
                value={getColor("positive")}
                onChange={(event) => onColorChange("positive", event)}
              />
            </div>
          </div>
        </>
      } />
      <hr></hr>
      <br />
      <span className="black">Layout</span>
      <div className="control-group">
        <div className="config-input-group">
          <select
            value={tenantConfig.layoutId}
            className="config-input"
            onChange={onLayoutChange}>
              {layouts.layouts.map((l: any) => <option key={l.layoutId} value={l.layoutId}>{l.name}</option>)}
          </select>
        </div>
      </div>
      <br />
      <div className="button-group button-group--card">
        <button
          type="button"
          className="button button--card"
          onClick={() => reset(tenantConfig.name)}
        >
          Cancel
        </button>
        <button type="submit" className="button button--card-solid" disabled={tenantConfig.name ? false : true}>
          Save
        </button>
      </div>
    </form>
  );
}

export default ConfigComponent;
