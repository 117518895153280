import { ReactNode } from 'react';
import { useTenantThisIsMeMobileLogo } from '../../../common/hooks/useTenantLogo';
import { LandingComponentLabels } from '../../../common/labels/landing-component.labels';
import './landing-component.scss';
import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from '../../../common/hooks/useTrackEvent';
import { NotificationComponent } from '../notification-component/notification.component';
import { useAppDispatch, useAppSelector } from '../../../common/store/hooks';
import { removeErrorMessage, selectNotificationsState } from '../../../common/store/notifications/notifications.slice';

export interface LandingComponentProps {
  children: ReactNode;
  labels: LandingComponentLabels;
}

export function LandingComponent(props: LandingComponentProps) {
  const tenantLogo = useTenantThisIsMeMobileLogo();
  const notifications = useAppSelector(selectNotificationsState);
  const dispatch = useAppDispatch();
  const trackEvent = useTrackEvent();


  return (
    <>
      <NotificationComponent
          isOpen={notifications.errorMessages.length > 0}
          type="error"
          notification={notifications.errorMessages[0]}
          onClose={(notification) => dispatch(removeErrorMessage(notification?.type))}
        />
    <div className="landing">
      <div className="landing-header">
        <img className="landing-header-logo" src={tenantLogo} alt="logo" />
      </div>
      <div className="landing-text">
        <div className="landing-text-title">{props.labels.WELCOME_TITLE}</div>
        <div className="landing-text-body" dangerouslySetInnerHTML={{ __html: props.labels.WELCOME_BODY }} />
      </div>
      <div className="landing-children">{props.children}</div>
      <div className="landing-disclaimer"
        dangerouslySetInnerHTML={{ __html: props.labels.DISCLAIMER }}>
      </div>
    </div>
    </>
  );
}

export default LandingComponent;
