import Popup from "reactjs-popup";

export interface ConfirmationModalComponentProps {
    header: string;
    body: string;
    buttonText: string;
    isOpen: boolean;
    closePopup: () => void;
    confirm: () => void;
}

export function ConfirmationModalComponent(props: ConfirmationModalComponentProps)
{
    return (
        <Popup
          open={props.isOpen}
          onClose={props.closePopup}
          position="center center"
          arrow={false}
          modal={true}
        >
          <div className="confirm-popup">
            <div className="modal-title">
              <span className="header-title">{props.header}</span>
              <span className="modal-title-icon button-close icon-times" style={{alignSelf: 'center', textAlign: 'center', marginRight: 0}} onClick={props.closePopup}/>
            </div>
            <div className="row" style={{ display: 'flex', height: '1px', background: "lightgray"}}/> 
            <div className="confirm-popup-body">
              <span>{props.body}</span>
            </div>
            <div>
            <div className="row" style={{ display: 'flex', height: '1px', background: "lightgray"}}/> 
            <div className="popup-button-div">
              <button
                className="button button--solid"
                onClick={props.confirm}
              >
                {props.buttonText}
              </button>
            </div>
            </div>
          </div>
        </Popup>
    );
}