import { CurrencyConfig, MoneyInput, selectAllText } from "@flexfront/ui/react";
import { ChangeEvent, FocusEvent, useEffect, useRef, useState } from "react";
import "./icon-card-component.scss";
import moment from "moment";
import { DateErrorLabels } from "@flexfront/models";
import { defaultCurrencyInputFormatter } from "../../../common/shared/locale";

export interface PreserveCapitalIconCardComponentProps {
  cardClassName?: string;
  iconClassName: string;
  tabIndex?: number;
  hideIcon?: boolean;
  titleLabel: string;
  valueLabel: string;
  valuePlaceholder?: string;
  totalLabel: string;
  minYear?: number;
  maxYear?: number;
  fromYear: number;
  toYear: number;
  amount: number;
  onChange?: (fromDate: string, toDate: string, amount: number) => void;
  allowSelection?: boolean;
  isSelected?: boolean;
  displaySimple?: boolean;
  onSelectionChange?: (isSelected: boolean) => void;
  dateErrorLabels?: DateErrorLabels;
  onValidate?: (hasError: boolean) => void;
  currencyConfig?: CurrencyConfig;
  onFromYearBlur?: () => void;
  onToYearBlur?: () => void;
  onAmountBlur?: () => void;
}

export function PreserveCapitalIconCardComponent(props: PreserveCapitalIconCardComponentProps) {
  const [isSelected, setIsSelected] = useState<boolean | undefined>(props.isSelected);

  const [minYear, setMinYear] = useState<number | undefined>(props.minYear);
  const [maxYear, setMaxYear] = useState<number | undefined>(props.maxYear);
  const [fromYear, setFromYear] = useState<number>(props.fromYear);
  const [toYear, setToYear] = useState<number>(props.toYear);
  const [amount, setAmount] = useState<number>(props.amount);

  const inputRef = useRef<HTMLInputElement>(null);

  function validate(fromYear?: number, toYear?: number) {
    let isValid = true;

    if (props.onValidate) {
      if (fromYear && toYear && fromYear > toYear) {
        isValid = false;
      }
  
      if (fromYear && minYear && fromYear < minYear) {
        isValid = false;
      }
  
      if (toYear && maxYear && toYear > maxYear) {
        isValid = false;
      }
      
      props.onValidate(isValid);
    }    

    return isValid;
  }

  function raiseValuesChanged(fromYear?: number, toYear?: number, amount?: number) {
      validate(fromYear, toYear);

    if (props.onChange && fromYear && toYear && amount !== undefined) {
      props.onChange(
        moment().year(fromYear).toISOString(true),
        moment().year(toYear).toISOString(true),
        amount);
    }
  }

  function onInputFocused(event: FocusEvent<HTMLInputElement>) {
    if (event.target.value.length > 0) {
      selectAllText(event);
    }
  }

  function onFromYearChange(event: ChangeEvent<HTMLInputElement>) {
    setFromYear(event.target.valueAsNumber);
  }

  function onToYearChange(event: ChangeEvent<HTMLInputElement>) {
    setToYear(event.target.valueAsNumber);
  }

  function onAmountChange(amount: number) {
    setAmount(amount);
    raiseValuesChanged(fromYear, toYear, amount);
  }

  function onFromYearBlur() {
    if (props.onFromYearBlur) {
      props.onFromYearBlur();
    }
    raiseValuesChanged(fromYear, toYear, amount);
  }

  function onToYearBlur() {
    if (props.onToYearBlur) {
      props.onToYearBlur();
    }
    raiseValuesChanged(fromYear, toYear, amount);
  }

  const onSelectionChange = () => {
    setIsSelected(!isSelected);
    if (props.allowSelection && props.onSelectionChange) {
      props.onSelectionChange(!isSelected)
    }
  }

  useEffect(() => {
    setMinYear(props.minYear);
  }, [props.minYear]);

  useEffect(() => {
    setMaxYear(props.maxYear);
  }, [props.maxYear]);

  useEffect(() => {
    setFromYear(props.fromYear);
  }, [props.fromYear]);

  useEffect(() => {
    setToYear(props.toYear);
  }, [props.toYear]);

  useEffect(() => {
    setAmount(props.amount);
  }, [props.amount]);

  let cardClassName = "icon-card"
  if (props.allowSelection) {
    cardClassName += " icon-card-selection";
  }
  if (props.allowSelection && isSelected) {
    cardClassName += " icon-card--selected";
  }
  cardClassName += ` ${props.cardClassName}`;

  let emptyClassName = "";
  if (!amount || amount === 0) {
    emptyClassName = "empty";
  }

  const fromYearTabindex = props.tabIndex ?? -1;
  let lastTabIndex = fromYearTabindex;
  const toYearTabindex = lastTabIndex + 1;
  lastTabIndex = toYearTabindex;
  const moneyInputTabIndex = lastTabIndex + 1;

  return (
    <div
      className={cardClassName}
      onClick={onSelectionChange}>
      <div className={`icon-card-type`}>
        {!props.hideIcon && <label className={`icon-card-icon ${props.iconClassName}`}></label>}
        <div className="icon-card-value">
          <span dangerouslySetInnerHTML={{ __html: props.titleLabel }}></span>
        </div>
      </div>
      {!props.displaySimple && <>
        <span className="icon-card-total" dangerouslySetInnerHTML={{ __html: props.valueLabel }}></span>
        <div className="icon-card-range">
          <input
            tabIndex={fromYearTabindex}
            onFocus={onInputFocused}
            className="icon-card-input"
            type="number"
            min={minYear}
            max={maxYear}
            step={1}
            value={fromYear > 0 ? fromYear : ""}
            placeholder="yyyy"
            onChange={onFromYearChange}
            onBlur={onFromYearBlur}
          />
          <div>-</div>
          <input
            tabIndex={toYearTabindex}
            onFocus={onInputFocused}
            className="icon-card-input"
            type="number"
            min={minYear}
            max={maxYear}
            step={1}
            value={toYear > 0 ? toYear : ""}
            placeholder="yyyy"
            onChange={onToYearChange}
            onBlur={onToYearBlur}
          />
        </div>
        <label className="icon-card-total">{props.totalLabel}</label>
        <MoneyInput
          tabIndex={moneyInputTabIndex}
          placeholder={props.valuePlaceholder}
          className={`icon-card-input ${emptyClassName}`}
          inputNumber={amount}
          currencyConfig={props.currencyConfig}
          currencyFormatter={defaultCurrencyInputFormatter}
          showCurrencySymbol={true}
          onChange={onAmountChange}
          onBlur={props.onAmountBlur}
          ref={inputRef}
          autoWidth={true}
          maxValue={9999999}
        />
      </>}
    </div>
  );
}

export default PreserveCapitalIconCardComponent;
