import { GetChildProperties } from "@flexfront/utils";
import { useComponentIcon } from "../hooks/useComponentIcon";
import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from "../hooks/useTrackEvent";
import { useDeviceBounds } from "../hooks/useDeviceBounds";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { savePersonal, selectPersonalState } from "../store/personal.slice";
import { GenderTypeLabel, Personal } from "@flexfront/models";
import { ChangeEvent, ReactNode, createContext, useContext, useEffect, useState } from "react";
import Dropdown, { Option } from 'react-dropdown';
import { useComponentVisibility } from "../hooks/useComponentVisibility";
import { addErrorMessage, removeErrorMessage } from "../store/notifications/notifications.slice";
import { NotificationType } from "../store/notifications/notifications.state";
import { ThisIsMeComponentLabels } from "../labels/this-is-me-component.labels";
import { SwiperClass } from "swiper/react";

export interface PersonalInfoContextProps {
    children: ReactNode;
    labels: ThisIsMeComponentLabels;
    insideMenu?: boolean;
    confirm?: string;
    tabOrder?: number;
    closeMenu?: () => void;
    ctaClick?: () => void;
    onRequiresUpdate?: () => void;
}

interface PersonalInfoFunctionality {
    labels: ThisIsMeComponentLabels;
    insideMenu?: boolean;
    confirm?: string;
    ctaClick?: () => void;
    isHorizontallyCompact: boolean;
    isNameHidden?: boolean,
    nameTabindex: number,
    name: string,
    handleNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleNameBlur: () => void;
    genderTabindex: number;
    gender: Option;
    handleGenderChange: (arg: Option, ignoreIsHorizontallyCompact?: boolean) => void;
    handleDropdownFocus: (dropdownRef: React.RefObject<Dropdown>) => void;
    genderOptions: {value:string,label:string}[];
    allowAgeInput: boolean;
    hasError: boolean;
    ageTabIndex: number;
    MIN_AGE: number;
    MAX_AGE: number;
    age: number;
    handleAgeChange: (event: ChangeEvent<HTMLInputElement>, shouldTrackEvent?: boolean, ignoreIsHorizontallyCompact?: boolean) => void;
    handleAgeBlur: () => void;
    setAllowAgeInput: React.Dispatch<React.SetStateAction<boolean>>;
    onSave: React.MouseEventHandler<HTMLButtonElement> | undefined;
    setToDefault: React.MouseEventHandler<HTMLButtonElement> | undefined;
    thisIsMeIcon: string | undefined;
    ageIcon: string | undefined;
    genderIcon: string | undefined;
    maleIcon: string | undefined;
    femaleIcon: string | undefined;
    opendropdown: boolean;
    setopendropdown: React.Dispatch<React.SetStateAction<boolean>>;
    ageArray: number[];
    handleAgeDropdownChange: (age:number) => void;
    setInstance: React.Dispatch<React.SetStateAction<SwiperClass | null>>;
}

const PersonalInfoContext = createContext<PersonalInfoFunctionality | undefined>(undefined);

export const usePersonalInfoContext = (): PersonalInfoFunctionality => {
    const context = useContext(PersonalInfoContext);
    if (!context) {
        throw new Error('usePersonalInfoContext must be used within a PersonalInfoProvider');
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useContext(PersonalInfoContext)!;
};

export const PersonalInfoProvider: React.FC<PersonalInfoContextProps> = (props: PersonalInfoContextProps) => {

    const MIN_AGE = 18;
    const MAX_AGE = 117;

    const { isHorizontallyCompact } = useDeviceBounds();
    const trackEvent = useTrackEvent();

    const thisIsMeIcon = useComponentIcon("THIS_IS_ME");
    const ageIcon = useComponentIcon("I_AM_AGE_ICON");
    const genderIcon = useComponentIcon("I_AM_GENDER_ICON");
    const maleIcon = useComponentIcon("I_AM_MALE_ICON");
    const femaleIcon = useComponentIcon("I_AM_FEMALE_ICON");

    const personal = useAppSelector(selectPersonalState);
    const dispatch = useAppDispatch();

    const genderOptions = GetChildProperties<GenderTypeLabel>(props.labels.PERSONAL.GENDER_TYPES).map((gender) => ({
        value: gender.TYPE,
        label: gender.DISPLAY,
    }));

    const [name, setName] = useState<string>(personal.name);
    const [age, setAge] = useState<number>(Number(personal.age));
    const [gender, setGender] = useState<Option>(genderOptions[1]);
    const [instance, setInstance] = useState<SwiperClass | null>(null);
    
    const [hasError, setHasError] = useState(false);
    const [opendropdown, setopendropdown] = useState(false);
    const [allowAgeInput, setAllowAgeInput] = useState(false);
    const isNameHidden = useComponentVisibility('THIS_IS_ME_NAME');
    const ageArray = [];

    for (let index = MIN_AGE; index < MAX_AGE; index++) {
        ageArray.push(index);
    }

    function handleLocalPersonalChanges(newPersonal: Personal) {
        if (newPersonal.age >= MIN_AGE && newPersonal.age <= MAX_AGE && props.onRequiresUpdate) {
            dispatch(savePersonal(newPersonal));
            props.onRequiresUpdate();
        }
    }

    function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
        setName(event.target.value);
        if (!isHorizontallyCompact) {
            handleLocalPersonalChanges({name: event.target.value, age: age, gender: gender.value});
        }
    }

    function handleNameBlur() {
        trackEvent({ category: trackEventCategories.THIS_IS_ME, action: trackEventActions.NAME, name: personal.name });
    }

    function handleAgeDropdownChange(age:number){
        age < MIN_AGE || age > MAX_AGE ? setHasError(true) : setHasError(false);
        setAge(age);
        if (!isHorizontallyCompact) {
            handleLocalPersonalChanges({name: name, age: age, gender: gender.value});
        }
        setopendropdown(!opendropdown);
        trackEvent({ category: trackEventCategories.THIS_IS_ME, action: trackEventActions.CLICK, name: trackEventNames.AGE, value: age });
    }
    
    function handleAgeChange(event: ChangeEvent<HTMLInputElement>, shouldTrackEvent = false, ignoreIsHorizontallyCompact = false) {
        const inputAge=Number(event.target.value);
        inputAge>9 && inputAge < MIN_AGE || inputAge > MAX_AGE ? setHasError(true) : setHasError(false);
        setAge(inputAge);
        if (ignoreIsHorizontallyCompact || !isHorizontallyCompact) {
            handleLocalPersonalChanges({name: name, age: inputAge, gender: gender.value});
        }
        if (shouldTrackEvent) {
            trackEvent({ category: trackEventCategories.THIS_IS_ME, action: trackEventActions.INPUT, name: trackEventNames.AGE, value: inputAge });
        }
    }

    function handleGenderChange(genderOption: any, ignoreIsHorizontallyCompact = false) {
        setGender(genderOption);
        if (ignoreIsHorizontallyCompact || !isHorizontallyCompact) {
            handleLocalPersonalChanges({name: name, age: age, gender: genderOption.value});
        }
        trackEvent({ category: trackEventCategories.THIS_IS_ME, action: trackEventActions.GENDER, name: genderOption.value });
    }

    function handleDropdownFocus(dropdownRef: React.RefObject<Dropdown>) {
        if (dropdownRef.current) {
            const dropdownRootRef = (dropdownRef.current as any).dropdownRef;
            if (dropdownRootRef && dropdownRootRef.current) {
                dropdownRootRef.current.tabIndex = genderTabindex;
                dropdownRootRef.current.focus();
            }
        }
    }

    function handleAgeBlur() {
        trackEvent({ category: trackEventCategories.THIS_IS_ME, action: trackEventActions.INPUT, name: trackEventNames.AGE, value: age });
        setHasError(age < MIN_AGE || age > MAX_AGE);
        setAllowAgeInput(!allowAgeInput);
    }

    function onSave() {
        trackEvent({ category: trackEventCategories.THIS_IS_ME, action: trackEventActions.CLICK, name: trackEventNames.SAVE });
        handleLocalPersonalChanges({name: name, age: age, gender: gender.value});
        if (props.closeMenu) {
            props.closeMenu();
        }
    }

    const ctaClick = () => {
        trackEvent({ category: trackEventCategories.LANDING, action: trackEventActions.CLICK, name: trackEventNames.CTA });
        handleLocalPersonalChanges({name: name, age: age, gender: gender.value});
        if (props.ctaClick) {
            props.ctaClick();
        }
    }

    function onClose() {
        trackEvent({ category: trackEventCategories.THIS_IS_ME, action: trackEventActions.CLICK, name: trackEventNames.CLOSE });
        
        if (props.closeMenu) {
            props.closeMenu();
        }
    }
    
    function setToDefault() {
        handleLocalPersonalChanges({name:'',age:35,gender:'F'});
        onClose();
    }

    useEffect(() => {
        setName(personal.name);
        setAge(personal.age);
        const selectedGenderOption = genderOptions.find((_) => _.value === personal.gender);
        if (selectedGenderOption) {
            setGender(selectedGenderOption);
        }
    }, [personal])

    useEffect(() => {
        const selectedGenderOption = genderOptions.find((_) => _.value === personal.gender);
        if (selectedGenderOption) {
            setGender(selectedGenderOption);
        }
    }, [props.labels.PERSONAL.GENDER_TYPES]);

    useEffect(() => {

        if (hasError) {
            const message=age < MIN_AGE ? props.labels.PERSONAL.MIN_AGE_ERROR_MESSAGE : props.labels.PERSONAL.MAX_AGE_ERROR_MESSAGE;
            dispatch(addErrorMessage({type: NotificationType.AGE_ERROR, message: message}));
        }else {
            dispatch(
                removeErrorMessage(NotificationType.AGE_ERROR)
            );
        }
    }, [hasError,age,props.labels]);

    const nameTabindex = +`${props.tabOrder}1`;
    let lastTabIndex = nameTabindex; 
    const genderTabindex = lastTabIndex +1;
    lastTabIndex = genderTabindex;
    const ageTabIndex = lastTabIndex + 1;

    const contextValue = {
        labels: props.labels,
        insideMenu: props.insideMenu,
        confirm: props.confirm,
        ctaClick: ctaClick,
        isHorizontallyCompact: isHorizontallyCompact,
        isNameHidden: isNameHidden,
        nameTabindex: nameTabindex,
        name: name,
        handleNameChange: handleNameChange,
        handleNameBlur: handleNameBlur,
        genderTabindex: genderTabindex,
        gender:gender,
        handleGenderChange: handleGenderChange,
        handleDropdownFocus: handleDropdownFocus,
        genderOptions: genderOptions,
        allowAgeInput: allowAgeInput,
        hasError: hasError,
        ageTabIndex: ageTabIndex,
        MIN_AGE: MIN_AGE,
        MAX_AGE: MAX_AGE,
        age: age,
        handleAgeChange: handleAgeChange,
        handleAgeBlur: handleAgeBlur,
        setAllowAgeInput: setAllowAgeInput,
        onSave: onSave,
        setToDefault: setToDefault,
        thisIsMeIcon: thisIsMeIcon,
        ageIcon: ageIcon,
        genderIcon: genderIcon,
        maleIcon: maleIcon,
        femaleIcon: femaleIcon,
        opendropdown: opendropdown,
        setopendropdown: setopendropdown,
        ageArray: ageArray,
        handleAgeDropdownChange: handleAgeDropdownChange,
        setInstance: setInstance
    };

    return (
        <PersonalInfoContext.Provider value={contextValue}>{props.children}</PersonalInfoContext.Provider>
      );
};