import { useAssetsContext } from "../../../common/providers/assets-provider";
import { getCurrencyFormattedAmount } from "../../../common/shared/locale";

export function IHaveReportComponent() {

    const {
        labels,
        currencyConfig,
    
        assetTypes,
        getAssetStateValue,

        liabilityTypes,
        getLiabilityStateValue,

        shouldRenderLiability,
        isAssetVisible,
    
      } = useAssetsContext();

    return <div className="report-table">

            {/* Headers */}

            <div className="report-row">

                <div className="report-cell report-cell--merged report-cell--header report-cell--title">
                    <label>{labels.TITLE}</label>
                    <label>{labels.ASSET.AMOUNT}</label>
                </div>

            </div>

            {assetTypes.filter(assetType => isAssetVisible(assetType.Type))
                .map((assetType) => {
                return <div
                    key={assetType.Type}
                    className="report-row">

                    <div className="report-cell report-cell--merged">
                        <label>{assetType.DISPLAY}</label>
                        <label>
                            {getCurrencyFormattedAmount(getAssetStateValue(assetType.Type), currencyConfig)} 
                        </label>
                    </div>
                </div>})}

            {liabilityTypes.filter(liabilityType => shouldRenderLiability(liabilityType, false) && isAssetVisible(liabilityType.Type))
                .map((liabilityType) => {
                return <div
                    key={liabilityType.Type}
                    className="report-row">

                    <div className="report-cell report-cell--merged">
                        <label>{liabilityType.DISPLAY}</label>
                        <label>
                            {getCurrencyFormattedAmount(getLiabilityStateValue(liabilityType.Type), currencyConfig)} 
                        </label>
                    </div>
                </div>})}

            <div className="report-row">
                <div className="report-cell report-cell--footer report-cell--title">
                    {labels.ASSET_TOTAL}
                </div>
                <div className="report-cell--footer report-cell--title report-cell--value">
                    {getCurrencyFormattedAmount(
                        assetTypes
                            .filter(assetType => isAssetVisible(assetType.Type))
                            .reduce((sum, current) => sum + getAssetStateValue(current.Type), 0)
                        - liabilityTypes
                            .filter(liabilityType => shouldRenderLiability(liabilityType, false) && isAssetVisible(liabilityType.Type))
                            .reduce((sum, current) => sum + getLiabilityStateValue(current.Type), 0),
                        currencyConfig
                    )}
                </div>
            </div>

        </div>;
}