import { saveDream, saveLegacy, savePreserveCapital, saveRetirement, saveSecureFamily, selectDreamState, selectLegacyState, selectPreserveCapitalState, selectRetirementState, selectSecureFamilyState } from "../../../common/store/goals/goals.slice";
import { useAppDispatch, useAppSelector } from "../../../common/store/hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import RetirementIconCardComponent from "../icon-card-component/retirement-icon-card-component";
import PreserveCapitalIconCardComponent from "../icon-card-component/preserve-capital-icon-card-component";
import IconCardComponent from "../icon-card-component/icon-card-component";
import { InflationComponentLabels } from "../../../common/labels/inflation-modal-component.labels";
import "./inflation-modal-component.scss";
import { useEffect, useState } from "react";
import { getYearFromString } from "@flexfront/ui/react";
import { calculateLifeSpan } from "../../../common/shared/calculateLifeSpan";
import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from "../../../common/hooks/useTrackEvent";
import { useComponentVisibility } from "../../../common/hooks/useComponentVisibility";

export interface InflationModalComponentProps {
    labels: InflationComponentLabels;
    onRequiresUpdate?: () => void;
    onClose?: () => void;
}

export function InflationModalComponent(props: InflationModalComponentProps) {
    const retirement = useAppSelector(selectRetirementState);
    const legacy = useAppSelector(selectLegacyState);
    const dream = useAppSelector(selectDreamState);
    const secureFamily = useAppSelector(selectSecureFamilyState);
    const preserveCapital = useAppSelector(selectPreserveCapitalState);
    const dispatch = useAppDispatch();

    const [hasLegacy, setHasLegacy] = useState<boolean>(false);
    const [hasRetirement, setHasRetirement] = useState<boolean>(false);
    const [hasDream, setHasDream] = useState<boolean>(false);
    const [hasSecureFamily, setHasSecureFamily] = useState<boolean>(false);
    const [hasPreserveCapital, setHasPreserveCapital] = useState<boolean>(false);
    const {currentYear, yearOfDeath} = calculateLifeSpan();

    const trackEvent = useTrackEvent();

    const isRetirementIconHidden = useComponentVisibility("I_WANT_RETIREMENT_ICON");
    const isDreamIconHidden = useComponentVisibility("I_WANT_DREAM_ICON");
    const isLegacyIconHidden = useComponentVisibility("I_WANT_LEGACY_ICON");
    const isPreserveCapitalIconHidden = useComponentVisibility("I_WANT_PRESERVE_CAPITAL_ICON");
    const isSecureFamilyIconHidden = useComponentVisibility("I_WANT_SECURE_FAMILY_ICON");

    const onLegacySelectionChange = (isSelected: boolean) => {
        trackEvent({ category: trackEventCategories.INFLATION, action: trackEventActions.CLICK, name: trackEventNames.LEGACY_PAYOUT, value: +isSelected });
        dispatch(saveLegacy({ ...legacy, nominal: !isSelected }));
        if (props.onRequiresUpdate) props.onRequiresUpdate();
    }

    const onRetirementSelectionChange = (isSelected: boolean) => {
        trackEvent({ category: trackEventCategories.INFLATION, action: trackEventActions.CLICK, name: trackEventNames.RETIREMENT_AGE, value: +isSelected });
        dispatch(saveRetirement({ ...retirement, nominal: !isSelected }));
        if (props.onRequiresUpdate) props.onRequiresUpdate();
    }

    const onDreamSelectionChange = (isSelected: boolean) => {
        trackEvent({ category: trackEventCategories.INFLATION, action: trackEventActions.CLICK, name: trackEventNames.DREAM_YEAR, value: +isSelected });
        dispatch(saveDream({ ...dream, nominal: !isSelected }));
        if (props.onRequiresUpdate) props.onRequiresUpdate();
    }

    const onSecureFamilySelectionChange = (isSelected: boolean) => {
        trackEvent({ category: trackEventCategories.INFLATION, action: trackEventActions.CLICK, name: trackEventNames.SECURE_FAMILY_YEAR, value: +isSelected });
        dispatch(saveSecureFamily({ ...secureFamily, nominal: !isSelected }));
        if (props.onRequiresUpdate) props.onRequiresUpdate();
    }

    const onPreserveCapitalSelectionChange = (isSelected: boolean) => {
        trackEvent({ category: trackEventCategories.INFLATION, action: trackEventActions.CLICK, name: trackEventNames.PRESERVE_CAPITAL_AMOUNT, value: +isSelected });
        dispatch(savePreserveCapital({ ...preserveCapital, nominal: !isSelected }));
        if (props.onRequiresUpdate) props.onRequiresUpdate();
    }

    useEffect(() => {
        setHasRetirement(retirement.retirementAge > 0 && retirement.retirementMonthlyPayout > 0);
      }, [retirement]);
    
      useEffect(() => {
        setHasDream(dream.year >= currentYear && dream.amount > 0);
      }, [dream, currentYear]);
    
      useEffect(() => {
        setHasSecureFamily(secureFamily.year >= currentYear && secureFamily.amount > 0);
      }, [secureFamily, currentYear]);
    
      useEffect(() => {
        setHasLegacy(legacy.amount > 0);
      }, [legacy]);
    
      useEffect(() => {
        setHasPreserveCapital(
          preserveCapital.amount > 0 && preserveCapital.startDate !== undefined && preserveCapital.endDate !== undefined
        );
      }, [preserveCapital]);

    return <div className="inflation-modal widget--slider">
        <div className="inflation-modal-title-bar">
            <span className="inflation-modal-title">{props.labels.TITLE}</span>
            <span className="inflation-modal-title-icon button-close icon-times" onClick={props.onClose} />
        </div>
        <span className="inflation-modal-desc" dangerouslySetInnerHTML={{ __html: props.labels.DESC }}></span>
        <span className="inflation-modal-select">{props.labels.SELECTION}</span>
        <div className="widget--slider">
            <div className="swiper-component">
                <Swiper
                    spaceBetween={4}
                    slidesPerView={4}
                    breakpoints={{
                        768: {
                        slidesPerView: 4,
                        pagination: { clickable: true, enabled: true }
                        },
                        1440: {
                        slidesPerView: 5,
                        pagination: { clickable: true, enabled: true }
                        }
                    }}
                    pagination={{ clickable: true, enabled: true }}
                    modules={[Pagination]}
                >
                    {hasRetirement && (
                    <SwiperSlide>
                        <RetirementIconCardComponent
                            cardClassName="icon-card--solid icon-card--simple"
                            valuePlaceholder={
                                props.labels.GOALS.RETIREMENT.AMOUNT_PLACEHOLDER
                            }
                            agePlaceholder={props.labels.GOALS.RETIREMENT.AGE_PLACEHOLDER}
                            titleLabel={props.labels.GOALS.RETIREMENT.TITLE}
                            valueLabel={props.labels.WHEN}
                            totalLabel={props.labels.GOALS.RETIREMENT.AMOUNT}
                            retirementAge={retirement.retirementAge}
                            retirementMonthlyPayout={retirement.retirementMonthlyPayout}
                            iconClassName="icon-pension"
                            hideIcon={isRetirementIconHidden}
                            allowSelection={true}
                            isSelected={retirement.nominal === false}
                            displaySimple={true}
                            onSelectionChange={onRetirementSelectionChange}
                        />
                    </SwiperSlide>)}
                    {hasLegacy &&(
                        <SwiperSlide>
                        <IconCardComponent
                            cardClassName="icon-card--solid icon-card--simple"
                            valueLabel={props.labels.GOALS.LEGACY.TITLE}
                            valuePlaceholder={props.labels.GOALS.LEGACY.AMOUNT_PLACEHOLDER}
                            totalLabel={props.labels.GOALS.GOAL.AMOUNT}
                            iconClassName="icon-legacy"
                            hideIcon={isLegacyIconHidden}
                            inputNumber={legacy.amount}
                            displayNumber={legacy.amount}
                            allowSelection={true}
                            isSelected={legacy.nominal === false}
                            displaySimple={true}
                            onSelectionChange={onLegacySelectionChange}
                        />
                    </SwiperSlide>
                    )}
                    {hasDream && (
                        <SwiperSlide>
                        <RetirementIconCardComponent
                            cardClassName="icon-card--solid icon-card--simple"
                            titleLabel={props.labels.GOALS.GOAL.TITLE}
                            valueLabel={props.labels.WHEN}
                            valuePlaceholder={props.labels.GOALS.GOAL.AMOUNT_PLACEHOLDER}
                            agePlaceholder={props.labels.GOALS.GOAL.YEAR_PLACEHOLDER}
                            totalLabel={props.labels.GOALS.GOAL.AMOUNT}
                            retirementAge={dream.year}
                            retirementMonthlyPayout={dream.amount}
                            iconClassName="icon-dream"
                            hideIcon={isDreamIconHidden}
                            allowSelection={true}
                            isSelected={dream.nominal === false}
                            displaySimple={true}
                            onSelectionChange={onDreamSelectionChange}
                        />
                    </SwiperSlide>
                    )}
                    {hasPreserveCapital && (
                        <SwiperSlide>
                        <PreserveCapitalIconCardComponent
                            cardClassName="icon-card--solid icon-card--simple"
                            titleLabel={props.labels.GOALS.PRESERVE_CAPITAL?.TITLE}
                            valueLabel={props.labels.WHEN}
                            valuePlaceholder={props.labels.GOALS.PRESERVE_CAPITAL?.AMOUNT_PLACEHOLDER}
                            totalLabel={props.labels.GOALS.PRESERVE_CAPITAL?.AMOUNT}
                            iconClassName="icon-piggy-bank"
                            hideIcon={isPreserveCapitalIconHidden}
                            fromYear={getYearFromString(preserveCapital.startDate) ?? currentYear}
                            toYear={getYearFromString(preserveCapital.endDate) ?? yearOfDeath}
                            amount={preserveCapital.amount}
                            allowSelection={true}
                            isSelected={preserveCapital.nominal === false}
                            displaySimple={true}
                            onSelectionChange={onPreserveCapitalSelectionChange}
                        />
                    </SwiperSlide>
                    )}
                    {hasSecureFamily && (
                        <SwiperSlide>
                        <RetirementIconCardComponent
                            cardClassName="icon-card--solid icon-card--simple"
                            titleLabel={props.labels.GOALS.SECURE_FAMILY.TITLE}
                            valueLabel={props.labels.WHEN}
                            valuePlaceholder={props.labels.GOALS.SECURE_FAMILY.AMOUNT_PLACEHOLDER}
                            agePlaceholder={props.labels.GOALS.SECURE_FAMILY.YEAR_PLACEHOLDER}
                            totalLabel={props.labels.GOALS.SECURE_FAMILY.AMOUNT}
                            retirementAge={secureFamily.year}
                            retirementMonthlyPayout={secureFamily.amount}
                            iconClassName="icon-secure-family"
                            hideIcon={isSecureFamilyIconHidden}
                            allowSelection={true}
                            isSelected={secureFamily.nominal === false}
                            displaySimple={true}
                            onSelectionChange={onSecureFamilySelectionChange}
                        />
                    </SwiperSlide>
                    )}
                </Swiper>
               
            </div>
            {!hasRetirement && !hasLegacy && !hasDream && !hasPreserveCapital && !hasSecureFamily && (
                    <div className="inflation-modal-desc">{props.labels.EMPTY}</div>
            )}
        </div>
    </div>;
}