import { useEffect, useState } from "react";

export interface ModalSelectionComponentProps {
    selectedLabel: string;
    isOpen: boolean;
    onClick?: (isOpen: boolean) => void;
}

export function ModalSelectionComponent(props: ModalSelectionComponentProps) {
    const [isOpen, setIsOpen] = useState<boolean>(props.isOpen);

    const onClick = () => {
        setIsOpen(!isOpen);
        if (props.onClick) {
            props.onClick(!isOpen);
        }
    }

    useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props.isOpen]);

    return <div
        className="Dropdown-root Dropdown-control Dropdown-control--button Dropdown-control--standalone"
        onClick={onClick}>
        <div className="Dropdown-placeholder is-selected">
            {props.selectedLabel}
        </div>
        <div className="Dropdown-arrow-wrapper">
            <span className={isOpen ? 'icon-up-arrow' : 'icon-down-arrow'}></span>
        </div>
    </div>;
}