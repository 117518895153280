import { Swiper, SwiperSlide } from 'swiper/react';
import { useResultContext } from '../../../common/providers/result-provider';
import { Pagination } from 'swiper/modules';
import GoalAchievementComponent from '../goal-achievement-component/goal-achievement-component';
import './results-component.scss';

export function ResultsComponent() {

  const {
    labels, 
    isHorizontallyCompact, 
    hasRetirement,
    hasDream,
    currencyConfig, 
    simulation,
    retirementIcon,
    dreamIcon
  } = useResultContext();

  const content = (
    <>
      {hasRetirement && (
        <SwiperSlide>
          <GoalAchievementComponent
            title={labels.RETIREMENT_GOAL}
            shortfallLabel={labels.SHORTFALL}
            achievementFraction={simulation.retirementResult.achievementFraction}
            shortfall={simulation.retirementResult.shortfall}
            icon={retirementIcon}
            currencyConfig={currencyConfig} />
        </SwiperSlide>
      )}
      {hasDream && (
        <SwiperSlide>
          <GoalAchievementComponent
            title={labels.DREAM_GOAL}
            shortfallLabel={labels.SHORTFALL}
            achievementFraction={simulation.dreamResult.achievementFraction}
            shortfall={simulation.dreamResult.shortfall}
            icon={dreamIcon}
            currencyConfig={currencyConfig} />
        </SwiperSlide>
      )}
      {!hasRetirement && !hasDream  && (
        <label className="results-empty">{labels.EMPTY}</label>
      )}
    </>
  );

  return (
    <div className="quirion-results-component-goals">
      {isHorizontallyCompact ? (
        <div className="swiper-component">
          <Swiper
            spaceBetween={6}
            slidesPerView={3}
            pagination={{ clickable: true, enabled: true }}
            modules={[Pagination]}
          >
            {content}
          </Swiper>
        </div>
      ) : (
        content
      )}
    </div>
  );
}

export default ResultsComponent;
