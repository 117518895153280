import { useEffect, useState } from "react";
import { useAppSelector } from "../../../common/store/hooks";
import { selectSimulationState } from "../../../common/store/simulation/simulation.slice";
import { useCurrencyConfig } from "../../../common/hooks/useCurrencyConfig";
import { NetAssetsReportComponentLabels } from "./net-assets-report-component.labels";
import { getCurrencyFormattedAmount } from "../../../common/shared/locale";

export interface NetAssetsReportComponentProps {
    labels: NetAssetsReportComponentLabels;
}

export function NetAssetsReportComponent(props: NetAssetsReportComponentProps) {

    
    const simulation = useAppSelector(selectSimulationState);
    const currencyConfig = useCurrencyConfig();

    function isExpectedTotalWealthPopulated() {
        return simulation.totalWealth
            && simulation.totalWealth.percentile50
            && simulation.totalWealth.percentile50.length > 0;
    }

    function getStartAssetsIncomePeriod() {
        if (isExpectedTotalWealthPopulated()) {
            return simulation.totalWealth.percentile50[0];
        }
        return 0;
    }

    function getEndAssetsIncomePeriod() {
        if (isExpectedTotalWealthPopulated() && simulation.activeQuarters) {
            return simulation.totalWealth.percentile50[simulation.activeQuarters - 1];
        }
        return 0;
    }

    function getEndAssetsRetirementPeriod() {
        if (isExpectedTotalWealthPopulated() && simulation.totalQuarters) {
            return simulation.totalWealth.percentile50[simulation.totalQuarters];
        }
        return 0;
    }

    const [startAssetsIncomePeriod, setStartAssetsIncomePeriod] = useState<number>(getStartAssetsIncomePeriod());
    const [endAssetsIncomePeriod, setEndAssetsIncomePeriod] = useState<number>(getEndAssetsIncomePeriod());
    const [endAssetsRetirementPeriod, setEndAssetsRetirementPeriod] = useState<number>(getEndAssetsRetirementPeriod());

    useEffect(() => {
        setStartAssetsIncomePeriod(getStartAssetsIncomePeriod());
    }, [simulation.totalWealth]);

    useEffect(() => {
        setEndAssetsIncomePeriod(getEndAssetsIncomePeriod());
    }, [simulation.totalWealth, simulation.activeQuarters]);

    useEffect(() => {
        setEndAssetsRetirementPeriod(getEndAssetsRetirementPeriod());
    }, [simulation.totalWealth, simulation.totalQuarters]);

    return <div className="report-table">

        {/* Headers */}

        <div className="report-row">

            <div className="report-cell report-cell--header report-cell--wide report-cell--title">
                {props.labels.NET_ASSETS.TITLE}
            </div>

            <div className="report-cell report-cell--header report-cell--title report-cell--value">
                {props.labels.NET_ASSETS.START}
            </div>

            <div className="report-cell report-cell--header report-cell--title report-cell--value">
                {props.labels.NET_ASSETS.END}
            </div>

        </div>

        <div className="report-row">

            <div className="report-cell report-cell--wide">
                {props.labels.INCOME_PERIOD}
            </div>

            <div className="report-cell report-cell--value">
                {getCurrencyFormattedAmount(startAssetsIncomePeriod, currencyConfig)}
            </div>

            <div className="report-cell report-cell--value">
                {getCurrencyFormattedAmount(endAssetsIncomePeriod, currencyConfig)}
            </div>

        </div>

        <div className="report-row">

            <div className="report-cell report-cell--wide">
                {props.labels.RETIREMENT_PERIOD}
            </div>

            <div className="report-cell report-cell--value">
                {getCurrencyFormattedAmount(endAssetsIncomePeriod, currencyConfig)}
            </div>

            <div className="report-cell report-cell--value">
                {getCurrencyFormattedAmount(endAssetsRetirementPeriod, currencyConfig)}
            </div>

        </div>

    </div>;
}