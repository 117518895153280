import { useEffect, useState } from "react";
import { useAppSelector } from "../store/hooks";
import { selectTenantConfig } from "../store/tenant-config/tenant-config.slice";

export const useTenantLogo = () => {
    const [tenantLogo, setTenantLogo] = useState<string>();
    const tenantConfig = useAppSelector(selectTenantConfig);
    useEffect(() => {
        setTenantLogo(tenantConfig.logo);
    }, [tenantConfig.logo]);
    
    return tenantLogo;
};

export const useTenantThisIsMeMobileLogo = () => {
    const [tenantLogo, setTenantLogo] = useState<string>();
    const tenantConfig = useAppSelector(selectTenantConfig);
    useEffect(() => {
        setTenantLogo(tenantConfig.thisIsMeMobileLogo);
    }, [tenantConfig.thisIsMeMobileLogo]);
    
    return tenantLogo;
};

export const useTenantMobileLogo = () => {
    const [tenantLogo, setTenantLogo] = useState<string>();
    const tenantConfig = useAppSelector(selectTenantConfig);
    useEffect(() => {
        setTenantLogo(tenantConfig.mobileLogo);
    }, [tenantConfig.mobileLogo]);
    
    return tenantLogo;
};

export const useReportLogo = () => {
    const [tenantLogo, setTenantLogo] = useState<string>();
    const tenantConfig = useAppSelector(selectTenantConfig);
    useEffect(() => {
        setTenantLogo(tenantConfig.reportLogo);
    }, [tenantConfig.reportLogo]);
    
    return tenantLogo;
};