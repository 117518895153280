export interface CurrencyFormatter {
  (value: number | null | undefined, currencyConfig?: CurrencyConfig): string;
}

export interface CurrencyInputFormat {
  symbol: string | undefined;
  value: string;
}

export interface CurrencyInputFormatter {
  (value: number | null | undefined, currencyConfig?: CurrencyConfig): CurrencyInputFormat;
}

export interface CurrencyConfig {
  locale: string;
  currency: string;
  groupSeparator: string;
  decimalSeparator: string;
  hasSymbolSuffix?: boolean;
}

export const getCurrencyFormat = (currencyConfig: CurrencyConfig, value: number | null | undefined) => {
  const formattedParts = getCurrencyNumberFormatParts(
    currencyConfig,
    value);

  return getCurrencyFormatFromParts(currencyConfig, formattedParts);
}

export const getCurrencyFormatWithoutSymbol = (currencyConfig: CurrencyConfig, value: number | null | undefined) => {
  const formattedParts = getCurrencyNumberFormatParts(
    currencyConfig,
    value);

  return getCurrencyFormatFromParts(currencyConfig, formattedParts, [], ["currency", "literal"]);
}

export const getCurrencyFormatFromParts = (
  currencyConfig: CurrencyConfig,
  formattedParts: Intl.NumberFormatPart[],
  inclusions?: string[] | undefined,
  exclusions?: string[] | undefined) => {

    let formattedValue = "";

    if (inclusions && inclusions.length > 0) {
      formattedParts = formattedParts
        .filter(p => inclusions.indexOf(p.type) !== -1);
    }

    if (exclusions && exclusions.length > 0) {
      formattedParts = formattedParts
        .filter(p => exclusions.indexOf(p.type) === -1);
    }

    if (formattedParts && formattedParts.length > 0) {
      formattedValue = formattedParts
        .map(p => p.type === "group" ? currencyConfig.groupSeparator : p.value)
        .reduce((v, part) => v + part);
    }

    return formattedValue;
}

export const getCurrencyNumberFormatParts = (currencyConfig: CurrencyConfig, value: number | null | undefined) => {
  let numberFormatParts: Intl.NumberFormatPart[] = [];

  if (value !== undefined && value !== null && !isNaN(value)) {
    numberFormatParts = new Intl.NumberFormat(currencyConfig.locale, {
      style: 'currency',
      currency: currencyConfig.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).formatToParts(value);
  }

  return numberFormatParts;
};
